<template>
  <div class="block">
    <div class="flex items-center">
      <BaseCheckbox
        :id="goodsServiceCode"
        :name="goodsServiceCode"
        :model-value="checked"
        @update:model-value="onChange"
      />
      <label
        :for="goodsServiceCode"
        class="text-grayAlpha80 ml-2 flex-1 cursor-pointer py-2 text-left text-sm font-normal tracking-wider"
      >
        {{ goodsServiceName }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { KlassId } from '@/types';
import { KlassSelectStoreKey } from '@/composables/injectionKeys';
import { goodsServices } from '@/constants/goodsServices';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import BaseCheckbox from '@/components/common/BaseCheckbox.vue';
import { KlassSelectStore } from '@/composables/useKlassSelect';

interface Props {
  klassId: KlassId;
  goodsServiceId: string;
}

const props = defineProps<Props>();

const {
  klassesForSearch,
  selectForSearch,
  deselectForSearch,
  selectForSubmit,
  deselectForSubmit,
} = inject(KlassSelectStoreKey) as KlassSelectStore;

useRoute();
useRouter();

const { locale } = useI18n({ useScope: 'global' });
const lang = computed(() => (locale.value === 'ja' ? 'ja' : 'en'));
const goodsServiceName = computed(
  // @ts-ignore
  () => goodsServices[props.klassId][props.goodsServiceId].name[lang.value],
);
// @ts-ignore
const goodsServiceCode: string =
  goodsServices[props.klassId][props.goodsServiceId].code;

const checked = computed(() => {
  const goodsServiceNamesForKlass = klassesForSearch.value[props.klassId];
  if (!goodsServiceNamesForKlass) {
    return false;
  }
  return goodsServiceNamesForKlass.includes(props.goodsServiceId);
});

function onChange () {
  if (!checked.value) {
    onSelect();
  } else {
    onDeselect();
  }
}

function onSelect () {
  selectForSearch(props.klassId, props.goodsServiceId);
  selectForSubmit(props.klassId, props.goodsServiceId);
}

function onDeselect () {
  deselectForSearch(props.klassId, props.goodsServiceId);
  deselectForSubmit(props.klassId, props.goodsServiceId);
}
</script>
