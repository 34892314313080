<template>
  <div>
    <FormItemWrapper title="商標">
      <FilePicker
        name="trademark-image"
        :image-url="imageDataURL"
        :accepts="['image/jpeg']"
        :max-size="5000000"
        @update:model-value="onImageSelect"
        @error:file-type="onImageTypeError"
        @error:file-size="onImageSizeError"
      />
      <div class="text-grayAlpha80 mt-2 text-sm">
        ※ 5MB以下、縦横それぞれ1180px以内のjpgファイルを選択してください。
      </div>
    </FormItemWrapper>
  </div>
  <div class="mt-6 flex cursor-pointer items-center">
    <div class="mr-4 text-base tracking-wider">
      ロゴ内の文字
    </div>
    <BaseRadioButtonGroup
      :options="existsTextOptions"
      :value="existsTextOptionsValue"
      @update:model-value="onExistsTextChange"
    />
  </div>
  <div
    v-if="existsText"
    class="mt-6 sm:grid sm:grid-cols-2 sm:grid-rows-1 sm:gap-x-4"
  >
    <FormTextInput
      title="文字"
      name="image-text"
      placeholder="例：Cotobox"
      :required="false"
      :shows-required-label="false"
      :shows-error="false"
      :model-value="imageText"
      @update:model-value="setTrademarkText"
    />
    <FormTextInput
      class="mt-4 sm:mt-0"
      title="よみがな(権利範囲の対象ではありません)"
      name="yomi"
      placeholder="例：ことぼっくす"
      :required="false"
      :shows-required-label="false"
      :shows-error="false"
      :model-value="imageYomi"
      @update:model-value="setImageYomi"
    >
      <template #default>
        <div class="ml-1 h-[20px] mt-[-2px]">
          <HelpLink
            title=""
            @click="updateShowsHelpPronunciation(true)"
          />
        </div>
      </template>
    </FormTextInput>
  </div>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue';
// 状態管理
import { TrademarkSearchStoreKey } from '@/composables/injectionKeys';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
// コンポーネント
import FilePicker from '@/components/common/FilePicker.vue';
import BaseRadioButtonGroup, {
  RadioButtonGroupOption,
} from '@/components/common/BaseRadioButtonGroup.vue';
// ライブラリ
import { useToast } from 'vue-toastification';
import HelpLink from '@/components/common/HelpLink.vue';

defineProps({
  showsHelpPronunciation: Boolean,
});

const emit = defineEmits(['update:showsHelpPronunciation']);
const updateShowsHelpPronunciation = (value: boolean) => emit('update:showsHelpPronunciation', value);

const toast = useToast();

const {
  imageDataURL,
  imageText,
  imageYomi,
  existsText,
  loadImageToken,
  setTrademarkText,
  setImageYomi,
  setExistsText,
} = inject(TrademarkSearchStoreKey) as TrademarkSearchStore;

async function onImageSelect (value: string) {
  const { data: image_token, error } = await loadImageToken(value);
  if (!image_token || error) {
    return toast.error('ロゴ画像のアップロードに失敗しました。');
  }
}

function onImageTypeError () {
  toast.error('5MB以下のJPEGファイルであることを確認して下さい。');
}

function onImageSizeError () {
  toast.error('5MB以下のJPEGファイルであることを確認して下さい。');
}

const existsTextOptions: RadioButtonGroupOption[] = [
  { label: 'あり', value: '1' },
  { label: 'なし', value: '0' },
];

const existsTextOptionsValue = computed(() => (existsText.value ? '1' : '0'));

function onExistsTextChange (value: string) {
  const boolValue = value === '1';
  setExistsText(boolValue);
}
</script>
