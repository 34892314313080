<template>
  <div class="relative h-[450px] bg-white">
    <div class="flex items-center">
      <div :class="actorWrapClass">
        <ActorImageCard actor-name="you" />
        <div
          class="justify-between"
          :class="baseArrowWrapClass"
        >
          <ArrowWithDescription
            direction="right"
            description="出願情報
            入力"
          />
          <ArrowWithDescription
            description="メッセージ
            受取"
            direction="left"
          />
        </div>
      </div>
      <div :class="actorWrapClass">
        <ActorImageCard actor-name="cotobox" />
        <div
          class="justify-between"
          :class="baseArrowWrapClass"
        >
          <ArrowWithDescription
            direction="right"
            description="商標情報"
          />
          <ArrowWithDescription
            description="メッセージ
            送信"
            direction="left"
          />
        </div>
      </div>
      <div :class="actorWrapClass">
        <ActorImageCard actor-name="attorney" />
        <div
          class="justify-end"
          :class="baseArrowWrapClass"
        >
          <ArrowWithDescription
            description="手続き"
            direction="left"
          />
        </div>
      </div>
      <div :class="actorWrapClass">
        <ActorImageCard actor-name="jpo" />
      </div>
    </div>
    <!-- 依頼契約矢印 -->
    <div class="absolute bottom-0 left-[74px] w-[660px] pb-5">
      <div class="h-[63px] w-full bg-contain bg-center bg-no-repeat bg-skip-arrow" />
      <div
        class="text-grayAlpha60 pt-2 text-center text-sm font-bold tracking-wider"
      >
        商標手続き依頼契約
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ActorImageCard from '@/features/top/components/ActorImageCard.vue';
import ArrowWithDescription from '@/features/top/components/ArrowWithDescription.vue';

const baseArrowWrapClass =
  'w-[80px] h-[220px] mx-8 flex flex-col items-stretch';
const actorWrapClass = 'flex items-center';
</script>

<style scoped>
.bg-skip-arrow {
  background-image: url('@/assets/images/creatives/arrow-skip.svg');
}
</style>
