<template>
  <div class="relative">
    <div class="h-[calc(100vh_-_72px)] w-full overflow-y-scroll">
      <div class="bg-bgGray min-h-full px-4 py-6">
        <Tabs
          :model-value="isAIFilter"
          :options="['全て', 'AI絞り込み']"
          @update:model-value="onKlassSelectTypeChange"
        />
        <KlassSelectAll v-if="isAIFilter === 0" />
        <KlassSelectAISearch
          v-if="isAIFilter === 1"
          class="mt-6"
        />
      </div>
    </div>
    <PrimaryButton
      class="fixed left-4 right-4 h-12 w-[calc(100vw_-_32px)] rounded-lg"
      style="bottom: 12px !important"
      title="完了"
      @click="onCloseButtonClick"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, provide } from 'vue';
// 状態管理
import { KlassListStoreKey } from '@/composables/injectionKeys';
import { useKlassList } from '@/composables/useKlassList';
// コンポーネント
import KlassSelectAISearch from '@/components/search/KlassSelectAISearch.vue';
import KlassSelectAll from '@/components/search/KlassSelectAll.vue';

const klassListModel = useKlassList();
provide(KlassListStoreKey, klassListModel);

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function onCloseButtonClick () {
  emit('close');
}

const isAIFilter = ref(0);

function onKlassSelectTypeChange (index: number) {
  isAIFilter.value = index;
}
</script>
