import { defineStore } from 'pinia';
export interface BankAccount {
  bankName: string;
  branchName: string;
  accountType: string;
  accountNumber: string;
  accountHolderName: string;
  amount: number;
  bankPaymentExpiresDate: string;
  invoicePdfUrl: string;
}

export const useBankAccountStore = defineStore({
  id: 'bankAccount',
  state: (): BankAccount => {
    return {
      bankName: '',
      branchName: '',
      accountType: 'saving', // 普通預金
      accountNumber: '',
      accountHolderName: '',
      amount: 0,
      bankPaymentExpiresDate: '',
      invoicePdfUrl: '',
    };
  },
  getters: {
    bankAccount: (state): BankAccount => {
      return state;
    },
  },
  actions: {
    async getBankAccount (paymentId: number) {
      const response = await fetch(
        `/app/api/xmas/payments/${paymentId}/bank_account`,
      );

      if (!response.ok) {
        return;
      }

      const responseJson = await response.json();

      this.bankName = responseJson.bankName;
      this.branchName = responseJson.branchName;
      this.accountNumber = responseJson.accountNumber;
      this.accountHolderName = responseJson.accountHolderName;
      this.amount = responseJson.amount;
      this.bankPaymentExpiresDate = responseJson.dueDate;
      this.invoicePdfUrl = responseJson.invoicePdfUrl;
    },
  },
});
