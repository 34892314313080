<template>
  <div>
    <!-- ヘッダー -->
    <div
      class="mb-4 flex items-center justify-between px-4 pt-6 sm:mx-auto sm:w-[1078px] sm:px-0 sm:pt-10"
    >
      <div class="text-xl font-bold">
        マイページ
      </div>
      <router-link to="/app/notifications">
        <SecondaryButton
          title="お知らせ"
          class="pl-4 pr-5"
          material-icon-name="notifications"
          :disabled="false"
        />
      </router-link>
    </div>
    <div
      v-if="$device.isDesktop"
      class="mx-auto my-0 w-[1078px] px-0 pb-10"
    >
      <!-- アカウント情報 -->
      <ContentBox
        :title="t('profileTitle')"
        class="mb-6"
      >
        <div class="grid grid-cols-2 grid-rows-2 gap-y-3 p-6">
          <div
            class="col-start-1 col-end-2 row-start-1 row-end-3 flex items-baseline text-xl font-bold"
          >
            <div>
              {{
                state.isPersonal
                  ? `${state.lastName} ${state.firstName}`
                  : state.companyName
              }}
            </div>
            <a
              class="text-blue ml-3 text-sm font-normal underline"
              href="/app/users/edit"
            >
              {{ t('profileEdit') }}
            </a>
          </div>
          <div class="col-start-1 col-end-2 row-start-2 row-end-3 flex">
            <div class="mr-4 font-bold">
              {{ t('profileIndustry') }}
            </div>
            <div>{{ state.industry }}</div>
          </div>
          <div class="col-start-2 col-end-3 row-start-2 row-end-3 flex">
            <div class="mr-4 font-bold">
              {{ t('profilePersonName') }}
            </div>
            <div>{{ `${state.lastName} ${state.firstName}` }}</div>
          </div>
        </div>
      </ContentBox>
      <div class="grid justify-end">
        <PrimaryButton
          :title="t('addTrademark')"
          :disabled="false"
          @click="$router.push('/app/search/')"
        />
      </div>
      <MyTrademarkTableDesktop />
    </div>
    <div v-if="$device.isMobile">
      <div class="solid grid border-y border-[#eee] p-4">
        <div class="col-start-1 col-end-3 row-start-1 row-end-2 mb-4 font-bold">
          {{
            state.isPersonal
              ? `${state.lastName} ${state.firstName}`
              : state.companyName
          }}
        </div>
        <a
          class="text-blue col-start-3 col-end-4 row-start-1 row-end-2 text-right text-sm underline"
          href="/app/users/edit"
        >
          {{ t('profileEdit') }}
        </a>
        <div
          class="col-start-1 col-end-2 row-start-2 row-end-3 mb-2 text-sm font-bold"
        >
          {{ t('profileIndustry') }}
        </div>
        <div class="col-start-2 col-end-4 row-start-2 row-end-3 text-sm">
          {{ state.industry }}
        </div>
        <div
          class="col-start-1 col-end-2 row-start-3 row-end-4 text-sm font-bold"
        >
          {{ t('profilePersonName') }}
        </div>
        <div class="col-start-2 col-end-4 row-start-3 row-end-4 text-sm">
          {{ `${state.lastName} ${state.firstName}` }}
        </div>
      </div>
      <div class="flex justify-center bg-[#fbfbfb] p-4">
        <div>
          <SecondaryButton
            :title="t('addTrademark')"
            :disabled="false"
            @click="$router.push('/app/search/')"
          />
        </div>
      </div>
      <MyTrademarkTableMobile />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import ContentBox from '@/components/applyForm/ContentBox.vue';
import {
  APIClientKey,
  ApplicationListKey,
  UserDataKey,
} from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import { inject, onMounted, provide } from 'vue';
import SecondaryButton from '@/components/common/SecondaryButton.vue';
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import MyTrademarkTableDesktop from '@/components/myTrademark/MyTrademarkTableDesktop.vue';
import { useApplicationList } from '@/composables/useApplicationList';
import MyTrademarkTableMobile from '../../../myTrademark/MyTrademarkTableMobile.vue';
import { APIClientInterface } from '@/api/APIClientInterface';
import { useToast } from 'vue-toastification';
import { useHead } from '@vueuse/head';

const { t } = useI18n({
  messages: {
    ja: {
      profileTitle: 'ユーザー情報',
      profileEdit: '登録情報を編集',
      profileIndustry: '業種',
      profilePersonName: '担当者',
      addTrademark: '新しく商標を検索する',
      pleaseReflesh:
        '情報の取得に失敗しました。恐れ入りますが画面をリロードしてください。',
    },
    en: {
      profileTitle: 'User Information',
      profileEdit: 'Edit Registration Information',
      profileIndustry: 'type of industry',
      profilePersonName:
        'person in charge (of an area of work, but not necessarily supervising other staff)',
      addTrademark: 'Search for new trademarks',
      pleaseReflesh:
        'Failed to retrieve information. Please reload the screen.',
    },
    zh: {
      profileTitle: '用户信息',
      profileEdit: '编辑注册信息',
      profileIndustry: '行业类型',
      profilePersonName: '负责人(工作领域，但不一定要监督其他工作人员)',
      addTrademark: '搜索新商标',
      pleaseReflesh: '检索信息失败。请重新加载屏幕。',
    },
  },
});

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});

const apiClient = inject(APIClientKey) as APIClientInterface;
const applicationList = useApplicationList(apiClient);
provide(ApplicationListKey, applicationList);

const { state, loadUser } = inject(UserDataKey) as UserDataStore;

const toast = useToast();

onMounted(async () => {
  const loadError = await loadUser();
  if (loadError) {
    window.location.href = '/app/login';
  }

  try {
    await applicationList.fetchTrademarkApplications();
  } catch (e) {
    return toast.error(t('pleaseReflesh'));
  }
});
</script>
