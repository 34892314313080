<template>
  <div class="flex items-center">
    <label
      v-for="(option, index) in options"
      :key="option.value"
      :data-last="index === options.length - 1"
      class="mr-2 flex cursor-pointer items-center data-[last=true]:mr-0"
    >
      <div
        class="h-5 w-5 cursor-pointer bg-cover bg-no-repeat"
        :style="{
          backgroundImage: `url(${
            option.value === modelValue ? Checked : Unchecked
          })`,
        }"
      />
      <input
        type="radio"
        class="hidden"
        :name="name"
        :value="option.value"
        :checked="option.value === modelValue"
        @change="$emit('update:model-value', option.value)"
      >
      <div
        class="ml-1 text-sm data-[selected=true]:font-semibold"
        :data-selected="option.value === modelValue"
      >
        {{ option.label }}
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
import Unchecked from '@/assets/images/icons/radio_button_unchecked.png';
import Checked from '@/assets/images/icons/radio_button_checked.png';
import { v4 as uuidv4 } from 'uuid';

export type RadioButtonGroupOption = {
  label: string;
  value: string;
};

defineProps<{
  options: RadioButtonGroupOption[];
  modelValue: string;
}>();

defineEmits<{
  (e: 'update:model-value', value: string): void;
}>();

const name = uuidv4();
</script>
