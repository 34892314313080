<template>
  <div>
    <div
      v-if="klassIdsForSubmit.length"
      class="bg-primaryOrange5 mt-6 rounded px-4 py-6 sm:px-0"
    >
      <div
        class="text-textBlack text-center text-lg font-semibold tracking-wider"
      >
        {{ `${klassIdsForSubmit.length}区分を選択中` }}
      </div>
      <template v-if="$device.isDesktop">
        <div class="mt-2 flex items-center justify-center text-center">
          <div class="text-textBlack text-lg font-semibold tracking-wider">
            出願費用：
          </div>
          <div class="text-primary text-lg font-semibold tracking-wider">
            {{ `${applySubTotalFee.toLocaleString()}円〜（税込）` }}
          </div>
        </div>
        <div class="text-grayAlpha60 mt-2 px-2 text-center text-sm">
          お見積書は次のページでご確認いただけます。
          <ul class="mx-auto w-[300px] list-disc pl-5">
            <li>オプションを含めた出願費用</li>
            <li>登録費用（２回目のお支払い金額）</li>
          </ul>
        </div>
      </template>
      <template v-else>
        <div
          class="text-textBlack text-center text-lg font-semibold tracking-wider"
        >
          出願費用
        </div>
        <div
          class="text-primary text-center text-lg font-semibold tracking-wider"
        >
          {{ `${applySubTotalFee.toLocaleString()}円〜（税込）` }}
        </div>
        <div class="text-grayAlpha60 mt-2 text-sm">
          お見積書は次のページでご確認いただけます。
          <ul class="mx-auto w-[300px] list-disc pl-5">
            <li>オプションを含めた出願費用</li>
            <li>登録費用（２回目のお支払い金額）</li>
          </ul>
        </div>
      </template>
    </div>
    <div
      v-else
      class="bg-primaryOrange5 mt-6 rounded py-4"
    >
      <div class="text-grayAlpha80 px-2 text-center text-sm">
        区分を1つ以上選択してください。
      </div>
    </div>
    <div class="mt-6 flex flex-col-reverse items-center sm:flex-row">
      <SecondaryButton
        v-if="authenticated"
        class="mr-0 w-full sm:mr-2 sm:w-40"
        title="検索条件の保存"
        :disabled="false"
        @click="onSaveButtonClick"
      />
      <PrimaryButton
        class="mb-2 w-full flex-1 sm:mb-0 sm:w-auto"
        title="申込内容/料金の確認に進む"
        :disabled="!klassIdsForSubmit.length || progress !== 'completed'"
        @click="onNextButtonClick(false)"
      />
    </div>
    <!-- 類似商標ありモーダル -->
    <Modal
      :is-visible="showsLittleChanceModal"
      @close="showsLittleChanceModal = false"
    >
      <LittleChanceModal
        :on-next-button-click="onNextButtonClick"
      />
    </Modal>
    <!-- 会員登録モーダル -->
    <Modal
      :is-visible="showsSignUpModal"
      close-button-label="閉じる"
      disable-overlay-close
      @close="showsSignUpModal = false"
    >
      <SignUpModalContent @close="showsSignUpModal = false" />
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, computed } from 'vue';
// 状態管理
import {
  UserDataKey,
  KlassSelectStoreKey,
  TrademarkSearchStoreKey,
} from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import { KlassSelectStore } from '@/composables/useKlassSelect';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
import { usePriceCalculator } from '@/composables/usePriceCalculator';
// 型定義
import { KlassId } from '@/types';
// コンポーネント
import LittleChanceModal from '@/components/search/LittleChanceModal.vue';
import SignUpModalContent from '@/components/signup/SignUpModalContent.vue';
// ライブラリ
import { useToast } from 'vue-toastification';
import { useRoute, useRouter } from 'vue-router';

const {
  state: userState,
  authenticated,
  isAttorneyCorporation,
} = inject(UserDataKey) as UserDataStore;

const { klassIdsForSubmit, klassesForSubmit } = inject(
  KlassSelectStoreKey,
) as KlassSelectStore;

const { trademarkType, textSearchResults, imageSearchResults, progress, save } =
  inject(TrademarkSearchStoreKey) as TrademarkSearchStore;

const klassCount = computed(() => klassIdsForSubmit.value.length);
const isPersonal = computed(() =>
  authenticated.value ? userState.isPersonal : false,
);

const { applySubTotalFee } = usePriceCalculator({
  klassCount,
  premiumPlan: ref(false),
  speedPlan: ref(false),
  authenticated,
  isPersonal,
  isAttorneyCorporation,
});

const loading = ref(false);
const toast = useToast();

async function onSaveButtonClick () {
  loading.value = true;
  const { error } = await save();
  loading.value = false;

  if (error) {
    if (error === 'already-exists') {
      return toast.error('すでに保存されています。');
    } else if (error === 'unauthorized') {
      return toast.error('お手数ですが再度ログインをお願いいたします。');
    } else {
      return toast.error('検索条件の保存に失敗しました。');
    }
  }

  toast.success('検索条件をマイページの「検討中」に保存しました。');
}

const route = useRoute();
const router = useRouter();

const isLittleChance = computed(() => {
  const searchResults =
    trademarkType.value === 'image'
      ? imageSearchResults.value
      : textSearchResults.value;
  const goodsServiceIdsForSubmit = Object.keys(klassesForSubmit.value)
    .map((klassId) => klassesForSubmit.value[klassId])
    .flat();
  const registrationStatuses = (Object.keys(searchResults) as KlassId[])
    .map((klassId) => searchResults[klassId]!)
    .flat()
    .filter((similarResult) =>
      goodsServiceIdsForSubmit.includes(similarResult.goodsServiceId),
    )
    .map((similarResult) => similarResult.registrationStatus);
  return registrationStatuses.includes('registered');
});

const showsLittleChanceModal = ref(false);
const showsSignUpModal = ref(false);

function onNextButtonClick (isLittleChanceConfirmed = false) {
  if (authenticated.value) {
    if (isLittleChance.value && !isLittleChanceConfirmed) {
      showsLittleChanceModal.value = true;
    } else {
      router.push({
        name: 'RegistTrademarkPage',
        query: {
          ...route.query,
          type: route.name === 'ImageSearchPage' ? 'image' : 'text',
          isLittleChanceConfirmed: isLittleChanceConfirmed.toString(),
        },
      });
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'begin_checkout' });
      }
    }
  } else {
    showsSignUpModal.value = true;
  }
}
</script>
