<template>
  <div class="items-centerpy-[2px] inline-flex px-[2px]">
    <div
      v-for="(label, index) in options"
      :key="index"
      class="mr-2 cursor-pointer border-b-2 border-solid px-2 py-1 font-semibold tracking-wider"
      :class="
        modelValue === index
          ? 'border-b-primary text-primary'
          : 'text-grayAlpha60 border-transparent'
      "
      @click="onSelect(index)"
    >
      {{ label }}
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  modelValue: number;
  options: string[];
}

interface Emits {
  (e: 'update:model-value', index: number): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const onSelect = (index: number) => {
  emit('update:model-value', index);
};
</script>
