import { TrademarkAdminStatus } from '@/api/APIClientInterface';

export const statuses = [
  {
    admin: '出願LD',
    user: { ja: '弁理士承諾待ち', en: 'Waiting', zh: '正在等待专利律师的批准' },
    step: 0,
  },
  {
    admin: '出願DRA',
    user: { ja: '弁理士作業中', en: 'In Progress', zh: '工作中的专利律师' },
    step: 0,
  },
  {
    admin: '出願CK',
    user: { ja: 'ご確認下さい', en: 'Please Check', zh: '请检查' },
    step: 1,
  },
  {
    admin: '出願PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 1,
  },
  {
    admin: '出願RSVD',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 1,
  },
  { admin: '出願CXL', user: { ja: '', en: '', zh: '' }, step: 20 },
  {
    admin: '早期審査LD',
    user: { ja: '要確認', en: 'Confirm', zh: '需要确认' },
    step: 1,
  },
  {
    admin: '早期審査DRA',
    user: { ja: '要確認', en: 'Confirm', zh: '需要确认' },
    step: 1,
  },
  {
    admin: '早期審査CK',
    user: { ja: 'ご確認下さい', en: 'Please Check', zh: '请检查' },
    step: 1,
  },
  {
    admin: '早期審査PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 1,
  },
  {
    admin: '庁指令LD',
    user: { ja: '拒絶対応申込前', en: 'Apply for Rejection', zh: '拒绝申请前' },
    step: 2,
  },
  {
    admin: '庁対応DRA',
    user: { ja: '要確認', en: 'Confirm', zh: '需要确认' },
    step: 2,
  },
  {
    admin: '庁対応CK',
    user: { ja: 'ご確認下さい', en: 'Please Check', zh: '请检查' },
    step: 2,
  },
  {
    admin: '庁対応PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 2,
  },
  { admin: '放棄', user: { ja: '', en: '', zh: '' }, step: 20 },
  {
    admin: '住所等変更LD',
    user: { ja: 'お支払い待ち', en: 'Waiting Payment', zh: '等待付款' },
    step: 2,
  },
  {
    admin: '住所等変更DR',
    user: { ja: '作業中', en: 'In Progress', zh: '正在进行的工作' },
    step: 2,
  },
  {
    admin: '住所等変更CK',
    user: { ja: 'ご確認下さい', en: 'Please Check', zh: '请检查' },
    step: 2,
  },
  {
    admin: '住所等変更PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 2,
  },
  {
    admin: '名義変更LD',
    user: { ja: 'お支払い待ち', en: 'Waiting Payment', zh: '等待付款' },
    step: 2,
  },
  {
    admin: '名義変更DR',
    user: { ja: '作業中', en: 'In Progress', zh: '正在进行的工作' },
    step: 2,
  },
  {
    admin: '名義変更CK',
    user: { ja: 'ご確認下さい', en: 'Please Check', zh: '请检查' },
    step: 2,
  },
  {
    admin: '名義変更PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 2,
  },
  {
    admin: '登録LD',
    user: { ja: 'お支払い待ち', en: 'Waiting Payment', zh: '等待付款' },
    step: 3,
  },
  {
    admin: '登録PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 3,
  },
  {
    admin: '登録待ち',
    user: { ja: '登録待ち', en: 'Waiting Regitration', zh: '等待登记' },
    step: 3,
  },
  { admin: '登録CXL', user: { ja: '', en: '', zh: '' }, step: 20 },
  {
    admin: '継続中',
    user: {
      ja: '特許庁審査中',
      en: 'Under Examination',
      zh: '正在接受专利局的审查',
    },
    step: 2,
  },
  {
    admin: '庁通知',
    user: {
      ja: '特許庁から通知',
      en: 'Notice from Office',
      zh: '由专利局通知',
    },
    step: 2,
  },
  {
    admin: '権利有効',
    user: { ja: '権利有効', en: 'Registered', zh: '注册' },
    step: 4,
  },
  { admin: '更新CXL', user: { ja: '', en: '', zh: '' }, step: 20 },
  {
    admin: '表示変更LD',
    user: { ja: '申込前', en: 'Not yet', zh: '应用前' },
    step: 10,
  },
  {
    admin: '表示変更DR',
    user: { ja: '作業中', en: 'In Progress', zh: '正在进行的工作' },
    step: 10,
  },
  {
    admin: '表示変更CK',
    user: { ja: 'ご確認下さい', en: 'Please Check', zh: '请检查' },
    step: 10,
  },
  {
    admin: '表示変更PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 10,
  },
  {
    admin: '権利移転LD',
    user: { ja: '申込前', en: 'Not yet', zh: '应用前' },
    step: 11,
  },
  {
    admin: '権利移転DR',
    user: { ja: '作業中', en: 'In Progress', zh: '正在进行的工作' },
    step: 11,
  },
  {
    admin: '権利移転CK',
    user: { ja: 'ご確認下さい', en: 'Please Check', zh: '请检查' },
    step: 11,
  },
  {
    admin: '権利移転PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 11,
  },
  {
    admin: '後期納付NO',
    user: { ja: '申込前', en: 'Not yet', zh: '应用前' },
    step: 12,
  },
  {
    admin: '後期納付LD',
    user: { ja: '申込前', en: 'Not yet', zh: '应用前' },
    step: 12,
  },
  {
    admin: '後期納付PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 12,
  },
  {
    admin: '後期納付反映待ち',
    user: { ja: '後期納付反映待ち', en: 'Preparing', zh: '准备提交' },
    step: 12,
  },
  {
    admin: '更新NO',
    user: { ja: '申込前', en: 'Not yet', zh: '应用前' },
    step: 12,
  },
  {
    admin: '更新LD',
    user: { ja: '申込前', en: 'Not yet', zh: '应用前' },
    step: 12,
  },
  {
    admin: '更新PREP',
    user: { ja: '提出準備中', en: 'Preparing', zh: '准备提交' },
    step: 12,
  },
  {
    admin: '更新反映待ち',
    user: { ja: '更新反映待ち', en: 'Preparing', zh: '准备提交' },
    step: 12,
  },
  { admin: '後期納付CXL', user: { ja: '', en: '', zh: '' }, step: 20 },
] as ApplicationStatus[];

export type ApplicationStatus = {
  admin: TrademarkAdminStatus;
  user: {
    ja: string;
    en: string;
    zh: string;
  };
  step: number;
};
