<template>
  <div :class="`${$options.name}`">
    <div :class="`${$options.name}__content`">
      <BaseCheckbox
        id="RegistTrademarkAgreeCheckbox"
        v-model="state.termsAgreed"
        name="RegistTrademarkAgreeCheckbox"
      />
      <div :class="`${$options.name}__text`">
        <label
          :for="`RegistTrademarkAgreeCheckbox`"
          class="cursor-pointer"
        >
          <i18n-t keypath="text">
            <a
              href="/app/terms_application"
              :class="`${$options.name}__link`"
              target="_blank"
            >{{ t("anchorText") }}</a>
          </i18n-t>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplyFormKey } from '@/composables/injectionKeys';
import { ApplyFormStore } from '@/composables/useApplyForm';
import BaseCheckbox from '@/components/common/BaseCheckbox.vue';

export default defineComponent({
  name: 'AgreeTerms',
  components: {
    BaseCheckbox,
  },
  setup () {
    const { t } = useI18n({
      messages: {
        ja: {
          text: '私は、弁理士に出願を依頼する{0}を読み、その内容に同意しました。',
          anchorText: '依頼契約書',
        },
        en: {
          text: 'I have read and agree with the content of {0} requesting a patent attorney to file the application.',
          anchorText: 'client agreement',
        },
        zh: {
          text: '我已阅读{0}并同意向专利代理人提出申请的内容。',
          anchorText: '雇佣合同',
        },
      },
    });

    const { state } = inject(ApplyFormKey) as ApplyFormStore;

    return {
      t,
      state,
    };
  },
});
</script>

<style lang="scss" scoped>
.AgreeTerms {
  border: 1px solid #e7e7e7;
  background-color: #fff4f1;
  padding: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 24px;

  &__content {
    display: flex;
    align-items: center;
  }

  &__text {
    color: #231815;
    line-height: 22px;
    margin-left: 8px;
  }

  &__link {
    color: $primary-color;
    text-decoration: underline;
  }
}
</style>
