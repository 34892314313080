<template>
  <div class="flex items-center">
    <div
      class="material-icons"
      :style="{ color: colorCode }"
    >
      check_circle
    </div>
    <div class="ml-1 text-sm tracking-wider">
      {{ text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

export type CheckListItemProps = {
  text: string;
  color: CheckMarkColor;
};

export type CheckMarkColor = 'primary' | 'green' | 'gray';

const props = defineProps<CheckListItemProps>();

const colorCode = computed(() => {
  switch (props.color) {
  case 'primary':
    return '#E6713D';
  case 'green':
    return '#3D664D';
  case 'gray':
    return '#22222299';
  default:
    console.error(`Invalid color: ${props.color}`);
    return '';
  }
});
</script>
