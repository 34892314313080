<template>
  <div class="border-borderGray rounded-2xl border border-solid bg-white">
    <table class="border-collapse">
      <TableHeader
        :widths="widths"
        :columns="columns"
      />
      <tbody>
        <TableRow
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
          :widths="widths"
          :header="rowHeaders[rowIndex]"
          :columns="row"
          :bold-col-index="boldColIndex(rowIndex)"
          :data-devider="dividerPositions.includes(rowIndex)"
          :data-last="rowIndex === rows.length - 1"
          class="border-borderGray border-b border-solid data-[devider=true]:border-b-[3px] data-[last=true]:border-b-0 data-[devider=true]:border-[#414446]"
        />
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Maybe } from '@/lib/types';
import TableHeader from './TableHeader.vue';
import TableRow from './TableRow.vue';

const widths = [150, 323, 323, 323];

const columns = computed<string[]>(() => [
  '',
  '自分でやる場合',
  '従来',
  'Cotobox',
]);

const rowHeaders = computed<string[]>(() => [
  '調査',
  '出願手続き',
  '支払い方法',
  '審査結果の対応',
  '更新管理',
  '出願までの期間',
  '費用',
]);

const rows = computed<Maybe<string | string[]>[][]>(() => [
  [
    '自分で調査',
    '弁理士へ調査',
    'Cotoboxの検索は無料\n申し込み後は提携弁理士が調査',
  ],
  [
    [
      '特許庁の指定に従い申請書類作成、書類を印刷',
      '郵送または特許庁へ出向いて提出',
      '電子化手数料の払込書が届くので、期限内に納付',
    ],
    [
      'メールや対面で申し込み、調査結果の報告を待つ',
      '正式に依頼を行うと、弁理士が書類の作成に着手',
      '弁理士が特許庁へ提出',
    ],
    [
      'オンラインで商標情報を入力し、その場で簡易調査結果の確認・申し込み',
      '提携弁理士が書類を整え、マイページで最終チェック',
      '提携弁理士が特許庁へ書類提出',
    ],
  ],
  ['郵便局で印紙を購入', '銀行振込など', 'クレジットカードまたは銀行振込'],
  ['自分で調べて対応', '担当弁理士が対応', '担当した提携弁理士へ依頼可能'],
  ['自分で管理', '管理委託可能なことも', 'Cotoboxを通してお知らせ'],
  ['約1ヶ月', '2日〜1週間程度', '最短1営業日'],
  ['29,200円〜', '平均14万円〜', '56,700円〜'],
]);

const dividerPositions = [4];

const boldTextCells = [[6, 0]];

function boldColIndex (rowIndex: number): Maybe<number> {
  const cell = boldTextCells.find((e) => e[0] === rowIndex);
  if (cell === undefined) {
    return null;
  }
  return cell[1];
}
</script>
