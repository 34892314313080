import { KlassId } from '@/types';

/** 例: `化学品` */
export type GoodsServiceName = string;

export type GoodsService = {
  id: GoodsServiceName;
  name: {
    ja: GoodsServiceName;
    en: string;
  };
  code: string;
  order: number;
};

export type GoodsServices = {
  [key in KlassId]: {
    [key in GoodsServiceName]?: GoodsService;
  };
};

export const goodsServices: GoodsServices = {
  1: {
    '化学品': {
      id: '化学品',
      name: {
        ja: '化学品',
        en: 'chemicals',
      },
      code: '1-QB',
      order: 1,
    },
    '工業用のり及び接着剤': {
      id: '工業用のり及び接着剤',
      name: {
        ja: '工業用のり及び接着剤',
        en: 'glue and adhesives for industrial purposes',
      },
      code: '-b2-',
      order: 2,
    },
    '植物成長調整剤類': {
      id: '植物成長調整剤類',
      name: {
        ja: '植物成長調整剤類',
        en: 'plant growth regulating preparations',
      },
      code: 'TIn3',
      order: 3,
    },
    '肥料': {
      id: '肥料',
      name: {
        ja: '肥料',
        en: 'fertilizers',
      },
      code: 'Xi_y',
      order: 4,
    },
    '陶磁器用釉薬': {
      id: '陶磁器用釉薬',
      name: {
        ja: '陶磁器用釉薬',
        en: 'ceramic glazings',
      },
      code: '4OJ6',
      order: 5,
    },
    '塗装用パテ': {
      id: '塗装用パテ',
      name: {
        ja: '塗装用パテ',
        en: 'priming putty',
      },
      code: 'TvFJ',
      order: 6,
    },
    '高級脂肪酸': {
      id: '高級脂肪酸',
      name: {
        ja: '高級脂肪酸',
        en: 'higher fatty acids',
      },
      code: 'qI-I',
      order: 7,
    },
    '非鉄金属': {
      id: '非鉄金属',
      name: {
        ja: '非鉄金属',
        en: 'nonferrous metals',
      },
      code: '0Jct',
      order: 8,
    },
    '非金属鉱物': {
      id: '非金属鉱物',
      name: {
        ja: '非金属鉱物',
        en: 'non-metallic minerals',
      },
      code: 'l4XW',
      order: 9,
    },
    '写真材料': {
      id: '写真材料',
      name: {
        ja: '写真材料',
        en: 'chemical compositions for developing and printing photographs',
      },
      code: 'l7HO',
      order: 10,
    },
    '試験紙（医療用のものを除く。）': {
      id: '試験紙（医療用のものを除く。）',
      name: {
        ja: '試験紙（医療用のものを除く。）',
        en: 'reagent paper [not for medical purposes]',
      },
      code: 'sPl3',
      order: 11,
    },
    '工業用人工甘味料': {
      id: '工業用人工甘味料',
      name: {
        ja: '工業用人工甘味料',
        en: 'artificial sweeteners for industrial purposes',
      },
      code: 'QCbj',
      order: 12,
    },
    '工業用粉類': {
      id: '工業用粉類',
      name: {
        ja: '工業用粉類',
        en: 'flour and starch for industrial purposes',
      },
      code: 'n9_y',
      order: 13,
    },
    '原料プラスチック': {
      id: '原料プラスチック',
      name: {
        ja: '原料プラスチック',
        en: 'plastics [raw materials]',
      },
      code: 'CzKm',
      order: 14,
    },
    'パルプ': {
      id: 'パルプ',
      name: {
        ja: 'パルプ',
        en: 'groundwood pulp or chemical pulps for manufacturing purposes',
      },
      code: 'hmRf',
      order: 15,
    },
  },
  2: {
    'カナダバルサム，コパール，サンダラック，セラック，ダンマール，媒染剤，腐蝕防止剤，防錆剤，マスチック，松脂，木材保存剤': {
      id: 'カナダバルサム，コパール，サンダラック，セラック，ダンマール，媒染剤，腐蝕防止剤，防錆剤，マスチック，松脂，木材保存剤',
      name: {
        ja: 'カナダバルサム，コパール，サンダラック，セラック，ダンマール，媒染剤，腐蝕防止剤，防錆剤，マスチック，松脂，木材保存剤',
        en: 'Canada balsam，copal，sandarac，shellac，dammar，mordants*，anti-corrosive preparations，anti-rust preparations，mastic，pine gum，wood preservatives',
      },
      code: 'L_lt',
      order: 1,
    },
    '染料': {
      id: '染料',
      name: {
        ja: '染料',
        en: 'dyestuffs',
      },
      code: 'gsYx',
      order: 2,
    },
    '顔料': {
      id: '顔料',
      name: {
        ja: '顔料',
        en: 'pigments',
      },
      code: 'ggKX',
      order: 3,
    },
    '塗料': {
      id: '塗料',
      name: {
        ja: '塗料',
        en: 'paints',
      },
      code: 'hH9x',
      order: 4,
    },
    '印刷インキ，絵の具': {
      id: '印刷インキ，絵の具',
      name: {
        ja: '印刷インキ，絵の具',
        en: 'printing ink、 other than mimeographing inks',
      },
      code: 'DP2U',
      order: 5,
    },
    '印刷インキ（「謄写版用インキ」を除く。）': {
      id: '印刷インキ（「謄写版用インキ」を除く。）',
      name: {
        ja: '印刷インキ（「謄写版用インキ」を除く。）',
        en: 'printing ink、 other than mimeographing inks',
      },
      code: 'kjPo',
      order: 6,
    },
    '謄写版用インキ，絵の具': {
      id: '謄写版用インキ，絵の具',
      name: {
        ja: '謄写版用インキ，絵の具',
        en: 'mimeographing ink，colors for painting pictures',
      },
      code: 'Zs78',
      order: 7,
    },
    '防錆グリース': {
      id: '防錆グリース',
      name: {
        ja: '防錆グリース',
        en: 'anti-rust greases',
      },
      code: 'EJ9F',
      order: 8,
    },
    '塗装用・装飾用・印刷用又は美術用の非鉄金属はく及び粉，塗装用・装飾用・印刷用又は美術用の貴金属はく及び粉': {
      id: '塗装用・装飾用・印刷用又は美術用の非鉄金属はく及び粉，塗装用・装飾用・印刷用又は美術用の貴金属はく及び粉',
      name: {
        ja: '塗装用・装飾用・印刷用又は美術用の非鉄金属はく及び粉，塗装用・装飾用・印刷用又は美術用の貴金属はく及び粉',
        en: 'nonferrous metals in foil or powder form for painters、 decorators、 printers and artists，precious metals in foil or powder form for painters、 decorators、 printers and artists',
      },
      code: 'm8IT',
      order: 9,
    },
  },
  3: {
    '家庭用帯電防止剤，家庭用脱脂剤，さび除去剤，染み抜きベンジン，洗濯用柔軟剤，洗濯用漂白剤': {
      id: '家庭用帯電防止剤，家庭用脱脂剤，さび除去剤，染み抜きベンジン，洗濯用柔軟剤，洗濯用漂白剤',
      name: {
        ja: '家庭用帯電防止剤，家庭用脱脂剤，さび除去剤，染み抜きベンジン，洗濯用柔軟剤，洗濯用漂白剤',
        en: 'antistatic preparations for household purposes，de-greasing preparations for household purposes，rust removing preparations，stain removing benzine，fabric softeners for laundry use，laundry bleach',
      },
      code: 'bHJu',
      order: 1,
    },
    'かつら装着用接着剤，洗濯用でん粉のり，洗濯用ふのり，つけまつ毛用接着剤': {
      id: 'かつら装着用接着剤，洗濯用でん粉のり，洗濯用ふのり，つけまつ毛用接着剤',
      name: {
        ja: 'かつら装着用接着剤，洗濯用でん粉のり，洗濯用ふのり，つけまつ毛用接着剤',
        en: 'adhesives for affixing false hair，laundry starch，seaweed gelatine for laundry use [Funori]，adhesives for affixing false eyelashes',
      },
      code: 'cCGy',
      order: 2,
    },
    '口臭用消臭剤，動物用防臭剤': {
      id: '口臭用消臭剤，動物用防臭剤',
      name: {
        ja: '口臭用消臭剤，動物用防臭剤',
        en: 'breath freshening preparations，deodorants for animals',
      },
      code: 'yqA8',
      order: 3,
    },
    '塗料用剥離剤': {
      id: '塗料用剥離剤',
      name: {
        ja: '塗料用剥離剤',
        en: 'paint stripping preparations',
      },
      code: '5uTv',
      order: 4,
    },
    '靴クリーム，靴墨': {
      id: '靴クリーム，靴墨',
      name: {
        ja: '靴クリーム，靴墨',
        en: 'shoe cream，shoe black [shoe polish]',
      },
      code: 'UBJp',
      order: 5,
    },
    'つや出し剤': {
      id: 'つや出し剤',
      name: {
        ja: 'つや出し剤',
        en: 'polishing preparations',
      },
      code: 'AMMu',
      order: 6,
    },
    'せっけん類': {
      id: 'せっけん類',
      name: {
        ja: 'せっけん類',
        en: 'soaps and detergents',
      },
      code: '00wt',
      order: 7,
    },
    '歯磨き': {
      id: '歯磨き',
      name: {
        ja: '歯磨き',
        en: 'dentifrices*',
      },
      code: '-_XB',
      order: 8,
    },
    '化粧品': {
      id: '化粧品',
      name: {
        ja: '化粧品',
        en: 'cosmetics',
      },
      code: 'AOBt',
      order: 9,
    },
    '香料': {
      id: '香料',
      name: {
        ja: '香料',
        en: 'perfume and flavour materials',
      },
      code: 'sq9i',
      order: 10,
    },
    '薫料': {
      id: '薫料',
      name: {
        ja: '薫料',
        en: 'incense',
      },
      code: 'Xr_1',
      order: 11,
    },
    '研磨紙，研磨布，研磨用砂，人造軽石，つや出し紙': {
      id: '研磨紙，研磨布，研磨用砂，人造軽石，つや出し紙',
      name: {
        ja: '研磨紙，研磨布，研磨用砂，人造軽石，つや出し紙',
        en: 'abrasive paper [sandpaper]，abrasive cloth，abrasive sand，artificial pumice stone，polishing paper',
      },
      code: 'rCpc',
      order: 12,
    },
    'つけづめ，つけまつ毛': {
      id: 'つけづめ，つけまつ毛',
      name: {
        ja: 'つけづめ，つけまつ毛',
        en: 'false nails，false eyelashes',
      },
      code: 'Je-K',
      order: 13,
    },
  },
  4: {
    '固形潤滑剤': {
      id: '固形潤滑剤',
      name: {
        ja: '固形潤滑剤',
        en: 'solid lubricants',
      },
      code: 'xOhe',
      order: 1,
    },
    '靴油': {
      id: '靴油',
      name: {
        ja: '靴油',
        en: 'grease for shoes and boots',
      },
      code: 'z5us',
      order: 2,
    },
    '保革油': {
      id: '保革油',
      name: {
        ja: '保革油',
        en: 'leather preserving oil and grease',
      },
      code: 'MBIa',
      order: 3,
    },
    '燃料': {
      id: '燃料',
      name: {
        ja: '燃料',
        en: 'fuel',
      },
      code: 'rmVH',
      order: 4,
    },
    '固体燃料': {
      id: '固体燃料',
      name: {
        ja: '固体燃料',
        en: 'solid fuels',
      },
      code: 'xF6i',
      order: 5,
    },
    '液体燃料，気体燃料': {
      id: '液体燃料，気体燃料',
      name: {
        ja: '液体燃料，気体燃料',
        en: 'liquid fuels，gaseous fuels',
      },
      code: 'in3T',
      order: 6,
    },
    '工業用油': {
      id: '工業用油',
      name: {
        ja: '工業用油',
        en: 'mineral oils and greases for industrial purposes [not for fuel]',
      },
      code: 'gThm',
      order: 7,
    },
    '工業用油脂': {
      id: '工業用油脂',
      name: {
        ja: '工業用油脂',
        en: 'non-mineral oils and greases for industrial purposes [not for fuel]',
      },
      code: 'k1s7',
      order: 8,
    },
    'ろう': {
      id: 'ろう',
      name: {
        ja: 'ろう',
        en: 'waxes [raw material]',
      },
      code: 'X37B',
      order: 9,
    },
    'ランプ用灯しん': {
      id: 'ランプ用灯しん',
      name: {
        ja: 'ランプ用灯しん',
        en: 'lamp wicks',
      },
      code: 'iahi',
      order: 10,
    },
    'ろうそく': {
      id: 'ろうそく',
      name: {
        ja: 'ろうそく',
        en: 'candles*',
      },
      code: 'wWmQ',
      order: 11,
    },
  },
  5: {
    '薬剤，医療用試験紙': {
      id: '薬剤，医療用試験紙',
      name: {
        ja: '薬剤，医療用試験紙',
        en: 'pharmaceutical preparations and other preparations for destroying vermin、 fungicides、 herbicides',
      },
      code: 'Tsjj',
      order: 1,
    },
    '薬剤（農薬に当たるものを除く。），医療用試験紙': {
      id: '薬剤（農薬に当たるものを除く。），医療用試験紙',
      name: {
        ja: '薬剤（農薬に当たるものを除く。），医療用試験紙',
        en: 'pharmaceutical preparations，reagent paper for medical purposes',
      },
      code: 'MzhL',
      order: 2,
    },
    '燻蒸剤（農薬に当たるものに限る。），殺菌剤（農薬に当たるものに限る。），殺そ剤（農薬に当たるものに限る。），殺虫剤（農薬に当たるものに限る。），除草剤，防虫剤（農薬に当たるものに限る。），防腐剤（農薬に当たるものに限る。）': {
      id: '燻蒸剤（農薬に当たるものに限る。），殺菌剤（農薬に当たるものに限る。），殺そ剤（農薬に当たるものに限る。），殺虫剤（農薬に当たるものに限る。），除草剤，防虫剤（農薬に当たるものに限る。），防腐剤（農薬に当たるものに限る。）',
      name: {
        ja: '燻蒸剤（農薬に当たるものに限る。），殺菌剤（農薬に当たるものに限る。），殺そ剤（農薬に当たるものに限る。），殺虫剤（農薬に当たるものに限る。），除草剤，防虫剤（農薬に当たるものに限る。），防腐剤（農薬に当たるものに限る。）',
        en: 'fumigants [only for agricultural purposes]，fungicides [only for agricultural purposes]，rodenticides [only for agricultural purposes]，insecticides [only for agricultural purposes]，herbicides [weedkillers]，insect-repellents [only for agricultural purposes]，antiseptics [only for agricultural purposes]',
      },
      code: 'CKBV',
      order: 3,
    },
    '医療用油紙，医療用接着テープ，オブラート，ガーゼ，カプセル，眼帯，耳帯，生理帯，生理用タンポン，生理用ナプキン，生理用パンティ，脱脂綿，ばんそうこう，包帯，包帯液，胸当てパッド，綿棒': {
      id: '医療用油紙，医療用接着テープ，オブラート，ガーゼ，カプセル，眼帯，耳帯，生理帯，生理用タンポン，生理用ナプキン，生理用パンティ，脱脂綿，ばんそうこう，包帯，包帯液，胸当てパッド，綿棒',
      name: {
        ja: '医療用油紙，医療用接着テープ，オブラート，ガーゼ，カプセル，眼帯，耳帯，生理帯，生理用タンポン，生理用ナプキン，生理用パンティ，脱脂綿，ばんそうこう，包帯，包帯液，胸当てパッド，綿棒',
        en: 'oiled paper for medical purposes，adhesive tapes for medical purposes，drug delivery agents in the form of edible wafers for wrapping powdered pharmaceuticals，gauze for dressings，empty capsules for pharmaceuticals，eyepatches for medical purposes，ear bandages，menstruation bandages，menstruation tampons，sanitary napkins，sanitary panties，absorbent cotton，adhesive plasters，bandages for dressings，liquid bandages，breast-nursing pads，cotton swabs for medical use',
      },
      code: '77or',
      order: 4,
    },
    '歯科用材料': {
      id: '歯科用材料',
      name: {
        ja: '歯科用材料',
        en: 'dental materials',
      },
      code: 'TBEt',
      order: 5,
    },
    'おむつ，おむつカバー': {
      id: 'おむつ，おむつカバー',
      name: {
        ja: 'おむつ，おむつカバー',
        en: 'diapers，diaper covers',
      },
      code: '65K9',
      order: 6,
    },
    'はえ取り紙': {
      id: 'はえ取り紙',
      name: {
        ja: 'はえ取り紙',
        en: 'fly catching paper',
      },
      code: 'Cg0H',
      order: 7,
    },
    '防虫紙': {
      id: '防虫紙',
      name: {
        ja: '防虫紙',
        en: 'mothproofing paper',
      },
      code: 'gwwr',
      order: 8,
    },
    '乳幼児用粉乳': {
      id: '乳幼児用粉乳',
      name: {
        ja: '乳幼児用粉乳',
        en: 'lacteal flour for babies',
      },
      code: '5ffC',
      order: 9,
    },
    'サプリメント': {
      id: 'サプリメント',
      name: {
        ja: 'サプリメント',
        en: 'dietary supplements for human beings',
      },
      code: '1kB8',
      order: 10,
    },
    '食餌療法用飲料，食餌療法用食品': {
      id: '食餌療法用飲料，食餌療法用食品',
      name: {
        ja: '食餌療法用飲料，食餌療法用食品',
        en: 'dietetic beverages adapted for medical purposes，dietetic foods adapted for medical purposes',
      },
      code: 'rrC9',
      order: 11,
    },
    '乳幼児用飲料，乳幼児用食品': {
      id: '乳幼児用飲料，乳幼児用食品',
      name: {
        ja: '乳幼児用飲料，乳幼児用食品',
        en: 'beverages for babies，food for babies',
      },
      code: 'QTLa',
      order: 12,
    },
    '栄養補助用飼料添加物（薬剤に属するものを除く。）': {
      id: '栄養補助用飼料添加物（薬剤に属するものを除く。）',
      name: {
        ja: '栄養補助用飼料添加物（薬剤に属するものを除く。）',
        en: 'dietary supplements for animals',
      },
      code: 'a-T_',
      order: 13,
    },
    '人工受精用精液': {
      id: '人工受精用精液',
      name: {
        ja: '人工受精用精液',
        en: 'semen for artificial insemination',
      },
      code: 'TN3g',
      order: 14,
    },
  },
  6: {
    '鉄及び鋼': {
      id: '鉄及び鋼',
      name: {
        ja: '鉄及び鋼',
        en: 'iron and steel',
      },
      code: '2HZc',
      order: 1,
    },
    '非鉄金属及びその合金': {
      id: '非鉄金属及びその合金',
      name: {
        ja: '非鉄金属及びその合金',
        en: 'nonferrous metals and their alloys',
      },
      code: 'Vb7N',
      order: 2,
    },
    '金属鉱石': {
      id: '金属鉱石',
      name: {
        ja: '金属鉱石',
        en: 'ores of metal',
      },
      code: 'hZEq',
      order: 3,
    },
    '建築用又は構築用の金属製専用材料': {
      id: '建築用又は構築用の金属製専用材料',
      name: {
        ja: '建築用又は構築用の金属製専用材料',
        en: 'metal materials for building or construction',
      },
      code: 'IAVX',
      order: 4,
    },
    '金属製建造物組立てセット': {
      id: '金属製建造物組立てセット',
      name: {
        ja: '金属製建造物組立てセット',
        en: 'prefabricated building assembly kits of metal',
      },
      code: 'Qd1G',
      order: 5,
    },
    '金属製荷役用パレット，荷役用ターンテーブル，荷役用トラバーサー': {
      id: '金属製荷役用パレット，荷役用ターンテーブル，荷役用トラバーサー',
      name: {
        ja: '金属製荷役用パレット，荷役用ターンテーブル，荷役用トラバーサー',
        en: 'loading and unloading pallets of metal，turn-tables for load handling，traversers for load handling',
      },
      code: 'SVKR',
      order: 6,
    },
    '金属製人工魚礁': {
      id: '金属製人工魚礁',
      name: {
        ja: '金属製人工魚礁',
        en: 'artificial fish reefs of metal',
      },
      code: '81tU',
      order: 7,
    },
    '金属製養鶏用かご': {
      id: '金属製養鶏用かご',
      name: {
        ja: '金属製養鶏用かご',
        en: 'prefabricated sectional poultry houses of metal',
      },
      code: 't28k',
      order: 8,
    },
    '金属製の吹付け塗装用ブース': {
      id: '金属製の吹付け塗装用ブース',
      name: {
        ja: '金属製の吹付け塗装用ブース',
        en: 'paint spraying booths of metal',
      },
      code: 'TSGs',
      order: 9,
    },
    '金属製セメント製品製造用型枠': {
      id: '金属製セメント製品製造用型枠',
      name: {
        ja: '金属製セメント製品製造用型枠',
        en: 'metal moulds for forming cement products',
      },
      code: 'M7y7',
      order: 10,
    },
    '金属製の滑車・ばね及びバルブ（機械要素に当たるものを除く。）': {
      id: '金属製の滑車・ばね及びバルブ（機械要素に当たるものを除く。）',
      name: {
        ja: '金属製の滑車・ばね及びバルブ（機械要素に当たるものを除く。）',
        en: 'metal pulleys、 springs and valves [not including machine elements]',
      },
      code: 'D1xC',
      order: 11,
    },
    '金属製滑車（機械要素に当たるものを除く。）': {
      id: '金属製滑車（機械要素に当たるものを除く。）',
      name: {
        ja: '金属製滑車（機械要素に当たるものを除く。）',
        en: 'pulleys of metal、 not machine elements',
      },
      code: '4oN8',
      order: 12,
    },
    '金属製ばね（機械要素に当たるものを除く。）': {
      id: '金属製ばね（機械要素に当たるものを除く。）',
      name: {
        ja: '金属製ばね（機械要素に当たるものを除く。）',
        en: 'springs of metal、 not machine elements',
      },
      code: 'SQFC',
      order: 13,
    },
    '金属製バルブ（機械要素に当たるものを除く。）': {
      id: '金属製バルブ（機械要素に当たるものを除く。）',
      name: {
        ja: '金属製バルブ（機械要素に当たるものを除く。）',
        en: 'valves of metal、 not machine elements',
      },
      code: '1OHs',
      order: 14,
    },
    '金属製管継ぎ手，金属製フランジ': {
      id: '金属製管継ぎ手，金属製フランジ',
      name: {
        ja: '金属製管継ぎ手，金属製フランジ',
        en: 'metal junctions for pipes，metal flanges',
      },
      code: '4iOH',
      order: 15,
    },
    'キー，コッタ': {
      id: 'キー，コッタ',
      name: {
        ja: 'キー，コッタ',
        en: 'machine keys，cotter pins',
      },
      code: 'zJ2n',
      order: 16,
    },
    'てんてつ機': {
      id: 'てんてつ機',
      name: {
        ja: 'てんてつ機',
        en: 'railway points',
      },
      code: 'ocQ9',
      order: 17,
    },
    '金属製道路標識（発光式又は機械式のものを除く。）': {
      id: '金属製道路標識（発光式又は機械式のものを除く。）',
      name: {
        ja: '金属製道路標識（発光式又は機械式のものを除く。）',
        en: 'road signs of metal [not luminous nor mechanical]',
      },
      code: 'xfn4',
      order: 18,
    },
    '金属製航路標識（発光式のものを除く。）': {
      id: '金属製航路標識（発光式のものを除く。）',
      name: {
        ja: '金属製航路標識（発光式のものを除く。）',
        en: 'beacons of metal、 non-luminous',
      },
      code: 'hqyU',
      order: 19,
    },
    '金属製貯蔵槽類': {
      id: '金属製貯蔵槽類',
      name: {
        ja: '金属製貯蔵槽類',
        en: 'reservoirs of metal',
      },
      code: '9qTh',
      order: 20,
    },
    '金属製液体貯蔵槽，金属製工業用水槽': {
      id: '金属製液体貯蔵槽，金属製工業用水槽',
      name: {
        ja: '金属製液体貯蔵槽，金属製工業用水槽',
        en: 'liquid storage tanks or reservoirs of metal，industrial water storage tanks of metal',
      },
      code: 'u-0s',
      order: 21,
    },
    'ガス貯蔵槽又は液化ガス貯蔵槽用のアルミニウム製の浮中ぶた，金属製液化ガス貯蔵槽，金属製ガス貯蔵槽': {
      id: 'ガス貯蔵槽又は液化ガス貯蔵槽用のアルミニウム製の浮中ぶた，金属製液化ガス貯蔵槽，金属製ガス貯蔵槽',
      name: {
        ja: 'ガス貯蔵槽又は液化ガス貯蔵槽用のアルミニウム製の浮中ぶた，金属製液化ガス貯蔵槽，金属製ガス貯蔵槽',
        en: 'internal floating lids of aluminium for use with gas or liquefied gas storage tanks，liquefied gas storage tanks of metal，gas storage tanks of metal',
      },
      code: 'i5Mu',
      order: 22,
    },
    'いかり': {
      id: 'いかり',
      name: {
        ja: 'いかり',
        en: 'anchors*',
      },
      code: '-eCD',
      order: 23,
    },
    '金属製輸送用コンテナ': {
      id: '金属製輸送用コンテナ',
      name: {
        ja: '金属製輸送用コンテナ',
        en: 'containers of metal for transport',
      },
      code: 'AfKr',
      order: 24,
    },
    'かな床，はちの巣': {
      id: 'かな床，はちの巣',
      name: {
        ja: 'かな床，はちの巣',
        en: 'anvils，swage blocks',
      },
      code: 'TGnf',
      order: 25,
    },
    '金属製金具': {
      id: '金属製金具',
      name: {
        ja: '金属製金具',
        en: 'metal hardware',
      },
      code: 'Tm_y',
      order: 26,
    },
    '金属製金具（「安全錠・鍵用金属製リング・金属製鍵・南京錠」を除く。）': {
      id: '金属製金具（「安全錠・鍵用金属製リング・金属製鍵・南京錠」を除く。）',
      name: {
        ja: '金属製金具（「安全錠・鍵用金属製リング・金属製鍵・南京錠」を除く。）',
        en: 'metal hardware、 not including "security locks、 ring of metal for keys、 keys of metal for locks and padlocks"',
      },
      code: 'XlBG',
      order: 27,
    },
    '安全錠，鍵用金属製リング，金属製鍵，南京錠': {
      id: '安全錠，鍵用金属製リング，金属製鍵，南京錠',
      name: {
        ja: '安全錠，鍵用金属製リング，金属製鍵，南京錠',
        en: 'security locks，rings of metal for keys，keys of metal for locks，padlocks',
      },
      code: 'qgkw',
      order: 28,
    },
    'ワイヤロープ': {
      id: 'ワイヤロープ',
      name: {
        ja: 'ワイヤロープ',
        en: 'wire rope',
      },
      code: 't_7t',
      order: 29,
    },
    '金網': {
      id: '金網',
      name: {
        ja: '金網',
        en: 'wire nets and gauzes',
      },
      code: 'SiIm',
      order: 30,
    },
    '金属製包装用容器': {
      id: '金属製包装用容器',
      name: {
        ja: '金属製包装用容器',
        en: 'industrial packaging containers of metal',
      },
      code: 'hk5f',
      order: 31,
    },
    '金属製包装用容器（「金属製栓・金属製ふた」を除く。）': {
      id: '金属製包装用容器（「金属製栓・金属製ふた」を除く。）',
      name: {
        ja: '金属製包装用容器（「金属製栓・金属製ふた」を除く。）',
        en: 'industrial packaging containers of metal、 not including "metal stoppers、 lids and caps"',
      },
      code: 'Vxgm',
      order: 32,
    },
    '金属製栓，金属製ふた': {
      id: '金属製栓，金属製ふた',
      name: {
        ja: '金属製栓，金属製ふた',
        en: 'metal stoppers for industrial packaging containers，metal lids and caps for industrial packaging containers',
      },
      code: 'vBBa',
      order: 33,
    },
    '金属製のネームプレート及び標札': {
      id: '金属製のネームプレート及び標札',
      name: {
        ja: '金属製のネームプレート及び標札',
        en: 'metal nameplates and door nameplates',
      },
      code: 'DWoO',
      order: 34,
    },
    '金属製手持式旗ざお': {
      id: '金属製手持式旗ざお',
      name: {
        ja: '金属製手持式旗ざお',
        en: 'hand-held flagpoles of metal',
      },
      code: 'e2Ub',
      order: 35,
    },
    '金属製植物の茎支持具': {
      id: '金属製植物の茎支持具',
      name: {
        ja: '金属製植物の茎支持具',
        en: 'stakes of metal for plants or trees',
      },
      code: 'u0a6',
      order: 36,
    },
    '金属製のきゃたつ及びはしご': {
      id: '金属製のきゃたつ及びはしご',
      name: {
        ja: '金属製のきゃたつ及びはしご',
        en: 'metal stepladders and ladders',
      },
      code: '_OS6',
      order: 37,
    },
    '金属製郵便受け': {
      id: '金属製郵便受け',
      name: {
        ja: '金属製郵便受け',
        en: 'letter boxes of metal',
      },
      code: 'oR8X',
      order: 38,
    },
    '金属製帽子掛けかぎ': {
      id: '金属製帽子掛けかぎ',
      name: {
        ja: '金属製帽子掛けかぎ',
        en: 'hat-hanging hooks of metal',
      },
      code: 'HURa',
      order: 39,
    },
    'スーパーマーケットで使用する金属製手提げ用買物かご': {
      id: 'スーパーマーケットで使用する金属製手提げ用買物かご',
      name: {
        ja: 'スーパーマーケットで使用する金属製手提げ用買物かご',
        en: 'hand-held supermarket shopping baskets of metal',
      },
      code: '2DRC',
      order: 40,
    },
    '金属製家庭用水槽': {
      id: '金属製家庭用水槽',
      name: {
        ja: '金属製家庭用水槽',
        en: 'water tanks of metal for household purposes',
      },
      code: 'fd0t',
      order: 41,
    },
    '金属製工具箱': {
      id: '金属製工具箱',
      name: {
        ja: '金属製工具箱',
        en: 'tool boxes of metal、 empty',
      },
      code: 'Wygl',
      order: 42,
    },
    '紙タオル取り出し用金属製箱，金属製のタオル用ディスペンサー': {
      id: '紙タオル取り出し用金属製箱，金属製のタオル用ディスペンサー',
      name: {
        ja: '紙タオル取り出し用金属製箱，金属製のタオル用ディスペンサー',
        en: 'boxes of metal for dispensing paper towels，towel dispensers of metal',
      },
      code: 'C9WK',
      order: 43,
    },
    '金属製建具，金庫': {
      id: '金属製建具，金庫',
      name: {
        ja: '金属製建具，金庫',
        en: 'metal joinery fittings，safes',
      },
      code: 'zk1M',
      order: 44,
    },
    '金属製屋外用ブラインド': {
      id: '金属製屋外用ブラインド',
      name: {
        ja: '金属製屋外用ブラインド',
        en: 'outdoor blinds of metal',
      },
      code: 'fR9s',
      order: 45,
    },
    '金属製立て看板': {
      id: '金属製立て看板',
      name: {
        ja: '金属製立て看板',
        en: 'upright signboards of metal',
      },
      code: 'B5G_',
      order: 46,
    },
    '金属製人工池': {
      id: '金属製人工池',
      name: {
        ja: '金属製人工池',
        en: 'man-made garden ponds of metal [structures]',
      },
      code: 'G2Rh',
      order: 47,
    },
    '金属製の可搬式家庭用温室': {
      id: '金属製の可搬式家庭用温室',
      name: {
        ja: '金属製の可搬式家庭用温室',
        en: 'transportable greenhouses of metal for household use',
      },
      code: '7ACL',
      order: 48,
    },
    '金属製記念カップ，金属製記念たて': {
      id: '金属製記念カップ，金属製記念たて',
      name: {
        ja: '金属製記念カップ，金属製記念たて',
        en: 'trophies [prize cups] of common metal，commemorative shields of common metal',
      },
      code: 'J0mM',
      order: 49,
    },
    '金属製の墓標及び墓碑用銘板': {
      id: '金属製の墓標及び墓碑用銘板',
      name: {
        ja: '金属製の墓標及び墓碑用銘板',
        en: 'metal tombs and metal tomb plaques',
      },
      code: 'AqDV',
      order: 50,
    },
    '金属製靴合わせくぎ，金属製靴くぎ，金属製靴びょう': {
      id: '金属製靴合わせくぎ，金属製靴くぎ，金属製靴びょう',
      name: {
        ja: '金属製靴合わせくぎ，金属製靴くぎ，金属製靴びょう',
        en: 'shoe dowels of metal，shoe pegs of metal，hobnails of metal',
      },
      code: 'XZK8',
      order: 51,
    },
    'つえ用金属製石突き': {
      id: 'つえ用金属製石突き',
      name: {
        ja: 'つえ用金属製石突き',
        en: 'ferrules of metal for canes and walking-sticks',
      },
      code: 'x1QH',
      order: 52,
    },
    '拍車': {
      id: '拍車',
      name: {
        ja: '拍車',
        en: 'spurs',
      },
      code: 'F6wM',
      order: 53,
    },
    'アイゼン，カラビナ，ハーケン': {
      id: 'アイゼン，カラビナ，ハーケン',
      name: {
        ja: 'アイゼン，カラビナ，ハーケン',
        en: 'crampons [climbing irons]，carabiners，rock pitons',
      },
      code: 'l-no',
      order: 54,
    },
    '金属製彫刻': {
      id: '金属製彫刻',
      name: {
        ja: '金属製彫刻',
        en: 'sculptures of metal',
      },
      code: 'uOLC',
      order: 55,
    },
  },
  7: {
    '金属加工機械器具': {
      id: '金属加工機械器具',
      name: {
        ja: '金属加工機械器具',
        en: 'metalworking machines and tools',
      },
      code: 'oNzY',
      order: 1,
    },
    '鉱山機械器具': {
      id: '鉱山機械器具',
      name: {
        ja: '鉱山機械器具',
        en: 'mining machines and apparatus',
      },
      code: 'bjHS',
      order: 2,
    },
    '土木機械器具，荷役機械器具': {
      id: '土木機械器具，荷役機械器具',
      name: {
        ja: '土木機械器具，荷役機械器具',
        en: 'construction machines and apparatus，loading-unloading machines and apparatus',
      },
      code: 'GjDD',
      order: 3,
    },
    '漁業用機械器具': {
      id: '漁業用機械器具',
      name: {
        ja: '漁業用機械器具',
        en: 'industrial fishing machines',
      },
      code: 'Vay6',
      order: 4,
    },
    '化学機械器具': {
      id: '化学機械器具',
      name: {
        ja: '化学機械器具',
        en: 'chemical processing machines and apparatus',
      },
      code: 'gGRl',
      order: 5,
    },
    '繊維機械器具': {
      id: '繊維機械器具',
      name: {
        ja: '繊維機械器具',
        en: 'textile machines and apparatus',
      },
      code: 'V-R6',
      order: 6,
    },
    '食料加工用又は飲料加工用の機械器具': {
      id: '食料加工用又は飲料加工用の機械器具',
      name: {
        ja: '食料加工用又は飲料加工用の機械器具',
        en: 'food or beverage processing machines and apparatus',
      },
      code: 'ghPO',
      order: 7,
    },
    '製材用・木工用又は合板用の機械器具': {
      id: '製材用・木工用又は合板用の機械器具',
      name: {
        ja: '製材用・木工用又は合板用の機械器具',
        en: 'lumbering、 woodworking、 or veneer or plywood making machines and apparatus',
      },
      code: 'QR99',
      order: 8,
    },
    'パルプ製造用・製紙用又は紙工用の機械器具': {
      id: 'パルプ製造用・製紙用又は紙工用の機械器具',
      name: {
        ja: 'パルプ製造用・製紙用又は紙工用の機械器具',
        en: 'pulp making、 papermaking or paper-working machines and apparatus',
      },
      code: 'f5up',
      order: 9,
    },
    '印刷用又は製本用の機械器具': {
      id: '印刷用又は製本用の機械器具',
      name: {
        ja: '印刷用又は製本用の機械器具',
        en: 'printing or bookbinding machines and apparatus',
      },
      code: 'jknD',
      order: 10,
    },
    'ミシン': {
      id: 'ミシン',
      name: {
        ja: 'ミシン',
        en: 'sewing machines',
      },
      code: 'Q4a7',
      order: 11,
    },
    '農業用機械器具': {
      id: '農業用機械器具',
      name: {
        ja: '農業用機械器具',
        en: 'agricultural machines and agricultural implements、 other than hand-operated',
      },
      code: 'wZHX',
      order: 12,
    },
    '耕うん機械器具（手持工具に当たるものを除く。），栽培機械器具，収穫機械器具，植物粗製繊維加工機械器具，飼料圧搾機，飼料裁断機，飼料配合機，飼料粉砕機': {
      id: '耕うん機械器具（手持工具に当たるものを除く。），栽培機械器具，収穫機械器具，植物粗製繊維加工機械器具，飼料圧搾機，飼料裁断機，飼料配合機，飼料粉砕機',
      name: {
        ja: '耕うん機械器具（手持工具に当たるものを除く。），栽培機械器具，収穫機械器具，植物粗製繊維加工機械器具，飼料圧搾機，飼料裁断機，飼料配合機，飼料粉砕機',
        en: 'plowing machines and implements、 other than hand-held tools，cultivating machines and implements，harvesting machines and implements，plant fiber processing machines and implements for agricultural purposes，fodder presses，fodder cutting machines [feed cutters]，fodder mixing machines [feed mixers]，cake-fodder crushing machines [feed mills]',
      },
      code: 'Rhzb',
      order: 13,
    },
    '牛乳ろ過器，搾乳機': {
      id: '牛乳ろ過器，搾乳機',
      name: {
        ja: '牛乳ろ過器，搾乳機',
        en: 'milk filtering machines，milking machines',
      },
      code: 'p-SW',
      order: 14,
    },
    '育雛器，ふ卵器': {
      id: '育雛器，ふ卵器',
      name: {
        ja: '育雛器，ふ卵器',
        en: 'chick brooders，incubators for eggs',
      },
      code: 'L6l4',
      order: 15,
    },
    '蚕種製造用又は養蚕用の機械器具': {
      id: '蚕種製造用又は養蚕用の機械器具',
      name: {
        ja: '蚕種製造用又は養蚕用の機械器具',
        en: 'sericultural machines and implements',
      },
      code: '-cZg',
      order: 16,
    },
    '靴製造機械，製革機械': {
      id: '靴製造機械，製革機械',
      name: {
        ja: '靴製造機械，製革機械',
        en: 'shoe making machines，leather tanning machines',
      },
      code: 'qjBr',
      order: 17,
    },
    'たばこ製造機械': {
      id: 'たばこ製造機械',
      name: {
        ja: 'たばこ製造機械',
        en: 'tobacco processing machines',
      },
      code: '93Wf',
      order: 18,
    },
    'ガラス器製造機械': {
      id: 'ガラス器製造機械',
      name: {
        ja: 'ガラス器製造機械',
        en: 'glassware manufacturing machines and apparatus',
      },
      code: 'Y6u4',
      order: 19,
    },
    '塗装機械器具': {
      id: '塗装機械器具',
      name: {
        ja: '塗装機械器具',
        en: 'painting machines and apparatus',
      },
      code: 'Eykb',
      order: 20,
    },
    '包装用機械器具': {
      id: '包装用機械器具',
      name: {
        ja: '包装用機械器具',
        en: 'packaging or wrapping machines and apparatus',
      },
      code: 'FpuF',
      order: 21,
    },
    '陶工用ろくろ': {
      id: '陶工用ろくろ',
      name: {
        ja: '陶工用ろくろ',
        en: 'power-operated potters\' wheels',
      },
      code: 'e2ZP',
      order: 22,
    },
    'プラスチック加工機械器具': {
      id: 'プラスチック加工機械器具',
      name: {
        ja: 'プラスチック加工機械器具',
        en: 'plastic processing machines and apparatus',
      },
      code: 'qKns',
      order: 23,
    },
    '半導体製造装置': {
      id: '半導体製造装置',
      name: {
        ja: '半導体製造装置',
        en: 'semiconductor manufacturing machines',
      },
      code: 'I2cF',
      order: 24,
    },
    'ゴム製品製造機械器具': {
      id: 'ゴム製品製造機械器具',
      name: {
        ja: 'ゴム製品製造機械器具',
        en: 'machines and apparatus for manufacturing rubber goods',
      },
      code: '4EYK',
      order: 25,
    },
    '石材加工機械器具': {
      id: '石材加工機械器具',
      name: {
        ja: '石材加工機械器具',
        en: 'stone working machines and apparatus',
      },
      code: 'X1OL',
      order: 26,
    },
    '動力機械器具（陸上の乗物用のものを除く。），動力機械器具の部品': {
      id: '動力機械器具（陸上の乗物用のものを除く。），動力機械器具の部品',
      name: {
        ja: '動力機械器具（陸上の乗物用のものを除く。），動力機械器具の部品',
        en: 'non-electric prime movers、 not for land vehicles，parts of non-electric prime movers',
      },
      code: '-Yz7',
      order: 27,
    },
    '動力機械器具（陸上の乗物用のもの及び「水車・風車」を除く。），動力機械器具（「水車・風車」を除く。）の部品': {
      id: '動力機械器具（陸上の乗物用のもの及び「水車・風車」を除く。），動力機械器具（「水車・風車」を除く。）の部品',
      name: {
        ja: '動力機械器具（陸上の乗物用のもの及び「水車・風車」を除く。），動力機械器具（「水車・風車」を除く。）の部品',
        en: 'non-electric prime movers、 not for land vehicles、 other than water mills and wind mills，parts of non-electric prime movers、 other than water mills and wind mills',
      },
      code: 'aEyl',
      order: 28,
    },
    '水車，風車': {
      id: '水車，風車',
      name: {
        ja: '水車，風車',
        en: 'water mills，wind mills',
      },
      code: 'DsT-',
      order: 29,
    },
    '風水力機械器具': {
      id: '風水力機械器具',
      name: {
        ja: '風水力機械器具',
        en: 'pneumatic or hydraulic machines and instruments',
      },
      code: 'xVvL',
      order: 30,
    },
    '機械式の接着テープディスペンサー，自動スタンプ打ち器': {
      id: '機械式の接着テープディスペンサー，自動スタンプ打ち器',
      name: {
        ja: '機械式の接着テープディスペンサー，自動スタンプ打ち器',
        en: 'adhesive tape dispensing machines，automatic stamping machines',
      },
      code: 'T0WF',
      order: 31,
    },
    '自動販売機': {
      id: '自動販売機',
      name: {
        ja: '自動販売機',
        en: 'vending machines',
      },
      code: 'KZvq',
      order: 32,
    },
    'ガソリンステーション用装置': {
      id: 'ガソリンステーション用装置',
      name: {
        ja: 'ガソリンステーション用装置',
        en: 'fuel dispensing machines for service stations',
      },
      code: 'FN9x',
      order: 33,
    },
    '電気洗濯機': {
      id: '電気洗濯機',
      name: {
        ja: '電気洗濯機',
        en: 'washing machines [laundry]',
      },
      code: 'l-Gq',
      order: 34,
    },
    '業務用電気洗濯機': {
      id: '業務用電気洗濯機',
      name: {
        ja: '業務用電気洗濯機',
        en: 'washing machines for industrial purposes [laundry]',
      },
      code: 'fUk0',
      order: 35,
    },
    '家庭用電気洗濯機': {
      id: '家庭用電気洗濯機',
      name: {
        ja: '家庭用電気洗濯機',
        en: 'washing machines for household purposes [laundry]',
      },
      code: 'cABc',
      order: 36,
    },
    '修繕用機械器具': {
      id: '修繕用機械器具',
      name: {
        ja: '修繕用機械器具',
        en: 'repairing or fixing machines and apparatus',
      },
      code: 'SfGz',
      order: 37,
    },
    '機械式駐車装置': {
      id: '機械式駐車装置',
      name: {
        ja: '機械式駐車装置',
        en: 'mechanical parking systems',
      },
      code: 'iRAj',
      order: 38,
    },
    '乗物用洗浄機': {
      id: '乗物用洗浄機',
      name: {
        ja: '乗物用洗浄機',
        en: 'vehicle washing installations',
      },
      code: '3z5q',
      order: 39,
    },
    '業務用攪はん混合機，業務用皮むき機，業務用切さい機': {
      id: '業務用攪はん混合機，業務用皮むき機，業務用切さい機',
      name: {
        ja: '業務用攪はん混合機，業務用皮むき機，業務用切さい機',
        en: 'food mixing machines for commercial use，food peeling machines for commercial use，food cutting、 chopping and slicing machines for commercial use',
      },
      code: 'MdgX',
      order: 40,
    },
    '食器洗浄機': {
      id: '食器洗浄機',
      name: {
        ja: '食器洗浄機',
        en: 'dishwashers',
      },
      code: 'PTbs',
      order: 41,
    },
    '業務用食器洗浄機': {
      id: '業務用食器洗浄機',
      name: {
        ja: '業務用食器洗浄機',
        en: 'dishwashers for industrial purposes',
      },
      code: 'YbFE',
      order: 42,
    },
    '家庭用食器洗浄機': {
      id: '家庭用食器洗浄機',
      name: {
        ja: '家庭用食器洗浄機',
        en: 'dishwashers for household purposes',
      },
      code: '6HX2',
      order: 43,
    },
    '電気式ワックス磨き機，電気掃除機': {
      id: '電気式ワックス磨き機，電気掃除機',
      name: {
        ja: '電気式ワックス磨き機，電気掃除機',
        en: 'machines and apparatus for wax-polishing、 electric，vacuum cleaners',
      },
      code: 'Pd-q',
      order: 44,
    },
    '業務用電気式ワックス磨き機，業務用電気掃除機': {
      id: '業務用電気式ワックス磨き機，業務用電気掃除機',
      name: {
        ja: '業務用電気式ワックス磨き機，業務用電気掃除機',
        en: 'electric wax-polishing machines for industrial purposes，vacuum cleaners for industrial purposes',
      },
      code: 'qv9Z',
      order: 45,
    },
    '家庭用電気式ワックス磨き機，家庭用電気掃除機': {
      id: '家庭用電気式ワックス磨き機，家庭用電気掃除機',
      name: {
        ja: '家庭用電気式ワックス磨き機，家庭用電気掃除機',
        en: 'electric wax-polishing machines for household purposes，vacuum cleaners for household purposes',
      },
      code: 'lT4y',
      order: 46,
    },
    '消毒・殺虫又は防臭用散布機（農業用のものを除く。）': {
      id: '消毒・殺虫又は防臭用散布機（農業用のものを除く。）',
      name: {
        ja: '消毒・殺虫又は防臭用散布機（農業用のものを除く。）',
        en: 'power sprayers for disinfecting、 insecticides and deodorants [not for agricultural purposes]',
      },
      code: 'L_4x',
      order: 47,
    },
    '機械要素（陸上の乗物用のものを除く。）': {
      id: '機械要素（陸上の乗物用のものを除く。）',
      name: {
        ja: '機械要素（陸上の乗物用のものを除く。）',
        en: 'machine elements、 not for land vehicles',
      },
      code: 'TAzT',
      order: 48,
    },
    '軸（陸上の乗物用のものを除く機械要素），軸受（陸上の乗物用のものを除く機械要素），軸継ぎ手（陸上の乗物用のものを除く機械要素）': {
      id: '軸（陸上の乗物用のものを除く機械要素），軸受（陸上の乗物用のものを除く機械要素），軸継ぎ手（陸上の乗物用のものを除く機械要素）',
      name: {
        ja: '軸（陸上の乗物用のものを除く機械要素），軸受（陸上の乗物用のものを除く機械要素），軸継ぎ手（陸上の乗物用のものを除く機械要素）',
        en: 'shafts、 axles or spindles、 machine elements other than land vehicles，bearings、 machine elements not for land vehicles，shafts、 couplings or connectors、 machine elements not for land vehicles',
      },
      code: 'KhWu',
      order: 49,
    },
    '動力伝導装置（陸上の乗物用のものを除く機械要素）': {
      id: '動力伝導装置（陸上の乗物用のものを除く機械要素）',
      name: {
        ja: '動力伝導装置（陸上の乗物用のものを除く機械要素）',
        en: 'power transmissions and gearing、 machine elements not for land vehicles',
      },
      code: 'MBaw',
      order: 50,
    },
    '緩衝器（陸上の乗物用のものを除く機械要素），ばね（陸上の乗物用のものを除く機械要素）': {
      id: '緩衝器（陸上の乗物用のものを除く機械要素），ばね（陸上の乗物用のものを除く機械要素）',
      name: {
        ja: '緩衝器（陸上の乗物用のものを除く機械要素），ばね（陸上の乗物用のものを除く機械要素）',
        en: 'shock absorbers、 machine elements not for land vehicles，springs、 machine elements not for land vehicles',
      },
      code: 'Uzgu',
      order: 51,
    },
    '制動装置（陸上の乗物用のものを除く機械要素）': {
      id: '制動装置（陸上の乗物用のものを除く機械要素）',
      name: {
        ja: '制動装置（陸上の乗物用のものを除く機械要素）',
        en: 'brakes、 machine elements not for land vehicles',
      },
      code: '4mAR',
      order: 52,
    },
    'バルブ（陸上の乗物用のものを除く機械要素）': {
      id: 'バルブ（陸上の乗物用のものを除く機械要素）',
      name: {
        ja: 'バルブ（陸上の乗物用のものを除く機械要素）',
        en: 'valves、 machine elements not for land vehicles',
      },
      code: 'uM3G',
      order: 53,
    },
    '芝刈機': {
      id: '芝刈機',
      name: {
        ja: '芝刈機',
        en: 'lawnmowers',
      },
      code: 'BWMo',
      order: 54,
    },
    '電動式カーテン引き装置，電動式扉自動開閉装置': {
      id: '電動式カーテン引き装置，電動式扉自動開閉装置',
      name: {
        ja: '電動式カーテン引き装置，電動式扉自動開閉装置',
        en: 'curtain drawing devices electrically operated，electric door openers',
      },
      code: 'rBNJ',
      order: 55,
    },
    '業務用廃棄物圧縮装置，業務用廃棄物破砕装置': {
      id: '業務用廃棄物圧縮装置，業務用廃棄物破砕装置',
      name: {
        ja: '業務用廃棄物圧縮装置，業務用廃棄物破砕装置',
        en: 'waste compacting machines and apparatus for industrial purposes，waste crushing machines for industrial purposes',
      },
      code: '_gWy',
      order: 56,
    },
    '３Ｄプリンター': {
      id: '３Ｄプリンター',
      name: {
        ja: '３Ｄプリンター',
        en: '3D printers',
      },
      code: 'j3A-',
      order: 57,
    },
    '起動器，交流電動機及び直流電動機（陸上の乗物用の交流電動機及び直流電動機（その部品を除く。）を除く。），交流発電機，直流発電機': {
      id: '起動器，交流電動機及び直流電動機（陸上の乗物用の交流電動機及び直流電動機（その部品を除く。）を除く。），交流発電機，直流発電機',
      name: {
        ja: '起動器，交流電動機及び直流電動機（陸上の乗物用の交流電動機及び直流電動機（その部品を除く。）を除く。），交流発電機，直流発電機',
        en: 'starters for motors and engines，AC motors and DC motors [not including those for land vehicles but including parts for any AC motors and DC motors]，AC generators [alternators]，DC generators',
      },
      code: 'ZFFF',
      order: 58,
    },
    '電気ミキサー': {
      id: '電気ミキサー',
      name: {
        ja: '電気ミキサー',
        en: 'electric food blenders for household purposes',
      },
      code: 'mI0O',
      order: 59,
    },
    '電機ブラシ': {
      id: '電機ブラシ',
      name: {
        ja: '電機ブラシ',
        en: 'dynamo brushes',
      },
      code: '2reG',
      order: 60,
    },
    'ミシン針': {
      id: 'ミシン針',
      name: {
        ja: 'ミシン針',
        en: 'needles for sewing machines',
      },
      code: '2ksa',
      order: 61,
    },
  },
  8: {
    'ピンセット': {
      id: 'ピンセット',
      name: {
        ja: 'ピンセット',
        en: 'tweezers',
      },
      code: 'KBmi',
      order: 1,
    },
    '組ひも機（手持工具に当たるものに限る。），人力織機': {
      id: '組ひも機（手持工具に当たるものに限る。），人力織機',
      name: {
        ja: '組ひも機（手持工具に当たるものに限る。），人力織機',
        en: 'braiders [hand-held ones only]，manual looms',
      },
      code: 'TEWq',
      order: 2,
    },
    'くわ，鋤，レーキ（手持工具に当たるものに限る。）': {
      id: 'くわ，鋤，レーキ（手持工具に当たるものに限る。）',
      name: {
        ja: 'くわ，鋤，レーキ（手持工具に当たるものに限る。）',
        en: 'hoes [hand-held]，digging forks [spading forks]，lawn rakes [hand-held ones only]',
      },
      code: 'DFfG',
      order: 3,
    },
    '靴製造用靴型（手持工具に当たるものに限る。）': {
      id: '靴製造用靴型（手持工具に当たるものに限る。）',
      name: {
        ja: '靴製造用靴型（手持工具に当たるものに限る。）',
        en: 'lasts for shoe-making [hand-held ones only]',
      },
      code: 'sRTp',
      order: 4,
    },
    '電気アイロン': {
      id: '電気アイロン',
      name: {
        ja: '電気アイロン',
        en: 'electric flat irons',
      },
      code: 'tIPg',
      order: 5,
    },
    '電気かみそり及び電気バリカン': {
      id: '電気かみそり及び電気バリカン',
      name: {
        ja: '電気かみそり及び電気バリカン',
        en: 'electric razors and electric hair clippers',
      },
      code: 'MBQR',
      order: 6,
    },
    'ヘアアイロン': {
      id: 'ヘアアイロン',
      name: {
        ja: 'ヘアアイロン',
        en: 'hair styling irons',
      },
      code: 'JDQK',
      order: 7,
    },
    '手動利器': {
      id: '手動利器',
      name: {
        ja: '手動利器',
        en: 'bladed or pointed hand tools and swords',
      },
      code: '8frl',
      order: 8,
    },
    '手動利器（「刀剣」を除く。）': {
      id: '手動利器（「刀剣」を除く。）',
      name: {
        ja: '手動利器（「刀剣」を除く。）',
        en: 'bladed or pointed hand tools',
      },
      code: 'bWYb',
      order: 9,
    },
    '刀剣': {
      id: '刀剣',
      name: {
        ja: '刀剣',
        en: 'swords',
      },
      code: 'g-y5',
      order: 10,
    },
    '手動工具': {
      id: '手動工具',
      name: {
        ja: '手動工具',
        en: 'hand tools、 hand operated、 other than bladed or pointed hand tools',
      },
      code: 'zTBs',
      order: 11,
    },
    '手動工具（「すみつぼ類・革砥・鋼砥・砥石」を除く。）': {
      id: '手動工具（「すみつぼ類・革砥・鋼砥・砥石」を除く。）',
      name: {
        ja: '手動工具（「すみつぼ類・革砥・鋼砥・砥石」を除く。）',
        en: 'hand tools [other than "carpenters\' inkpots and their accessories、 razor strops、 sharpening steels and whetstones"]',
      },
      code: 'qbJG',
      order: 12,
    },
    'すみつぼ類': {
      id: 'すみつぼ類',
      name: {
        ja: 'すみつぼ類',
        en: 'carpenters\' inkpots and their accessories',
      },
      code: 'X1Mx',
      order: 13,
    },
    '革砥，鋼砥，砥石': {
      id: '革砥，鋼砥，砥石',
      name: {
        ja: '革砥，鋼砥，砥石',
        en: 'razor strops [leather strops]，sharpening steels，whetstones [sharpening stones]',
      },
      code: 'WXxX',
      order: 14,
    },
    'エッグスライサー（電気式のものを除く。），かつお節削り器，缶切，スプーン，チーズスライサー（電気式のものを除く。），ピザカッター（電気式のものを除く。），フォーク': {
      id: 'エッグスライサー（電気式のものを除く。），かつお節削り器，缶切，スプーン，チーズスライサー（電気式のものを除く。），ピザカッター（電気式のものを除く。），フォーク',
      name: {
        ja: 'エッグスライサー（電気式のものを除く。），かつお節削り器，缶切，スプーン，チーズスライサー（電気式のものを除く。），ピザカッター（電気式のものを除く。），フォーク',
        en: 'egg slicers、 non-electric，non-electric planes for flaking dried bonito blocks [Katsuo-bushi planes]，can openers、 non-electric，spoons*，cheese slicers、 non-electric，pizza cutters、 non-electric，forks [cutlery]',
      },
      code: 'LZYT',
      order: 15,
    },
    'チャコ削り器': {
      id: 'チャコ削り器',
      name: {
        ja: 'チャコ削り器',
        en: 'dressmakers\' chalk sharpeners',
      },
      code: '6OvW',
      order: 16,
    },
    '十能，暖炉用ふいご（手持工具に当たるものに限る。），火ばし': {
      id: '十能，暖炉用ふいご（手持工具に当たるものに限る。），火ばし',
      name: {
        ja: '十能，暖炉用ふいご（手持工具に当たるものに限る。），火ばし',
        en: 'fire pans for carrying live charcoal [Juno]，fireplace bellows [hand-tools]，fire tongs',
      },
      code: 'cqzL',
      order: 17,
    },
    '護身棒': {
      id: '護身棒',
      name: {
        ja: '護身棒',
        en: 'bludgeons',
      },
      code: 'ipJC',
      order: 18,
    },
    'ひげそり用具入れ，ペディキュアセット，まつ毛カール器，マニキュアセット': {
      id: 'ひげそり用具入れ，ペディキュアセット，まつ毛カール器，マニキュアセット',
      name: {
        ja: 'ひげそり用具入れ，ペディキュアセット，まつ毛カール器，マニキュアセット',
        en: 'shaving cases，pedicure sets，eyelash curlers，manicure sets',
      },
      code: '5VLt',
      order: 19,
    },
    'ピッケル': {
      id: 'ピッケル',
      name: {
        ja: 'ピッケル',
        en: 'ice axes',
      },
      code: 'nAk4',
      order: 20,
    },
    '水中ナイフ，水中ナイフ保持具': {
      id: '水中ナイフ，水中ナイフ保持具',
      name: {
        ja: '水中ナイフ，水中ナイフ保持具',
        en: 'diving knives，diving knife holders',
      },
      code: 'exIl',
      order: 21,
    },
    'パレットナイフ': {
      id: 'パレットナイフ',
      name: {
        ja: 'パレットナイフ',
        en: 'palette knives',
      },
      code: 'cXMk',
      order: 22,
    },
  },
  9: {
    '潜水用耳栓': {
      id: '潜水用耳栓',
      name: {
        ja: '潜水用耳栓',
        en: 'ear plugs for divers',
      },
      code: 'TJAQ',
      order: 1,
    },
    '検卵器': {
      id: '検卵器',
      name: {
        ja: '検卵器',
        en: 'egg-candlers',
      },
      code: 'zfaF',
      order: 2,
    },
    '青写真複写機，金銭登録機，硬貨の計数用又は選別用の機械，写真複写機，製図用又は図案用の機械器具，タイムスタンプ，タイムレコーダー，パンチカードシステム機械，票数計算機，郵便切手の貼り付けチェック装置': {
      id: '青写真複写機，金銭登録機，硬貨の計数用又は選別用の機械，写真複写機，製図用又は図案用の機械器具，タイムスタンプ，タイムレコーダー，パンチカードシステム機械，票数計算機，郵便切手の貼り付けチェック装置',
      name: {
        ja: '青写真複写機，金銭登録機，硬貨の計数用又は選別用の機械，写真複写機，製図用又は図案用の機械器具，タイムスタンプ，タイムレコーダー，パンチカードシステム機械，票数計算機，郵便切手の貼り付けチェック装置',
        en: 'blueprint apparatus，cash registers，coin counting or sorting machines，photo-copying machines，mathematical instruments，time and date stamping machines，time clocks [time recording devices]，punched card office machines，voting machines，postage stamp checking apparatus',
      },
      code: 'BGvE',
      order: 3,
    },
    '駐車場用硬貨作動式ゲート': {
      id: '駐車場用硬貨作動式ゲート',
      name: {
        ja: '駐車場用硬貨作動式ゲート',
        en: 'mechanisms for coin-operated car parking gates',
      },
      code: 'WTwh',
      order: 4,
    },
    '救命用具': {
      id: '救命用具',
      name: {
        ja: '救命用具',
        en: 'life-saving apparatus and equipment',
      },
      code: 'CFLm',
      order: 5,
    },
    '消火器，消火栓，消火ホース，消火ホース用ノズル，スプリンクラー消火装置': {
      id: '消火器，消火栓，消火ホース，消火ホース用ノズル，スプリンクラー消火装置',
      name: {
        ja: '消火器，消火栓，消火ホース，消火ホース用ノズル，スプリンクラー消火装置',
        en: 'fire extinguishers，fireplugs，fire hoses，fire hose nozzles，sprinkler systems for fire protection',
      },
      code: 'QLf9',
      order: 6,
    },
    '火災報知機，ガス漏れ警報器，盗難警報器': {
      id: '火災報知機，ガス漏れ警報器，盗難警報器',
      name: {
        ja: '火災報知機，ガス漏れ警報器，盗難警報器',
        en: 'fire alarms，gas alarms，anti-theft warning apparatus',
      },
      code: 'G1H9',
      order: 7,
    },
    '保安用ヘルメット': {
      id: '保安用ヘルメット',
      name: {
        ja: '保安用ヘルメット',
        en: 'safety helmets',
      },
      code: 'R8SX',
      order: 8,
    },
    '鉄道用信号機': {
      id: '鉄道用信号機',
      name: {
        ja: '鉄道用信号機',
        en: 'railway signal apparatus、 luminous or mechanical',
      },
      code: '9VlB',
      order: 9,
    },
    '乗物の故障の警告用の三角標識，発光式又は機械式の道路標識': {
      id: '乗物の故障の警告用の三角標識，発光式又は機械式の道路標識',
      name: {
        ja: '乗物の故障の警告用の三角標識，発光式又は機械式の道路標識',
        en: 'vehicle breakdown warning triangles，road signs、 luminous or mechanical',
      },
      code: '4-F6',
      order: 10,
    },
    '潜水用機械器具': {
      id: '潜水用機械器具',
      name: {
        ja: '潜水用機械器具',
        en: 'diving machinery and equipment',
      },
      code: 'tKrI',
      order: 11,
    },
    '業務用テレビゲーム機用プログラム': {
      id: '業務用テレビゲーム機用プログラム',
      name: {
        ja: '業務用テレビゲーム機用プログラム',
        en: 'game programs for arcade video game machines',
      },
      code: 'G6db',
      order: 12,
    },
    '乗物運転技能訓練用シミュレーター': {
      id: '乗物運転技能訓練用シミュレーター',
      name: {
        ja: '乗物運転技能訓練用シミュレーター',
        en: 'simulators for the steering and control of vehicles',
      },
      code: 'tZwE',
      order: 13,
    },
    '運動技能訓練用シミュレーター': {
      id: '運動技能訓練用シミュレーター',
      name: {
        ja: '運動技能訓練用シミュレーター',
        en: 'sports training simulators',
      },
      code: 'lV3y',
      order: 14,
    },
    '理化学機械器具': {
      id: '理化学機械器具',
      name: {
        ja: '理化学機械器具',
        en: 'laboratory apparatus and instruments',
      },
      code: 'FbmJ',
      order: 15,
    },
    '写真機械器具，映画機械器具，光学機械器具': {
      id: '写真機械器具，映画機械器具，光学機械器具',
      name: {
        ja: '写真機械器具，映画機械器具，光学機械器具',
        en: 'photographic machines and apparatus，cinematographic machines and apparatus，optical machines and apparatus',
      },
      code: 'Mhu1',
      order: 16,
    },
    '測定機械器具': {
      id: '測定機械器具',
      name: {
        ja: '測定機械器具',
        en: 'measuring or testing machines and instruments',
      },
      code: 'fqf0',
      order: 17,
    },
    '配電用又は制御用の機械器具，回転変流機，調相機': {
      id: '配電用又は制御用の機械器具，回転変流機，調相機',
      name: {
        ja: '配電用又は制御用の機械器具，回転変流機，調相機',
        en: 'power distribution or control machines and apparatus，rotary converters，phase modifiers',
      },
      code: 'JxS5',
      order: 18,
    },
    '太陽電池': {
      id: '太陽電池',
      name: {
        ja: '太陽電池',
        en: 'solar batteries',
      },
      code: 'KsLA',
      order: 19,
    },
    '電池': {
      id: '電池',
      name: {
        ja: '電池',
        en: 'batteries and cells',
      },
      code: 'U1xx',
      order: 20,
    },
    '電気磁気測定器': {
      id: '電気磁気測定器',
      name: {
        ja: '電気磁気測定器',
        en: 'electric or magnetic meters and testers',
      },
      code: 'k6LN',
      order: 21,
    },
    '電線及びケーブル': {
      id: '電線及びケーブル',
      name: {
        ja: '電線及びケーブル',
        en: 'electric wires and cables',
      },
      code: '4_uz',
      order: 22,
    },
    '電気通信機械器具': {
      id: '電気通信機械器具',
      name: {
        ja: '電気通信機械器具',
        en: 'telecommunication machines and apparatus',
      },
      code: 'hUx0',
      order: 23,
    },
    '携帯情報端末': {
      id: '携帯情報端末',
      name: {
        ja: '携帯情報端末',
        en: 'personal digital assistants',
      },
      code: 'rEsr',
      order: 24,
    },
    '電子応用機械器具及びその部品': {
      id: '電子応用機械器具及びその部品',
      name: {
        ja: '電子応用機械器具及びその部品',
        en: 'electron tubes，semi-conductor elements，electronic circuits、 not including those recorded with computer programs，computer programs',
      },
      code: 'pTw6',
      order: 25,
    },
    '電子応用機械器具（「ガイガー計数器・高周波ミシン・サイクロトロン・産業用Ｘ線機械器具・産業用ベータートロン・磁気探鉱機・磁気探知機・地震探鉱機械器具・水中聴音機械器具・超音波応用測深器・超音波応用探傷器・超音波応用探知機・電子応用扉自動開閉装置・電子顕微鏡」を除く。），電子管，半導体素子，電子回路（「電子計算機用プログラムを記憶させた電子回路」を除く。），電子計算機用プログラム': {
      id: '電子応用機械器具（「ガイガー計数器・高周波ミシン・サイクロトロン・産業用Ｘ線機械器具・産業用ベータートロン・磁気探鉱機・磁気探知機・地震探鉱機械器具・水中聴音機械器具・超音波応用測深器・超音波応用探傷器・超音波応用探知機・電子応用扉自動開閉装置・電子顕微鏡」を除く。），電子管，半導体素子，電子回路（「電子計算機用プログラムを記憶させた電子回路」を除く。），電子計算機用プログラム',
      name: {
        ja: '電子応用機械器具（「ガイガー計数器・高周波ミシン・サイクロトロン・産業用Ｘ線機械器具・産業用ベータートロン・磁気探鉱機・磁気探知機・地震探鉱機械器具・水中聴音機械器具・超音波応用測深器・超音波応用探傷器・超音波応用探知機・電子応用扉自動開閉装置・電子顕微鏡」を除く。），電子管，半導体素子，電子回路（「電子計算機用プログラムを記憶させた電子回路」を除く。），電子計算機用プログラム',
        en: 'electron tubes，semi-conductor elements，electronic circuits、 not including those recorded with computer programs，computer programs',
      },
      code: 'yjzq',
      order: 26,
    },
    'ガイガー計数器，高周波ミシン，サイクロトロン，産業用Ｘ線機械器具，産業用ベータートロン，磁気探鉱機，磁気探知機，地震探鉱機械器具，水中聴音機械器具，超音波応用測深器，超音波応用探傷器，超音波応用探知機，電子応用扉自動開閉装置，電子顕微鏡': {
      id: 'ガイガー計数器，高周波ミシン，サイクロトロン，産業用Ｘ線機械器具，産業用ベータートロン，磁気探鉱機，磁気探知機，地震探鉱機械器具，水中聴音機械器具，超音波応用測深器，超音波応用探傷器，超音波応用探知機，電子応用扉自動開閉装置，電子顕微鏡',
      name: {
        ja: 'ガイガー計数器，高周波ミシン，サイクロトロン，産業用Ｘ線機械器具，産業用ベータートロン，磁気探鉱機，磁気探知機，地震探鉱機械器具，水中聴音機械器具，超音波応用測深器，超音波応用探傷器，超音波応用探知機，電子応用扉自動開閉装置，電子顕微鏡',
        en: 'geiger counters，high frequency apparatus for welding，cyclotrons、 not for medical purposes，industrial X-ray machines and apparatus、 not for medical use，industrial betatrons、 not for medical use，magnetic prospecting machines，magnetic object detectors，seismic exploration machines and apparatus，hydrophone machines and apparatus，echo sounders，ultrasonic flaw detectors，ultrasonic sensors，electronic door closing control systems，electron microscopes',
      },
      code: 'a1mz',
      order: 27,
    },
    '磁心，抵抗線，電極': {
      id: '磁心，抵抗線，電極',
      name: {
        ja: '磁心，抵抗線，電極',
        en: 'magnetic cores，resistance wires，electrodes、 other than welding electrodes or medical electrodes',
      },
      code: '4Ivw',
      order: 28,
    },
    '消防艇': {
      id: '消防艇',
      name: {
        ja: '消防艇',
        en: 'fire boats',
      },
      code: 'deDI',
      order: 29,
    },
    '科学用人工衛星': {
      id: '科学用人工衛星',
      name: {
        ja: '科学用人工衛星',
        en: 'satellites for scientific purposes',
      },
      code: 'ec-M',
      order: 30,
    },
    '消防車': {
      id: '消防車',
      name: {
        ja: '消防車',
        en: 'fire engines',
      },
      code: 'hpV0',
      order: 31,
    },
    '防じんマスク，防毒マスク，溶接マスク': {
      id: '防じんマスク，防毒マスク，溶接マスク',
      name: {
        ja: '防じんマスク，防毒マスク，溶接マスク',
        en: 'dust masks，gas masks，welding masks',
      },
      code: 'xQ2N',
      order: 32,
    },
    '防火被服，防災頭巾': {
      id: '防火被服，防災頭巾',
      name: {
        ja: '防火被服，防災頭巾',
        en: 'fireproof garments，protective hoods for the prevention of accident or injury',
      },
      code: '2m6p',
      order: 33,
    },
    '事故防護用手袋': {
      id: '事故防護用手袋',
      name: {
        ja: '事故防護用手袋',
        en: 'gloves for protection against accidents',
      },
      code: '1_e5',
      order: 34,
    },
    '眼鏡': {
      id: '眼鏡',
      name: {
        ja: '眼鏡',
        en: 'spectacles [eyeglasses and goggles]',
      },
      code: 'Nbvc',
      order: 35,
    },
    '家庭用テレビゲーム機用プログラム，携帯用液晶画面ゲーム機用のプログラムを記憶させた電子回路及びＣＤ－ＲＯＭ': {
      id: '家庭用テレビゲーム機用プログラム，携帯用液晶画面ゲーム機用のプログラムを記憶させた電子回路及びＣＤ－ＲＯＭ',
      name: {
        ja: '家庭用テレビゲーム機用プログラム，携帯用液晶画面ゲーム機用のプログラムを記憶させた電子回路及びＣＤ－ＲＯＭ',
        en: 'game programs for home video game machines，electronic circuits and CD-ROMs recorded with programs for hand-held games with liquid crystal displays',
      },
      code: '1UTQ',
      order: 36,
    },
    'スロットマシン用プログラム，ぱちんこ器具用プログラム': {
      id: 'スロットマシン用プログラム，ぱちんこ器具用プログラム',
      name: {
        ja: 'スロットマシン用プログラム，ぱちんこ器具用プログラム',
        en: 'slot machine programs，pachinko programs',
      },
      code: 'Gstv',
      order: 37,
    },
    '運動用保護ヘルメット，ホイッスル': {
      id: '運動用保護ヘルメット，ホイッスル',
      name: {
        ja: '運動用保護ヘルメット，ホイッスル',
        en: 'protective helmets for sports，sports whistles',
      },
      code: 'KxUD',
      order: 38,
    },
    'ウエイトベルト，エアタンク，シュノーケル，レギュレーター，水上スポーツ用特殊衣服': {
      id: 'ウエイトベルト，エアタンク，シュノーケル，レギュレーター，水上スポーツ用特殊衣服',
      name: {
        ja: 'ウエイトベルト，エアタンク，シュノーケル，レギュレーター，水上スポーツ用特殊衣服',
        en: 'weight belts for scuba diving，air tanks for scuba diving，snorkels，regulators for scuba diving，clothes for water sports',
      },
      code: '0kyS',
      order: 39,
    },
    'メトロノーム，電子楽器用自動演奏プログラムを記憶させた電子回路及びＣＤ－ＲＯＭ，楽器用エフェクター': {
      id: 'メトロノーム，電子楽器用自動演奏プログラムを記憶させた電子回路及びＣＤ－ＲＯＭ，楽器用エフェクター',
      name: {
        ja: 'メトロノーム，電子楽器用自動演奏プログラムを記憶させた電子回路及びＣＤ－ＲＯＭ，楽器用エフェクター',
        en: 'metronomes，electronic circuits and CD-ROMs recorded with automatic performance programs for electronic musical instruments，electric and electronic effects units for musical instruments',
      },
      code: 'QrSf',
      order: 40,
    },
    'レコード，インターネットを利用して受信し及び保存することができる音楽ファイル': {
      id: 'レコード，インターネットを利用して受信し及び保存することができる音楽ファイル',
      name: {
        ja: 'レコード，インターネットを利用して受信し及び保存することができる音楽ファイル',
        en: 'phonograph records，downloadable music files',
      },
      code: 'Z1We',
      order: 41,
    },
    'インターネットを利用して受信し及び保存することができる画像ファイル，録画済みビデオディスク及びビデオテープ': {
      id: 'インターネットを利用して受信し及び保存することができる画像ファイル，録画済みビデオディスク及びビデオテープ',
      name: {
        ja: 'インターネットを利用して受信し及び保存することができる画像ファイル，録画済みビデオディスク及びビデオテープ',
        en: 'downloadable image files，recorded video discs and video tapes',
      },
      code: 'Xe31',
      order: 42,
    },
    '電子出版物': {
      id: '電子出版物',
      name: {
        ja: '電子出版物',
        en: 'electronic publications',
      },
      code: 'Owqj',
      order: 43,
    },
    '映写フィルム，スライドフィルム，スライドフィルム用マウント': {
      id: '映写フィルム，スライドフィルム，スライドフィルム用マウント',
      name: {
        ja: '映写フィルム，スライドフィルム，スライドフィルム用マウント',
        en: 'exposed cinematographic films，exposed slide films，slide film mounts',
      },
      code: 'FXXc',
      order: 44,
    },
  },
  10: {
    '医療用指サック，衛生マスク，おしゃぶり，氷まくら，三角きん，支持包帯，手術用キャットガット，吸い飲み，スポイト，乳首，氷のう，氷のうつり，哺乳用具，魔法哺乳器': {
      id: '医療用指サック，衛生マスク，おしゃぶり，氷まくら，三角きん，支持包帯，手術用キャットガット，吸い飲み，スポイト，乳首，氷のう，氷のうつり，哺乳用具，魔法哺乳器',
      name: {
        ja: '医療用指サック，衛生マスク，おしゃぶり，氷まくら，三角きん，支持包帯，手術用キャットガット，吸い飲み，スポイト，乳首，氷のう，氷のうつり，哺乳用具，魔法哺乳器',
        en: 'finger guards for medical purposes，sanitary masks，pacifiers for babies，ice bag pillows for medical purposes，triangular bandages，support bandages，surgical catguts，feeding cups for medical purposes，dropping pipettes for medical purposes，teats，medical ice bags，medical ice bag holders，baby bottles，nursing bottles',
      },
      code: '-I_l',
      order: 1,
    },
    '避妊用具': {
      id: '避妊用具',
      name: {
        ja: '避妊用具',
        en: 'contraceptives、 non-chemical',
      },
      code: 'ejed',
      order: 2,
    },
    '人工鼓膜用材料，補綴充てん用材料（歯科用のものを除く。）': {
      id: '人工鼓膜用材料，補綴充てん用材料（歯科用のものを除く。）',
      name: {
        ja: '人工鼓膜用材料，補綴充てん用材料（歯科用のものを除く。）',
        en: 'artificial tympanic membranes，prosthetics or fillings materials、 not for dental use',
      },
      code: '19Wv',
      order: 3,
    },
    '水泳用耳栓，睡眠用耳栓，防音用耳栓': {
      id: '水泳用耳栓，睡眠用耳栓，防音用耳栓',
      name: {
        ja: '水泳用耳栓，睡眠用耳栓，防音用耳栓',
        en: 'ear plugs for swimmers，ear plugs for sleeping，ear plugs for soundproofing',
      },
      code: '1E4Z',
      order: 4,
    },
    '業務用超音波美顔器，業務用美容マッサージ器': {
      id: '業務用超音波美顔器，業務用美容マッサージ器',
      name: {
        ja: '業務用超音波美顔器，業務用美容マッサージ器',
        en: 'facial equipment using ultrasonic waves for commercial use，esthetic massage apparatus for commercial use',
      },
      code: 'OTBa',
      order: 5,
    },
    '医療用機械器具': {
      id: '医療用機械器具',
      name: {
        ja: '医療用機械器具',
        en: 'medical apparatus and instruments',
      },
      code: 'X5gd',
      order: 6,
    },
    '医療用機械器具（「歩行補助器・松葉づえ」を除く。）': {
      id: '医療用機械器具（「歩行補助器・松葉づえ」を除く。）',
      name: {
        ja: '医療用機械器具（「歩行補助器・松葉づえ」を除く。）',
        en: 'medical apparatus and instruments、 other than walking aids、 and crutches',
      },
      code: '94wf',
      order: 7,
    },
    '歩行補助器，松葉づえ': {
      id: '歩行補助器，松葉づえ',
      name: {
        ja: '歩行補助器，松葉づえ',
        en: 'walking aids for medical purposes，crutches',
      },
      code: '0heH',
      order: 8,
    },
    '家庭用超音波美顔器': {
      id: '家庭用超音波美顔器',
      name: {
        ja: '家庭用超音波美顔器',
        en: 'facial equipment using ultrasonic waves for household purposes',
      },
      code: 'BJkL',
      order: 9,
    },
    '家庭用電気マッサージ器': {
      id: '家庭用電気マッサージ器',
      name: {
        ja: '家庭用電気マッサージ器',
        en: 'electric massage apparatus for household purposes',
      },
      code: 'x5oS',
      order: 10,
    },
    '医療用手袋': {
      id: '医療用手袋',
      name: {
        ja: '医療用手袋',
        en: 'gloves for medical purposes',
      },
      code: 'DzRu',
      order: 11,
    },
    'しびん，病人用差込み便器': {
      id: 'しびん，病人用差込み便器',
      name: {
        ja: 'しびん，病人用差込み便器',
        en: 'urinals for medical purposes，bed pans',
      },
      code: 'GDC1',
      order: 12,
    },
    '耳かき': {
      id: '耳かき',
      name: {
        ja: '耳かき',
        en: 'ear picks',
      },
      code: 'Nkdf',
      order: 13,
    },
  },
  11: {
    '便所ユニット，浴室ユニット': {
      id: '便所ユニット，浴室ユニット',
      name: {
        ja: '便所ユニット，浴室ユニット',
        en: 'toilet bowls and seats sold as a unit，prefabricated bath installations sold as a unit',
      },
      code: 'QgH9',
      order: 1,
    },
    '化学製品製造用乾燥装置，化学製品製造用換熱器，化学製品製造用蒸煮装置，化学製品製造用蒸発装置，化学製品製造用蒸留装置，化学製品製造用熱交換器': {
      id: '化学製品製造用乾燥装置，化学製品製造用換熱器，化学製品製造用蒸煮装置，化学製品製造用蒸発装置，化学製品製造用蒸留装置，化学製品製造用熱交換器',
      name: {
        ja: '化学製品製造用乾燥装置，化学製品製造用換熱器，化学製品製造用蒸煮装置，化学製品製造用蒸発装置，化学製品製造用蒸留装置，化学製品製造用熱交換器',
        en: 'drying apparatus for chemical processing，recuperators for chemical processing，steamers for chemical processing，evaporators for chemical processing，distillers for chemical processing，heat exchangers for chemical processing',
      },
      code: 'oR-R',
      order: 2,
    },
    '化学繊維製造用乾燥機': {
      id: '化学繊維製造用乾燥機',
      name: {
        ja: '化学繊維製造用乾燥機',
        en: 'drying apparatus for textile manufacture',
      },
      code: 'YBXx',
      order: 3,
    },
    '牛乳殺菌機，業務用アイスクリーム製造機，業務用製パン機': {
      id: '牛乳殺菌機，業務用アイスクリーム製造機，業務用製パン機',
      name: {
        ja: '牛乳殺菌機，業務用アイスクリーム製造機，業務用製パン機',
        en: 'milk sterilizers，ice-cream making machines for industrial purposes，bread-making machines for industrial purposes',
      },
      code: 'FY4j',
      order: 4,
    },
    'ベニヤ製造用乾燥機': {
      id: 'ベニヤ製造用乾燥機',
      name: {
        ja: 'ベニヤ製造用乾燥機',
        en: 'plywood drying machines',
      },
      code: '5-sx',
      order: 5,
    },
    '工業用炉，原子炉': {
      id: '工業用炉，原子炉',
      name: {
        ja: '工業用炉，原子炉',
        en: 'industrial furnaces，nuclear reactors [atomic piles]',
      },
      code: '5Z33',
      order: 6,
    },
    '収穫物乾燥機，飼料乾燥装置': {
      id: '収穫物乾燥機，飼料乾燥装置',
      name: {
        ja: '収穫物乾燥機，飼料乾燥装置',
        en: 'drying apparatus for harvest，forage drying apparatus',
      },
      code: 'wdM0',
      order: 7,
    },
    'ボイラー（動力機械部品・機関用のものを除く。）': {
      id: 'ボイラー（動力機械部品・機関用のものを除く。）',
      name: {
        ja: 'ボイラー（動力機械部品・機関用のものを除く。）',
        en: 'boilers、 other than parts of non-electric prime movers or engines',
      },
      code: '55t6',
      order: 8,
    },
    '業務用暖冷房装置': {
      id: '業務用暖冷房装置',
      name: {
        ja: '業務用暖冷房装置',
        en: 'air conditioners for industrial purposes',
      },
      code: 'D_f5',
      order: 9,
    },
    '業務用冷凍機械器具': {
      id: '業務用冷凍機械器具',
      name: {
        ja: '業務用冷凍機械器具',
        en: 'freezing machines and apparatus for industrial purposes',
      },
      code: '6veV',
      order: 10,
    },
    '業務用衣類乾燥機': {
      id: '業務用衣類乾燥機',
      name: {
        ja: '業務用衣類乾燥機',
        en: 'laundry dryers、 electric、 for industrial purposes',
      },
      code: 'lhB-',
      order: 11,
    },
    'タオル蒸し器，美容院用頭髪乾燥機，美容院用頭髪蒸し器，理髪店用洗髪台': {
      id: 'タオル蒸し器，美容院用頭髪乾燥機，美容院用頭髪蒸し器，理髪店用洗髪台',
      name: {
        ja: 'タオル蒸し器，美容院用頭髪乾燥機，美容院用頭髪蒸し器，理髪店用洗髪台',
        en: 'towel steamers for hairdressing purposes，hair drying machines for beauty salon use，hair steamers for beauty salon use，shampoo basins for barbers\' shop use',
      },
      code: 'lg_-',
      order: 12,
    },
    '業務用加熱調理機械器具，業務用食器乾燥機，業務用食器消毒器，業務用調理台，業務用流し台': {
      id: '業務用加熱調理機械器具，業務用食器乾燥機，業務用食器消毒器，業務用調理台，業務用流し台',
      name: {
        ja: '業務用加熱調理機械器具，業務用食器乾燥機，業務用食器消毒器，業務用調理台，業務用流し台',
        en: 'cooking apparatus and installations for commercial use，dish drying machines for commercial use，dish disinfectant apparatus for commercial use，kitchen sinks incorporating integrated worktops for commercial use，kitchen sinks for commercial use',
      },
      code: 't78P',
      order: 13,
    },
    '水道用栓，タンク用水位制御弁，パイプライン用栓': {
      id: '水道用栓，タンク用水位制御弁，パイプライン用栓',
      name: {
        ja: '水道用栓，タンク用水位制御弁，パイプライン用栓',
        en: 'tap water faucets，level controlling valves for tanks，pipe line cocks',
      },
      code: 'eqSz',
      order: 14,
    },
    '汚水浄化槽，し尿処理槽': {
      id: '汚水浄化槽，し尿処理槽',
      name: {
        ja: '汚水浄化槽，し尿処理槽',
        en: 'waste water treatment tanks for industrial purposes，septic tanks for industrial purposes',
      },
      code: 'EX2b',
      order: 15,
    },
    'ごみ焼却炉': {
      id: 'ごみ焼却炉',
      name: {
        ja: 'ごみ焼却炉',
        en: 'garbage incinerators',
      },
      code: '0ZId',
      order: 16,
    },
    '業務用ごみ焼却炉': {
      id: '業務用ごみ焼却炉',
      name: {
        ja: '業務用ごみ焼却炉',
        en: 'garbage incinerators for industrial purposes',
      },
      code: 'oFhN',
      order: 17,
    },
    '家庭用ごみ焼却炉': {
      id: '家庭用ごみ焼却炉',
      name: {
        ja: '家庭用ごみ焼却炉',
        en: 'garbage incinerators for household purposes',
      },
      code: 'T8zf',
      order: 18,
    },
    '太陽熱利用温水器': {
      id: '太陽熱利用温水器',
      name: {
        ja: '太陽熱利用温水器',
        en: 'solar water heaters',
      },
      code: 'HKJL',
      order: 19,
    },
    '業務用浄水装置': {
      id: '業務用浄水装置',
      name: {
        ja: '業務用浄水装置',
        en: 'water purifying apparatus for industrial purposes',
      },
      code: 'mwn-',
      order: 20,
    },
    '電球類及び照明用器具': {
      id: '電球類及び照明用器具',
      name: {
        ja: '電球類及び照明用器具',
        en: 'electric lamps and other lighting apparatus',
      },
      code: 'DRSD',
      order: 21,
    },
    '家庭用電熱用品類': {
      id: '家庭用電熱用品類',
      name: {
        ja: '家庭用電熱用品類',
        en: 'household electrothermic appliances',
      },
      code: '1Ir_',
      order: 22,
    },
    '家庭用電熱用品類（美容用又は衛生用のものを除く。）': {
      id: '家庭用電熱用品類（美容用又は衛生用のものを除く。）',
      name: {
        ja: '家庭用電熱用品類（美容用又は衛生用のものを除く。）',
        en: 'household electrothermic appliances、 other than for beauty or sanitary purposes',
      },
      code: 'XEtz',
      order: 23,
    },
    '美容用又は衛生用の家庭用電熱用品類': {
      id: '美容用又は衛生用の家庭用電熱用品類',
      name: {
        ja: '美容用又は衛生用の家庭用電熱用品類',
        en: 'household electrothermic appliances for beauty or sanitary purposes',
      },
      code: 'l52S',
      order: 24,
    },
    '水道蛇口用座金，水道蛇口用ワッシャー': {
      id: '水道蛇口用座金，水道蛇口用ワッシャー',
      name: {
        ja: '水道蛇口用座金，水道蛇口用ワッシャー',
        en: 'washers for water taps，washers for water taps',
      },
      code: '-A2e',
      order: 25,
    },
    'ガス湯沸かし器': {
      id: 'ガス湯沸かし器',
      name: {
        ja: 'ガス湯沸かし器',
        en: 'gas water heaters for household purposes',
      },
      code: 'i6UQ',
      order: 26,
    },
    '家庭用加熱器（電気式のものを除く。），家庭用調理台，家庭用流し台': {
      id: '家庭用加熱器（電気式のものを除く。），家庭用調理台，家庭用流し台',
      name: {
        ja: '家庭用加熱器（電気式のものを除く。），家庭用調理台，家庭用流し台',
        en: 'non-electric cooking heaters for household purposes，kitchen sinks incorporating integrated worktops for household purposes，kitchen sinks for household purposes',
      },
      code: 'KRBc',
      order: 27,
    },
    '家庭用浄水器（電気式のものを除く。）': {
      id: '家庭用浄水器（電気式のものを除く。）',
      name: {
        ja: '家庭用浄水器（電気式のものを除く。）',
        en: 'household tap-water filters、 non-electric',
      },
      code: '7Kjz',
      order: 28,
    },
    'あんどん，ちょうちん': {
      id: 'あんどん，ちょうちん',
      name: {
        ja: 'あんどん，ちょうちん',
        en: 'standing paper lanterns [Andon]，portable paper lanterns [Chochin]',
      },
      code: 'XXSu',
      order: 29,
    },
    'ガスランプ，石油ランプ，ほや': {
      id: 'ガスランプ，石油ランプ，ほや',
      name: {
        ja: 'ガスランプ，石油ランプ，ほや',
        en: 'gas lamps，oil lamps，lamp chimneys',
      },
      code: 'oEU4',
      order: 30,
    },
    'あんか，懐炉，湯たんぽ': {
      id: 'あんか，懐炉，湯たんぽ',
      name: {
        ja: 'あんか，懐炉，湯たんぽ',
        en: 'warming pans、 non-electric，pocket warmers、 non-electric，hot water bottles for warming one\'s feet in bed',
      },
      code: 'YrT8',
      order: 31,
    },
    '家庭用汚水浄化槽，家庭用し尿処理槽': {
      id: '家庭用汚水浄化槽，家庭用し尿処理槽',
      name: {
        ja: '家庭用汚水浄化槽，家庭用し尿処理槽',
        en: 'waste water treatment tanks for household purposes，septic tanks for household purposes',
      },
      code: 'aQXm',
      order: 32,
    },
    '身体用保冷パック（医療用のものを除く。）': {
      id: '身体用保冷パック（医療用のものを除く。）',
      name: {
        ja: '身体用保冷パック（医療用のものを除く。）',
        en: 'personal cooling packs、 not for medical purposes',
      },
      code: 'esup',
      order: 33,
    },
    '洗浄機能付き便座，便器，和式便器用椅子': {
      id: '洗浄機能付き便座，便器，和式便器用椅子',
      name: {
        ja: '洗浄機能付き便座，便器，和式便器用椅子',
        en: 'toilet stool units with a washing water squirter，toilet bowls，seats for use with Japanese style toilet bowls',
      },
      code: 'WfAI',
      order: 34,
    },
    '浴槽類': {
      id: '浴槽類',
      name: {
        ja: '浴槽類',
        en: 'bath fittings',
      },
      code: 'mR1f',
      order: 35,
    },
    'ストーブ類（電気式のものを除く。）': {
      id: 'ストーブ類（電気式のものを除く。）',
      name: {
        ja: 'ストーブ類（電気式のものを除く。）',
        en: 'stoves [space heaters] for household purposes、 non-electric',
      },
      code: 'O5A6',
      order: 36,
    },
  },
  12: {
    '牽引車，荷役用索道': {
      id: '牽引車，荷役用索道',
      name: {
        ja: '牽引車，荷役用索道',
        en: 'traction engine，ropeways for cargo or freight handling',
      },
      code: 'ch8d',
      order: 1,
    },
    '陸上の乗物用の動力機械器具（その部品を除く。）': {
      id: '陸上の乗物用の動力機械器具（その部品を除く。）',
      name: {
        ja: '陸上の乗物用の動力機械器具（その部品を除く。）',
        en: 'non-electric prime movers for land vehicles、 not including their parts',
      },
      code: 'RbUB',
      order: 2,
    },
    '陸上の乗物用の機械要素': {
      id: '陸上の乗物用の機械要素',
      name: {
        ja: '陸上の乗物用の機械要素',
        en: 'mechanical elements for land vehicles',
      },
      code: '6g4D',
      order: 3,
    },
    '軸（陸上の乗物用の機械要素），軸受（陸上の乗物用の機械要素），軸継ぎ手（陸上の乗物用の機械要素）': {
      id: '軸（陸上の乗物用の機械要素），軸受（陸上の乗物用の機械要素），軸継ぎ手（陸上の乗物用の機械要素）',
      name: {
        ja: '軸（陸上の乗物用の機械要素），軸受（陸上の乗物用の機械要素），軸継ぎ手（陸上の乗物用の機械要素）',
        en: 'shafts、 axles or spindles、 machine elements for land vehicles，bearings、 machine elements for land vehicles，shaft couplings or connectors、 machine elements for land vehicles',
      },
      code: 'wxQn',
      order: 4,
    },
    '動力伝導装置（陸上の乗物用の機械要素）': {
      id: '動力伝導装置（陸上の乗物用の機械要素）',
      name: {
        ja: '動力伝導装置（陸上の乗物用の機械要素）',
        en: 'power transmissions and gearing、 machine elements for land vehicles',
      },
      code: 'PXEC',
      order: 5,
    },
    '緩衝器（陸上の乗物用の機械要素），ばね（陸上の乗物用の機械要素）': {
      id: '緩衝器（陸上の乗物用の機械要素），ばね（陸上の乗物用の機械要素）',
      name: {
        ja: '緩衝器（陸上の乗物用の機械要素），ばね（陸上の乗物用の機械要素）',
        en: 'shock absorbers、 machine elements for land vehicles，springs、 machine elements for land vehicles',
      },
      code: 'AkeZ',
      order: 6,
    },
    '制動装置（陸上の乗物用の機械要素）': {
      id: '制動装置（陸上の乗物用の機械要素）',
      name: {
        ja: '制動装置（陸上の乗物用の機械要素）',
        en: 'brakes、 machine elements for land vehicles',
      },
      code: '43YT',
      order: 7,
    },
    '落下傘': {
      id: '落下傘',
      name: {
        ja: '落下傘',
        en: 'parachutes',
      },
      code: 'uQIV',
      order: 8,
    },
    '乗物用盗難警報器': {
      id: '乗物用盗難警報器',
      name: {
        ja: '乗物用盗難警報器',
        en: 'anti-theft alarms for vehicles',
      },
      code: '8avL',
      order: 9,
    },
    '車椅子': {
      id: '車椅子',
      name: {
        ja: '車椅子',
        en: 'wheelchairs',
      },
      code: 'A0_k',
      order: 10,
    },
    '陸上の乗物用の交流電動機又は直流電動機（その部品を除く。）': {
      id: '陸上の乗物用の交流電動機又は直流電動機（その部品を除く。）',
      name: {
        ja: '陸上の乗物用の交流電動機又は直流電動機（その部品を除く。）',
        en: 'AC motors or DC motors for land vehicles、 not including their parts',
      },
      code: 'fOLN',
      order: 11,
    },
    '船舶並びにその部品及び附属品': {
      id: '船舶並びにその部品及び附属品',
      name: {
        ja: '船舶並びにその部品及び附属品',
        en: 'vessels and their parts and fittings',
      },
      code: '__Om',
      order: 12,
    },
    '船舶並びにその部品及び附属品（「エアクッション艇」を除く。）': {
      id: '船舶並びにその部品及び附属品（「エアクッション艇」を除く。）',
      name: {
        ja: '船舶並びにその部品及び附属品（「エアクッション艇」を除く。）',
        en: 'vessels and their parts and fittings、 other than air cushion vehicles',
      },
      code: 'b4gx',
      order: 13,
    },
    'エアクッション艇': {
      id: 'エアクッション艇',
      name: {
        ja: 'エアクッション艇',
        en: 'air cushion vehicles',
      },
      code: 'YA6m',
      order: 14,
    },
    '航空機並びにその部品及び附属品': {
      id: '航空機並びにその部品及び附属品',
      name: {
        ja: '航空機並びにその部品及び附属品',
        en: 'aircraft and their parts and fittings',
      },
      code: 'ZbZv',
      order: 15,
    },
    '鉄道車両並びにその部品及び附属品': {
      id: '鉄道車両並びにその部品及び附属品',
      name: {
        ja: '鉄道車両並びにその部品及び附属品',
        en: 'railway rolling stock and their parts and fittings',
      },
      code: '0k25',
      order: 16,
    },
    '自動車並びにその部品及び附属品': {
      id: '自動車並びにその部品及び附属品',
      name: {
        ja: '自動車並びにその部品及び附属品',
        en: 'automobiles and their parts and fittings',
      },
      code: '852i',
      order: 17,
    },
    '二輪自動車・自転車並びにそれらの部品及び附属品': {
      id: '二輪自動車・自転車並びにそれらの部品及び附属品',
      name: {
        ja: '二輪自動車・自転車並びにそれらの部品及び附属品',
        en: 'two-wheeled motor vehicles、 bicycles and their parts and fittings',
      },
      code: 'Osrk',
      order: 18,
    },
    '人力車，そり，手押し車，荷車，馬車，リヤカー': {
      id: '人力車，そり，手押し車，荷車，馬車，リヤカー',
      name: {
        ja: '人力車，そり，手押し車，荷車，馬車，リヤカー',
        en: 'rickshaws，sleighs and sleds [vehicles]，trolleys*，carts*，horse-drawn carriages，riyakah [two-wheeled carts]',
      },
      code: 'yWLn',
      order: 19,
    },
    'タイヤ又はチューブの修繕用ゴム貼り付け片': {
      id: 'タイヤ又はチューブの修繕用ゴム貼り付け片',
      name: {
        ja: 'タイヤ又はチューブの修繕用ゴム貼り付け片',
        en: 'adhesive rubber patches for repairing tubes or tires',
      },
      code: 'ZgcK',
      order: 20,
    },
    '乳母車': {
      id: '乳母車',
      name: {
        ja: '乳母車',
        en: 'baby carriage [prams]',
      },
      code: 'CtpU',
      order: 21,
    },
  },
  13: {
    '銃砲': {
      id: '銃砲',
      name: {
        ja: '銃砲',
        en: 'firearms',
      },
      code: 'X-fE',
      order: 1,
    },
    '銃砲弾，火薬，爆薬，火工品及びその補助器具': {
      id: '銃砲弾，火薬，爆薬，火工品及びその補助器具',
      name: {
        ja: '銃砲弾，火薬，爆薬，火工品及びその補助器具',
        en: 'ammunition for firearms，gunpowder，explosive powders，pyrotechnic products and their peripherals',
      },
      code: 'PGby',
      order: 2,
    },
    '戦車': {
      id: '戦車',
      name: {
        ja: '戦車',
        en: 'tanks [weapons]',
      },
      code: 'izIi',
      order: 3,
    },
    'スターターピストル': {
      id: 'スターターピストル',
      name: {
        ja: 'スターターピストル',
        en: 'starting pistols',
      },
      code: '8WcT',
      order: 4,
    },
    '水中銃（運動用具）': {
      id: '水中銃（運動用具）',
      name: {
        ja: '水中銃（運動用具）',
        en: 'spear guns [sports articles]',
      },
      code: 'f7PH',
      order: 5,
    },
  },
  14: {
    '貴金属': {
      id: '貴金属',
      name: {
        ja: '貴金属',
        en: 'precious metals',
      },
      code: 'tdKG',
      order: 1,
    },
    '宝玉及びその原石並びに宝玉の模造品': {
      id: '宝玉及びその原石並びに宝玉の模造品',
      name: {
        ja: '宝玉及びその原石並びに宝玉の模造品',
        en: 'unwrought and semi-wrought precious stones and their imitations',
      },
      code: 'K_xj',
      order: 2,
    },
    '宝玉の原石': {
      id: '宝玉の原石',
      name: {
        ja: '宝玉の原石',
        en: 'unwrought precious stones',
      },
      code: 'h984',
      order: 3,
    },
    '宝玉及びその模造品': {
      id: '宝玉及びその模造品',
      name: {
        ja: '宝玉及びその模造品',
        en: 'semi-wrought precious stones and their imitations',
      },
      code: 'UHeI',
      order: 4,
    },
    'キーホルダー': {
      id: 'キーホルダー',
      name: {
        ja: 'キーホルダー',
        en: 'key rings',
      },
      code: 'zku1',
      order: 5,
    },
    '宝石箱': {
      id: '宝石箱',
      name: {
        ja: '宝石箱',
        en: 'jewellery boxes',
      },
      code: 'giZd',
      order: 6,
    },
    '貴金属製記念カップ，貴金属製記念たて': {
      id: '貴金属製記念カップ，貴金属製記念たて',
      name: {
        ja: '貴金属製記念カップ，貴金属製記念たて',
        en: 'trophies [prize cups] of precious metal，commemorative shields of precious metal',
      },
      code: '0tZb',
      order: 7,
    },
    '身飾品': {
      id: '身飾品',
      name: {
        ja: '身飾品',
        en: 'personal ornaments [jewellery、 jewelry (Am.)]',
      },
      code: 'DNAC',
      order: 8,
    },
    '身飾品（「カフスボタン」を除く。）': {
      id: '身飾品（「カフスボタン」を除く。）',
      name: {
        ja: '身飾品（「カフスボタン」を除く。）',
        en: 'personal ornaments、 other than cuff links、 in the nature of jewellery',
      },
      code: 'GIEo',
      order: 9,
    },
    'カフスボタン': {
      id: 'カフスボタン',
      name: {
        ja: 'カフスボタン',
        en: 'cuff links',
      },
      code: 'TzKk',
      order: 10,
    },
    '貴金属製靴飾り': {
      id: '貴金属製靴飾り',
      name: {
        ja: '貴金属製靴飾り',
        en: 'shoe ornaments of precious metal',
      },
      code: '5pSt',
      order: 11,
    },
    '時計': {
      id: '時計',
      name: {
        ja: '時計',
        en: 'clocks and watches',
      },
      code: 'FmJR',
      order: 12,
    },
  },
  15: {
    '調律機': {
      id: '調律機',
      name: {
        ja: '調律機',
        en: 'tuning apparatus for musical instruments',
      },
      code: 'xJKE',
      order: 1,
    },
    '楽器，楽譜台，指揮棒，音さ': {
      id: '楽器，楽譜台，指揮棒，音さ',
      name: {
        ja: '楽器，楽譜台，指揮棒，音さ',
        en: 'musical instruments，music stands，conductors\' batons，tuning forks',
      },
      code: 'kZfC',
      order: 2,
    },
  },
  16: {
    '事務用又は家庭用ののり及び接着剤': {
      id: '事務用又は家庭用ののり及び接着剤',
      name: {
        ja: '事務用又は家庭用ののり及び接着剤',
        en: 'pastes and other adhesives for stationery or household purposes',
      },
      code: 'Gxql',
      order: 1,
    },
    '封ろう': {
      id: '封ろう',
      name: {
        ja: '封ろう',
        en: 'sealing wax',
      },
      code: 'Jt3i',
      order: 2,
    },
    '印刷用インテル，活字': {
      id: '印刷用インテル，活字',
      name: {
        ja: '印刷用インテル，活字',
        en: 'printers\' reglets [interline leads]，printing type',
      },
      code: 'eawk',
      order: 3,
    },
    'あて名印刷機，印字用インクリボン，自動印紙貼り付け機，事務用電動式ステープラ，事務用封かん機，消印機，製図用具，タイプライター，チェックライター，謄写版，凸版複写機，文書細断機，郵便料金計器，輪転謄写機': {
      id: 'あて名印刷機，印字用インクリボン，自動印紙貼り付け機，事務用電動式ステープラ，事務用封かん機，消印機，製図用具，タイプライター，チェックライター，謄写版，凸版複写機，文書細断機，郵便料金計器，輪転謄写機',
      name: {
        ja: 'あて名印刷機，印字用インクリボン，自動印紙貼り付け機，事務用電動式ステープラ，事務用封かん機，消印機，製図用具，タイプライター，チェックライター，謄写版，凸版複写機，文書細断機，郵便料金計器，輪転謄写機',
        en: 'addressing machines，ink ribbons，automatic stamp affixing machines，electric staplers for offices，envelope sealing machines for offices，stamp obliterating machines，drawing instruments，typewriters，checkwriters，mimeographs，relief duplicators，paper shredders for office use，franking machines，rotary duplicators',
      },
      code: 'vhO8',
      order: 4,
    },
    'マーキング用孔開型板': {
      id: 'マーキング用孔開型板',
      name: {
        ja: 'マーキング用孔開型板',
        en: 'marking templates',
      },
      code: 'OWGO',
      order: 5,
    },
    '装飾塗工用ブラシ': {
      id: '装飾塗工用ブラシ',
      name: {
        ja: '装飾塗工用ブラシ',
        en: 'decorators\' paintbrushes',
      },
      code: '9DSC',
      order: 6,
    },
    '紙製包装用容器': {
      id: '紙製包装用容器',
      name: {
        ja: '紙製包装用容器',
        en: 'containers of paper、 for packaging',
      },
      code: 'k9qb',
      order: 7,
    },
    'プラスチック製包装用袋': {
      id: 'プラスチック製包装用袋',
      name: {
        ja: 'プラスチック製包装用袋',
        en: 'bags [pouches] of plastics、 for packaging',
      },
      code: 'ofSE',
      order: 8,
    },
    '家庭用食品包装フィルム': {
      id: '家庭用食品包装フィルム',
      name: {
        ja: '家庭用食品包装フィルム',
        en: 'food wrapping plastic film for household purposes',
      },
      code: 'R3Sz',
      order: 9,
    },
    '紙製ごみ収集用袋，プラスチック製ごみ収集用袋': {
      id: '紙製ごみ収集用袋，プラスチック製ごみ収集用袋',
      name: {
        ja: '紙製ごみ収集用袋，プラスチック製ごみ収集用袋',
        en: 'garbage bags of paper for household purposes，garbage bags of plastics for household purposes',
      },
      code: 'tC6M',
      order: 10,
    },
    '型紙，裁縫用チャコ': {
      id: '型紙，裁縫用チャコ',
      name: {
        ja: '型紙，裁縫用チャコ',
        en: 'paper patterns，tailors\' chalk',
      },
      code: 'ZgQt',
      order: 11,
    },
    '紙製のぼり，紙製旗': {
      id: '紙製のぼり，紙製旗',
      name: {
        ja: '紙製のぼり，紙製旗',
        en: 'banners of paper，flags of paper',
      },
      code: 'sByY',
      order: 12,
    },
    '衛生手ふき，紙製タオル，紙製テーブルナプキン，紙製手ふき，紙製ハンカチ': {
      id: '衛生手ふき，紙製タオル，紙製テーブルナプキン，紙製手ふき，紙製ハンカチ',
      name: {
        ja: '衛生手ふき，紙製タオル，紙製テーブルナプキン，紙製手ふき，紙製ハンカチ',
        en: 'hygienic hand towels of paper，towels of paper，table napkins of paper，hand towels of paper，hand towels of paper',
      },
      code: 'u7j4',
      order: 13,
    },
    '荷札': {
      id: '荷札',
      name: {
        ja: '荷札',
        en: 'shipping tags',
      },
      code: 'opn-',
      order: 14,
    },
    'いろがみ，写し絵，折り紙，切り抜き，千代紙，ぬり絵': {
      id: 'いろがみ，写し絵，折り紙，切り抜き，千代紙，ぬり絵',
      name: {
        ja: 'いろがみ，写し絵，折り紙，切り抜き，千代紙，ぬり絵',
        en: 'assorted pieces of colored paper [paper toy]，transfer-pictures [paper toy]，origami folding paper，cutout pictures of paper，Chiyogami [assorted pieces of Japanese paper with colorful patterns printed thereon]，coloring pictures',
      },
      code: 'c638',
      order: 15,
    },
    '紙類': {
      id: '紙類',
      name: {
        ja: '紙類',
        en: 'paper and cardboard',
      },
      code: 'ANEL',
      order: 16,
    },
    '文房具類': {
      id: '文房具類',
      name: {
        ja: '文房具類',
        en: 'stationery',
      },
      code: '4Kuw',
      order: 17,
    },
    '印刷物': {
      id: '印刷物',
      name: {
        ja: '印刷物',
        en: 'printed matter',
      },
      code: 'klxq',
      order: 18,
    },
    '書画': {
      id: '書画',
      name: {
        ja: '書画',
        en: 'paintings and calligraphic works',
      },
      code: 'ZB15',
      order: 19,
    },
    '写真': {
      id: '写真',
      name: {
        ja: '写真',
        en: 'photographs [printed]',
      },
      code: 'N-OA',
      order: 20,
    },
  },
  17: {
    '雲母': {
      id: '雲母',
      name: {
        ja: '雲母',
        en: 'mica [raw or partly processed]',
      },
      code: 'uEM-',
      order: 1,
    },
    'ゴム製又はバルカンファイバー製のバルブ（機械要素に当たるものを除く。）': {
      id: 'ゴム製又はバルカンファイバー製のバルブ（機械要素に当たるものを除く。）',
      name: {
        ja: 'ゴム製又はバルカンファイバー製のバルブ（機械要素に当たるものを除く。）',
        en: 'valves of rubber or vulcanized fiber [not including machine elements]',
      },
      code: 'tvVK',
      order: 2,
    },
    'ガスケット，管継ぎ手（金属製のものを除く。），パッキング': {
      id: 'ガスケット，管継ぎ手（金属製のものを除く。），パッキング',
      name: {
        ja: 'ガスケット，管継ぎ手（金属製のものを除く。），パッキング',
        en: 'gaskets，junctions for pipes、 not of metal，joint packings',
      },
      code: 'N0Rq',
      order: 3,
    },
    'オイルフェンス': {
      id: 'オイルフェンス',
      name: {
        ja: 'オイルフェンス',
        en: 'floating anti-pollution barriers',
      },
      code: 'lLQl',
      order: 4,
    },
    '電気絶縁材料': {
      id: '電気絶縁材料',
      name: {
        ja: '電気絶縁材料',
        en: 'electrical insulating materials',
      },
      code: 'STbS',
      order: 5,
    },
    'ゴム製又はバルカンファイバー製の座金及びワッシャー': {
      id: 'ゴム製又はバルカンファイバー製の座金及びワッシャー',
      name: {
        ja: 'ゴム製又はバルカンファイバー製の座金及びワッシャー',
        en: 'washers of rubber or vulcanized fiber',
      },
      code: 'kZ3C',
      order: 6,
    },
    '化学繊維（織物用のものを除く。）': {
      id: '化学繊維（織物用のものを除く。）',
      name: {
        ja: '化学繊維（織物用のものを除く。）',
        en: 'chemical fiber、 not for textile use',
      },
      code: 'w9NI',
      order: 7,
    },
    '岩石繊維，鉱さい綿': {
      id: '岩石繊維，鉱さい綿',
      name: {
        ja: '岩石繊維，鉱さい綿',
        en: 'rock wool，slag wool',
      },
      code: 'ptx0',
      order: 8,
    },
    '糸ゴム及び被覆ゴム糸（織物用のものを除く。），化学繊維糸（織物用のものを除く。）': {
      id: '糸ゴム及び被覆ゴム糸（織物用のものを除く。），化学繊維糸（織物用のものを除く。）',
      name: {
        ja: '糸ゴム及び被覆ゴム糸（織物用のものを除く。），化学繊維糸（織物用のものを除く。）',
        en: 'rubber thread and covered rubber yarn、 not for textile use，chemical fiber yarn and thread、 not for textile use',
      },
      code: 'c4kj',
      order: 9,
    },
    'ゴムひも': {
      id: 'ゴムひも',
      name: {
        ja: 'ゴムひも',
        en: 'rubber cords and laces',
      },
      code: '4BlD',
      order: 10,
    },
    'ゴム製包装用容器': {
      id: 'ゴム製包装用容器',
      name: {
        ja: 'ゴム製包装用容器',
        en: 'industrial packaging containers of rubber',
      },
      code: 'brWM',
      order: 11,
    },
    'ゴム製栓，ゴム製ふた': {
      id: 'ゴム製栓，ゴム製ふた',
      name: {
        ja: 'ゴム製栓，ゴム製ふた',
        en: 'rubber stoppers，rubber lids and caps for industrial packaging containers',
      },
      code: '1syT',
      order: 12,
    },
    '農業用プラスチックシート': {
      id: '農業用プラスチックシート',
      name: {
        ja: '農業用プラスチックシート',
        en: 'plastic sheeting for agricultural purposes',
      },
      code: 'OaFF',
      order: 13,
    },
    'コンデンサーペーパー，バルカンファイバー': {
      id: 'コンデンサーペーパー，バルカンファイバー',
      name: {
        ja: 'コンデンサーペーパー，バルカンファイバー',
        en: 'condenser paper，vulcanized fiber',
      },
      code: '8jj3',
      order: 14,
    },
    '接着テープ（医療用・事務用又は家庭用のものを除く。）': {
      id: '接着テープ（医療用・事務用又は家庭用のものを除く。）',
      name: {
        ja: '接着テープ（医療用・事務用又は家庭用のものを除く。）',
        en: 'adhesive tapes、 other than stationery and not for medical or household purposes',
      },
      code: '8tBN',
      order: 15,
    },
    'プラスチック基礎製品': {
      id: 'プラスチック基礎製品',
      name: {
        ja: 'プラスチック基礎製品',
        en: 'plastic semi-worked products',
      },
      code: 'U64X',
      order: 16,
    },
    'ゴム': {
      id: 'ゴム',
      name: {
        ja: 'ゴム',
        en: 'rubber [raw or semi-worked]',
      },
      code: 'ZxO7',
      order: 17,
    },
    '岩石繊維製防音材（建築用のものを除く。）': {
      id: '岩石繊維製防音材（建築用のものを除く。）',
      name: {
        ja: '岩石繊維製防音材（建築用のものを除く。）',
        en: 'soundproofing materials of rock wool、 not for building purposes',
      },
      code: 'aD6L',
      order: 18,
    },
  },
  18: {
    '蹄鉄': {
      id: '蹄鉄',
      name: {
        ja: '蹄鉄',
        en: 'horseshoes',
      },
      code: 's9DC',
      order: 1,
    },
    'レザークロス': {
      id: 'レザークロス',
      name: {
        ja: 'レザークロス',
        en: 'leathercloth',
      },
      code: 'dCQu',
      order: 2,
    },
    '皮革': {
      id: '皮革',
      name: {
        ja: '皮革',
        en: 'leather and fur、 unworked or semi-worked',
      },
      code: '96BW',
      order: 3,
    },
    '革ひも': {
      id: '革ひも',
      name: {
        ja: '革ひも',
        en: 'leather straps',
      },
      code: 'cqbQ',
      order: 4,
    },
    '原革，原皮，なめし革': {
      id: '原革，原皮，なめし革',
      name: {
        ja: '原革，原皮，なめし革',
        en: 'rawhides，raw skins，tanned leather',
      },
      code: 'M7ya',
      order: 5,
    },
    '毛皮': {
      id: '毛皮',
      name: {
        ja: '毛皮',
        en: 'fur',
      },
      code: 'ldLM',
      order: 6,
    },
    '皮革製包装用容器': {
      id: '皮革製包装用容器',
      name: {
        ja: '皮革製包装用容器',
        en: 'industrial packaging containers of leather',
      },
      code: 'W-Fb',
      order: 7,
    },
    'ペット用被服類': {
      id: 'ペット用被服類',
      name: {
        ja: 'ペット用被服類',
        en: 'clothing for domestic pets',
      },
      code: 'Wu8p',
      order: 8,
    },
    'かばん類，袋物': {
      id: 'かばん類，袋物',
      name: {
        ja: 'かばん類，袋物',
        en: 'pouches',
      },
      code: '29Ga',
      order: 9,
    },
    '携帯用化粧道具入れ': {
      id: '携帯用化粧道具入れ',
      name: {
        ja: '携帯用化粧道具入れ',
        en: 'vanity cases、 not fitted',
      },
      code: '1R74',
      order: 10,
    },
    '傘': {
      id: '傘',
      name: {
        ja: '傘',
        en: 'umbrellas and their parts',
      },
      code: 'LMpj',
      order: 11,
    },
    'ステッキ，つえ，つえ金具，つえの柄': {
      id: 'ステッキ，つえ，つえ金具，つえの柄',
      name: {
        ja: 'ステッキ，つえ，つえ金具，つえの柄',
        en: 'walking sticks，canes*，metal parts of canes and walking-sticks，handles for canes and walking sticks',
      },
      code: '1eMh',
      order: 12,
    },
    '乗馬用具': {
      id: '乗馬用具',
      name: {
        ja: '乗馬用具',
        en: 'saddlery',
      },
      code: 'ZmNE',
      order: 13,
    },
  },
  19: {
    'タール，ピッチ': {
      id: 'タール，ピッチ',
      name: {
        ja: 'タール，ピッチ',
        en: 'tar，pitch',
      },
      code: 'leeh',
      order: 1,
    },
    '建築用又は構築用の非金属鉱物': {
      id: '建築用又は構築用の非金属鉱物',
      name: {
        ja: '建築用又は構築用の非金属鉱物',
        en: 'non-metallic minerals for building or construction',
      },
      code: 'Lo0K',
      order: 2,
    },
    '陶磁製建築専用材料・れんが及び耐火物': {
      id: '陶磁製建築専用材料・れんが及び耐火物',
      name: {
        ja: '陶磁製建築専用材料・れんが及び耐火物',
        en: 'ceramic building materials、 bricks and refractory products',
      },
      code: 'q6kk',
      order: 3,
    },
    'リノリウム製建築専用材料，プラスチック製建築専用材料，合成建築専用材料，アスファルト及びアスファルト製の建築用又は構築用の専用材料，ゴム製の建築用又は構築用の専用材料，しっくい，石灰製の建築用又は構築用の専用材料，石こう製の建築用又は構築用の専用材料': {
      id: 'リノリウム製建築専用材料，プラスチック製建築専用材料，合成建築専用材料，アスファルト及びアスファルト製の建築用又は構築用の専用材料，ゴム製の建築用又は構築用の専用材料，しっくい，石灰製の建築用又は構築用の専用材料，石こう製の建築用又は構築用の専用材料',
      name: {
        ja: 'リノリウム製建築専用材料，プラスチック製建築専用材料，合成建築専用材料，アスファルト及びアスファルト製の建築用又は構築用の専用材料，ゴム製の建築用又は構築用の専用材料，しっくい，石灰製の建築用又は構築用の専用材料，石こう製の建築用又は構築用の専用材料',
        en: 'building materials made of linoleum for fixing to existing walls or floors，plastic building materials，synthetic building materials，asphalt、 and asphalt building or construction materials，rubber building or construction materials，plaster for building purposes，lime building or construction materials，building or construction materials of plaster',
      },
      code: '5jg_',
      order: 4,
    },
    '旗掲揚柱（金属製のものを除く。）': {
      id: '旗掲揚柱（金属製のものを除く。）',
      name: {
        ja: '旗掲揚柱（金属製のものを除く。）',
        en: 'flagpoles [structures]、 not of metal',
      },
      code: 'vv9U',
      order: 5,
    },
    '建造物組立てセット（金属製のものを除く。）': {
      id: '建造物組立てセット（金属製のものを除く。）',
      name: {
        ja: '建造物組立てセット（金属製のものを除く。）',
        en: 'buildings、 not of metal',
      },
      code: 'A4u4',
      order: 6,
    },
    '土砂崩壊防止用植生板': {
      id: '土砂崩壊防止用植生板',
      name: {
        ja: '土砂崩壊防止用植生板',
        en: 'erosion control mats integrating plants seeds',
      },
      code: 'Ed8Z',
      order: 7,
    },
    '窓口風防通話板': {
      id: '窓口風防通話板',
      name: {
        ja: '窓口風防通話板',
        en: 'plastic security windows allowing communication',
      },
      code: 'Yyz5',
      order: 8,
    },
    '区画表示帯': {
      id: '区画表示帯',
      name: {
        ja: '区画表示帯',
        en: 'road and field marking sheets and strips',
      },
      code: 'lHco',
      order: 9,
    },
    'セメント及びその製品': {
      id: 'セメント及びその製品',
      name: {
        ja: 'セメント及びその製品',
        en: 'cement and its products',
      },
      code: 'DuE0',
      order: 10,
    },
    '木材': {
      id: '木材',
      name: {
        ja: '木材',
        en: 'building timber',
      },
      code: 'MDwY',
      order: 11,
    },
    '石材': {
      id: '石材',
      name: {
        ja: '石材',
        en: 'building stone',
      },
      code: 'FT0Y',
      order: 12,
    },
    '建築用ガラス': {
      id: '建築用ガラス',
      name: {
        ja: '建築用ガラス',
        en: 'building glass',
      },
      code: 'QYhp',
      order: 13,
    },
    '人工魚礁（金属製のものを除く。）': {
      id: '人工魚礁（金属製のものを除く。）',
      name: {
        ja: '人工魚礁（金属製のものを除く。）',
        en: 'artificial fish reefs、 not of metal',
      },
      code: '0KDf',
      order: 14,
    },
    '養鶏用かご（金属製のものを除く。）': {
      id: '養鶏用かご（金属製のものを除く。）',
      name: {
        ja: '養鶏用かご（金属製のものを除く。）',
        en: 'poultry houses、 not of metal',
      },
      code: 'f_Gc',
      order: 15,
    },
    '吹付け塗装用ブース（金属製のものを除く。）': {
      id: '吹付け塗装用ブース（金属製のものを除く。）',
      name: {
        ja: '吹付け塗装用ブース（金属製のものを除く。）',
        en: 'paint spraying booths、 not of metal',
      },
      code: 'NjxN',
      order: 16,
    },
    'セメント製品製造用型枠（金属製のものを除く。）': {
      id: 'セメント製品製造用型枠（金属製のものを除く。）',
      name: {
        ja: 'セメント製品製造用型枠（金属製のものを除く。）',
        en: 'moulds for forming cement products、 not of metal',
      },
      code: 'zZ_n',
      order: 17,
    },
    '送水管用バルブ（金属製又はプラスチック製のものを除く。）': {
      id: '送水管用バルブ（金属製又はプラスチック製のものを除く。）',
      name: {
        ja: '送水管用バルブ（金属製又はプラスチック製のものを除く。）',
        en: 'water-pipe valves、 not of metal or plastics',
      },
      code: '0RkB',
      order: 18,
    },
    '道路標識（金属製又は発光式若しくは機械式のものを除く。）': {
      id: '道路標識（金属製又は発光式若しくは機械式のものを除く。）',
      name: {
        ja: '道路標識（金属製又は発光式若しくは機械式のものを除く。）',
        en: 'road signs、 not of metal、 non-luminous and non-mechanical',
      },
      code: 'XhRd',
      order: 19,
    },
    '航路標識（金属製又は発光式のものを除く。）': {
      id: '航路標識（金属製又は発光式のものを除く。）',
      name: {
        ja: '航路標識（金属製又は発光式のものを除く。）',
        en: 'beacons [not of metal、 non-luminous]',
      },
      code: 'fqSF',
      order: 20,
    },
    '貯蔵槽類（金属製又はプラスチック製のものを除く。）': {
      id: '貯蔵槽類（金属製又はプラスチック製のものを除く。）',
      name: {
        ja: '貯蔵槽類（金属製又はプラスチック製のものを除く。）',
        en: 'reservoirs、 not of metal or plastics',
      },
      code: 'vRDQ',
      order: 21,
    },
    '石製家庭用水槽': {
      id: '石製家庭用水槽',
      name: {
        ja: '石製家庭用水槽',
        en: 'water tanks of masonry for household purposes',
      },
      code: 'jdF7',
      order: 22,
    },
    '石製郵便受け': {
      id: '石製郵便受け',
      name: {
        ja: '石製郵便受け',
        en: 'letter boxes of masonry',
      },
      code: 'YE2U',
      order: 23,
    },
    '建具（金属製のものを除く。）': {
      id: '建具（金属製のものを除く。）',
      name: {
        ja: '建具（金属製のものを除く。）',
        en: 'joinery fittings、 not of metal',
      },
      code: 'GcIM',
      order: 24,
    },
    '屋外用ブラインド（金属製又は織物製のものを除く。）': {
      id: '屋外用ブラインド（金属製又は織物製のものを除く。）',
      name: {
        ja: '屋外用ブラインド（金属製又は織物製のものを除く。）',
        en: 'outdoor blinds、 not of metal or textile',
      },
      code: 'PHWM',
      order: 25,
    },
    '灯ろう': {
      id: '灯ろう',
      name: {
        ja: '灯ろう',
        en: 'stone lanterns',
      },
      code: 'glo4',
      order: 26,
    },
    '人工池（金属製のものを除く。）': {
      id: '人工池（金属製のものを除く。）',
      name: {
        ja: '人工池（金属製のものを除く。）',
        en: 'man-made garden ponds [structures、 not of metal]',
      },
      code: 'WF0Q',
      order: 27,
    },
    '可搬式家庭用温室（金属製のものを除く。）': {
      id: '可搬式家庭用温室（金属製のものを除く。）',
      name: {
        ja: '可搬式家庭用温室（金属製のものを除く。）',
        en: 'transportable greenhouses、 not of metal、 for household use',
      },
      code: 'Yaqe',
      order: 28,
    },
    '石製・コンクリート製又は大理石製の記念カップ，石製・コンクリート製又は大理石製の記念たて': {
      id: '石製・コンクリート製又は大理石製の記念カップ，石製・コンクリート製又は大理石製の記念たて',
      name: {
        ja: '石製・コンクリート製又は大理石製の記念カップ，石製・コンクリート製又は大理石製の記念たて',
        en: 'prize cups of stone、 concrete or marble，commemorative shields of stone、 concrete or marble',
      },
      code: 'tZWQ',
      order: 29,
    },
    '墓標及び墓碑用銘板（金属製のものを除く。）': {
      id: '墓標及び墓碑用銘板（金属製のものを除く。）',
      name: {
        ja: '墓標及び墓碑用銘板（金属製のものを除く。）',
        en: 'gravestones and tomb plaques、 not of metal',
      },
      code: 'CsOT',
      order: 30,
    },
    '石製彫刻，コンクリート製彫刻，大理石製彫刻': {
      id: '石製彫刻，コンクリート製彫刻，大理石製彫刻',
      name: {
        ja: '石製彫刻，コンクリート製彫刻，大理石製彫刻',
        en: 'stone sculptures，concrete sculptures，marble sculptures',
      },
      code: '6Avf',
      order: 31,
    },
    '鉱物性基礎材料': {
      id: '鉱物性基礎材料',
      name: {
        ja: '鉱物性基礎材料',
        en: 'nonmetallic mineral materials [unworked or partly worked]',
      },
      code: '0V7j',
      order: 32,
    },
    '無機繊維の板及び粉': {
      id: '無機繊維の板及び粉',
      name: {
        ja: '無機繊維の板及び粉',
        en: 'boards and powder of inorganic fiber',
      },
      code: 'IZmt',
      order: 33,
    },
    '石こうの板': {
      id: '石こうの板',
      name: {
        ja: '石こうの板',
        en: 'plaster boards',
      },
      code: 'NUVG',
      order: 34,
    },
    '鉱さい': {
      id: '鉱さい',
      name: {
        ja: '鉱さい',
        en: 'slag',
      },
      code: 'nUKp',
      order: 35,
    },
  },
  20: {
    '海泡石，こはく': {
      id: '海泡石，こはく',
      name: {
        ja: '海泡石，こはく',
        en: 'meerschaum [raw or partly worked material]，yellow amber',
      },
      code: 'd3ql',
      order: 1,
    },
    '荷役用パレット（金属製のものを除く。）': {
      id: '荷役用パレット（金属製のものを除く。）',
      name: {
        ja: '荷役用パレット（金属製のものを除く。）',
        en: 'loading pallets、 not of metal',
      },
      code: 'MNJh',
      order: 2,
    },
    '養蜂用巣箱': {
      id: '養蜂用巣箱',
      name: {
        ja: '養蜂用巣箱',
        en: 'beehives [hive boxes or honeycombs]',
      },
      code: 'ED8R',
      order: 3,
    },
    '美容院用椅子，理髪用椅子': {
      id: '美容院用椅子，理髪用椅子',
      name: {
        ja: '美容院用椅子，理髪用椅子',
        en: 'hairdresser\'s chairs，barbers\' chairs',
      },
      code: 'PsVB',
      order: 4,
    },
    'プラスチック製バルブ（機械要素に当たるものを除く。）': {
      id: 'プラスチック製バルブ（機械要素に当たるものを除く。）',
      name: {
        ja: 'プラスチック製バルブ（機械要素に当たるものを除く。）',
        en: 'valves of plastics、 not including machine elements',
      },
      code: 'DW1v',
      order: 5,
    },
    '貯蔵槽類（金属製又は石製のものを除く。）': {
      id: '貯蔵槽類（金属製又は石製のものを除く。）',
      name: {
        ja: '貯蔵槽類（金属製又は石製のものを除く。）',
        en: 'reservoirs、 not of metal nor of masonry',
      },
      code: 'bUtd',
      order: 6,
    },
    '液体貯蔵槽（金属製又は石製のものを除く。），工業用水槽（金属製又は石製のものを除く。）': {
      id: '液体貯蔵槽（金属製又は石製のものを除く。），工業用水槽（金属製又は石製のものを除く。）',
      name: {
        ja: '液体貯蔵槽（金属製又は石製のものを除く。），工業用水槽（金属製又は石製のものを除く。）',
        en: 'liquid storage tanks or reservoirs、 not of metal or masonry，industrial water storage tanks、 not of metal or masonry',
      },
      code: 'RXH_',
      order: 7,
    },
    '液化ガス貯蔵槽（金属製又は石製のものを除く。），ガス貯蔵槽（金属製又は石製のものを除く。）': {
      id: '液化ガス貯蔵槽（金属製又は石製のものを除く。），ガス貯蔵槽（金属製又は石製のものを除く。）',
      name: {
        ja: '液化ガス貯蔵槽（金属製又は石製のものを除く。），ガス貯蔵槽（金属製又は石製のものを除く。）',
        en: 'liquefied gas storage tanks、 not of metal or masonry，gas storage tanks、 not of metal or masonry',
      },
      code: 'JEis',
      order: 8,
    },
    '輸送用コンテナ（金属製のものを除く。）': {
      id: '輸送用コンテナ（金属製のものを除く。）',
      name: {
        ja: '輸送用コンテナ（金属製のものを除く。）',
        en: 'containers for transport、 not of metal',
      },
      code: 'D3xy',
      order: 9,
    },
    'カーテン金具，金属代用のプラスチック製締め金具，くぎ・くさび・ナット・ねじくぎ・びょう・ボルト・リベット及びキャスター（金属製のものを除く。），座金及びワッシャー（金属製・ゴム製又はバルカンファイバー製のものを除く。）': {
      id: 'カーテン金具，金属代用のプラスチック製締め金具，くぎ・くさび・ナット・ねじくぎ・びょう・ボルト・リベット及びキャスター（金属製のものを除く。），座金及びワッシャー（金属製・ゴム製又はバルカンファイバー製のものを除く。）',
      name: {
        ja: 'カーテン金具，金属代用のプラスチック製締め金具，くぎ・くさび・ナット・ねじくぎ・びょう・ボルト・リベット及びキャスター（金属製のものを除く。），座金及びワッシャー（金属製・ゴム製又はバルカンファイバー製のものを除く。）',
        en: 'curtain fittings，metal-substitute plastic fasteners，nails、 wedges、 nuts、 screws、 tacks、 bolts、 rivets and casters、 not of metal，washers、 not of metal、 not of rubber or vulcanized fiber',
      },
      code: 'nXx6',
      order: 10,
    },
    '錠（電気式又は金属製のものを除く。）': {
      id: '錠（電気式又は金属製のものを除く。）',
      name: {
        ja: '錠（電気式又は金属製のものを除く。）',
        en: 'locks、 non-electric、 not of metal',
      },
      code: '4oM7',
      order: 11,
    },
    'クッション，座布団，まくら，マットレス': {
      id: 'クッション，座布団，まくら，マットレス',
      name: {
        ja: 'クッション，座布団，まくら，マットレス',
        en: 'cushions [furniture]，Japanese floor cushions [Zabuton]，pillows，mattresses*',
      },
      code: 'iA0E',
      order: 12,
    },
    '木製・竹製又はプラスチック製の包装用容器': {
      id: '木製・竹製又はプラスチック製の包装用容器',
      name: {
        ja: '木製・竹製又はプラスチック製の包装用容器',
        en: 'industrial packaging containers of wood、 bamboo or plastics',
      },
      code: 'QcHY',
      order: 13,
    },
    '木製の包装用容器（「コルク製及び木製栓・木製ふた」を除く。）': {
      id: '木製の包装用容器（「コルク製及び木製栓・木製ふた」を除く。）',
      name: {
        ja: '木製の包装用容器（「コルク製及び木製栓・木製ふた」を除く。）',
        en: 'industrial packaging containers of wood [not including "corks nor wooden stoppers、 lids and covers"]',
      },
      code: 'b2xD',
      order: 14,
    },
    '竹製の包装用容器': {
      id: '竹製の包装用容器',
      name: {
        ja: '竹製の包装用容器',
        en: 'industrial packaging containers of bamboo',
      },
      code: 'HcRY',
      order: 15,
    },
    'プラスチック製の包装用容器（「プラスチック製栓・ふた及び瓶」を除く。）': {
      id: 'プラスチック製の包装用容器（「プラスチック製栓・ふた及び瓶」を除く。）',
      name: {
        ja: 'プラスチック製の包装用容器（「プラスチック製栓・ふた及び瓶」を除く。）',
        en: 'industrial packaging containers of plastics [not including "plastic stoppers、 lids、 covers and bottles"]',
      },
      code: 'E9hZ',
      order: 16,
    },
    'コルク製・プラスチック製及び木製の栓，プラスチック製及び木製のふた': {
      id: 'コルク製・プラスチック製及び木製の栓，プラスチック製及び木製のふた',
      name: {
        ja: 'コルク製・プラスチック製及び木製の栓，プラスチック製及び木製のふた',
        en: 'corks、 plastics and wooden stoppers for industrial packaging containers，plastic and wooden lid and covers for industrial packaging containers',
      },
      code: 'GG4j',
      order: 17,
    },
    '刺しゅう用枠': {
      id: '刺しゅう用枠',
      name: {
        ja: '刺しゅう用枠',
        en: 'embroidery frames and hoops',
      },
      code: 'XNPt',
      order: 18,
    },
    '浴室用腰掛け': {
      id: '浴室用腰掛け',
      name: {
        ja: '浴室用腰掛け',
        en: 'bathroom stools',
      },
      code: 'tte_',
      order: 19,
    },
    'ネームプレート及び標札（金属製のものを除く。）': {
      id: 'ネームプレート及び標札（金属製のものを除く。）',
      name: {
        ja: 'ネームプレート及び標札（金属製のものを除く。）',
        en: 'nameplates and door nameplates、 not of metal',
      },
      code: 'gk16',
      order: 20,
    },
    '手持式旗ざお（金属製のものを除く。）': {
      id: '手持式旗ざお（金属製のものを除く。）',
      name: {
        ja: '手持式旗ざお（金属製のものを除く。）',
        en: 'hand-held flagpoles、 not of metal',
      },
      code: 'K8Lo',
      order: 21,
    },
    'うちわ，扇子': {
      id: 'うちわ，扇子',
      name: {
        ja: 'うちわ，扇子',
        en: 'hand-held flat fans，hand-held folding fans',
      },
      code: 'iAS5',
      order: 22,
    },
    '植物の茎支持具（金属製のものを除く。）': {
      id: '植物の茎支持具（金属製のものを除く。）',
      name: {
        ja: '植物の茎支持具（金属製のものを除く。）',
        en: 'stakes、 not of metal、 for plants or trees',
      },
      code: 'vA3u',
      order: 23,
    },
    '犬小屋，小鳥用巣箱，ペット用ベッド': {
      id: '犬小屋，小鳥用巣箱，ペット用ベッド',
      name: {
        ja: '犬小屋，小鳥用巣箱，ペット用ベッド',
        en: 'dog kennels，nesting boxes for small birds，beds for household pets',
      },
      code: 'a4t6',
      order: 24,
    },
    'きゃたつ及びはしご（金属製のものを除く。）': {
      id: 'きゃたつ及びはしご（金属製のものを除く。）',
      name: {
        ja: 'きゃたつ及びはしご（金属製のものを除く。）',
        en: 'step ladders and ladders、 not of metal',
      },
      code: '1Rnz',
      order: 25,
    },
    '郵便受け（金属製又は石製のものを除く。）': {
      id: '郵便受け（金属製又は石製のものを除く。）',
      name: {
        ja: '郵便受け（金属製又は石製のものを除く。）',
        en: 'letter boxes [not of metal or masonry]',
      },
      code: '_Kf1',
      order: 26,
    },
    '帽子掛けかぎ（金属製のものを除く。）': {
      id: '帽子掛けかぎ（金属製のものを除く。）',
      name: {
        ja: '帽子掛けかぎ（金属製のものを除く。）',
        en: 'hat hooks、 not of metal',
      },
      code: 'UFux',
      order: 27,
    },
    'スーパーマーケットで使用する手提げ用買物かご（金属製のものを除く。）': {
      id: 'スーパーマーケットで使用する手提げ用買物かご（金属製のものを除く。）',
      name: {
        ja: 'スーパーマーケットで使用する手提げ用買物かご（金属製のものを除く。）',
        en: 'hand-held supermarket shopping baskets、 not of metal',
      },
      code: 'rnNc',
      order: 28,
    },
    '家庭用水槽（金属製又は石製のものを除く。）': {
      id: '家庭用水槽（金属製又は石製のものを除く。）',
      name: {
        ja: '家庭用水槽（金属製又は石製のものを除く。）',
        en: 'water tanks for household purposes、 not of metal or masonry',
      },
      code: 'OH9e',
      order: 29,
    },
    'ハンガーボード': {
      id: 'ハンガーボード',
      name: {
        ja: 'ハンガーボード',
        en: 'hanging boards [Japanese style pegboards using positional hooks]',
      },
      code: '79Xo',
      order: 30,
    },
    '工具箱（金属製のものを除く。）': {
      id: '工具箱（金属製のものを除く。）',
      name: {
        ja: '工具箱（金属製のものを除く。）',
        en: 'tool boxes、 not of metal',
      },
      code: 'i_5s',
      order: 31,
    },
    '紙タオル取り出し用箱（金属製のものを除く。），タオル用ディスペンサー（金属製のものを除く。）': {
      id: '紙タオル取り出し用箱（金属製のものを除く。），タオル用ディスペンサー（金属製のものを除く。）',
      name: {
        ja: '紙タオル取り出し用箱（金属製のものを除く。），タオル用ディスペンサー（金属製のものを除く。）',
        en: 'boxes、 not of metal、 for dispensing paper towels，towel dispensers、 not of metal',
      },
      code: 'ZbCN',
      order: 32,
    },
    '家具': {
      id: '家具',
      name: {
        ja: '家具',
        en: 'furniture',
      },
      code: 'YA70',
      order: 33,
    },
    '屋内用ブラインド，すだれ，装飾用ビーズカーテン，日よけ': {
      id: '屋内用ブラインド，すだれ，装飾用ビーズカーテン，日よけ',
      name: {
        ja: '屋内用ブラインド，すだれ，装飾用ビーズカーテン，日よけ',
        en: 'indoor window blinds [shade] [furniture]，blinds of reed、 rattan or bamboo [Sudare]，bead curtains for decoration，window shades',
      },
      code: '66Zq',
      order: 34,
    },
    '風鈴': {
      id: '風鈴',
      name: {
        ja: '風鈴',
        en: 'wind chimes',
      },
      code: 'IiPO',
      order: 35,
    },
    'つい立て，びょうぶ': {
      id: 'つい立て，びょうぶ',
      name: {
        ja: 'つい立て，びょうぶ',
        en: 'oriental single panel standing partition [Tsuitate]，oriental folding partition screens [Byoubu]',
      },
      code: 'rHfC',
      order: 36,
    },
    'ベンチ': {
      id: 'ベンチ',
      name: {
        ja: 'ベンチ',
        en: 'benches',
      },
      code: 'Q_3m',
      order: 37,
    },
    'アドバルーン，木製又はプラスチック製の立て看板': {
      id: 'アドバルーン，木製又はプラスチック製の立て看板',
      name: {
        ja: 'アドバルーン，木製又はプラスチック製の立て看板',
        en: 'advertising balloons，upright signboards of wood or plastics',
      },
      code: 'Sfno',
      order: 38,
    },
    '食品見本模型': {
      id: '食品見本模型',
      name: {
        ja: '食品見本模型',
        en: 'artificial model food samples',
      },
      code: 'R5pT',
      order: 39,
    },
    '木製・ろう製・石膏製又はプラスチック製の記念カップ，木製・ろう製・石膏製又はプラスチック製の記念たて': {
      id: '木製・ろう製・石膏製又はプラスチック製の記念カップ，木製・ろう製・石膏製又はプラスチック製の記念たて',
      name: {
        ja: '木製・ろう製・石膏製又はプラスチック製の記念カップ，木製・ろう製・石膏製又はプラスチック製の記念たて',
        en: 'prize cups of wood、 wax、 plaster or plastic，commemorative shields of wood、 wax、 plaster or plastic',
      },
      code: 'qf1i',
      order: 40,
    },
    '葬祭用具': {
      id: '葬祭用具',
      name: {
        ja: '葬祭用具',
        en: 'ritual equipment',
      },
      code: 'Sr6H',
      order: 41,
    },
    '懐中鏡，鏡袋': {
      id: '懐中鏡，鏡袋',
      name: {
        ja: '懐中鏡，鏡袋',
        en: 'pocket mirrors，pocket mirror bags',
      },
      code: 'mB22',
      order: 42,
    },
    '靴合わせくぎ（金属製のものを除く。），靴くぎ（金属製のものを除く。），靴びょう（金属製のものを除く。）': {
      id: '靴合わせくぎ（金属製のものを除く。），靴くぎ（金属製のものを除く。），靴びょう（金属製のものを除く。）',
      name: {
        ja: '靴合わせくぎ（金属製のものを除く。），靴くぎ（金属製のものを除く。），靴びょう（金属製のものを除く。）',
        en: 'shoe dowels、 not of metal，shoe pegs、 not of metal，hobnails、 not of metal',
      },
      code: '1ZQI',
      order: 43,
    },
    '揺りかご，幼児用歩行器': {
      id: '揺りかご，幼児用歩行器',
      name: {
        ja: '揺りかご，幼児用歩行器',
        en: 'cradles，infant walkers',
      },
      code: 'v1kM',
      order: 44,
    },
    'マネキン人形，洋服飾り型類': {
      id: 'マネキン人形，洋服飾り型類',
      name: {
        ja: 'マネキン人形，洋服飾り型類',
        en: 'mannequins，costume display stands',
      },
      code: 'Po2r',
      order: 45,
    },
    '額縁': {
      id: '額縁',
      name: {
        ja: '額縁',
        en: 'picture frames',
      },
      code: 'EpEZ',
      order: 46,
    },
    '石こう製彫刻，プラスチック製彫刻，木製彫刻': {
      id: '石こう製彫刻，プラスチック製彫刻，木製彫刻',
      name: {
        ja: '石こう製彫刻，プラスチック製彫刻，木製彫刻',
        en: 'plaster sculptures，plastic sculptures，wooden sculptures',
      },
      code: 'X0iJ',
      order: 47,
    },
    '写真立て': {
      id: '写真立て',
      name: {
        ja: '写真立て',
        en: 'standing photograph frames',
      },
      code: '1sxS',
      order: 48,
    },
    '経木，しだ，竹，竹皮，つる，とう，木皮': {
      id: '経木，しだ，竹，竹皮，つる，とう，木皮',
      name: {
        ja: '経木，しだ，竹，竹皮，つる，とう，木皮',
        en: 'Japanese food wrapping sheets of wood as materials [Kyogi]，ferns [unworked or partly worked material]，bamboo、 unworked or semi-worked，bamboo skins [unworked or partly worked material]，vines，rattan [unworked or partly worked material]，tree barks',
      },
      code: 'nUlc',
      order: 49,
    },
    'あし，い，おにがや，すげ，すさ，麦わら，わら': {
      id: 'あし，い，おにがや，すげ，すさ，麦わら，わら',
      name: {
        ja: 'あし，い，おにがや，すげ，すさ，麦わら，わら',
        en: 'reeds [raw or partly worked material]，rushes [raw or partly worked material]，Onigaya hay [raw or partly worked material]，sedges [unworked or partly worked material]，vegetable aggregate for plaster [Susa]，wheat、 barley or oat straw，rice straw',
      },
      code: 'VY1_',
      order: 50,
    },
    '牙，鯨のひげ，甲殻，人工角，象牙，角，歯，べっこう，骨': {
      id: '牙，鯨のひげ，甲殻，人工角，象牙，角，歯，べっこう，骨',
      name: {
        ja: '牙，鯨のひげ，甲殻，人工角，象牙，角，歯，べっこう，骨',
        en: 'tusks [raw or partly worked material]，whalebones，shells and crusts，artificial horns，ivory [unworked or partly worked material]，animal horns，animal teeth，tortoiseshells [unworked or partly worked material]，animal bone [unworked or partly worked material]',
      },
      code: 'PuSX',
      order: 51,
    },
    'さんご': {
      id: 'さんご',
      name: {
        ja: 'さんご',
        en: 'coral [unworked or partly worked]',
      },
      code: '5j5D',
      order: 52,
    },
  },
  21: {
    'デンタルフロス': {
      id: 'デンタルフロス',
      name: {
        ja: 'デンタルフロス',
        en: 'floss for dental purposes',
      },
      code: '-9EC',
      order: 1,
    },
    'ガラス基礎製品（建築用のものを除く。）': {
      id: 'ガラス基礎製品（建築用のものを除く。）',
      name: {
        ja: 'ガラス基礎製品（建築用のものを除く。）',
        en: 'unworked or semi-worked glass、 not for building',
      },
      code: 'SmQo',
      order: 2,
    },
    'かいばおけ': {
      id: 'かいばおけ',
      name: {
        ja: 'かいばおけ',
        en: 'mangers for animals [troughs for livestock]',
      },
      code: 'dp3b',
      order: 3,
    },
    '家きん用リング': {
      id: '家きん用リング',
      name: {
        ja: '家きん用リング',
        en: 'poultry rings',
      },
      code: 'Na5L',
      order: 4,
    },
    '香炉': {
      id: '香炉',
      name: {
        ja: '香炉',
        en: 'perfume burners',
      },
      code: 'ZY7R',
      order: 5,
    },
    '化粧用具': {
      id: '化粧用具',
      name: {
        ja: '化粧用具',
        en: 'cosmetic utensils',
      },
      code: '9GIs',
      order: 6,
    },
    '電気式歯ブラシ': {
      id: '電気式歯ブラシ',
      name: {
        ja: '電気式歯ブラシ',
        en: 'toothbrushes、 electric',
      },
      code: '_Jai',
      order: 7,
    },
    '化粧用具（「電気式歯ブラシ」を除く。）': {
      id: '化粧用具（「電気式歯ブラシ」を除く。）',
      name: {
        ja: '化粧用具（「電気式歯ブラシ」を除く。）',
        en: 'cosmetic utensils、 other than "toothbrushes、 electric"',
      },
      code: 'dWAB',
      order: 8,
    },
    'おけ用ブラシ，金ブラシ，管用ブラシ，工業用刷毛，船舶ブラシ': {
      id: 'おけ用ブラシ，金ブラシ，管用ブラシ，工業用刷毛，船舶ブラシ',
      name: {
        ja: 'おけ用ブラシ，金ブラシ，管用ブラシ，工業用刷毛，船舶ブラシ',
        en: 'tub brushes，metal brushes，brushes for pipes，industrial brushes，ship-scrubbing brushes',
      },
      code: 'Ijh_',
      order: 9,
    },
    '家事用手袋': {
      id: '家事用手袋',
      name: {
        ja: '家事用手袋',
        en: 'gloves for household purposes',
      },
      code: 'VyHL',
      order: 10,
    },
    'ガラス製又は陶磁製の包装用容器': {
      id: 'ガラス製又は陶磁製の包装用容器',
      name: {
        ja: 'ガラス製又は陶磁製の包装用容器',
        en: 'industrial packaging containers of glass or porcelain',
      },
      code: 'hV-t',
      order: 11,
    },
    'ガラス製包装用容器（「ガラス製栓・ガラス製ふた」を除く。）': {
      id: 'ガラス製包装用容器（「ガラス製栓・ガラス製ふた」を除く。）',
      name: {
        ja: 'ガラス製包装用容器（「ガラス製栓・ガラス製ふた」を除く。）',
        en: 'industrial packaging containers of glass、 not including glass stoppers、 lids and covers',
      },
      code: 'vHlG',
      order: 12,
    },
    '陶磁製包装用容器': {
      id: '陶磁製包装用容器',
      name: {
        ja: '陶磁製包装用容器',
        en: 'industrial packaging containers of ceramics',
      },
      code: 'c4iI',
      order: 13,
    },
    'ガラス製栓，ガラス製ふた': {
      id: 'ガラス製栓，ガラス製ふた',
      name: {
        ja: 'ガラス製栓，ガラス製ふた',
        en: 'glass stoppers for industrial packaging containers，glass covers and lids for industrial packaging containers',
      },
      code: 'madq',
      order: 14,
    },
    'プラスチック製の包装用瓶': {
      id: 'プラスチック製の包装用瓶',
      name: {
        ja: 'プラスチック製の包装用瓶',
        en: 'industrial packaging bottles of plastics',
      },
      code: 'bkQ6',
      order: 15,
    },
    '台所用品（「ガス湯沸かし器・加熱器・調理台・流し台」を除く。）': {
      id: '台所用品（「ガス湯沸かし器・加熱器・調理台・流し台」を除く。）',
      name: {
        ja: '台所用品（「ガス湯沸かし器・加熱器・調理台・流し台」を除く。）',
        en: 'kitchen implements and containers、 not including gas water heaters for household use、 non-electric cooking heaters for household purposes、 kitchen sinks incorporating integrated worktops for household purpose and kitchen sinks for household purpose',
      },
      code: 'SfSH',
      order: 16,
    },
    '鍋類，コーヒー沸かし（電気式のものを除く。），鉄瓶，やかん': {
      id: '鍋類，コーヒー沸かし（電気式のものを除く。），鉄瓶，やかん',
      name: {
        ja: '鍋類，コーヒー沸かし（電気式のものを除く。），鉄瓶，やかん',
        en: 'cooking pots and pans、 non-electric，coffee-makers、 non-electric，Japanese cast iron kettles、 non-electric [Tetsubin]，kettles、 non-electric',
      },
      code: 'H1tn',
      order: 17,
    },
    '食器類': {
      id: '食器類',
      name: {
        ja: '食器類',
        en: 'dinnerware、 other than knives、 forks and spoons',
      },
      code: '7-6S',
      order: 18,
    },
    'アイスボックス，氷冷蔵庫，米びつ，食品保存用ガラス瓶，水筒，魔法瓶': {
      id: 'アイスボックス，氷冷蔵庫，米びつ，食品保存用ガラス瓶，水筒，魔法瓶',
      name: {
        ja: 'アイスボックス，氷冷蔵庫，米びつ，食品保存用ガラス瓶，水筒，魔法瓶',
        en: 'non-electric ice chests for household purposes，ice-cooling refrigerators for household purposes，kitchen containers for rice，food preserving jars of glass，drinking flasks for travellers，vacuum bottles [insulated flasks]',
      },
      code: '_tvZ',
      order: 19,
    },
    '調理用具，アイスペール，角砂糖挟み，くるみ割り器，こしょう入れ，砂糖入れ，ざる，塩振り出し容器，しゃもじ，じょうご，ストロー，膳，栓抜（電気式のものを除く。），卵立て，タルト取り分け用へら，ナプキンホルダー，ナプキンリング，鍋敷き，はし，はし箱，ひしゃく，ふるい，盆，ようじ，ようじ入れ': {
      id: '調理用具，アイスペール，角砂糖挟み，くるみ割り器，こしょう入れ，砂糖入れ，ざる，塩振り出し容器，しゃもじ，じょうご，ストロー，膳，栓抜（電気式のものを除く。），卵立て，タルト取り分け用へら，ナプキンホルダー，ナプキンリング，鍋敷き，はし，はし箱，ひしゃく，ふるい，盆，ようじ，ようじ入れ',
      name: {
        ja: '調理用具，アイスペール，角砂糖挟み，くるみ割り器，こしょう入れ，砂糖入れ，ざる，塩振り出し容器，しゃもじ，じょうご，ストロー，膳，栓抜（電気式のものを除く。），卵立て，タルト取り分け用へら，ナプキンホルダー，ナプキンリング，鍋敷き，はし，はし箱，ひしゃく，ふるい，盆，ようじ，ようじ入れ',
        en: 'cookware，ice pails，sugar tongs，nutcrackers，pepper pots，sugar bowls，colanders，salt shakers，Japanese style cooked rice scoops [Shamoji]，cooking funnels，drinking straws，Japanese style personal dining trays or stands [Zen]，bottle openers、 non-electric，egg cups，tart scoops，napkin holders，napkin rings，hot pads [trivets]，chopsticks，chopstick cases，ladles and dippers for kitchen use，cooking sieves and sifters，trays，toothpicks，toothpick holders',
      },
      code: '0KZJ',
      order: 20,
    },
    '清掃用具及び洗濯用具': {
      id: '清掃用具及び洗濯用具',
      name: {
        ja: '清掃用具及び洗濯用具',
        en: 'cleaning tools and washing utensils',
      },
      code: 'f3Vu',
      order: 21,
    },
    'アイロン台，霧吹き，こて台，へら台': {
      id: 'アイロン台，霧吹き，こて台，へら台',
      name: {
        ja: 'アイロン台，霧吹き，こて台，へら台',
        en: 'ironing boards，tailors\' sprayers，ironing boards [Kotedai]，Hera-dai [fabric marking boards]',
      },
      code: 'btuG',
      order: 22,
    },
    '湯かき棒，浴室用手おけ': {
      id: '湯かき棒，浴室用手おけ',
      name: {
        ja: '湯かき棒，浴室用手おけ',
        en: 'yukakibo [Japanese bath utensils for hot bathtub water]，bathroom pails',
      },
      code: 'UjTA',
      order: 23,
    },
    'ろうそく消し，ろうそく立て': {
      id: 'ろうそく消し，ろうそく立て',
      name: {
        ja: 'ろうそく消し，ろうそく立て',
        en: 'candle extinguishers，candlesticks',
      },
      code: 'VPhN',
      order: 24,
    },
    '家庭用燃え殻ふるい，五徳，石炭入れ，火消しつぼ': {
      id: '家庭用燃え殻ふるい，五徳，石炭入れ，火消しつぼ',
      name: {
        ja: '家庭用燃え殻ふるい，五徳，石炭入れ，火消しつぼ',
        en: 'cinder sifters for household purposes，Gotoku [trivets for Japanese charcoal heaters for household purposes]，coal scuttles，Japanese charcoal extinguishers [Hikeshi-tsubo]',
      },
      code: '2ST2',
      order: 25,
    },
    'ねずみ取り器，はえたたき': {
      id: 'ねずみ取り器，はえたたき',
      name: {
        ja: 'ねずみ取り器，はえたたき',
        en: 'mouse and rat traps，fly swatters',
      },
      code: 'qJnG',
      order: 26,
    },
    '植木鉢，家庭園芸用の水耕式植物栽培器，じょうろ': {
      id: '植木鉢，家庭園芸用の水耕式植物栽培器，じょうろ',
      name: {
        ja: '植木鉢，家庭園芸用の水耕式植物栽培器，じょうろ',
        en: 'flower pots，hydroponic plant pots for home gardening，watering cans',
      },
      code: 'Ha3r',
      order: 27,
    },
    '小鳥かご，小鳥用水盤，ペット用食器，ペット用ブラシ': {
      id: '小鳥かご，小鳥用水盤，ペット用食器，ペット用ブラシ',
      name: {
        ja: '小鳥かご，小鳥用水盤，ペット用食器，ペット用ブラシ',
        en: 'bird cages，bird baths*，feeding vessels for pets，brushes for pets',
      },
      code: '8pHS',
      order: 28,
    },
    '洋服ブラシ': {
      id: '洋服ブラシ',
      name: {
        ja: '洋服ブラシ',
        en: 'clothes brushes',
      },
      code: 'Br0i',
      order: 29,
    },
    '寝室用簡易便器': {
      id: '寝室用簡易便器',
      name: {
        ja: '寝室用簡易便器',
        en: 'chamber pots',
      },
      code: 'hCvP',
      order: 30,
    },
    '貯金箱': {
      id: '貯金箱',
      name: {
        ja: '貯金箱',
        en: 'piggy banks',
      },
      code: 'iJNz',
      order: 31,
    },
    'お守り，おみくじ': {
      id: 'お守り，おみくじ',
      name: {
        ja: 'お守り，おみくじ',
        en: 'Good luck charms，fortune telling',
      },
      code: 'NIoG',
      order: 32,
    },
    '食品及び飲料の保冷用アイスパック': {
      id: '食品及び飲料の保冷用アイスパック',
      name: {
        ja: '食品及び飲料の保冷用アイスパック',
        en: 'cold packs for chilling food and beverages',
      },
      code: 'a-Nq',
      order: 33,
    },
    'せっけん用ディスペンサーボトル': {
      id: 'せっけん用ディスペンサーボトル',
      name: {
        ja: 'せっけん用ディスペンサーボトル',
        en: 'soap dispensing bottles',
      },
      code: 'eZ2d',
      order: 34,
    },
    '観賞魚用水槽及びその附属品': {
      id: '観賞魚用水槽及びその附属品',
      name: {
        ja: '観賞魚用水槽及びその附属品',
        en: 'tanks [indoor aquaria] and their fittings',
      },
      code: 'xkLB',
      order: 35,
    },
    'トイレットペーパーホルダー': {
      id: 'トイレットペーパーホルダー',
      name: {
        ja: 'トイレットペーパーホルダー',
        en: 'toilet paper holders',
      },
      code: 'Ufws',
      order: 36,
    },
    '花瓶，水盤': {
      id: '花瓶，水盤',
      name: {
        ja: '花瓶，水盤',
        en: 'flower vases，flower bowls',
      },
      code: 'Ol-B',
      order: 37,
    },
    'ガラス製又は磁器製の立て看板': {
      id: 'ガラス製又は磁器製の立て看板',
      name: {
        ja: 'ガラス製又は磁器製の立て看板',
        en: 'upright signboards of glass or ceramics',
      },
      code: 'p8C5',
      order: 38,
    },
    '磁器製・陶器製・土器製・テラコッタ製又はガラス製の記念カップ，磁器製・陶器製・土器製・テラコッタ製又はガラス製の記念たて': {
      id: '磁器製・陶器製・土器製・テラコッタ製又はガラス製の記念カップ，磁器製・陶器製・土器製・テラコッタ製又はガラス製の記念たて',
      name: {
        ja: '磁器製・陶器製・土器製・テラコッタ製又はガラス製の記念カップ，磁器製・陶器製・土器製・テラコッタ製又はガラス製の記念たて',
        en: 'prize cups of porcelain、 ceramic、 earthenware、 terra-cotta or glass，commemorative shields of porcelain、 ceramic、 earthenware、 terra-cotta or glass',
      },
      code: 'ii1e',
      order: 39,
    },
    '靴ブラシ，靴べら，靴磨き布，軽便靴クリーナー，シューツリー': {
      id: '靴ブラシ，靴べら，靴磨き布，軽便靴クリーナー，シューツリー',
      name: {
        ja: '靴ブラシ，靴べら，靴磨き布，軽便靴クリーナー，シューツリー',
        en: 'shoe brushes，shoe horns，shoe shine cloths，shoe shine sponges and cloths，shoe-trees [stretchers]',
      },
      code: '2R3q',
      order: 40,
    },
    'コッフェル': {
      id: 'コッフェル',
      name: {
        ja: 'コッフェル',
        en: 'portable cooking kits for outdoor use',
      },
      code: 'SIA1',
      order: 41,
    },
    '昆虫採集箱，昆虫胴乱': {
      id: '昆虫採集箱，昆虫胴乱',
      name: {
        ja: '昆虫採集箱，昆虫胴乱',
        en: 'insect collectors\' boxes，insect collecting boxes in the form of a flattened cylindrical metal case',
      },
      code: 'qThY',
      order: 42,
    },
    'ブラシ用牛毛・たぬきの毛・豚毛及び馬毛': {
      id: 'ブラシ用牛毛・たぬきの毛・豚毛及び馬毛',
      name: {
        ja: 'ブラシ用牛毛・たぬきの毛・豚毛及び馬毛',
        en: 'cattle hair for brushes、 raccoon dog hair for brushes、 pig bristles for brushes and horsehair for brushes',
      },
      code: 'Xkv5',
      order: 43,
    },
  },
  22: {
    '落石防止網（金属製のものを除く。）': {
      id: '落石防止網（金属製のものを除く。）',
      name: {
        ja: '落石防止網（金属製のものを除く。）',
        en: 'rockfall prevention nets、 not of metal',
      },
      code: 'drQi',
      order: 1,
    },
    '船舶用オーニング，ターポリン，帆': {
      id: '船舶用オーニング，ターポリン，帆',
      name: {
        ja: '船舶用オーニング，ターポリン，帆',
        en: 'awnings for vessels，tarpaulins for ships，sails',
      },
      code: 'YeC7',
      order: 2,
    },
    '原料繊維': {
      id: '原料繊維',
      name: {
        ja: '原料繊維',
        en: 'raw textile fibers',
      },
      code: '7ijd',
      order: 3,
    },
    '綿繊維': {
      id: '綿繊維',
      name: {
        ja: '綿繊維',
        en: 'cotton fibers',
      },
      code: 'uHM0',
      order: 4,
    },
    '麻繊維': {
      id: '麻繊維',
      name: {
        ja: '麻繊維',
        en: 'hemp fibers',
      },
      code: 'v37q',
      order: 5,
    },
    '絹繊維': {
      id: '絹繊維',
      name: {
        ja: '絹繊維',
        en: 'silk fibers',
      },
      code: 'bMwH',
      order: 6,
    },
    '毛繊維': {
      id: '毛繊維',
      name: {
        ja: '毛繊維',
        en: 'coarse animal hairs',
      },
      code: 'OUUF',
      order: 7,
    },
    '織物用化学繊維': {
      id: '織物用化学繊維',
      name: {
        ja: '織物用化学繊維',
        en: 'chemical fibers for textile use',
      },
      code: 'cAlO',
      order: 8,
    },
    '織物用無機繊維': {
      id: '織物用無機繊維',
      name: {
        ja: '織物用無機繊維',
        en: 'inorganic fibers for textile use',
      },
      code: '9n3Z',
      order: 9,
    },
    '衣服綿，ハンモック，布団袋，布団綿': {
      id: '衣服綿，ハンモック，布団袋，布団綿',
      name: {
        ja: '衣服綿，ハンモック，布団袋，布団綿',
        en: 'cotton waddings for clothes，hammocks，Futon bags，cotton batting for Futon',
      },
      code: 'GkHZ',
      order: 10,
    },
    '編みひも，真田ひも，のり付けひも，よりひも，綱類': {
      id: '編みひも，真田ひも，のり付けひも，よりひも，綱類',
      name: {
        ja: '編みひも，真田ひも，のり付けひも，よりひも，綱類',
        en: 'knitted cords，Sanada-himo [Japanese-style cords]，starched cords，twisted cords，cordage',
      },
      code: 'X-2y',
      order: 11,
    },
    '網類（金属製のものを除く。）': {
      id: '網類（金属製のものを除く。）',
      name: {
        ja: '網類（金属製のものを除く。）',
        en: 'netting、 not of metal',
      },
      code: 'ru-q',
      order: 12,
    },
    '布製包装用容器': {
      id: '布製包装用容器',
      name: {
        ja: '布製包装用容器',
        en: 'industrial packaging containers of textile',
      },
      code: 'arV_',
      order: 13,
    },
    'わら製包装用容器': {
      id: 'わら製包装用容器',
      name: {
        ja: 'わら製包装用容器',
        en: 'industrial packaging containers of straw',
      },
      code: 'AU6L',
      order: 14,
    },
    '結束用ゴムバンド': {
      id: '結束用ゴムバンド',
      name: {
        ja: '結束用ゴムバンド',
        en: 'elastic bands for binding',
      },
      code: 'Majz',
      order: 15,
    },
    '雨覆い，織物製屋外用ブラインド，天幕': {
      id: '雨覆い，織物製屋外用ブラインド，天幕',
      name: {
        ja: '雨覆い，織物製屋外用ブラインド，天幕',
        en: 'tarpaulins、 not for ships，outdoor blinds of textile，tents、 not for camping',
      },
      code: 'Hfnr',
      order: 16,
    },
    '靴用ろう引き縫糸': {
      id: '靴用ろう引き縫糸',
      name: {
        ja: '靴用ろう引き縫糸',
        en: 'wax ends',
      },
      code: 'HsmT',
      order: 17,
    },
    'ザイル，登山用又はキャンプ用のテント': {
      id: 'ザイル，登山用又はキャンプ用のテント',
      name: {
        ja: 'ザイル，登山用又はキャンプ用のテント',
        en: 'mountaineering ropes，tents for mountaineering or camping',
      },
      code: '9xzl',
      order: 18,
    },
    'ウインドサーフィン用のセイル': {
      id: 'ウインドサーフィン用のセイル',
      name: {
        ja: 'ウインドサーフィン用のセイル',
        en: 'sails for windsurfing',
      },
      code: 'MMYk',
      order: 19,
    },
    'おがくず，カポック，詰め物用かんなくず，木毛，もみがら，ろうくず': {
      id: 'おがくず，カポック，詰め物用かんなくず，木毛，もみがら，ろうくず',
      name: {
        ja: 'おがくず，カポック，詰め物用かんなくず，木毛，もみがら，ろうくず',
        en: 'sawdust，kapok，wood shavings for stuffing，wood wool [wooden excelsior]，rice hulls，wax waste for stuffing',
      },
      code: 'qwi-',
      order: 20,
    },
    '牛毛・たぬきの毛・豚毛及び馬毛（未加工のものに限る。）': {
      id: '牛毛・たぬきの毛・豚毛及び馬毛（未加工のものに限る。）',
      name: {
        ja: '牛毛・たぬきの毛・豚毛及び馬毛（未加工のものに限る。）',
        en: 'cattle hair、 raccoon dog hair、 pig bristles and horsehair [raw material]',
      },
      code: 'uJb1',
      order: 21,
    },
    '羽': {
      id: '羽',
      name: {
        ja: '羽',
        en: 'feathers and downs',
      },
      code: 'OJuM',
      order: 22,
    },
  },
  23: {
    '糸': {
      id: '糸',
      name: {
        ja: '糸',
        en: 'threads and yarns',
      },
      code: '6xYt',
      order: 1,
    },
    '糸（「脱脂屑糸」を除く。）': {
      id: '糸（「脱脂屑糸」を除く。）',
      name: {
        ja: '糸（「脱脂屑糸」を除く。）',
        en: 'threads and yarns、 other than degreased waste threads and yarns',
      },
      code: 'xxTu',
      order: 2,
    },
    '脱脂屑糸': {
      id: '脱脂屑糸',
      name: {
        ja: '脱脂屑糸',
        en: 'degreased waste threads and yarns',
      },
      code: 'oIAY',
      order: 3,
    },
  },
  24: {
    '織物': {
      id: '織物',
      name: {
        ja: '織物',
        en: 'woven fabrics',
      },
      code: 'iHWF',
      order: 1,
    },
    '織物（「畳べり地」を除く。）': {
      id: '織物（「畳べり地」を除く。）',
      name: {
        ja: '織物（「畳べり地」を除く。）',
        en: 'woven fabrics、 other than edging ribbons for Tatami mats',
      },
      code: 'VhcT',
      order: 2,
    },
    '畳べり地': {
      id: '畳べり地',
      name: {
        ja: '畳べり地',
        en: 'cloth for Tatami mat edging ribbons',
      },
      code: 'P_Sf',
      order: 3,
    },
    'メリヤス生地': {
      id: 'メリヤス生地',
      name: {
        ja: 'メリヤス生地',
        en: 'knitted fabrics',
      },
      code: 'U8DL',
      order: 4,
    },
    'フェルト及び不織布': {
      id: 'フェルト及び不織布',
      name: {
        ja: 'フェルト及び不織布',
        en: 'felt and non-woven textile fabrics',
      },
      code: 'L0Qh',
      order: 5,
    },
    'オイルクロス，ゴム引防水布，ビニルクロス，ラバークロス，ろ過布': {
      id: 'オイルクロス，ゴム引防水布，ビニルクロス，ラバークロス，ろ過布',
      name: {
        ja: 'オイルクロス，ゴム引防水布，ビニルクロス，ラバークロス，ろ過布',
        en: 'oilcloth，gummed waterproof cloth，vinyl coated cloth，rubberized cloth，filtering materials of textile',
      },
      code: 'j1lq',
      order: 6,
    },
    '布製身の回り品': {
      id: '布製身の回り品',
      name: {
        ja: '布製身の回り品',
        en: 'woven textile goods for personal use',
      },
      code: 'gYNk',
      order: 7,
    },
    'かや，敷布，布団，布団カバー，布団側，まくらカバー，毛布': {
      id: 'かや，敷布，布団，布団カバー，布団側，まくらカバー，毛布',
      name: {
        ja: 'かや，敷布，布団，布団カバー，布団側，まくらカバー，毛布',
        en: 'mosquito nets，bedsheets，Futon quilts，quilt covers for Futon，Futon ticks [unstuffed Futon]，pillowcases [pillow slips]，blankets',
      },
      code: '_oHP',
      order: 8,
    },
    '織物製テーブルナプキン': {
      id: '織物製テーブルナプキン',
      name: {
        ja: '織物製テーブルナプキン',
        en: 'table napkins of textile',
      },
      code: 'HZRj',
      order: 9,
    },
    'ふきん': {
      id: 'ふきん',
      name: {
        ja: 'ふきん',
        en: 'dish towels for drying',
      },
      code: 'dCox',
      order: 10,
    },
    'シャワーカーテン': {
      id: 'シャワーカーテン',
      name: {
        ja: 'シャワーカーテン',
        en: 'shower curtains',
      },
      code: 'Z-80',
      order: 11,
    },
    'のぼり及び旗（紙製のものを除く。）': {
      id: 'のぼり及び旗（紙製のものを除く。）',
      name: {
        ja: 'のぼり及び旗（紙製のものを除く。）',
        en: 'banners and flags、 not of paper',
      },
      code: '68Bo',
      order: 12,
    },
    '織物製トイレットシートカバー': {
      id: '織物製トイレットシートカバー',
      name: {
        ja: '織物製トイレットシートカバー',
        en: 'toilet seat covers of textile',
      },
      code: 'Cqtt',
      order: 13,
    },
    '織物製椅子カバー，織物製壁掛け，織物製又はプラスチック製のカーテン，テーブル掛け，どん帳': {
      id: '織物製椅子カバー，織物製壁掛け，織物製又はプラスチック製のカーテン，テーブル掛け，どん帳',
      name: {
        ja: '織物製椅子カバー，織物製壁掛け，織物製又はプラスチック製のカーテン，テーブル掛け，どん帳',
        en: 'seat covers of textile，wall hangings of textile，curtains of textile or plastic，table cloths、 not of paper，draperies [thick drop curtains]',
      },
      code: 'cwQ9',
      order: 14,
    },
    '遺体覆い，経かたびら，紅白幕，黒白幕': {
      id: '遺体覆い，経かたびら，紅白幕，黒白幕',
      name: {
        ja: '遺体覆い，経かたびら，紅白幕，黒白幕',
        en: 'shrouds [sheets for wrapping corpses]，shrouds for dressing a corpse for funeral [Kyokatabira kimono]，red-and-white striped curtains，black-and-white striped curtains',
      },
      code: 'ht-P',
      order: 15,
    },
    'ビリヤードクロス': {
      id: 'ビリヤードクロス',
      name: {
        ja: 'ビリヤードクロス',
        en: 'billiard cloth [baize]',
      },
      code: 'WPq5',
      order: 16,
    },
    'スリーピングバッグ': {
      id: 'スリーピングバッグ',
      name: {
        ja: 'スリーピングバッグ',
        en: 'sleeping bags',
      },
      code: 'hPGb',
      order: 17,
    },
  },
  25: {
    '被服': {
      id: '被服',
      name: {
        ja: '被服',
        en: 'clothing*',
      },
      code: 'Tf5B',
      order: 1,
    },
    '洋服，コート，セーター類，ワイシャツ類': {
      id: '洋服，コート，セーター類，ワイシャツ類',
      name: {
        ja: '洋服，コート，セーター類，ワイシャツ類',
        en: 'non-Japanese style outerclothing，coats，，',
      },
      code: '2e2c',
      order: 2,
    },
    '寝巻き類，下着，水泳着，水泳帽': {
      id: '寝巻き類，下着，水泳着，水泳帽',
      name: {
        ja: '寝巻き類，下着，水泳着，水泳帽',
        en: 'nightwear，underwear [underclothing]，swimwear [bathing suits]，swimming caps [bathing caps]',
      },
      code: 'xc3I',
      order: 3,
    },
    'キャミソール，タンクトップ，ティーシャツ': {
      id: 'キャミソール，タンクトップ，ティーシャツ',
      name: {
        ja: 'キャミソール，タンクトップ，ティーシャツ',
        en: 'camisoles，tank-tops，tee-shirts',
      },
      code: 'NSLU',
      order: 4,
    },
    '和服': {
      id: '和服',
      name: {
        ja: '和服',
        en: 'Japanese traditional clothing',
      },
      code: '1yIP',
      order: 5,
    },
    'アイマスク，エプロン，えり巻き，靴下，ゲートル，毛皮製ストール，ショール，スカーフ，足袋，足袋カバー，手袋，ネクタイ，ネッカチーフ，バンダナ，保温用サポーター，マフラー，耳覆い': {
      id: 'アイマスク，エプロン，えり巻き，靴下，ゲートル，毛皮製ストール，ショール，スカーフ，足袋，足袋カバー，手袋，ネクタイ，ネッカチーフ，バンダナ，保温用サポーター，マフラー，耳覆い',
      name: {
        ja: 'アイマスク，エプロン，えり巻き，靴下，ゲートル，毛皮製ストール，ショール，スカーフ，足袋，足袋カバー，手袋，ネクタイ，ネッカチーフ，バンダナ，保温用サポーター，マフラー，耳覆い',
        en: 'sleep masks，aprons [clothing]，neck scarves，socks and stockings other than special sportswear，puttees and gaiters，fur stoles，shawls，scarves [scarfs]，Japanese style socks [Tabi]，slipcovers for Japanese style socks [slipcovers for tabi]，gloves and mittens [clothing]，neckties，neckerchieves，bandanas [neckerchiefs]，thermal supporters [clothing]，mufflers [clothing]，ear muffs [clothing]',
      },
      code: 'kp-W',
      order: 6,
    },
    'ナイトキャップ，帽子': {
      id: 'ナイトキャップ，帽子',
      name: {
        ja: 'ナイトキャップ，帽子',
        en: 'nightcaps，headgear for wear',
      },
      code: 'T1NF',
      order: 7,
    },
    'ガーター，靴下留め，ズボンつり，バンド，ベルト': {
      id: 'ガーター，靴下留め，ズボンつり，バンド，ベルト',
      name: {
        ja: 'ガーター，靴下留め，ズボンつり，バンド，ベルト',
        en: 'garters，sock suspenders，braces [suspenders] for clothing，waistbands，belts [clothing]',
      },
      code: 'Pcyn',
      order: 8,
    },
    '履物': {
      id: '履物',
      name: {
        ja: '履物',
        en: 'footwear [other than special footwear for sports]',
      },
      code: 'EYwd',
      order: 9,
    },
    '靴類': {
      id: '靴類',
      name: {
        ja: '靴類',
        en: 'shoes and boots [other than special footwear for sports]',
      },
      code: '274d',
      order: 10,
    },
    'げた，草履類': {
      id: 'げた，草履類',
      name: {
        ja: 'げた，草履類',
        en: 'Japanese style wooden clogs [Geta]，Japanese style sandals [Zori]',
      },
      code: 'EY37',
      order: 11,
    },
    '靴保護具': {
      id: '靴保護具',
      name: {
        ja: '靴保護具',
        en: 'protective members for footwear',
      },
      code: 'KfcY',
      order: 12,
    },
    '仮装用衣服': {
      id: '仮装用衣服',
      name: {
        ja: '仮装用衣服',
        en: 'masquerade costumes',
      },
      code: 'lYD0',
      order: 13,
    },
    '運動用特殊靴': {
      id: '運動用特殊靴',
      name: {
        ja: '運動用特殊靴',
        en: 'special footwear for sports',
      },
      code: 'X5rH',
      order: 14,
    },
    '運動用特殊靴（「乗馬靴」及び「ウインドサーフィン用シューズ」を除く。）': {
      id: '運動用特殊靴（「乗馬靴」及び「ウインドサーフィン用シューズ」を除く。）',
      name: {
        ja: '運動用特殊靴（「乗馬靴」及び「ウインドサーフィン用シューズ」を除く。）',
        en: 'special footwear for sports、 other than horse-riding boots and windsurfing shoes',
      },
      code: 'uGOr',
      order: 15,
    },
    '乗馬靴': {
      id: '乗馬靴',
      name: {
        ja: '乗馬靴',
        en: 'horse-riding boots',
      },
      code: 'Tr1A',
      order: 16,
    },
    'ウインドサーフィン用シューズ': {
      id: 'ウインドサーフィン用シューズ',
      name: {
        ja: 'ウインドサーフィン用シューズ',
        en: 'windsurfing shoes',
      },
      code: '9mFB',
      order: 17,
    },
    '運動用特殊衣服（「水上スポーツ用特殊衣服」を除く。）': {
      id: '運動用特殊衣服（「水上スポーツ用特殊衣服」を除く。）',
      name: {
        ja: '運動用特殊衣服（「水上スポーツ用特殊衣服」を除く。）',
        en: 'clothes for sports、 other than clothes for water sports',
      },
      code: 'TSDx',
      order: 18,
    },
  },
  26: {
    '漁網製作用杼': {
      id: '漁網製作用杼',
      name: {
        ja: '漁網製作用杼',
        en: 'shuttles for making fishing nets',
      },
      code: 'nV8R',
      order: 1,
    },
    '電気式ヘアカーラー': {
      id: '電気式ヘアカーラー',
      name: {
        ja: '電気式ヘアカーラー',
        en: 'electric hair curlers',
      },
      code: 'aeBE',
      order: 2,
    },
    '針類（ミシン針を除く。）': {
      id: '針類（ミシン針を除く。）',
      name: {
        ja: '針類（ミシン針を除く。）',
        en: 'needles、 not for sewing machines',
      },
      code: 'GS39',
      order: 3,
    },
    'かばん金具，がま口用留め具，被服用はとめ': {
      id: 'かばん金具，がま口用留め具，被服用はとめ',
      name: {
        ja: 'かばん金具，がま口用留め具，被服用はとめ',
        en: 'metal fittings for bags，clasps for coin purses，eyelets for clothing',
      },
      code: '69_q',
      order: 4,
    },
    'テープ，リボン': {
      id: 'テープ，リボン',
      name: {
        ja: 'テープ，リボン',
        en: 'tapes [haberdashery]，ribbons',
      },
      code: 'SSvr',
      order: 5,
    },
    '編みレース生地，刺しゅうレース生地': {
      id: '編みレース生地，刺しゅうレース生地',
      name: {
        ja: '編みレース生地，刺しゅうレース生地',
        en: 'knitted raschel lace fabrics，embroidery lace fabrics',
      },
      code: 'Z5UG',
      order: 6,
    },
    '房類': {
      id: '房類',
      name: {
        ja: '房類',
        en: 'tufts and tassels [semi-finished]',
      },
      code: 'fEqM',
      order: 7,
    },
    '組ひも': {
      id: '組ひも',
      name: {
        ja: '組ひも',
        en: 'braids',
      },
      code: '5wYf',
      order: 8,
    },
    '編み棒，糸通し器，裁縫箱，裁縫用へら，裁縫用指抜き，針刺し，針箱': {
      id: '編み棒，糸通し器，裁縫箱，裁縫用へら，裁縫用指抜き，針刺し，針箱',
      name: {
        ja: '編み棒，糸通し器，裁縫箱，裁縫用へら，裁縫用指抜き，針刺し，針箱',
        en: 'knitting sticks，needle-threaders，sewing boxes，dressmakers\' impressing blades，sewing thimbles，pin and needle cushions，boxes for needles',
      },
      code: 'Ilqo',
      order: 9,
    },
    '造花': {
      id: '造花',
      name: {
        ja: '造花',
        en: 'artificial flowers',
      },
      code: '2asx',
      order: 10,
    },
    '造花の花輪': {
      id: '造花の花輪',
      name: {
        ja: '造花の花輪',
        en: 'artificial garlands and wreaths for ceremonies or funerals',
      },
      code: 'R4o-',
      order: 11,
    },
    '造花（「造花の花輪」を除く。）': {
      id: '造花（「造花の花輪」を除く。）',
      name: {
        ja: '造花（「造花の花輪」を除く。）',
        en: 'artificial flowers、 other than artificial garlands and wreaths',
      },
      code: 'tsvH',
      order: 12,
    },
    '腕留め': {
      id: '腕留め',
      name: {
        ja: '腕留め',
        en: 'armband for holding sleeves',
      },
      code: 'RtJG',
      order: 13,
    },
    '衣服用き章（貴金属製のものを除く。），衣服用バックル，衣服用バッジ（貴金属製のものを除く。），衣服用ブローチ，帯留，ワッペン，腕章': {
      id: '衣服用き章（貴金属製のものを除く。），衣服用バックル，衣服用バッジ（貴金属製のものを除く。），衣服用ブローチ，帯留，ワッペン，腕章',
      name: {
        ja: '衣服用き章（貴金属製のものを除く。），衣服用バックル，衣服用バッジ（貴金属製のものを除く。），衣服用ブローチ，帯留，ワッペン，腕章',
        en: 'insignias for wear、 not of precious metal，buckles for clothing [clothing buckles]，badges for wear、 not of precious metal，brooches for clothing，special sash clips for obi [Obi-dome]，ornamental adhesive patches for jackets，brassards',
      },
      code: 'sZc9',
      order: 14,
    },
    '頭飾品': {
      id: '頭飾品',
      name: {
        ja: '頭飾品',
        en: 'hair ornaments',
      },
      code: 'Bn_o',
      order: 15,
    },
    'ボタン類': {
      id: 'ボタン類',
      name: {
        ja: 'ボタン類',
        en: 'buttons',
      },
      code: '2fRn',
      order: 16,
    },
    'つけあごひげ，つけ口ひげ，ヘアカーラー（電気式のものを除く。）': {
      id: 'つけあごひげ，つけ口ひげ，ヘアカーラー（電気式のものを除く。）',
      name: {
        ja: 'つけあごひげ，つけ口ひげ，ヘアカーラー（電気式のものを除く。）',
        en: 'false beards，false moustaches，hair curlers、 non-electric',
      },
      code: 'joAb',
      order: 17,
    },
    '靴飾り（貴金属製のものを除く。），靴はとめ，靴ひも，靴ひも代用金具': {
      id: '靴飾り（貴金属製のものを除く。），靴はとめ，靴ひも，靴ひも代用金具',
      name: {
        ja: '靴飾り（貴金属製のものを除く。），靴はとめ，靴ひも，靴ひも代用金具',
        en: 'shoe ornaments、 not of precious metal，shoe eyelets，shoe laces，metal fasteners for shoes and boots',
      },
      code: 'Y9mX',
      order: 18,
    },
    '人毛': {
      id: '人毛',
      name: {
        ja: '人毛',
        en: 'human hair',
      },
      code: 'oZm7',
      order: 19,
    },
  },
  27: {
    '洗い場用マット': {
      id: '洗い場用マット',
      name: {
        ja: '洗い場用マット',
        en: 'bath mats for wash places',
      },
      code: 'mkbC',
      order: 1,
    },
    '畳類': {
      id: '畳類',
      name: {
        ja: '畳類',
        en: 'tatami mats',
      },
      code: 'qgOr',
      order: 2,
    },
    '敷物，壁掛け（織物製のものを除く。）': {
      id: '敷物，壁掛け（織物製のものを除く。）',
      name: {
        ja: '敷物，壁掛け（織物製のものを除く。）',
        en: 'floor coverings，wall hangings、 not of textile',
      },
      code: 'qFsF',
      order: 3,
    },
    '人工芝': {
      id: '人工芝',
      name: {
        ja: '人工芝',
        en: 'artificial turf',
      },
      code: 'gEpT',
      order: 4,
    },
    '体操用マット': {
      id: '体操用マット',
      name: {
        ja: '体操用マット',
        en: 'gymnastic mats',
      },
      code: 'bgCA',
      order: 5,
    },
    '壁紙': {
      id: '壁紙',
      name: {
        ja: '壁紙',
        en: 'wallpaper',
      },
      code: 'Cbhy',
      order: 6,
    },
  },
  28: {
    '遊園地用機械器具': {
      id: '遊園地用機械器具',
      name: {
        ja: '遊園地用機械器具',
        en: 'amusement machines and apparatus for use in amusement parks',
      },
      code: 'ZWg-',
      order: 1,
    },
    'ペット用おもちゃ': {
      id: 'ペット用おもちゃ',
      name: {
        ja: 'ペット用おもちゃ',
        en: 'toys for domestic pets',
      },
      code: 'zsPW',
      order: 2,
    },
    '印刷したくじ（「おもちゃ」を除く。）': {
      id: '印刷したくじ（「おもちゃ」を除く。）',
      name: {
        ja: '印刷したくじ（「おもちゃ」を除く。）',
        en: 'printed paper for lot、 other than toy',
      },
      code: '7pKj',
      order: 3,
    },
    'おもちゃ，人形': {
      id: 'おもちゃ，人形',
      name: {
        ja: 'おもちゃ，人形',
        en: 'toys*，dolls',
      },
      code: 'OMmb',
      order: 4,
    },
    '囲碁用具，将棋用具，歌がるた，さいころ，すごろく，ダイスカップ，ダイヤモンドゲーム，チェス用具，チェッカー用具，手品用具，ドミノ用具，トランプ，花札，マージャン用具': {
      id: '囲碁用具，将棋用具，歌がるた，さいころ，すごろく，ダイスカップ，ダイヤモンドゲーム，チェス用具，チェッカー用具，手品用具，ドミノ用具，トランプ，花札，マージャン用具',
      name: {
        ja: '囲碁用具，将棋用具，歌がるた，さいころ，すごろく，ダイスカップ，ダイヤモンドゲーム，チェス用具，チェッカー用具，手品用具，ドミノ用具，トランプ，花札，マージャン用具',
        en: 'Go games，Japanese chess [Shogi games]，Japanese playing cards [Utagaruta]，dice，Japanese dice games [Sugoroku]，cups for dice，Chinese checkers [games]，chess games，checkers [checker sets]，conjuring apparatus，dominoes，playing cards，Japanese playing cards [Hanafuda]，mah-jong',
      },
      code: 'wJgP',
      order: 5,
    },
    '遊戯用器具，ビリヤード用具': {
      id: '遊戯用器具，ビリヤード用具',
      name: {
        ja: '遊戯用器具，ビリヤード用具',
        en: 'billiard equipment',
      },
      code: '0Sao',
      order: 6,
    },
    '運動用具': {
      id: '運動用具',
      name: {
        ja: '運動用具',
        en: 'sports equipment',
      },
      code: '5wzW',
      order: 7,
    },
    '運動用具（登山用・サーフィン用・水上スキー用・スキューバダイビング用のものを除く。）': {
      id: '運動用具（登山用・サーフィン用・水上スキー用・スキューバダイビング用のものを除く。）',
      name: {
        ja: '運動用具（登山用・サーフィン用・水上スキー用・スキューバダイビング用のものを除く。）',
        en: 'sports equipment、 other than for climbing、 surfing、 water-skiing and scuba diving',
      },
      code: '-j-3',
      order: 8,
    },
    '登山用ハーネス': {
      id: '登山用ハーネス',
      name: {
        ja: '登山用ハーネス',
        en: 'climbers\' harness',
      },
      code: 'hcAO',
      order: 9,
    },
    'サーフィン用・水上スキー用・スキューバダイビング用運動用具': {
      id: 'サーフィン用・水上スキー用・スキューバダイビング用運動用具',
      name: {
        ja: 'サーフィン用・水上スキー用・スキューバダイビング用運動用具',
        en: 'sports equipment for surfing、 water-skiing and scuba diving',
      },
      code: 'ubCq',
      order: 10,
    },
    '釣り具': {
      id: '釣り具',
      name: {
        ja: '釣り具',
        en: 'fishing tackle',
      },
      code: 'Mc6u',
      order: 11,
    },
    '柄付き捕虫網，殺虫管，毒つぼ': {
      id: '柄付き捕虫網，殺虫管，毒つぼ',
      name: {
        ja: '柄付き捕虫網，殺虫管，毒つぼ',
        en: 'butterfly nets，tubes for killing specimen insects with poison，pots for killing specimen insects with poison',
      },
      code: 'wzfO',
      order: 12,
    },
  },
  29: {
    '菓子（肉・魚・果物・野菜・豆類又はナッツを主原料とするものに限る。）': {
      id: '菓子（肉・魚・果物・野菜・豆類又はナッツを主原料とするものに限る。）',
      name: {
        ja: '菓子（肉・魚・果物・野菜・豆類又はナッツを主原料とするものに限る。）',
        en: 'meat-based、 fish-based、 fruit-based、 vegetable-based、 bean-based or nut-based snacks',
      },
      code: '9d4c',
      order: 1,
    },
    '食用油脂': {
      id: '食用油脂',
      name: {
        ja: '食用油脂',
        en: 'edible oils and fats',
      },
      code: 'UEY-',
      order: 2,
    },
    '乳製品': {
      id: '乳製品',
      name: {
        ja: '乳製品',
        en: 'milk products',
      },
      code: 'EIPG',
      order: 3,
    },
    '食肉': {
      id: '食肉',
      name: {
        ja: '食肉',
        en: 'meat for human consumption [fresh、 chilled or frozen]',
      },
      code: 'vUbk',
      order: 4,
    },
    '卵': {
      id: '卵',
      name: {
        ja: '卵',
        en: 'eggs*',
      },
      code: 'bnUP',
      order: 5,
    },
    '食用魚介類（生きているものを除く。）': {
      id: '食用魚介類（生きているものを除く。）',
      name: {
        ja: '食用魚介類（生きているものを除く。）',
        en: 'fresh、 chilled or frozen edible aquatic animals (not live)',
      },
      code: '1Vkw',
      order: 6,
    },
    '冷凍野菜': {
      id: '冷凍野菜',
      name: {
        ja: '冷凍野菜',
        en: 'frozen vegetables',
      },
      code: 'Gnpi',
      order: 7,
    },
    '冷凍果実': {
      id: '冷凍果実',
      name: {
        ja: '冷凍果実',
        en: 'frozen fruits',
      },
      code: 'ohIK',
      order: 8,
    },
    '肉製品，加工水産物': {
      id: '肉製品，加工水産物',
      name: {
        ja: '肉製品，加工水産物',
        en: 'processed meat products',
      },
      code: 'gtfC',
      order: 9,
    },
    '肉製品，加工水産物（「かつお節・寒天・削り節・食用魚粉・とろろ昆布・干しのり・干しひじき・干しわかめ・焼きのり」を除く。）': {
      id: '肉製品，加工水産物（「かつお節・寒天・削り節・食用魚粉・とろろ昆布・干しのり・干しひじき・干しわかめ・焼きのり」を除く。）',
      name: {
        ja: '肉製品，加工水産物（「かつお節・寒天・削り節・食用魚粉・とろろ昆布・干しのり・干しひじき・干しわかめ・焼きのり」を除く。）',
        en: 'processed meat products，processed seafood products [other than "blocks of boiled、 smoked and then dried bonitos [Katsuo-bushi]、 dried pieces of agar jelly [Kanten]、 flakes of dried fish meat [Kezuri-bushi]、 fishmeal for human consumption、 edible shavings of dried kelp [Tororo-kombu]、 sheets of dried laver [Hoshi-nori]、 dried brown alga [Hoshi-hijiki]、 dried edible seaweed [Hoshi-wakame] and toasted sheets of laver [Yaki-nori]"]',
      },
      code: 'Gxcm',
      order: 10,
    },
    'かつお節，寒天，削り節，食用魚粉，とろろ昆布，干しのり，干しひじき，干しわかめ，焼きのり': {
      id: 'かつお節，寒天，削り節，食用魚粉，とろろ昆布，干しのり，干しひじき，干しわかめ，焼きのり',
      name: {
        ja: 'かつお節，寒天，削り節，食用魚粉，とろろ昆布，干しのり，干しひじき，干しわかめ，焼きのり',
        en: 'blocks of boiled、 smoked and then dried bonitos [Katsuo-bushi]，dried pieces of agar jelly [Kanten]，flakes of dried fish meat [Kezuri-bushi]，fish meal for human consumption，edible shavings of dried kelp [Tororo-kombu]，sheets of dried laver [Hoshi-nori]，dried brown alga [Hoshi-hijiki]，dried edible seaweed [Hoshi-wakame]，toasted sheets of laver [Yaki-nori]',
      },
      code: '4ZHn',
      order: 11,
    },
    '加工野菜及び加工果実': {
      id: '加工野菜及び加工果実',
      name: {
        ja: '加工野菜及び加工果実',
        en: 'processed vegetables and fruits',
      },
      code: 'Ape7',
      order: 12,
    },
    '油揚げ，凍り豆腐，こんにゃく，豆乳，豆腐，納豆': {
      id: '油揚げ，凍り豆腐，こんにゃく，豆乳，豆腐，納豆',
      name: {
        ja: '油揚げ，凍り豆腐，こんにゃく，豆乳，豆腐，納豆',
        en: 'fried tofu pieces [Abura-age]，freeze-dried tofu pieces [Kohri-dofu]，jelly made from devils\' tongue root [Konnyaku]，soya milk，tofu，fermented soybeans [Natto]',
      },
      code: '0Wxp',
      order: 13,
    },
    '加工卵': {
      id: '加工卵',
      name: {
        ja: '加工卵',
        en: 'processed eggs',
      },
      code: '2Dj2',
      order: 14,
    },
    'カレー・シチュー又はスープのもと': {
      id: 'カレー・シチュー又はスープのもと',
      name: {
        ja: 'カレー・シチュー又はスープのもと',
        en: 'pre-cooked curry stew、 stew and soup mixes',
      },
      code: 'K6qt',
      order: 15,
    },
    'お茶漬けのり，ふりかけ': {
      id: 'お茶漬けのり，ふりかけ',
      name: {
        ja: 'お茶漬けのり，ふりかけ',
        en: 'dried flakes of laver for sprinkling on rice in hot water [Ochazuke-nori]，Furi-kake [dried flakes of fish、 meat、 vegetables or seaweed]',
      },
      code: 'N5t8',
      order: 16,
    },
    'なめ物': {
      id: 'なめ物',
      name: {
        ja: 'なめ物',
        en: 'side-dish made of fermented soybean [Name-mono]',
      },
      code: 'Gc8y',
      order: 17,
    },
    '豆': {
      id: '豆',
      name: {
        ja: '豆',
        en: 'preserved pulses',
      },
      code: 'SYI7',
      order: 18,
    },
  },
  30: {
    'アイスクリーム用凝固剤，ホイップクリーム用安定剤，料理用食肉軟化剤': {
      id: 'アイスクリーム用凝固剤，ホイップクリーム用安定剤，料理用食肉軟化剤',
      name: {
        ja: 'アイスクリーム用凝固剤，ホイップクリーム用安定剤，料理用食肉軟化剤',
        en: 'binding agents for ice cream，preparations for stiffening whipped cream，meat tenderizers for culinary purposes',
      },
      code: 'O821',
      order: 1,
    },
    '食品香料（精油のものを除く。）': {
      id: '食品香料（精油のものを除く。）',
      name: {
        ja: '食品香料（精油のものを除く。）',
        en: 'aromatic preparations for food、 not from essential oils',
      },
      code: '5sSI',
      order: 2,
    },
    '茶': {
      id: '茶',
      name: {
        ja: '茶',
        en: 'tea*',
      },
      code: 'oAHt',
      order: 3,
    },
    'コーヒー，ココア': {
      id: 'コーヒー，ココア',
      name: {
        ja: 'コーヒー，ココア',
        en: 'prepared coffee and coffee-based beverages，prepared cocoa and cocoa-based beverages',
      },
      code: 'e1Ms',
      order: 4,
    },
    '氷': {
      id: '氷',
      name: {
        ja: '氷',
        en: 'ice',
      },
      code: 'aEBv',
      order: 5,
    },
    '菓子（肉・魚・果物・野菜・豆類又はナッツを主原料とするものを除く。），パン，サンドイッチ，中華まんじゅう，ハンバーガー，ピザ，ホットドッグ，ミートパイ': {
      id: '菓子（肉・魚・果物・野菜・豆類又はナッツを主原料とするものを除く。），パン，サンドイッチ，中華まんじゅう，ハンバーガー，ピザ，ホットドッグ，ミートパイ',
      name: {
        ja: '菓子（肉・魚・果物・野菜・豆類又はナッツを主原料とするものを除く。），パン，サンドイッチ，中華まんじゅう，ハンバーガー，ピザ，ホットドッグ，ミートパイ',
        en: 'sweets、 confectionery and snacks other than meat-based、 fish-based、 fruit-based、 vegetable-based、 bean-based or nut-based，bread and buns，sandwiches，steamed buns stuffed with minced meat [Chuka-manjuh]，hamburgers [sandwiches]，pizzas，hot dog sandwiches，meat pies',
      },
      code: 'ZS7v',
      order: 6,
    },
    '調味料': {
      id: '調味料',
      name: {
        ja: '調味料',
        en: 'seasonings [other than spices]',
      },
      code: '0Trt',
      order: 7,
    },
    'みそ': {
      id: 'みそ',
      name: {
        ja: 'みそ',
        en: 'miso',
      },
      code: 'Cm1q',
      order: 8,
    },
    'ウースターソース，グレービーソース，ケチャップソース，しょうゆ，食酢，酢の素，そばつゆ，ドレッシング，ホワイトソース，マヨネーズソース，焼肉のたれ': {
      id: 'ウースターソース，グレービーソース，ケチャップソース，しょうゆ，食酢，酢の素，そばつゆ，ドレッシング，ホワイトソース，マヨネーズソース，焼肉のたれ',
      name: {
        ja: 'ウースターソース，グレービーソース，ケチャップソース，しょうゆ，食酢，酢の素，そばつゆ，ドレッシング，ホワイトソース，マヨネーズソース，焼肉のたれ',
        en: 'worcester sauce，meat gravies，ketchup，soy sauce [soya sauce]，vinegar，vinegar mixes，seasoning soy sauce [Soba-tsuyu]，salad dressings，white sauce，mayonnaise，sauces for barbecued meat',
      },
      code: 'TbGd',
      order: 9,
    },
    '角砂糖，果糖，氷砂糖（調味料），砂糖，麦芽糖，はちみつ，ぶどう糖，粉末あめ，水あめ（調味料），料理用人工甘味料': {
      id: '角砂糖，果糖，氷砂糖（調味料），砂糖，麦芽糖，はちみつ，ぶどう糖，粉末あめ，水あめ（調味料），料理用人工甘味料',
      name: {
        ja: '角砂糖，果糖，氷砂糖（調味料），砂糖，麦芽糖，はちみつ，ぶどう糖，粉末あめ，水あめ（調味料），料理用人工甘味料',
        en: 'cube sugar，fructose for culinary purposes，crystal sugar [not confectionery]，sugar*，maltose for culinary purposes，honey，glucose for culinary purposes，powdered starch syrup for culinary purposes，starch syrup for culinary purposes，artificial sweeteners for culinary purposes',
      },
      code: 'nWXo',
      order: 10,
    },
    'ごま塩，食塩，すりごま，セロリーソルト': {
      id: 'ごま塩，食塩，すりごま，セロリーソルト',
      name: {
        ja: 'ごま塩，食塩，すりごま，セロリーソルト',
        en: 'table salt mixed with sesame seeds，cooking salt，roasted and ground sesame seeds，celery salt',
      },
      code: 'zAqn',
      order: 11,
    },
    'うま味調味料': {
      id: 'うま味調味料',
      name: {
        ja: 'うま味調味料',
        en: 'Umami seasonings',
      },
      code: '5bTV',
      order: 12,
    },
    '香辛料': {
      id: '香辛料',
      name: {
        ja: '香辛料',
        en: 'spices',
      },
      code: 'A7Lq',
      order: 13,
    },
    'アイスクリームのもと，シャーベットのもと': {
      id: 'アイスクリームのもと，シャーベットのもと',
      name: {
        ja: 'アイスクリームのもと，シャーベットのもと',
        en: 'ice cream mixes，sherbet mixes',
      },
      code: 'nvgd',
      order: 14,
    },
    'コーヒー豆（生のもの）': {
      id: 'コーヒー豆（生のもの）',
      name: {
        ja: 'コーヒー豆（生のもの）',
        en: 'unroasted coffee beans',
      },
      code: 'rR-A',
      order: 15,
    },
    '穀物の加工品': {
      id: '穀物の加工品',
      name: {
        ja: '穀物の加工品',
        en: 'cereal preparations',
      },
      code: 'DM5B',
      order: 16,
    },
    'チョコレートスプレッド': {
      id: 'チョコレートスプレッド',
      name: {
        ja: 'チョコレートスプレッド',
        en: 'chocolate spread',
      },
      code: 'kj16',
      order: 17,
    },
    'ぎょうざ，しゅうまい，すし，たこ焼き，弁当，ラビオリ': {
      id: 'ぎょうざ，しゅうまい，すし，たこ焼き，弁当，ラビオリ',
      name: {
        ja: 'ぎょうざ，しゅうまい，すし，たこ焼き，弁当，ラビオリ',
        en: 'Chinese stuffed dumplings [Gyoza、 cooked]，Chinese steamed dumplings [Shumai、 cooked]，sushi，fried balls of batter mix with small pieces of octopus [Takoyaki]，boxed lunches consisting of rice、 with added meat、 fish or vegetables，ravioli',
      },
      code: '6_Yr',
      order: 18,
    },
    'イーストパウダー，こうじ，酵母，ベーキングパウダー': {
      id: 'イーストパウダー，こうじ，酵母，ベーキングパウダー',
      name: {
        ja: 'イーストパウダー，こうじ，酵母，ベーキングパウダー',
        en: 'yeast powder，Koji [fermented malted rice]，yeast*，baking powder',
      },
      code: 'qpap',
      order: 19,
    },
    '即席菓子のもと': {
      id: '即席菓子のもと',
      name: {
        ja: '即席菓子のもと',
        en: 'instant confectionery mixes',
      },
      code: 'WxEp',
      order: 20,
    },
    'パスタソース': {
      id: 'パスタソース',
      name: {
        ja: 'パスタソース',
        en: 'pasta sauce',
      },
      code: 'xtfc',
      order: 21,
    },
    '食用酒かす': {
      id: '食用酒かす',
      name: {
        ja: '食用酒かす',
        en: 'by-product of rice for food [Sake lees]',
      },
      code: 'JSP1',
      order: 22,
    },
    '米，脱穀済みのえん麦，脱穀済みの大麦': {
      id: '米，脱穀済みのえん麦，脱穀済みの大麦',
      name: {
        ja: '米，脱穀済みのえん麦，脱穀済みの大麦',
        en: 'husked rice，husked oats，husked barley',
      },
      code: 'uvNY',
      order: 23,
    },
    '食用グルテン': {
      id: '食用グルテン',
      name: {
        ja: '食用グルテン',
        en: 'gluten for food',
      },
      code: 'YRv2',
      order: 24,
    },
    '食用粉類': {
      id: '食用粉類',
      name: {
        ja: '食用粉類',
        en: 'flour',
      },
      code: 'uZl9',
      order: 25,
    },
  },
  31: {
    '動物用寝わら': {
      id: '動物用寝わら',
      name: {
        ja: '動物用寝わら',
        en: 'litter for animals',
      },
      code: 'QDUV',
      order: 1,
    },
    '生花の花輪': {
      id: '生花の花輪',
      name: {
        ja: '生花の花輪',
        en: 'wreaths of natural flowers for ceremonies or funerals',
      },
      code: 'pBxc',
      order: 2,
    },
    '釣り用餌': {
      id: '釣り用餌',
      name: {
        ja: '釣り用餌',
        en: 'fishing baits',
      },
      code: 'HnwO',
      order: 3,
    },
    'ホップ': {
      id: 'ホップ',
      name: {
        ja: 'ホップ',
        en: 'hops',
      },
      code: '_1Db',
      order: 4,
    },
    '食用魚介類（生きているものに限る。）': {
      id: '食用魚介類（生きているものに限る。）',
      name: {
        ja: '食用魚介類（生きているものに限る。）',
        en: 'edible aquatic animals、 live',
      },
      code: '_lft',
      order: 5,
    },
    '海藻類': {
      id: '海藻類',
      name: {
        ja: '海藻類',
        en: 'edible seaweeds、 unprocessed',
      },
      code: '-7-E',
      order: 6,
    },
    '野菜': {
      id: '野菜',
      name: {
        ja: '野菜',
        en: 'vegetables、 fresh',
      },
      code: 'LV4M',
      order: 7,
    },
    '野菜（「茶の葉」を除く。）': {
      id: '野菜（「茶の葉」を除く。）',
      name: {
        ja: '野菜（「茶の葉」を除く。）',
        en: 'fresh vegetables、 other than tea leaves',
      },
      code: 'px9U',
      order: 8,
    },
    '茶の葉': {
      id: '茶の葉',
      name: {
        ja: '茶の葉',
        en: 'tea leaves、 unprocessed',
      },
      code: 'IHVJ',
      order: 9,
    },
    '糖料作物': {
      id: '糖料作物',
      name: {
        ja: '糖料作物',
        en: 'sugar crops',
      },
      code: '-8GW',
      order: 10,
    },
    '果実': {
      id: '果実',
      name: {
        ja: '果実',
        en: 'fruit、 fresh',
      },
      code: '__Xk',
      order: 11,
    },
    '麦芽': {
      id: '麦芽',
      name: {
        ja: '麦芽',
        en: 'malt、 not for food',
      },
      code: 'Ob_e',
      order: 12,
    },
    'あわ，きび，ごま，そば（穀物），とうもろこし（穀物），ひえ，麦，籾米，もろこし': {
      id: 'あわ，きび，ごま，そば（穀物），とうもろこし（穀物），ひえ，麦，籾米，もろこし',
      name: {
        ja: 'あわ，きび，ごま，そば（穀物），とうもろこし（穀物），ひえ，麦，籾米，もろこし',
        en: 'foxtail millet、 unprocessed，proso millet、 unprocessed，sesame、 unprocessed，buckwheat、 unprocessed，corn [unprocessed grain]，Japanese barnyard millet、 unprocessed，wheat、 barley and oats、 unprocessed，rice、 unprocessed，sorghum、 unprocessed',
      },
      code: 'ErGY',
      order: 13,
    },
    '飼料': {
      id: '飼料',
      name: {
        ja: '飼料',
        en: 'animal foodstuffs',
      },
      code: '17Aa',
      order: 14,
    },
    '種子類': {
      id: '種子類',
      name: {
        ja: '種子類',
        en: 'seeds and bulbs',
      },
      code: 'KvfR',
      order: 15,
    },
    '木，草，芝，ドライフラワー，苗，苗木，花，牧草，盆栽': {
      id: '木，草，芝，ドライフラワー，苗，苗木，花，牧草，盆栽',
      name: {
        ja: '木，草，芝，ドライフラワー，苗，苗木，花，牧草，盆栽',
        en: 'trees，grasses [plants]，turf、 natural，dried flowers，seedlings，saplings，flowers [natural]，pasture grass，potted dwarfed trees [Bonsai]',
      },
      code: 'LFOr',
      order: 16,
    },
    '獣類・魚類（食用のものを除く。）・鳥類及び昆虫類（生きているものに限る。）': {
      id: '獣類・魚類（食用のものを除く。）・鳥類及び昆虫類（生きているものに限る。）',
      name: {
        ja: '獣類・魚類（食用のものを除く。）・鳥類及び昆虫類（生きているものに限る。）',
        en: 'live mammals、 fish [not for food]、 birds and insects',
      },
      code: 'PZgv',
      order: 17,
    },
    '蚕種，種繭': {
      id: '蚕種，種繭',
      name: {
        ja: '蚕種，種繭',
        en: 'silkworm eggs，cocoons for silkworm breeding',
      },
      code: 'Xo28',
      order: 18,
    },
    '種卵': {
      id: '種卵',
      name: {
        ja: '種卵',
        en: 'eggs for hatching',
      },
      code: 'YzWv',
      order: 19,
    },
    '漆の実': {
      id: '漆の実',
      name: {
        ja: '漆の実',
        en: 'Urushi tree seeds',
      },
      code: 'auxo',
      order: 20,
    },
    '未加工のコルク，やしの葉': {
      id: '未加工のコルク，やしの葉',
      name: {
        ja: '未加工のコルク，やしの葉',
        en: 'rough cork，palm tree leaves、 unworked',
      },
      code: 'zdFf',
      order: 21,
    },
  },
  32: {
    'ビール': {
      id: 'ビール',
      name: {
        ja: 'ビール',
        en: 'beer',
      },
      code: 'mSsO',
      order: 1,
    },
    '清涼飲料，果実飲料，飲料用野菜ジュース': {
      id: '清涼飲料，果実飲料，飲料用野菜ジュース',
      name: {
        ja: '清涼飲料，果実飲料，飲料用野菜ジュース',
        en: 'carbonated drinks [refreshing beverages]，fruit juices，vegetable juices [beverages]',
      },
      code: '7ifj',
      order: 2,
    },
    'ビール製造用ホップエキス': {
      id: 'ビール製造用ホップエキス',
      name: {
        ja: 'ビール製造用ホップエキス',
        en: 'extracts of hops for making beer',
      },
      code: 'E9vK',
      order: 3,
    },
    '乳清飲料': {
      id: '乳清飲料',
      name: {
        ja: '乳清飲料',
        en: 'whey beverages',
      },
      code: 'Neip',
      order: 4,
    },
  },
  33: {
    '清酒，焼酎，合成清酒，白酒，直し，みりん': {
      id: '清酒，焼酎，合成清酒，白酒，直し，みりん',
      name: {
        ja: '清酒，焼酎，合成清酒，白酒，直し，みりん',
        en: 'sake，Japanese white liquor [Shochu]，Sake substitute，Japanese sweet rice-based mixed liquor [Shiro-zake]，Naoshi [Japanese liquor]，Japanese Shochu-based mixed liquor [Mirin]',
      },
      code: 'D9GU',
      order: 1,
    },
    '洋酒，果実酒，酎ハイ，ビール風味の麦芽発泡酒': {
      id: '洋酒，果実酒，酎ハイ，ビール風味の麦芽発泡酒',
      name: {
        ja: '洋酒，果実酒，酎ハイ，ビール風味の麦芽発泡酒',
        en: 'western liquors in general，alcoholic fruit beverages，Japanese Shochu-based beverages [Chuhai]，',
      },
      code: 'R90G',
      order: 2,
    },
    '中国酒': {
      id: '中国酒',
      name: {
        ja: '中国酒',
        en: 'Chinese liquors in general',
      },
      code: 'twUy',
      order: 3,
    },
    '薬味酒': {
      id: '薬味酒',
      name: {
        ja: '薬味酒',
        en: 'flavored liquors',
      },
      code: '-Roq',
      order: 4,
    },
  },
  34: {
    たばこ: {
      id: 'たばこ',
      name: {
        ja: 'たばこ',
        en: 'tobacco',
      },
      code: 'nMQY',
      order: 1,
    },
    電子たばこ: {
      id: '電子たばこ',
      name: {
        ja: '電子たばこ',
        en: 'electronic cigarettes',
      },
      code: 'HaqP',
      order: 2,
    },
    喫煙用具: {
      id: '喫煙用具',
      name: {
        ja: '喫煙用具',
        en: 'smokers\' articles',
      },
      code: 'd9eg',
      order: 3,
    },
    マッチ: {
      id: 'マッチ',
      name: {
        ja: 'マッチ',
        en: 'matches',
      },
      code: 'fQk_',
      order: 4,
    },
  },
  35: {
    '広告業': {
      id: '広告業',
      name: {
        ja: '広告業',
        en: 'advertising and publicity services',
      },
      code: '5wxj',
      order: 1,
    },
    'トレーディングスタンプの発行': {
      id: 'トレーディングスタンプの発行',
      name: {
        ja: 'トレーディングスタンプの発行',
        en: 'promoting the goods and services of others through the administration of sales and promotional incentive schemes involving trading stamps',
      },
      code: 'kPgc',
      order: 2,
    },
    '経営の診断又は経営に関する助言，事業の管理，市場調査又は分析，商品の販売に関する情報の提供': {
      id: '経営の診断又は経営に関する助言，事業の管理，市場調査又は分析，商品の販売に関する情報の提供',
      name: {
        ja: '経営の診断又は経営に関する助言，事業の管理，市場調査又は分析，商品の販売に関する情報の提供',
        en: 'business management analysis or business consultancy，business management，marketing research or analysis，providing information concerning commercial sales',
      },
      code: '4PjH',
      order: 3,
    },
    '財務書類の作成又は監査若しくは証明': {
      id: '財務書類の作成又は監査若しくは証明',
      name: {
        ja: '財務書類の作成又は監査若しくは証明',
        en: 'preparation、 auditing or attesting of financial statements',
      },
      code: 'de8r',
      order: 4,
    },
    '職業のあっせん': {
      id: '職業のあっせん',
      name: {
        ja: '職業のあっせん',
        en: 'employment agency services',
      },
      code: 'amj3',
      order: 5,
    },
    '競売の運営': {
      id: '競売の運営',
      name: {
        ja: '競売の運営',
        en: 'auctioneering',
      },
      code: 'yIAs',
      order: 6,
    },
    '輸出入に関する事務の代理又は代行': {
      id: '輸出入に関する事務の代理又は代行',
      name: {
        ja: '輸出入に関する事務の代理又は代行',
        en: 'import-export agency services',
      },
      code: 'qD_u',
      order: 7,
    },
    '新聞の予約購読の取次ぎ': {
      id: '新聞の予約購読の取次ぎ',
      name: {
        ja: '新聞の予約購読の取次ぎ',
        en: 'arranging newspaper subscriptions for others',
      },
      code: 'FRRB',
      order: 8,
    },
    '速記，筆耕': {
      id: '速記，筆耕',
      name: {
        ja: '速記，筆耕',
        en: 'shorthand，transcription',
      },
      code: 'qg4s',
      order: 9,
    },
    '書類の複製': {
      id: '書類の複製',
      name: {
        ja: '書類の複製',
        en: 'document reproduction',
      },
      code: 'dnr7',
      order: 10,
    },
    '文書又は磁気テープのファイリング': {
      id: '文書又は磁気テープのファイリング',
      name: {
        ja: '文書又は磁気テープのファイリング',
        en: 'office functions、 namely filing、 in particular documents or magnetic tapes',
      },
      code: 'pbst',
      order: 11,
    },
    'コンピュータデータベースへの情報編集': {
      id: 'コンピュータデータベースへの情報編集',
      name: {
        ja: 'コンピュータデータベースへの情報編集',
        en: 'compilation of information into computer databases',
      },
      code: 'd7hn',
      order: 12,
    },
    '電子計算機・タイプライター・テレックス又はこれらに準ずる事務用機器の操作': {
      id: '電子計算機・タイプライター・テレックス又はこれらに準ずる事務用機器の操作',
      name: {
        ja: '電子計算機・タイプライター・テレックス又はこれらに準ずる事務用機器の操作',
        en: 'providing business assistance to others in the operation of data processing apparatus namely、 computers、 typewriters、 telex machines and other similar office machines',
      },
      code: 'WDtU',
      order: 13,
    },
    '建築物における来訪者の受付及び案内': {
      id: '建築物における来訪者の受付及び案内',
      name: {
        ja: '建築物における来訪者の受付及び案内',
        en: 'reception services for visitors in buildings [office functions]',
      },
      code: 'hWv2',
      order: 14,
    },
    '広告用具の貸与': {
      id: '広告用具の貸与',
      name: {
        ja: '広告用具の貸与',
        en: 'publicity material rental',
      },
      code: 'XjdN',
      order: 15,
    },
    '複写機の貸与': {
      id: '複写機の貸与',
      name: {
        ja: '複写機の貸与',
        en: 'rental of photocopying machines',
      },
      code: 'VQ6J',
      order: 16,
    },
    '消費者のための商品及び役務の選択における助言と情報の提供': {
      id: '消費者のための商品及び役務の選択における助言と情報の提供',
      name: {
        ja: '消費者のための商品及び役務の選択における助言と情報の提供',
        en: 'providing commercial information and advice for consumers in the choice of products and services',
      },
      code: 'KuG-',
      order: 17,
    },
    '求人情報の提供': {
      id: '求人情報の提供',
      name: {
        ja: '求人情報の提供',
        en: 'providing employment information',
      },
      code: 'GqEo',
      order: 18,
    },
    '新聞記事情報の提供': {
      id: '新聞記事情報の提供',
      name: {
        ja: '新聞記事情報の提供',
        en: 'providing information about newspaper articles',
      },
      code: 'm4Xl',
      order: 19,
    },
    '自動販売機の貸与': {
      id: '自動販売機の貸与',
      name: {
        ja: '自動販売機の貸与',
        en: 'rental of vending machines',
      },
      code: '60Zb',
      order: 20,
    },
    '織物及び寝具類の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '織物及び寝具類の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '織物及び寝具類の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for woven fabrics and beddings',
      },
      code: 'q46Q',
      order: 22,
    },
    '被服の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '被服の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '被服の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for clothing',
      },
      code: 'TM3G',
      order: 23,
    },
    'おむつの小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: 'おむつの小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: 'おむつの小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for diapers',
      },
      code: 'xC1B',
      order: 24,
    },
    '履物の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '履物の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '履物の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for footwear、 other than special footwear for sports',
      },
      code: 'CCYY',
      order: 25,
    },
    'かばん類及び袋物の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: 'かばん類及び袋物の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: 'かばん類及び袋物の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for bags and pouches',
      },
      code: 'vSwD',
      order: 26,
    },
    '身の回り品の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '身の回り品の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '身の回り品の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for personal articles',
      },
      code: 'tqw5',
      order: 27,
    },
    '飲食料品の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '飲食料品の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '飲食料品の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for foods and beverages',
      },
      code: 'Dl1D',
      order: 28,
    },
    '酒類の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '酒類の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '酒類の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for liquor',
      },
      code: 'qXu-',
      order: 29,
    },
    '食肉の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '食肉の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '食肉の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for meat',
      },
      code: 'N0pI',
      order: 30,
    },
    '食用水産物の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '食用水産物の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '食用水産物の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for sea food',
      },
      code: 'cIMZ',
      order: 31,
    },
    '野菜及び果実の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '野菜及び果実の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '野菜及び果実の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for vegetables and fruits',
      },
      code: 'N6Zh',
      order: 32,
    },
    '菓子及びパンの小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '菓子及びパンの小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '菓子及びパンの小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for confectionery、 bread and buns',
      },
      code: '-AlG',
      order: 33,
    },
    '米穀類の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '米穀類の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '米穀類の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for rice and cereals',
      },
      code: 'CCND',
      order: 34,
    },
    '牛乳の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '牛乳の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '牛乳の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for milk',
      },
      code: 'xL8I',
      order: 35,
    },
    '清涼飲料及び果実飲料の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '清涼飲料及び果実飲料の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '清涼飲料及び果実飲料の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for carbonated drinks [refreshing beverages] and non-alcoholic fruit juice beverages',
      },
      code: 'ZuCP',
      order: 36,
    },
    '茶・コーヒー及びココアの小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '茶・コーヒー及びココアの小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '茶・コーヒー及びココアの小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for tea、 coffee and cocoa',
      },
      code: 'VS6S',
      order: 37,
    },
    '加工食料品の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '加工食料品の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '加工食料品の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for processed food',
      },
      code: 'shRJ',
      order: 38,
    },
    '自動車の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '自動車の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '自動車の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for automobiles',
      },
      code: 'jeO9',
      order: 39,
    },
    '二輪自動車の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '二輪自動車の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '二輪自動車の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for two-wheeled motor vehicles',
      },
      code: 'jLa8',
      order: 40,
    },
    '自転車の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '自転車の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '自転車の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for bicycles',
      },
      code: 'cfVS',
      order: 41,
    },
    '家具の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '家具の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '家具の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for furniture',
      },
      code: 'M1ga',
      order: 42,
    },
    '建具の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '建具の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '建具の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for joinery fittings',
      },
      code: 'i8Uz',
      order: 43,
    },
    '畳類の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '畳類の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '畳類の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for tatami mats',
      },
      code: 'a4je',
      order: 44,
    },
    '葬祭用具の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '葬祭用具の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '葬祭用具の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for ritual equipment',
      },
      code: 'uM__',
      order: 45,
    },
    '電気機械器具類の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '電気機械器具類の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '電気機械器具類の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for electrical machinery and apparatuses',
      },
      code: 'v6bh',
      order: 46,
    },
    '手動利器・手動工具及び金具の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '手動利器・手動工具及び金具の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '手動利器・手動工具及び金具の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for bladed or pointed hand tools、 hand tools and metal hardware',
      },
      code: '6qA8',
      order: 47,
    },
    '台所用品・清掃用具及び洗濯用具の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '台所用品・清掃用具及び洗濯用具の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '台所用品・清掃用具及び洗濯用具の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for kitchen equipment、 cleaning tools and washing utensils',
      },
      code: 'JrAp',
      order: 48,
    },
    '薬剤及び医療補助品の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '薬剤及び医療補助品の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '薬剤及び医療補助品の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for pharmaceutical、 veterinary and sanitary preparations and medical supplies',
      },
      code: '5Uj4',
      order: 49,
    },
    '化粧品・歯磨き及びせっけん類の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '化粧品・歯磨き及びせっけん類の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '化粧品・歯磨き及びせっけん類の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for cosmetics、 toiletries、 dentifrices、 soaps and detergents',
      },
      code: 'KdTI',
      order: 50,
    },
    '農耕用品の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '農耕用品の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '農耕用品の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for agricultural machines、 implements and supplies',
      },
      code: 'Qdxr',
      order: 51,
    },
    '花及び木の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '花及び木の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '花及び木の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for flowers [natural] and trees',
      },
      code: '1y9g',
      order: 52,
    },
    '燃料の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '燃料の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '燃料の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for fuel',
      },
      code: 'ZDOL',
      order: 53,
    },
    '印刷物の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '印刷物の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '印刷物の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for printed matter',
      },
      code: '9Djd',
      order: 54,
    },
    '紙類及び文房具類の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '紙類及び文房具類の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '紙類及び文房具類の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for paper and stationery',
      },
      code: 'Eowc',
      order: 55,
    },
    '運動具の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '運動具の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '運動具の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for sports goods',
      },
      code: '9Nt_',
      order: 56,
    },
    'おもちゃ・人形及び娯楽用具の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: 'おもちゃ・人形及び娯楽用具の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: 'おもちゃ・人形及び娯楽用具の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for toys、 dolls、 game machines and apparatus',
      },
      code: 'Luqt',
      order: 57,
    },
    '楽器及びレコードの小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '楽器及びレコードの小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '楽器及びレコードの小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for musical instruments and records',
      },
      code: 'tta0',
      order: 58,
    },
    '写真機械器具及び写真材料の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '写真機械器具及び写真材料の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '写真機械器具及び写真材料の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for photographic machines and apparatus and photographic supplies',
      },
      code: 'i08D',
      order: 59,
    },
    '時計及び眼鏡の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '時計及び眼鏡の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '時計及び眼鏡の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for clocks、 watches and spectacles [eyeglasses and goggles]',
      },
      code: 'd6X2',
      order: 60,
    },
    'たばこ及び喫煙用具の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: 'たばこ及び喫煙用具の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: 'たばこ及び喫煙用具の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for tobaccos and smokers\' articles',
      },
      code: 'Fv7g',
      order: 61,
    },
    '建築材料の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '建築材料の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '建築材料の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for building materials',
      },
      code: 'vKxI',
      order: 62,
    },
    '宝玉及びその模造品の小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: '宝玉及びその模造品の小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: '宝玉及びその模造品の小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for semi-wrought precious stones and their imitations',
      },
      code: 'MG5G',
      order: 63,
    },
    'ペットの小売又は卸売の業務において行われる顧客に対する便益の提供': {
      id: 'ペットの小売又は卸売の業務において行われる顧客に対する便益の提供',
      name: {
        ja: 'ペットの小売又は卸売の業務において行われる顧客に対する便益の提供',
        en: 'retail services or wholesale services for pets',
      },
      code: '7UqZ',
      order: 64,
    },
  },
  36: {
    '預金の受入れ（債券の発行により代える場合を含む。）及び定期積金の受入れ，資金の貸付け及び手形の割引，内国為替取引，債務の保証及び手形の引受け，有価証券の貸付け，金銭債権の取得及び譲渡，有価証券・貴金属その他の物品の保護預かり，両替，金融先物取引の受託，金銭・有価証券・金銭債権・動産・土地若しくはその定着物又は地上権若しくは土地の賃借権の信託の引受け，債券の募集の受託，外国為替取引，信用状に関する業務，信用購入あっせん，暗号資産の管理，暗号資産の売買又は他の暗号資産との交換，暗号資産の売買又は他の暗号資産との交換の媒介・取次ぎ又は代理': {
      id: '預金の受入れ（債券の発行により代える場合を含む。）及び定期積金の受入れ，資金の貸付け及び手形の割引，内国為替取引，債務の保証及び手形の引受け，有価証券の貸付け，金銭債権の取得及び譲渡，有価証券・貴金属その他の物品の保護預かり，両替，金融先物取引の受託，金銭・有価証券・金銭債権・動産・土地若しくはその定着物又は地上権若しくは土地の賃借権の信託の引受け，債券の募集の受託，外国為替取引，信用状に関する業務，信用購入あっせん，暗号資産の管理，暗号資産の売買又は他の暗号資産との交換，暗号資産の売買又は他の暗号資産との交換の媒介・取次ぎ又は代理',
      name: {
        ja: '預金の受入れ（債券の発行により代える場合を含む。）及び定期積金の受入れ，資金の貸付け及び手形の割引，内国為替取引，債務の保証及び手形の引受け，有価証券の貸付け，金銭債権の取得及び譲渡，有価証券・貴金属その他の物品の保護預かり，両替，金融先物取引の受託，金銭・有価証券・金銭債権・動産・土地若しくはその定着物又は地上権若しくは土地の賃借権の信託の引受け，債券の募集の受託，外国為替取引，信用状に関する業務，信用購入あっせん，暗号資産の管理，暗号資産の売買又は他の暗号資産との交換，暗号資産の売買又は他の暗号資産との交換の媒介・取次ぎ又は代理',
        en: 'acceptance of deposits [including substitute bond issuance] and acceptance of fixed interval installment deposits，loans [financing] and discount of bills，domestic exchange settlement，liability guarantee and acceptance of bills，securities lending，acquisition and transfer of monetary claims，safekeeping of valuables including securities and precious metals [safe deposit services]，exchanging money，trusteeship of financial futures contracts，trusteeship of money、 securities、 monetary claims、 personal property、 land、 rights on land fixtures、 surface rights or lease on land，agencies for bond subscriptions，foreign exchange transactions，letter-of-Credit related services，brokerage of credit purchase，management of crypto assets，purchase and sale of a crypto asset or exchange with another crypto asset，intermediary、 brokerage or agency services for purchase and sale of a crypto asset or exchange with another crypto asset',
      },
      code: 'nHwE',
      order: 1,
    },
    '前払式支払手段の発行': {
      id: '前払式支払手段の発行',
      name: {
        ja: '前払式支払手段の発行',
        en: 'issuance of prepaid vouchers',
      },
      code: 'h1MY',
      order: 2,
    },
    'ガス料金又は電気料金の徴収の代行，商品代金の徴収の代行': {
      id: 'ガス料金又は電気料金の徴収の代行，商品代金の徴収の代行',
      name: {
        ja: 'ガス料金又は電気料金の徴収の代行，商品代金の徴収の代行',
        en: 'agencies for collecting gas or electric power utility payments，consigned collection of payment for goods',
      },
      code: 'QNSI',
      order: 3,
    },
    '有価証券の売買，有価証券指数等先物取引，有価証券オプション取引，外国市場証券先物取引，有価証券の売買・有価証券指数等先物取引・有価証券オプション取引及び外国市場証券先物取引の媒介・取次ぎ又は代理，有価証券市場における有価証券の売買取引・有価証券指数等先物取引及び有価証券オプション取引の委託の媒介・取次ぎ又は代理，外国有価証券市場における有価証券の売買取引及び外国市場証券先物取引の委託の媒介・取次ぎ又は代理，有価証券先渡取引・有価証券店頭指数等先渡取引・有価証券店頭オプション取引若しくは有価証券店頭指数等スワップ取引又はこれらの取引の媒介・取次ぎ若しくは代理，有価証券等清算取次ぎ，有価証券の引受け，有価証券の売出し，有価証券の募集又は売出しの取扱い，株式市況に関する情報の提供': {
      id: '有価証券の売買，有価証券指数等先物取引，有価証券オプション取引，外国市場証券先物取引，有価証券の売買・有価証券指数等先物取引・有価証券オプション取引及び外国市場証券先物取引の媒介・取次ぎ又は代理，有価証券市場における有価証券の売買取引・有価証券指数等先物取引及び有価証券オプション取引の委託の媒介・取次ぎ又は代理，外国有価証券市場における有価証券の売買取引及び外国市場証券先物取引の委託の媒介・取次ぎ又は代理，有価証券先渡取引・有価証券店頭指数等先渡取引・有価証券店頭オプション取引若しくは有価証券店頭指数等スワップ取引又はこれらの取引の媒介・取次ぎ若しくは代理，有価証券等清算取次ぎ，有価証券の引受け，有価証券の売出し，有価証券の募集又は売出しの取扱い，株式市況に関する情報の提供',
      name: {
        ja: '有価証券の売買，有価証券指数等先物取引，有価証券オプション取引，外国市場証券先物取引，有価証券の売買・有価証券指数等先物取引・有価証券オプション取引及び外国市場証券先物取引の媒介・取次ぎ又は代理，有価証券市場における有価証券の売買取引・有価証券指数等先物取引及び有価証券オプション取引の委託の媒介・取次ぎ又は代理，外国有価証券市場における有価証券の売買取引及び外国市場証券先物取引の委託の媒介・取次ぎ又は代理，有価証券先渡取引・有価証券店頭指数等先渡取引・有価証券店頭オプション取引若しくは有価証券店頭指数等スワップ取引又はこれらの取引の媒介・取次ぎ若しくは代理，有価証券等清算取次ぎ，有価証券の引受け，有価証券の売出し，有価証券の募集又は売出しの取扱い，株式市況に関する情報の提供',
        en: 'buying and selling of securities，trading of securities index futures，trading of securities options，trading of overseas market securities futures，agencies for brokerage of securities、 securities index futures、 securities options、 and overseas market securities futures，agencies for brokerage of entrusting agents with on-commission trading in domestic markets of securities、 securities index futures and securities options，agencies for brokerage of entrusting agents with on-commission trading in overseas markets of securities and securities index futures，agencies for brokerage of forward agreement of securities、 for forward agreement of securities index futures、 for forward agreement of securities options、 spot and forward transaction of securities index futures，brokerage for securities liquidation，securities underwriting，securities offering，brokerage services relating to securities subscription or offering，providing stock market information',
      },
      code: 'fO18',
      order: 4,
    },
    '商品市場における先物取引の受託': {
      id: '商品市場における先物取引の受託',
      name: {
        ja: '商品市場における先物取引の受託',
        en: 'agencies for commodity futures trading',
      },
      code: 'PUHD',
      order: 5,
    },
    '生命保険契約の締結の媒介，生命保険の引受け，損害保険契約の締結の代理，損害保険に係る損害の査定，損害保険の引受け，保険料率の算出': {
      id: '生命保険契約の締結の媒介，生命保険の引受け，損害保険契約の締結の代理，損害保険に係る損害の査定，損害保険の引受け，保険料率の算出',
      name: {
        ja: '生命保険契約の締結の媒介，生命保険の引受け，損害保険契約の締結の代理，損害保険に係る損害の査定，損害保険の引受け，保険料率の算出',
        en: 'life insurance brokerage，life insurance underwriting，agencies for non-life insurance，claim adjustment for non-life insurance，non-life insurance underwriting，insurance premium rate computing',
      },
      code: 'ccfU',
      order: 6,
    },
    '建物の管理，建物の貸借の代理又は媒介，建物の貸与，建物の売買，建物の売買の代理又は媒介，建物又は土地の鑑定評価，建物又は土地の情報の提供，土地の管理，土地の貸借の代理又は媒介，土地の貸与，土地の売買，土地の売買の代理又は媒介': {
      id: '建物の管理，建物の貸借の代理又は媒介，建物の貸与，建物の売買，建物の売買の代理又は媒介，建物又は土地の鑑定評価，建物又は土地の情報の提供，土地の管理，土地の貸借の代理又は媒介，土地の貸与，土地の売買，土地の売買の代理又は媒介',
      name: {
        ja: '建物の管理，建物の貸借の代理又は媒介，建物の貸与，建物の売買，建物の売買の代理又は媒介，建物又は土地の鑑定評価，建物又は土地の情報の提供，土地の管理，土地の貸借の代理又は媒介，土地の貸与，土地の売買，土地の売買の代理又は媒介',
        en: 'management of buildings，agency services for the leasing or rental of buildings，leasing or renting of buildings，purchase and sale of buildings，real estate agency services for the purchase or sales of buildings，real estate appraisal，providing information on buildings or land [real estate affairs]，land management，agency services for the leasing or rental of land，leasing of land，purchase and sale of land，real estate agency services for the purchase or sales of land',
      },
      code: '5KFY',
      order: 7,
    },
    '骨董品の評価，美術品の評価，宝玉の評価': {
      id: '骨董品の評価，美術品の評価，宝玉の評価',
      name: {
        ja: '骨董品の評価，美術品の評価，宝玉の評価',
        en: 'antique appraisal，art appraisal，precious stone appraisal',
      },
      code: 'lUjw',
      order: 8,
    },
    '中古自動車の評価': {
      id: '中古自動車の評価',
      name: {
        ja: '中古自動車の評価',
        en: 'used automobiles appraisal',
      },
      code: '3dnv',
      order: 9,
    },
    '企業の信用に関する調査': {
      id: '企業の信用に関する調査',
      name: {
        ja: '企業の信用に関する調査',
        en: 'financial assessment of company credit',
      },
      code: 'rYUi',
      order: 10,
    },
    '税務相談，税務代理': {
      id: '税務相談，税務代理',
      name: {
        ja: '税務相談，税務代理',
        en: 'advisory services relating to tax planning，tax payment processing services',
      },
      code: 'f1LR',
      order: 11,
    },
    '慈善のための募金': {
      id: '慈善のための募金',
      name: {
        ja: '慈善のための募金',
        en: 'charitable fund raising',
      },
      code: 'uzML',
      order: 12,
    },
    '紙幣・硬貨計算機の貸与，現金支払機の貸与，現金自動預け払い機の貸与': {
      id: '紙幣・硬貨計算機の貸与，現金支払機の貸与，現金自動預け払い機の貸与',
      name: {
        ja: '紙幣・硬貨計算機の貸与，現金支払機の貸与，現金自動預け払い機の貸与',
        en: 'rental of paper money and coin counting or processing machines，rental of cash dispensers，rental of automated-teller machines',
      },
      code: 'm3ao',
      order: 13,
    },
  },
  37: {
    '建設工事': {
      id: '建設工事',
      name: {
        ja: '建設工事',
        en: 'construction*',
      },
      code: 'OVf-',
      order: 1,
    },
    '建設工事に関する助言': {
      id: '建設工事に関する助言',
      name: {
        ja: '建設工事に関する助言',
        en: 'construction consultancy',
      },
      code: 'boo4',
      order: 2,
    },
    '建築設備の運転・点検・整備': {
      id: '建築設備の運転・点検・整備',
      name: {
        ja: '建築設備の運転・点検・整備',
        en: 'operation and maintenance of building equipment',
      },
      code: 'xyeU',
      order: 3,
    },
    '船舶の建造，船舶の修理又は整備': {
      id: '船舶の建造，船舶の修理又は整備',
      name: {
        ja: '船舶の建造，船舶の修理又は整備',
        en: 'shipbuilding，repair or maintenance of vessels',
      },
      code: 'jWVQ',
      order: 4,
    },
    '航空機の修理又は整備': {
      id: '航空機の修理又は整備',
      name: {
        ja: '航空機の修理又は整備',
        en: 'aircraft repair or maintenance',
      },
      code: '8-s9',
      order: 5,
    },
    '自転車の修理': {
      id: '自転車の修理',
      name: {
        ja: '自転車の修理',
        en: 'repair of bicycles',
      },
      code: '2Bxg',
      order: 6,
    },
    '自動車の修理又は整備': {
      id: '自動車の修理又は整備',
      name: {
        ja: '自動車の修理又は整備',
        en: 'repair or maintenance of automobiles',
      },
      code: 'UrwR',
      order: 7,
    },
    '鉄道車両の修理又は整備': {
      id: '鉄道車両の修理又は整備',
      name: {
        ja: '鉄道車両の修理又は整備',
        en: 'repair or maintenance of railway rolling stocks',
      },
      code: 'wfvS',
      order: 8,
    },
    '二輪自動車の修理又は整備': {
      id: '二輪自動車の修理又は整備',
      name: {
        ja: '二輪自動車の修理又は整備',
        en: 'repair or maintenance of two-wheeled motor vehicles',
      },
      code: 'rVmf',
      order: 9,
    },
    '映画機械器具の修理又は保守，光学機械器具の修理又は保守，写真機械器具の修理又は保守': {
      id: '映画機械器具の修理又は保守，光学機械器具の修理又は保守，写真機械器具の修理又は保守',
      name: {
        ja: '映画機械器具の修理又は保守，光学機械器具の修理又は保守，写真機械器具の修理又は保守',
        en: 'repair or maintenance of cinematographic machines and apparatus，repair or maintenance of optical machines and apparatus，repair or maintenance of photographic machines and apparatus',
      },
      code: 'OkNi',
      order: 10,
    },
    '荷役機械器具の修理又は保守': {
      id: '荷役機械器具の修理又は保守',
      name: {
        ja: '荷役機械器具の修理又は保守',
        en: 'repair or maintenance of loading-unloading machines and apparatus',
      },
      code: 'd3kW',
      order: 11,
    },
    '火災報知機の修理又は保守': {
      id: '火災報知機の修理又は保守',
      name: {
        ja: '火災報知機の修理又は保守',
        en: 'repair or maintenance of fire alarms',
      },
      code: 'qwmh',
      order: 12,
    },
    '事務用機械器具の修理又は保守': {
      id: '事務用機械器具の修理又は保守',
      name: {
        ja: '事務用機械器具の修理又は保守',
        en: 'repair or maintenance of office machines and equipment',
      },
      code: 'BAJp',
      order: 13,
    },
    '業務用暖冷房装置の修理又は保守，バーナーの修理又は保守，ボイラーの修理又は保守，ポンプの修理又は保守，業務用冷凍機械器具の修理又は保守': {
      id: '業務用暖冷房装置の修理又は保守，バーナーの修理又は保守，ボイラーの修理又は保守，ポンプの修理又は保守，業務用冷凍機械器具の修理又は保守',
      name: {
        ja: '業務用暖冷房装置の修理又は保守，バーナーの修理又は保守，ボイラーの修理又は保守，ポンプの修理又は保守，業務用冷凍機械器具の修理又は保守',
        en: 'repair or maintenance of air conditioners for industrial purposes，repair or maintenance of burners，repair or maintenance of boilers，repair or maintenance of pumps，repair or maintenance of freezing machines and apparatus for industrial purposes',
      },
      code: '_Y5D',
      order: 14,
    },
    '電子応用機械器具の修理又は保守': {
      id: '電子応用機械器具の修理又は保守',
      name: {
        ja: '電子応用機械器具の修理又は保守',
        en: 'repair or maintenance of electronic machines and apparatus',
      },
      code: 'gP81',
      order: 15,
    },
    '電気通信機械器具の修理又は保守': {
      id: '電気通信機械器具の修理又は保守',
      name: {
        ja: '電気通信機械器具の修理又は保守',
        en: 'repair or maintenance of telecommunication machines and apparatus',
      },
      code: '3oW_',
      order: 16,
    },
    '電話機械器具の修理又は保守': {
      id: '電話機械器具の修理又は保守',
      name: {
        ja: '電話機械器具の修理又は保守',
        en: 'repair or maintenance of telephone apparatus',
      },
      code: 'QvvS',
      order: 17,
    },
    'ラジオ受信機又はテレビジョン受信機の修理': {
      id: 'ラジオ受信機又はテレビジョン受信機の修理',
      name: {
        ja: 'ラジオ受信機又はテレビジョン受信機の修理',
        en: 'repair of radio receivers or television receivers',
      },
      code: 'Xk6g',
      order: 18,
    },
    '電気通信機械器具（「電話機械器具・ラジオ受信機及びテレビジョン受信機」を除く。）の修理又は保守': {
      id: '電気通信機械器具（「電話機械器具・ラジオ受信機及びテレビジョン受信機」を除く。）の修理又は保守',
      name: {
        ja: '電気通信機械器具（「電話機械器具・ラジオ受信機及びテレビジョン受信機」を除く。）の修理又は保守',
        en: 'repair or maintenance of telecommunication machines and apparatus [other than telephone apparatus、 radio receivers and television receivers]',
      },
      code: 'eA74',
      order: 19,
    },
    '土木機械器具の修理又は保守': {
      id: '土木機械器具の修理又は保守',
      name: {
        ja: '土木機械器具の修理又は保守',
        en: 'repair or maintenance of construction machines and apparatus',
      },
      code: 'fYnr',
      order: 20,
    },
    '民生用電気機械器具の修理又は保守': {
      id: '民生用電気機械器具の修理又は保守',
      name: {
        ja: '民生用電気機械器具の修理又は保守',
        en: 'repair or maintenance of consumer electric appliances',
      },
      code: 'x8t4',
      order: 21,
    },
    '照明用器具の修理又は保守': {
      id: '照明用器具の修理又は保守',
      name: {
        ja: '照明用器具の修理又は保守',
        en: 'repair or maintenance of electric lighting apparatus',
      },
      code: 'uPsy',
      order: 22,
    },
    '電動機の修理又は保守，配電用又は制御用の機械器具の修理又は保守，発電機の修理又は保守': {
      id: '電動機の修理又は保守，配電用又は制御用の機械器具の修理又は保守，発電機の修理又は保守',
      name: {
        ja: '電動機の修理又は保守，配電用又は制御用の機械器具の修理又は保守，発電機の修理又は保守',
        en: 'repair or maintenance of electric motors，repair or maintenance of power distribution or control machines and apparatus，repair or maintenance of power generators',
      },
      code: 'AiEP',
      order: 23,
    },
    '理化学機械器具の修理又は保守': {
      id: '理化学機械器具の修理又は保守',
      name: {
        ja: '理化学機械器具の修理又は保守',
        en: 'repair or maintenance of laboratory apparatus and instruments',
      },
      code: 'Jhcs',
      order: 24,
    },
    '測定機械器具の修理又は保守': {
      id: '測定機械器具の修理又は保守',
      name: {
        ja: '測定機械器具の修理又は保守',
        en: 'repair or maintenance of measuring and testing machines and instruments',
      },
      code: 'abfJ',
      order: 25,
    },
    '医療用機械器具の修理又は保守': {
      id: '医療用機械器具の修理又は保守',
      name: {
        ja: '医療用機械器具の修理又は保守',
        en: 'repair or maintenance of medical apparatus and instruments',
      },
      code: '5SPM',
      order: 26,
    },
    '銃砲の修理又は保守': {
      id: '銃砲の修理又は保守',
      name: {
        ja: '銃砲の修理又は保守',
        en: 'repair or maintenance of firearms',
      },
      code: '6i7O',
      order: 27,
    },
    '印刷用又は製本用の機械器具の修理又は保守': {
      id: '印刷用又は製本用の機械器具の修理又は保守',
      name: {
        ja: '印刷用又は製本用の機械器具の修理又は保守',
        en: 'repair or maintenance of printing or bookbinding machines and apparatus',
      },
      code: 'hum1',
      order: 28,
    },
    '化学機械器具の修理又は保守': {
      id: '化学機械器具の修理又は保守',
      name: {
        ja: '化学機械器具の修理又は保守',
        en: 'repair or maintenance of chemical processing machines and apparatus',
      },
      code: 'PQHc',
      order: 29,
    },
    'ガラス器製造機械の修理又は保守': {
      id: 'ガラス器製造機械の修理又は保守',
      name: {
        ja: 'ガラス器製造機械の修理又は保守',
        en: 'repair or maintenance of glassware manufacturing machines and apparatus',
      },
      code: 'FMCm',
      order: 30,
    },
    '漁業用機械器具の修理又は保守': {
      id: '漁業用機械器具の修理又は保守',
      name: {
        ja: '漁業用機械器具の修理又は保守',
        en: 'repair or maintenance of fishing machines and instruments',
      },
      code: 'gmhj',
      order: 31,
    },
    '金属加工機械器具の修理又は保守': {
      id: '金属加工機械器具の修理又は保守',
      name: {
        ja: '金属加工機械器具の修理又は保守',
        en: 'repair or maintenance of metalworking machines and tools',
      },
      code: 'eVYv',
      order: 32,
    },
    '靴製造機械の修理又は保守': {
      id: '靴製造機械の修理又は保守',
      name: {
        ja: '靴製造機械の修理又は保守',
        en: 'repair or maintenance of shoe making machines',
      },
      code: 'cxP8',
      order: 33,
    },
    '工業用炉の修理又は保守': {
      id: '工業用炉の修理又は保守',
      name: {
        ja: '工業用炉の修理又は保守',
        en: 'repair or maintenance of industrial furnaces',
      },
      code: 'xfGy',
      order: 34,
    },
    '鉱山機械器具の修理又は保守': {
      id: '鉱山機械器具の修理又は保守',
      name: {
        ja: '鉱山機械器具の修理又は保守',
        en: 'repair or maintenance of mining machines and apparatus',
      },
      code: 'vO0g',
      order: 35,
    },
    'ゴム製品製造機械器具の修理又は保守': {
      id: 'ゴム製品製造機械器具の修理又は保守',
      name: {
        ja: 'ゴム製品製造機械器具の修理又は保守',
        en: 'repair or maintenance of rubber-goods manufacturing machines and apparatus',
      },
      code: 'ZnZ_',
      order: 36,
    },
    '集積回路製造装置の修理又は保守，半導体製造装置の修理又は保守': {
      id: '集積回路製造装置の修理又は保守，半導体製造装置の修理又は保守',
      name: {
        ja: '集積回路製造装置の修理又は保守，半導体製造装置の修理又は保守',
        en: 'repair or maintenance of integrated circuits manufacturing machines，repair or maintenance of semiconductor manufacturing machines',
      },
      code: '-lCh',
      order: 37,
    },
    '食料加工用又は飲料加工用の機械器具の修理又は保守': {
      id: '食料加工用又は飲料加工用の機械器具の修理又は保守',
      name: {
        ja: '食料加工用又は飲料加工用の機械器具の修理又は保守',
        en: 'repair or maintenance of machines and apparatus for processing foods or beverages',
      },
      code: 'NAsj',
      order: 38,
    },
    '製材用・木工用又は合板用の機械器具の修理又は保守': {
      id: '製材用・木工用又は合板用の機械器具の修理又は保守',
      name: {
        ja: '製材用・木工用又は合板用の機械器具の修理又は保守',
        en: 'repair or maintenance of machines and apparatus for lumbering、 woodworking、 or veneer or plywood making',
      },
      code: 'MOX8',
      order: 39,
    },
    '繊維機械器具の修理又は保守': {
      id: '繊維機械器具の修理又は保守',
      name: {
        ja: '繊維機械器具の修理又は保守',
        en: 'repair or maintenance of textile machines and apparatus',
      },
      code: 'SMY8',
      order: 40,
    },
    'たばこ製造機械の修理又は保守': {
      id: 'たばこ製造機械の修理又は保守',
      name: {
        ja: 'たばこ製造機械の修理又は保守',
        en: 'repair or maintenance of tobacco processing machines',
      },
      code: '66Ow',
      order: 41,
    },
    '塗装機械器具の修理又は保守': {
      id: '塗装機械器具の修理又は保守',
      name: {
        ja: '塗装機械器具の修理又は保守',
        en: 'repair or maintenance of painting machines and apparatus',
      },
      code: '5mB2',
      order: 42,
    },
    '農業用機械器具の修理又は保守': {
      id: '農業用機械器具の修理又は保守',
      name: {
        ja: '農業用機械器具の修理又は保守',
        en: 'repair or maintenance of agricultural machines and implements',
      },
      code: 'kCkc',
      order: 43,
    },
    '耕うん機械器具（手持工具に当たるものを除く。）の修理又は保守，栽培機械器具の修理又は保守，収穫機械器具の修理又は保守，植物粗製繊維加工機械器具の修理又は保守，飼料圧搾機の修理又は保守，飼料裁断機の修理又は保守，飼料配合機の修理又は保守，飼料粉砕機の修理又は保守': {
      id: '耕うん機械器具（手持工具に当たるものを除く。）の修理又は保守，栽培機械器具の修理又は保守，収穫機械器具の修理又は保守，植物粗製繊維加工機械器具の修理又は保守，飼料圧搾機の修理又は保守，飼料裁断機の修理又は保守，飼料配合機の修理又は保守，飼料粉砕機の修理又は保守',
      name: {
        ja: '耕うん機械器具（手持工具に当たるものを除く。）の修理又は保守，栽培機械器具の修理又は保守，収穫機械器具の修理又は保守，植物粗製繊維加工機械器具の修理又は保守，飼料圧搾機の修理又は保守，飼料裁断機の修理又は保守，飼料配合機の修理又は保守，飼料粉砕機の修理又は保守',
        en: 'repair or maintenance of plowing machines and implements、 other than hand-held tools，repair or maintenance of cultivating machines and implements，repair or maintenance of harvesting machines and implements，repair or maintenance of plant fiber processing machines and implements，repair or maintenance of fodder presses，repair or maintenance of fodder cutting machines，repair or maintenance of fodder mixing machines，repair or maintenance of cake-fodder crushing machines',
      },
      code: 'TTa-',
      order: 44,
    },
    '牛乳ろ過器の修理又は保守，搾乳機の修理又は保守': {
      id: '牛乳ろ過器の修理又は保守，搾乳機の修理又は保守',
      name: {
        ja: '牛乳ろ過器の修理又は保守，搾乳機の修理又は保守',
        en: 'repair or maintenance of milk filtering machines，repair or maintenance of milking machines',
      },
      code: 'PN7h',
      order: 45,
    },
    '育雛器の修理又は保守，ふ卵器の修理又は保守': {
      id: '育雛器の修理又は保守，ふ卵器の修理又は保守',
      name: {
        ja: '育雛器の修理又は保守，ふ卵器の修理又は保守',
        en: 'repair or maintenance of chick brooders，repair or maintenance of egg incubators',
      },
      code: '1u-9',
      order: 46,
    },
    '蚕種製造用又は養蚕用の機械器具の修理又は保守': {
      id: '蚕種製造用又は養蚕用の機械器具の修理又は保守',
      name: {
        ja: '蚕種製造用又は養蚕用の機械器具の修理又は保守',
        en: 'repair or maintenance of sericultural machines and implements',
      },
      code: '9cbN',
      order: 47,
    },
    'パルプ製造用・製紙用又は紙工用の機械器具の修理又は保守': {
      id: 'パルプ製造用・製紙用又は紙工用の機械器具の修理又は保守',
      name: {
        ja: 'パルプ製造用・製紙用又は紙工用の機械器具の修理又は保守',
        en: 'repair or maintenance of machines and apparatus for pulp-making、 papermaking or paper-working',
      },
      code: 'Y5cK',
      order: 48,
    },
    'プラスチック加工機械器具の修理又は保守': {
      id: 'プラスチック加工機械器具の修理又は保守',
      name: {
        ja: 'プラスチック加工機械器具の修理又は保守',
        en: 'repair or maintenance of plastic processing machines and apparatus',
      },
      code: 'St9h',
      order: 49,
    },
    '包装用機械器具の修理又は保守': {
      id: '包装用機械器具の修理又は保守',
      name: {
        ja: '包装用機械器具の修理又は保守',
        en: 'repair or maintenance of packaging or wrapping machines and apparatus',
      },
      code: 'oGVj',
      order: 50,
    },
    'ミシンの修理又は保守': {
      id: 'ミシンの修理又は保守',
      name: {
        ja: 'ミシンの修理又は保守',
        en: 'repair or maintenance of sewing machines',
      },
      code: 'dhNg',
      order: 51,
    },
    '貯蔵槽類の修理又は保守': {
      id: '貯蔵槽類の修理又は保守',
      name: {
        ja: '貯蔵槽類の修理又は保守',
        en: 'repair or maintenance of reservoirs',
      },
      code: 'KSSP',
      order: 52,
    },
    'ガソリンステーション用装置の修理又は保守': {
      id: 'ガソリンステーション用装置の修理又は保守',
      name: {
        ja: 'ガソリンステーション用装置の修理又は保守',
        en: 'repair or maintenance of gasoline station equipment',
      },
      code: 'L37C',
      order: 53,
    },
    '機械式駐車装置の修理又は保守，自転車駐輪器具の修理又は保守': {
      id: '機械式駐車装置の修理又は保守，自転車駐輪器具の修理又は保守',
      name: {
        ja: '機械式駐車装置の修理又は保守，自転車駐輪器具の修理又は保守',
        en: 'repair or maintenance of mechanical parking systems，repair or maintenance of bicycle parking apparatus',
      },
      code: '8nHm',
      order: 54,
    },
    '業務用加熱調理機械器具の修理又は保守，業務用食器洗浄機の修理又は保守': {
      id: '業務用加熱調理機械器具の修理又は保守，業務用食器洗浄機の修理又は保守',
      name: {
        ja: '業務用加熱調理機械器具の修理又は保守，業務用食器洗浄機の修理又は保守',
        en: 'repair or maintenance of cooking equipment for industrial purposes，repair or maintenance of industrial dishwashers',
      },
      code: 'TLHd',
      order: 55,
    },
    '業務用電気洗濯機の修理又は保守': {
      id: '業務用電気洗濯機の修理又は保守',
      name: {
        ja: '業務用電気洗濯機の修理又は保守',
        en: 'repair or maintenance of electric washing machines for industrial purposes',
      },
      code: 'T2DY',
      order: 56,
    },
    '乗物用洗浄機の修理又は保守': {
      id: '乗物用洗浄機の修理又は保守',
      name: {
        ja: '乗物用洗浄機の修理又は保守',
        en: 'repair or maintenance of vehicle washing installations',
      },
      code: 'ijmf',
      order: 57,
    },
    '自動販売機の修理又は保守': {
      id: '自動販売機の修理又は保守',
      name: {
        ja: '自動販売機の修理又は保守',
        en: 'repair or maintenance of vending machines',
      },
      code: '23Uz',
      order: 58,
    },
    '動力付床洗浄機の修理又は保守': {
      id: '動力付床洗浄機の修理又は保守',
      name: {
        ja: '動力付床洗浄機の修理又は保守',
        en: 'repair or maintenance of power-driven floor cleaning machines',
      },
      code: 'VlG0',
      order: 59,
    },
    '遊園地用機械器具の修理又は保守': {
      id: '遊園地用機械器具の修理又は保守',
      name: {
        ja: '遊園地用機械器具の修理又は保守',
        en: 'repair or maintenance of amusement machines and apparatus',
      },
      code: 'h16H',
      order: 60,
    },
    '美容院用又は理髪店用の機械器具の修理又は保守': {
      id: '美容院用又は理髪店用の機械器具の修理又は保守',
      name: {
        ja: '美容院用又は理髪店用の機械器具の修理又は保守',
        en: 'repair or maintenance of apparatus and instruments for use in beauty salons or barbers\' shops',
      },
      code: 'x5qH',
      order: 61,
    },
    '水質汚濁防止装置の修理又は保守': {
      id: '水質汚濁防止装置の修理又は保守',
      name: {
        ja: '水質汚濁防止装置の修理又は保守',
        en: 'repair or maintenance of water pollution control equipment',
      },
      code: 'pQAS',
      order: 62,
    },
    '業務用浄水装置の修理又は保守': {
      id: '業務用浄水装置の修理又は保守',
      name: {
        ja: '業務用浄水装置の修理又は保守',
        en: 'repair or maintenance of water purifying apparatus for industrial purposes',
      },
      code: 'CG6h',
      order: 63,
    },
    '業務用廃棄物圧縮装置の修理又は保守，業務用廃棄物破砕装置の修理又は保守': {
      id: '業務用廃棄物圧縮装置の修理又は保守，業務用廃棄物破砕装置の修理又は保守',
      name: {
        ja: '業務用廃棄物圧縮装置の修理又は保守，業務用廃棄物破砕装置の修理又は保守',
        en: 'repair or maintenance of waste compacting machines and apparatus for industrial purposes，repair or maintenance of waste crushing machines for industrial purposes',
      },
      code: 'TYSD',
      order: 64,
    },
    '潜水用機械器具の修理又は保守': {
      id: '潜水用機械器具の修理又は保守',
      name: {
        ja: '潜水用機械器具の修理又は保守',
        en: 'repair or maintenance of divers\' apparatus',
      },
      code: 'ucQG',
      order: 65,
    },
    '化学プラントの修理又は保守，原子力発電プラントの修理又は保守': {
      id: '化学プラントの修理又は保守，原子力発電プラントの修理又は保守',
      name: {
        ja: '化学プラントの修理又は保守，原子力発電プラントの修理又は保守',
        en: 'repair or maintenance of chemical plants，repair or maintenance of nuclear power plants',
      },
      code: 'HwdM',
      order: 66,
    },
    '３Ｄプリンターの修理又は保守': {
      id: '３Ｄプリンターの修理又は保守',
      name: {
        ja: '３Ｄプリンターの修理又は保守',
        en: 'repair and maintenance of 3D printers',
      },
      code: 'qejJ',
      order: 67,
    },
    '家具の修理': {
      id: '家具の修理',
      name: {
        ja: '家具の修理',
        en: 'furniture restoration',
      },
      code: 'uaMq',
      order: 68,
    },
    '傘の修理': {
      id: '傘の修理',
      name: {
        ja: '傘の修理',
        en: 'umbrella repair',
      },
      code: 'gNQx',
      order: 69,
    },
    '楽器の修理又は保守': {
      id: '楽器の修理又は保守',
      name: {
        ja: '楽器の修理又は保守',
        en: 'repair or maintenance of musical instruments',
      },
      code: 'Iqby',
      order: 70,
    },
    '金庫の修理又は保守': {
      id: '金庫の修理又は保守',
      name: {
        ja: '金庫の修理又は保守',
        en: 'safe maintenance or repair',
      },
      code: 'aG1-',
      order: 71,
    },
    '靴の修理': {
      id: '靴の修理',
      name: {
        ja: '靴の修理',
        en: 'shoe repair',
      },
      code: 'Ime9',
      order: 72,
    },
    '時計の修理又は保守': {
      id: '時計の修理又は保守',
      name: {
        ja: '時計の修理又は保守',
        en: 'clock and watch repair or maintenance',
      },
      code: '7dYS',
      order: 73,
    },
    'はさみ研ぎ及びほうちょう研ぎ': {
      id: 'はさみ研ぎ及びほうちょう研ぎ',
      name: {
        ja: 'はさみ研ぎ及びほうちょう研ぎ',
        en: 'sharpening of scissors and kitchen knives',
      },
      code: 'y1RG',
      order: 74,
    },
    '錠前の取付け又は修理': {
      id: '錠前の取付け又は修理',
      name: {
        ja: '錠前の取付け又は修理',
        en: 'setup or repair of locks',
      },
      code: 'VmJn',
      order: 75,
    },
    'ガス湯沸かし器の修理又は保守，家庭用加熱器（電気式のものを除く。）の修理又は保守，鍋類の修理又は保守': {
      id: 'ガス湯沸かし器の修理又は保守，家庭用加熱器（電気式のものを除く。）の修理又は保守，鍋類の修理又は保守',
      name: {
        ja: 'ガス湯沸かし器の修理又は保守，家庭用加熱器（電気式のものを除く。）の修理又は保守，鍋類の修理又は保守',
        en: 'repair or maintenance of gas water heaters，repair or maintenance of non-electric cooking heaters for household purposes，repair or maintenance of cooking pots and pans',
      },
      code: 'PH7-',
      order: 76,
    },
    '看板の修理又は保守': {
      id: '看板の修理又は保守',
      name: {
        ja: '看板の修理又は保守',
        en: 'repair or maintenance of signboards',
      },
      code: 'pOca',
      order: 77,
    },
    'かばん類又は袋物の修理': {
      id: 'かばん類又は袋物の修理',
      name: {
        ja: 'かばん類又は袋物の修理',
        en: 'repair of bags or pouches',
      },
      code: 'l7PK',
      order: 78,
    },
    '身飾品の修理': {
      id: '身飾品の修理',
      name: {
        ja: '身飾品の修理',
        en: 'repair of personal ornaments',
      },
      code: 'LUtl',
      order: 79,
    },
    'おもちゃ又は人形の修理': {
      id: 'おもちゃ又は人形の修理',
      name: {
        ja: 'おもちゃ又は人形の修理',
        en: 'repair of toys or dolls',
      },
      code: 'U-e1',
      order: 80,
    },
    '運動用具の修理': {
      id: '運動用具の修理',
      name: {
        ja: '運動用具の修理',
        en: 'repair of sports equipment',
      },
      code: 'Kpsh',
      order: 81,
    },
    'ビリヤード用具の修理，遊戯用器具の修理': {
      id: 'ビリヤード用具の修理，遊戯用器具の修理',
      name: {
        ja: 'ビリヤード用具の修理，遊戯用器具の修理',
        en: 'repair of billiard equipment，repair of game machines and apparatus',
      },
      code: 'GPnK',
      order: 82,
    },
    '浴槽類の修理又は保守': {
      id: '浴槽類の修理又は保守',
      name: {
        ja: '浴槽類の修理又は保守',
        en: 'repair or maintenance of bath fittings',
      },
      code: 'dZF6',
      order: 83,
    },
    '洗浄機能付き便座の修理': {
      id: '洗浄機能付き便座の修理',
      name: {
        ja: '洗浄機能付き便座の修理',
        en: 'repair of toilet stool units with a washing water squirts',
      },
      code: '9TJf',
      order: 84,
    },
    '釣り具の修理': {
      id: '釣り具の修理',
      name: {
        ja: '釣り具の修理',
        en: 'repair of fishing tackle',
      },
      code: 'QcAh',
      order: 85,
    },
    '眼鏡の修理': {
      id: '眼鏡の修理',
      name: {
        ja: '眼鏡の修理',
        en: 'repair of spectacles',
      },
      code: 'GUzN',
      order: 86,
    },
    '毛皮製品の手入れ又は修理': {
      id: '毛皮製品の手入れ又は修理',
      name: {
        ja: '毛皮製品の手入れ又は修理',
        en: 'fur care and repair',
      },
      code: 'MHur',
      order: 87,
    },
    '洗濯，被服のプレス': {
      id: '洗濯，被服のプレス',
      name: {
        ja: '洗濯，被服のプレス',
        en: 'laundering，pressing of clothing',
      },
      code: 'V8YS',
      order: 88,
    },
    '被服の修理': {
      id: '被服の修理',
      name: {
        ja: '被服の修理',
        en: 'clothing repair [mending clothing]',
      },
      code: 'S1GE',
      order: 89,
    },
    '布団綿の打直し': {
      id: '布団綿の打直し',
      name: {
        ja: '布団綿の打直し',
        en: 'fluffing of cotton batting for futon',
      },
      code: 'jRUo',
      order: 90,
    },
    '畳類の修理': {
      id: '畳類の修理',
      name: {
        ja: '畳類の修理',
        en: 'repair of tatami mats',
      },
      code: '-Zah',
      order: 91,
    },
    '煙突の清掃': {
      id: '煙突の清掃',
      name: {
        ja: '煙突の清掃',
        en: 'chimney sweeping',
      },
      code: 'WdAq',
      order: 92,
    },
    '建築物の外壁の清掃，窓の清掃，床敷物の清掃，床磨き': {
      id: '建築物の外壁の清掃，窓の清掃，床敷物の清掃，床磨き',
      name: {
        ja: '建築物の外壁の清掃，窓の清掃，床敷物の清掃，床磨き',
        en: 'cleaning of building exterior surfaces，window cleaning，carpet and rug cleaning，floor polishing',
      },
      code: 'ba3B',
      order: 93,
    },
    'し尿処理槽の清掃': {
      id: 'し尿処理槽の清掃',
      name: {
        ja: 'し尿処理槽の清掃',
        en: 'septic tank cleaning',
      },
      code: 'BYip',
      order: 94,
    },
    '浴槽又は浴槽がまの清掃': {
      id: '浴槽又は浴槽がまの清掃',
      name: {
        ja: '浴槽又は浴槽がまの清掃',
        en: 'bathtub and bath boiler cleaning',
      },
      code: 'B8VI',
      order: 95,
    },
    '道路の清掃': {
      id: '道路の清掃',
      name: {
        ja: '道路の清掃',
        en: 'street cleaning',
      },
      code: 'rv5E',
      order: 96,
    },
    '貯蔵槽類の清掃': {
      id: '貯蔵槽類の清掃',
      name: {
        ja: '貯蔵槽類の清掃',
        en: 'reservoirs cleaning',
      },
      code: '2FbX',
      order: 97,
    },
    '電話機の消毒': {
      id: '電話機の消毒',
      name: {
        ja: '電話機の消毒',
        en: 'disinfecting of telephone hand-sets',
      },
      code: 'BgFp',
      order: 98,
    },
    '有害動物の防除（農業・水産養殖業・園芸又は林業に関するものを除く。）': {
      id: '有害動物の防除（農業・水産養殖業・園芸又は林業に関するものを除く。）',
      name: {
        ja: '有害動物の防除（農業・水産養殖業・園芸又は林業に関するものを除く。）',
        en: 'vermin exterminating [other than for agriculture、 aquaculture、 horticulture and forestry]',
      },
      code: 'RCL2',
      order: 99,
    },
    '医療用機械器具の殺菌・滅菌': {
      id: '医療用機械器具の殺菌・滅菌',
      name: {
        ja: '医療用機械器具の殺菌・滅菌',
        en: 'sterilization of medical apparatus and instruments',
      },
      code: 'D1ha',
      order: 100,
    },
    '土木機械器具の貸与': {
      id: '土木機械器具の貸与',
      name: {
        ja: '土木機械器具の貸与',
        en: 'rental of construction machines and apparatus',
      },
      code: 'OWUq',
      order: 101,
    },
    '床洗浄機の貸与，モップの貸与': {
      id: '床洗浄機の貸与，モップの貸与',
      name: {
        ja: '床洗浄機の貸与，モップの貸与',
        en: 'rental of floor cleaning machines，rental of mops',
      },
      code: 'cZHb',
      order: 102,
    },
    '洗車機の貸与': {
      id: '洗車機の貸与',
      name: {
        ja: '洗車機の貸与',
        en: 'rental of car-washing apparatus',
      },
      code: 'V9x9',
      order: 103,
    },
    '衣類乾燥機の貸与，衣類脱水機の貸与，電気洗濯機の貸与': {
      id: '衣類乾燥機の貸与，衣類脱水機の貸与，電気洗濯機の貸与',
      name: {
        ja: '衣類乾燥機の貸与，衣類脱水機の貸与，電気洗濯機の貸与',
        en: 'rental of laundry dryers，rental of spin dryers for clothes，rental of electric washing machines',
      },
      code: 'Jz0H',
      order: 104,
    },
    '鉱山機械器具の貸与': {
      id: '鉱山機械器具の貸与',
      name: {
        ja: '鉱山機械器具の貸与',
        en: 'rental of mining machines and apparatus',
      },
      code: 'Oz_A',
      order: 105,
    },
    '排水用ポンプの貸与': {
      id: '排水用ポンプの貸与',
      name: {
        ja: '排水用ポンプの貸与',
        en: 'rental of drainage pumps',
      },
      code: 'XwWp',
      order: 106,
    },
    '業務用食器乾燥機の貸与，業務用食器洗浄機の貸与': {
      id: '業務用食器乾燥機の貸与，業務用食器洗浄機の貸与',
      name: {
        ja: '業務用食器乾燥機の貸与，業務用食器洗浄機の貸与',
        en: 'rental of dish drying machines for restaurants，rental of dish washing machines for industrial purposes',
      },
      code: 'Q37X',
      order: 107,
    },
  },
  38: {
    '電気通信': {
      id: '電気通信',
      name: {
        ja: '電気通信',
        en: 'telecommunication',
      },
      code: '9dDn',
      order: 1,
    },
    '電気通信（「放送」を除く。）': {
      id: '電気通信（「放送」を除く。）',
      name: {
        ja: '電気通信（「放送」を除く。）',
        en: 'telecommunication、 other than broadcasting',
      },
      code: 'EEOU',
      order: 2,
    },
    '放送': {
      id: '放送',
      name: {
        ja: '放送',
        en: 'broadcasting',
      },
      code: 'MgLH',
      order: 3,
    },
    '報道をする者に対するニュースの供給': {
      id: '報道をする者に対するニュースの供給',
      name: {
        ja: '報道をする者に対するニュースの供給',
        en: 'news agency services',
      },
      code: '_OoE',
      order: 4,
    },
    '電話機・ファクシミリその他の通信機器の貸与': {
      id: '電話機・ファクシミリその他の通信機器の貸与',
      name: {
        ja: '電話機・ファクシミリその他の通信機器の貸与',
        en: 'rental of telecommunication equipment including telephones and facsimile apparatus',
      },
      code: 'M07X',
      order: 5,
    },
  },
  39: {
    '鉄道による輸送': {
      id: '鉄道による輸送',
      name: {
        ja: '鉄道による輸送',
        en: 'railway transport',
      },
      code: 'X99Q',
      order: 1,
    },
    '車両による輸送': {
      id: '車両による輸送',
      name: {
        ja: '車両による輸送',
        en: 'car transport',
      },
      code: '1fpc',
      order: 2,
    },
    '道路情報の提供': {
      id: '道路情報の提供',
      name: {
        ja: '道路情報の提供',
        en: 'providing road and traffic information',
      },
      code: 'Gs5J',
      order: 3,
    },
    '自動車の運転の代行': {
      id: '自動車の運転の代行',
      name: {
        ja: '自動車の運転の代行',
        en: 'vehicle-driving services',
      },
      code: 'olUu',
      order: 4,
    },
    '船舶による輸送': {
      id: '船舶による輸送',
      name: {
        ja: '船舶による輸送',
        en: 'boat transport',
      },
      code: 'BeKT',
      order: 5,
    },
    '航空機による輸送': {
      id: '航空機による輸送',
      name: {
        ja: '航空機による輸送',
        en: 'air transport',
      },
      code: 'Ez3w',
      order: 6,
    },
    '貨物のこん包': {
      id: '貨物のこん包',
      name: {
        ja: '貨物のこん包',
        en: 'packaging of goods',
      },
      code: 'oRXg',
      order: 7,
    },
    '貨物の輸送の媒介': {
      id: '貨物の輸送の媒介',
      name: {
        ja: '貨物の輸送の媒介',
        en: 'freight brokerage',
      },
      code: 'O6nV',
      order: 8,
    },
    '貨物の積卸し': {
      id: '貨物の積卸し',
      name: {
        ja: '貨物の積卸し',
        en: 'cargo unloading',
      },
      code: '4PXY',
      order: 9,
    },
    '引越の代行': {
      id: '引越の代行',
      name: {
        ja: '引越の代行',
        en: 'removal services',
      },
      code: 'CFpv',
      order: 10,
    },
    '船舶の貸与・売買又は運航の委託の媒介': {
      id: '船舶の貸与・売買又は運航の委託の媒介',
      name: {
        ja: '船舶の貸与・売買又は運航の委託の媒介',
        en: 'brokerage for rental、 selling、 purchasing or chartering of vessels',
      },
      code: '0yEH',
      order: 11,
    },
    '船舶の引揚げ': {
      id: '船舶の引揚げ',
      name: {
        ja: '船舶の引揚げ',
        en: 'refloating of ships',
      },
      code: 'glSC',
      order: 12,
    },
    '水先案内': {
      id: '水先案内',
      name: {
        ja: '水先案内',
        en: 'ship piloting',
      },
      code: 'Dk9K',
      order: 13,
    },
    '寄託を受けた物品の倉庫における保管': {
      id: '寄託を受けた物品の倉庫における保管',
      name: {
        ja: '寄託を受けた物品の倉庫における保管',
        en: 'warehousing services',
      },
      code: '7Ygr',
      order: 14,
    },
    '他人の携帯品の一時預かり，配達物の一時預かり': {
      id: '他人の携帯品の一時預かり，配達物の一時預かり',
      name: {
        ja: '他人の携帯品の一時預かり，配達物の一時預かり',
        en: 'temporary safekeeping of personal belongings，temporary storage of deliveries',
      },
      code: 'fBKn',
      order: 15,
    },
    'ガスの供給': {
      id: 'ガスの供給',
      name: {
        ja: 'ガスの供給',
        en: 'gas supplying [distribution]',
      },
      code: 'DAs_',
      order: 16,
    },
    '電気の供給': {
      id: '電気の供給',
      name: {
        ja: '電気の供給',
        en: 'electricity distribution',
      },
      code: 'T5QP',
      order: 17,
    },
    '水の供給': {
      id: '水の供給',
      name: {
        ja: '水の供給',
        en: 'water supplying [distribution]',
      },
      code: 'Br5i',
      order: 18,
    },
    '熱の供給': {
      id: '熱の供給',
      name: {
        ja: '熱の供給',
        en: 'heat supplying [distribution]',
      },
      code: '2ZTs',
      order: 19,
    },
    '倉庫の提供': {
      id: '倉庫の提供',
      name: {
        ja: '倉庫の提供',
        en: 'rental of warehouse space',
      },
      code: 'kDxR',
      order: 20,
    },
    '駐車場の提供': {
      id: '駐車場の提供',
      name: {
        ja: '駐車場の提供',
        en: 'provision of parking spaces',
      },
      code: 'ynkv',
      order: 21,
    },
    '有料道路の提供': {
      id: '有料道路の提供',
      name: {
        ja: '有料道路の提供',
        en: 'providing toll roads',
      },
      code: 'wlOa',
      order: 22,
    },
    '係留施設の提供': {
      id: '係留施設の提供',
      name: {
        ja: '係留施設の提供',
        en: 'providing vessel mooring facilities',
      },
      code: '-ups',
      order: 23,
    },
    '飛行場の提供': {
      id: '飛行場の提供',
      name: {
        ja: '飛行場の提供',
        en: 'airport services',
      },
      code: '6MMv',
      order: 24,
    },
    '駐車場の管理': {
      id: '駐車場の管理',
      name: {
        ja: '駐車場の管理',
        en: 'operating parking lots',
      },
      code: 'PmVk',
      order: 25,
    },
    '荷役機械器具の貸与': {
      id: '荷役機械器具の貸与',
      name: {
        ja: '荷役機械器具の貸与',
        en: 'rental of Loading-unloading machines and apparatus',
      },
      code: '2BHJ',
      order: 26,
    },
    '自動車の貸与': {
      id: '自動車の貸与',
      name: {
        ja: '自動車の貸与',
        en: 'car rental',
      },
      code: 'jRh9',
      order: 27,
    },
    '船舶の貸与': {
      id: '船舶の貸与',
      name: {
        ja: '船舶の貸与',
        en: 'rental of vessels',
      },
      code: 'SolH',
      order: 28,
    },
    '自転車の貸与': {
      id: '自転車の貸与',
      name: {
        ja: '自転車の貸与',
        en: 'rental of bicycles',
      },
      code: 'lsKh',
      order: 29,
    },
    '航空機の貸与': {
      id: '航空機の貸与',
      name: {
        ja: '航空機の貸与',
        en: 'aircraft rental',
      },
      code: 'PgeY',
      order: 30,
    },
    '機械式駐車装置の貸与': {
      id: '機械式駐車装置の貸与',
      name: {
        ja: '機械式駐車装置の貸与',
        en: 'rental of mechanical parking systems',
      },
      code: '6DFx',
      order: 31,
    },
    '包装用機械器具の貸与': {
      id: '包装用機械器具の貸与',
      name: {
        ja: '包装用機械器具の貸与',
        en: 'rental of packaging or wrapping machines and apparatus',
      },
      code: 'lPRn',
      order: 32,
    },
    '家庭用冷凍庫の貸与，家庭用冷凍冷蔵庫の貸与': {
      id: '家庭用冷凍庫の貸与，家庭用冷凍冷蔵庫の貸与',
      name: {
        ja: '家庭用冷凍庫の貸与，家庭用冷凍冷蔵庫の貸与',
        en: 'rental of freezers for household purposes，rental of refrigerator-freezers for household purposes',
      },
      code: 'CmQn',
      order: 33,
    },
    '車椅子の貸与': {
      id: '車椅子の貸与',
      name: {
        ja: '車椅子の貸与',
        en: 'rental of wheelchairs',
      },
      code: 'STvm',
      order: 34,
    },
    '信書の送達': {
      id: '信書の送達',
      name: {
        ja: '信書の送達',
        en: 'correspondence delivery services',
      },
      code: 'VWlH',
      order: 35,
    },
    '企画旅行の実施，旅行者の案内，旅行に関する契約（宿泊に関するものを除く。）の代理・媒介又は取次ぎ': {
      id: '企画旅行の実施，旅行者の案内，旅行に関する契約（宿泊に関するものを除く。）の代理・媒介又は取次ぎ',
      name: {
        ja: '企画旅行の実施，旅行者の案内，旅行に関する契約（宿泊に関するものを除く。）の代理・媒介又は取次ぎ',
        en: 'conducting of travel tours，escorting of travel tours，travel arrangement and reservation services、 excluding those for lodging',
      },
      code: 'oHIS',
      order: 36,
    },
    '廃棄物の収集': {
      id: '廃棄物の収集',
      name: {
        ja: '廃棄物の収集',
        en: 'collection of waste and trash',
      },
      code: 'tW8d',
      order: 37,
    },
    '一般廃棄物の収集': {
      id: '一般廃棄物の収集',
      name: {
        ja: '一般廃棄物の収集',
        en: 'collection of domestic waste and trash',
      },
      code: 'PN6h',
      order: 38,
    },
    '産業廃棄物の収集': {
      id: '産業廃棄物の収集',
      name: {
        ja: '産業廃棄物の収集',
        en: 'collection of industrial waste and trash',
      },
      code: 'QPk8',
      order: 39,
    },
    '航空機用エンジンの貸与': {
      id: '航空機用エンジンの貸与',
      name: {
        ja: '航空機用エンジンの貸与',
        en: 'rental of aircraft engines',
      },
      code: 'bZF_',
      order: 40,
    },
    '業務用冷凍機械器具の貸与': {
      id: '業務用冷凍機械器具の貸与',
      name: {
        ja: '業務用冷凍機械器具の貸与',
        en: 'rental of freezing machines and apparatus for industrial purposes',
      },
      code: 'Oc3S',
      order: 41,
    },
    'ガソリンステーション用装置（自動車の修理又は整備用のものを除く。）の貸与': {
      id: 'ガソリンステーション用装置（自動車の修理又は整備用のものを除く。）の貸与',
      name: {
        ja: 'ガソリンステーション用装置（自動車の修理又は整備用のものを除く。）の貸与',
        en: 'rental of gasoline station equipment、 not for repair and maintenance of automobiles',
      },
      code: 'JdGf',
      order: 42,
    },
  },
  40: {
    '除染': {
      id: '除染',
      name: {
        ja: '除染',
        en: 'removal of radiation rays',
      },
      code: 'dd5S',
      order: 1,
    },
    '布地・被服又は毛皮の加工処理（乾燥処理を含む。）': {
      id: '布地・被服又は毛皮の加工処理（乾燥処理を含む。）',
      name: {
        ja: '布地・被服又は毛皮の加工処理（乾燥処理を含む。）',
        en: 'treatment or processing of cloth、 clothing or fur、 including drying',
      },
      code: 'idUO',
      order: 2,
    },
    '裁縫，刺しゅう': {
      id: '裁縫，刺しゅう',
      name: {
        ja: '裁縫，刺しゅう',
        en: 'tailoring or dressmaking，embroidering',
      },
      code: 'ASRq',
      order: 3,
    },
    '金属の加工': {
      id: '金属の加工',
      name: {
        ja: '金属の加工',
        en: 'metal treating',
      },
      code: 'xveK',
      order: 4,
    },
    'ゴムの加工，プラスチックの加工': {
      id: 'ゴムの加工，プラスチックの加工',
      name: {
        ja: 'ゴムの加工，プラスチックの加工',
        en: 'processing of rubber，processing of plastics',
      },
      code: 'R9QH',
      order: 5,
    },
    'セラミックの加工': {
      id: 'セラミックの加工',
      name: {
        ja: 'セラミックの加工',
        en: 'ceramic processing',
      },
      code: 'DKM9',
      order: 6,
    },
    '木材の加工': {
      id: '木材の加工',
      name: {
        ja: '木材の加工',
        en: 'woodworking',
      },
      code: 'Mz-O',
      order: 7,
    },
    '紙の加工': {
      id: '紙の加工',
      name: {
        ja: '紙の加工',
        en: 'paper treating and working',
      },
      code: 'f8rl',
      order: 8,
    },
    '石材の加工': {
      id: '石材の加工',
      name: {
        ja: '石材の加工',
        en: 'stone treating',
      },
      code: 'id-y',
      order: 9,
    },
    '剥製': {
      id: '剥製',
      name: {
        ja: '剥製',
        en: 'taxidermy',
      },
      code: 'kMje',
      order: 10,
    },
    '竹・木皮・とう・つる・その他の植物性基礎材料の加工（「食物原材料の加工」を除く。）': {
      id: '竹・木皮・とう・つる・その他の植物性基礎材料の加工（「食物原材料の加工」を除く。）',
      name: {
        ja: '竹・木皮・とう・つる・その他の植物性基礎材料の加工（「食物原材料の加工」を除く。）',
        en: 'processing of bamboo、 tree barks、 rattan、 vines or other mineral vegetable materials、 other than processing of ingredients',
      },
      code: 'cxjZ',
      order: 11,
    },
    '食料品の加工': {
      id: '食料品の加工',
      name: {
        ja: '食料品の加工',
        en: 'food processing',
      },
      code: 'L3na',
      order: 12,
    },
    '義肢又は義歯の加工（「医療材料の加工」を含む。）': {
      id: '義肢又は義歯の加工（「医療材料の加工」を含む。）',
      name: {
        ja: '義肢又は義歯の加工（「医療材料の加工」を含む。）',
        en: 'processing of artificial limbs or artificial teeth、 including processing of medical materials',
      },
      code: '7Rke',
      order: 13,
    },
    '映画用フィルムの現像，写真の引き伸ばし，写真のプリント，写真用フィルムの現像': {
      id: '映画用フィルムの現像，写真の引き伸ばし，写真のプリント，写真用フィルムの現像',
      name: {
        ja: '映画用フィルムの現像，写真の引き伸ばし，写真のプリント，写真用フィルムの現像',
        en: 'processing of cinematographic films，photographic enlarging，photographic printing，photographic film development',
      },
      code: 'gVoo',
      order: 14,
    },
    '製本': {
      id: '製本',
      name: {
        ja: '製本',
        en: 'bookbinding',
      },
      code: 'Ke2w',
      order: 15,
    },
    '浄水処理': {
      id: '浄水処理',
      name: {
        ja: '浄水処理',
        en: 'water treating',
      },
      code: '9UxD',
      order: 16,
    },
    '廃棄物の再生': {
      id: '廃棄物の再生',
      name: {
        ja: '廃棄物の再生',
        en: 'recycling of waste and trash',
      },
      code: 'mqjS',
      order: 17,
    },
    '核燃料の再加工処理': {
      id: '核燃料の再加工処理',
      name: {
        ja: '核燃料の再加工処理',
        en: 'nuclear fuel reprocessing',
      },
      code: 'AxV9',
      order: 18,
    },
    '印章の彫刻': {
      id: '印章の彫刻',
      name: {
        ja: '印章の彫刻',
        en: 'engraving of sealing stamps',
      },
      code: 'odK6',
      order: 19,
    },
    'グラビア製版': {
      id: 'グラビア製版',
      name: {
        ja: 'グラビア製版',
        en: 'photogravure',
      },
      code: '3sJX',
      order: 20,
    },
    '繊維機械器具の貸与': {
      id: '繊維機械器具の貸与',
      name: {
        ja: '繊維機械器具の貸与',
        en: 'rental of textile machines and apparatus',
      },
      code: 'x2Ft',
      order: 21,
    },
    '写真の現像用・焼付け用・引き伸ばし用又は仕上げ用の機械器具の貸与': {
      id: '写真の現像用・焼付け用・引き伸ばし用又は仕上げ用の機械器具の貸与',
      name: {
        ja: '写真の現像用・焼付け用・引き伸ばし用又は仕上げ用の機械器具の貸与',
        en: 'rental of machines and apparatus for film development、 photograph printing、 photograph enlargement or photograph finishing',
      },
      code: 'A2me',
      order: 22,
    },
    '金属加工機械器具の貸与': {
      id: '金属加工機械器具の貸与',
      name: {
        ja: '金属加工機械器具の貸与',
        en: 'rental of metal treating machines and tools',
      },
      code: 'rpSM',
      order: 23,
    },
    '製本機械の貸与': {
      id: '製本機械の貸与',
      name: {
        ja: '製本機械の貸与',
        en: 'rental of bookbinding machines',
      },
      code: 'kqri',
      order: 24,
    },
    '食料加工用又は飲料加工用の機械器具の貸与': {
      id: '食料加工用又は飲料加工用の機械器具の貸与',
      name: {
        ja: '食料加工用又は飲料加工用の機械器具の貸与',
        en: 'rental of machines and apparatus for processing foods or beverages',
      },
      code: 'QVck',
      order: 25,
    },
    '製材用・木工用又は合板用の機械器具の貸与': {
      id: '製材用・木工用又は合板用の機械器具の貸与',
      name: {
        ja: '製材用・木工用又は合板用の機械器具の貸与',
        en: 'rental of machines and apparatus for lumbering、 wood-working、 or veneering or plywood making',
      },
      code: '3nUa',
      order: 26,
    },
    'パルプ製造用・製紙用又は紙工用の機械器具の貸与': {
      id: 'パルプ製造用・製紙用又は紙工用の機械器具の貸与',
      name: {
        ja: 'パルプ製造用・製紙用又は紙工用の機械器具の貸与',
        en: 'rental of machines and apparatus for pulp-making、 papermaking or paper-working',
      },
      code: '7PZD',
      order: 27,
    },
    '浄水装置の貸与': {
      id: '浄水装置の貸与',
      name: {
        ja: '浄水装置の貸与',
        en: 'rental of water purifying apparatus',
      },
      code: 'RoQF',
      order: 28,
    },
    '廃棄物圧縮装置の貸与，廃棄物破砕装置の貸与': {
      id: '廃棄物圧縮装置の貸与，廃棄物破砕装置の貸与',
      name: {
        ja: '廃棄物圧縮装置の貸与，廃棄物破砕装置の貸与',
        en: 'rental of waste compacting machines and apparatus，rental of waste crushing machines',
      },
      code: 'V9qF',
      order: 29,
    },
    '化学機械器具の貸与': {
      id: '化学機械器具の貸与',
      name: {
        ja: '化学機械器具の貸与',
        en: 'rental of chemical processing machines and apparatus',
      },
      code: 'NOUU',
      order: 30,
    },
    'ガラス器製造機械の貸与': {
      id: 'ガラス器製造機械の貸与',
      name: {
        ja: 'ガラス器製造機械の貸与',
        en: 'rental of glassware manufacturing machines and apparatus',
      },
      code: 'teMS',
      order: 31,
    },
    '靴製造機械の貸与': {
      id: '靴製造機械の貸与',
      name: {
        ja: '靴製造機械の貸与',
        en: 'rental of shoe making machines',
      },
      code: 'je6k',
      order: 32,
    },
    'たばこ製造機械の貸与': {
      id: 'たばこ製造機械の貸与',
      name: {
        ja: 'たばこ製造機械の貸与',
        en: 'rental of tobacco processing machines',
      },
      code: 'LoBu',
      order: 33,
    },
    '３Ｄプリンターの貸与': {
      id: '３Ｄプリンターの貸与',
      name: {
        ja: '３Ｄプリンターの貸与',
        en: 'rental of 3D printers',
      },
      code: 'o1D0',
      order: 34,
    },
    '材料処理情報の提供': {
      id: '材料処理情報の提供',
      name: {
        ja: '材料処理情報の提供',
        en: 'providing material treatment information',
      },
      code: '9g99',
      order: 35,
    },
    '印刷': {
      id: '印刷',
      name: {
        ja: '印刷',
        en: 'printing',
      },
      code: 'CXa6',
      order: 36,
    },
    '廃棄物の分別及び処分': {
      id: '廃棄物の分別及び処分',
      name: {
        ja: '廃棄物の分別及び処分',
        en: 'sorting and disposal of waste and trash',
      },
      code: 'HlUJ',
      order: 37,
    },
    '一般廃棄物の分別及び処分': {
      id: '一般廃棄物の分別及び処分',
      name: {
        ja: '一般廃棄物の分別及び処分',
        en: 'sorting and disposal of domestic waste and trash',
      },
      code: 'iVIv',
      order: 38,
    },
    '産業廃棄物の分別及び処分': {
      id: '産業廃棄物の分別及び処分',
      name: {
        ja: '産業廃棄物の分別及び処分',
        en: 'sorting and disposal of industrial waste and trash',
      },
      code: 'tCK3',
      order: 39,
    },
    '編み機の貸与，ミシンの貸与': {
      id: '編み機の貸与，ミシンの貸与',
      name: {
        ja: '編み機の貸与，ミシンの貸与',
        en: 'rental of knitting machines，rental of sewing machines',
      },
      code: 'wOSM',
      order: 40,
    },
    '家庭用暖冷房機の貸与，家庭用加湿器の貸与，家庭用空気清浄器の貸与，発電機の貸与': {
      id: '家庭用暖冷房機の貸与，家庭用加湿器の貸与，家庭用空気清浄器の貸与，発電機の貸与',
      name: {
        ja: '家庭用暖冷房機の貸与，家庭用加湿器の貸与，家庭用空気清浄器の貸与，発電機の貸与',
        en: 'rental of air-conditioning apparatus for household purposes，rental of humidifier for household purposes，rental of air purifiers for household purposes，rental of electricity generators',
      },
      code: '0nJc',
      order: 41,
    },
    '印刷用機械器具の貸与': {
      id: '印刷用機械器具の貸与',
      name: {
        ja: '印刷用機械器具の貸与',
        en: 'rental of printing machines and apparatus',
      },
      code: 'I9Ea',
      order: 42,
    },
    'ボイラーの貸与': {
      id: 'ボイラーの貸与',
      name: {
        ja: 'ボイラーの貸与',
        en: 'rental of boilers',
      },
      code: '97UB',
      order: 43,
    },
    '業務用加湿器の貸与，業務用空気清浄器の貸与，業務用暖冷房装置の貸与': {
      id: '業務用加湿器の貸与，業務用空気清浄器の貸与，業務用暖冷房装置の貸与',
      name: {
        ja: '業務用加湿器の貸与，業務用空気清浄器の貸与，業務用暖冷房装置の貸与',
        en: 'rental of humidifier for industrial purposes，rental of air purifiers for industrial purposes，rental of air conditioners for industrial purposes',
      },
      code: 'RW3Q',
      order: 44,
    },
  },
  41: {
    '当せん金付証票の発売': {
      id: '当せん金付証票の発売',
      name: {
        ja: '当せん金付証票の発売',
        en: 'operating lotteries',
      },
      code: 'TPyf',
      order: 1,
    },
    '録音又は録画済み記録媒体の複製': {
      id: '録音又は録画済み記録媒体の複製',
      name: {
        ja: '録音又は録画済み記録媒体の複製',
        en: 'dubbing',
      },
      code: 'zxBo',
      order: 2,
    },
    '技芸・スポーツ又は知識の教授': {
      id: '技芸・スポーツ又は知識の教授',
      name: {
        ja: '技芸・スポーツ又は知識の教授',
        en: 'educational and instruction services relating to arts、 crafts、 sports or general knowledge',
      },
      code: 'LgNd',
      order: 3,
    },
    '献体に関する情報の提供，献体の手配': {
      id: '献体に関する情報の提供，献体の手配',
      name: {
        ja: '献体に関する情報の提供，献体の手配',
        en: 'providing information on donation of human corpses for medical education，arranging of donation of human corpses for medical education',
      },
      code: 'xP_c',
      order: 4,
    },
    'セミナーの企画・運営又は開催': {
      id: 'セミナーの企画・運営又は開催',
      name: {
        ja: 'セミナーの企画・運営又は開催',
        en: 'arranging、 conducting and organization of seminars',
      },
      code: 'WG_3',
      order: 5,
    },
    '動物の調教': {
      id: '動物の調教',
      name: {
        ja: '動物の調教',
        en: 'animal training',
      },
      code: 'dYe9',
      order: 6,
    },
    '植物の供覧，動物の供覧': {
      id: '植物の供覧，動物の供覧',
      name: {
        ja: '植物の供覧，動物の供覧',
        en: 'plant exhibitions，animal exhibitions',
      },
      code: 'wj9k',
      order: 7,
    },
    '電子出版物の提供，図書及び記録の供覧，図書の貸与': {
      id: '電子出版物の提供，図書及び記録の供覧，図書の貸与',
      name: {
        ja: '電子出版物の提供，図書及び記録の供覧，図書の貸与',
        en: 'providing electronic publications，services of reference libraries for literature and documentary records，book rental',
      },
      code: '4y3n',
      order: 8,
    },
    '美術品の展示': {
      id: '美術品の展示',
      name: {
        ja: '美術品の展示',
        en: 'art exhibitions',
      },
      code: 'pAXa',
      order: 9,
    },
    '庭園の供覧，洞窟の供覧': {
      id: '庭園の供覧，洞窟の供覧',
      name: {
        ja: '庭園の供覧，洞窟の供覧',
        en: 'gardens for public admission，caves for public admission',
      },
      code: 'z4ks',
      order: 10,
    },
    '書籍の制作': {
      id: '書籍の制作',
      name: {
        ja: '書籍の制作',
        en: 'publication of books',
      },
      code: 'Jo5q',
      order: 11,
    },
    '映画・演芸・演劇又は音楽の演奏の興行の企画又は運営': {
      id: '映画・演芸・演劇又は音楽の演奏の興行の企画又は運営',
      name: {
        ja: '映画・演芸・演劇又は音楽の演奏の興行の企画又は運営',
        en: 'arranging and planning of movies、 shows、 plays or musical performances',
      },
      code: 'a2QX',
      order: 12,
    },
    'インターネットを利用して行う映像の提供，映画の上映・制作又は配給': {
      id: 'インターネットを利用して行う映像の提供，映画の上映・制作又は配給',
      name: {
        ja: 'インターネットを利用して行う映像の提供，映画の上映・制作又は配給',
        en: 'providing videos from the internet、 not downloadable，movie theatre presentations or movie film production and distribution',
      },
      code: 'mcgb',
      order: 13,
    },
    'インターネットを利用して行う音楽の提供，演芸の上演，演劇の演出又は上演，音楽の演奏': {
      id: 'インターネットを利用して行う音楽の提供，演芸の上演，演劇の演出又は上演，音楽の演奏',
      name: {
        ja: 'インターネットを利用して行う音楽の提供，演芸の上演，演劇の演出又は上演，音楽の演奏',
        en: 'providing digital music from the Internet、 not downloadable，presentation of live show performances，direction or presentation of plays，presentation of musical performances',
      },
      code: 'iv8Q',
      order: 14,
    },
    '放送番組の制作': {
      id: '放送番組の制作',
      name: {
        ja: '放送番組の制作',
        en: 'production of radio or television programs',
      },
      code: 'LbZb',
      order: 15,
    },
    '教育・文化・娯楽・スポーツ用ビデオの制作（映画・放送番組・広告用のものを除く。）': {
      id: '教育・文化・娯楽・スポーツ用ビデオの制作（映画・放送番組・広告用のものを除く。）',
      name: {
        ja: '教育・文化・娯楽・スポーツ用ビデオの制作（映画・放送番組・広告用のものを除く。）',
        en: 'production of videotape film in the field of education、 culture、 entertainment or sports [not for movies or television programs and not for advertising or publicity]',
      },
      code: 'p3rs',
      order: 16,
    },
    '放送番組の制作における演出': {
      id: '放送番組の制作における演出',
      name: {
        ja: '放送番組の制作における演出',
        en: 'directing of radio and television programs',
      },
      code: 'dHmp',
      order: 17,
    },
    '映像機器・音声機器等の機器であって放送番組の制作のために使用されるものの操作': {
      id: '映像機器・音声機器等の機器であって放送番組の制作のために使用されるものの操作',
      name: {
        ja: '映像機器・音声機器等の機器であって放送番組の制作のために使用されるものの操作',
        en: 'operation of video and audio equipment for production of radio and television programs',
      },
      code: 'zl5x',
      order: 18,
    },
    'スポーツの興行の企画・運営又は開催': {
      id: 'スポーツの興行の企画・運営又は開催',
      name: {
        ja: 'スポーツの興行の企画・運営又は開催',
        en: 'organization、 arranging and conducting of sports competitions',
      },
      code: 'mCal',
      order: 19,
    },
    '興行の企画・運営又は開催（映画・演芸・演劇・音楽の演奏の興行及びスポーツ・競馬・競輪・競艇・小型自動車競走の興行に関するものを除く。）': {
      id: '興行の企画・運営又は開催（映画・演芸・演劇・音楽の演奏の興行及びスポーツ・競馬・競輪・競艇・小型自動車競走の興行に関するものを除く。）',
      name: {
        ja: '興行の企画・運営又は開催（映画・演芸・演劇・音楽の演奏の興行及びスポーツ・競馬・競輪・競艇・小型自動車競走の興行に関するものを除く。）',
        en: 'organization of entertainment events excluding movies、 shows、 plays、 musical performances、 sports、 horse races、 bicycle races、 boat races and auto races',
      },
      code: 's7dT',
      order: 20,
    },
    '競馬の企画・運営又は開催': {
      id: '競馬の企画・運営又は開催',
      name: {
        ja: '競馬の企画・運営又は開催',
        en: 'organization、 arranging and conducting of horse races',
      },
      code: '5ZXd',
      order: 21,
    },
    '競輪の企画・運営又は開催': {
      id: '競輪の企画・運営又は開催',
      name: {
        ja: '競輪の企画・運営又は開催',
        en: 'organization、 arranging and conducting of bicycle races',
      },
      code: 'V2vE',
      order: 22,
    },
    '競艇の企画・運営又は開催': {
      id: '競艇の企画・運営又は開催',
      name: {
        ja: '競艇の企画・運営又は開催',
        en: 'organization、 arranging and conducting of boat races',
      },
      code: 'eefg',
      order: 23,
    },
    '小型自動車競走の企画・運営又は開催': {
      id: '小型自動車競走の企画・運営又は開催',
      name: {
        ja: '小型自動車競走の企画・運営又は開催',
        en: 'organization、 arranging and conducting of auto races',
      },
      code: 'w2Hp',
      order: 24,
    },
    '音響用又は映像用のスタジオの提供': {
      id: '音響用又は映像用のスタジオの提供',
      name: {
        ja: '音響用又は映像用のスタジオの提供',
        en: 'providing audio or video studio services',
      },
      code: 'ymoG',
      order: 25,
    },
    '運動施設の提供': {
      id: '運動施設の提供',
      name: {
        ja: '運動施設の提供',
        en: 'providing sports facilities',
      },
      code: '_GNf',
      order: 26,
    },
    '娯楽施設の提供': {
      id: '娯楽施設の提供',
      name: {
        ja: '娯楽施設の提供',
        en: 'providing amusement facilities',
      },
      code: 'gun_',
      order: 27,
    },
    '映画・演芸・演劇・音楽又は教育研修のための施設の提供': {
      id: '映画・演芸・演劇・音楽又は教育研修のための施設の提供',
      name: {
        ja: '映画・演芸・演劇・音楽又は教育研修のための施設の提供',
        en: 'providing facilities for movies、 shows、 plays、 music or educational training',
      },
      code: 'k3hF',
      order: 28,
    },
    '興行場の座席の手配': {
      id: '興行場の座席の手配',
      name: {
        ja: '興行場の座席の手配',
        en: 'booking of seats for shows',
      },
      code: 'EAlF',
      order: 29,
    },
    '映画機械器具の貸与，映写フィルムの貸与': {
      id: '映画機械器具の貸与，映写フィルムの貸与',
      name: {
        ja: '映画機械器具の貸与，映写フィルムの貸与',
        en: 'rental of cinematographic machines and apparatus，rental of cine-films',
      },
      code: 'nBwD',
      order: 30,
    },
    '楽器の貸与': {
      id: '楽器の貸与',
      name: {
        ja: '楽器の貸与',
        en: 'rental of musical instruments',
      },
      code: '4pbn',
      order: 31,
    },
    '運動用具の貸与': {
      id: '運動用具の貸与',
      name: {
        ja: '運動用具の貸与',
        en: 'rental of sports equipment',
      },
      code: 'bYqi',
      order: 32,
    },
    'テレビジョン受信機の貸与，ラジオ受信機の貸与': {
      id: 'テレビジョン受信機の貸与，ラジオ受信機の貸与',
      name: {
        ja: 'テレビジョン受信機の貸与，ラジオ受信機の貸与',
        en: 'rental of television sets，rental of radio sets',
      },
      code: 'u-BZ',
      order: 33,
    },
    'レコード又は録音済み磁気テープの貸与，録画済み磁気テープの貸与': {
      id: 'レコード又は録音済み磁気テープの貸与，録画済み磁気テープの貸与',
      name: {
        ja: 'レコード又は録音済み磁気テープの貸与，録画済み磁気テープの貸与',
        en: 'rental of records or sound-recorded magnetic tapes，rental of image-recorded magnetic tapes',
      },
      code: 'bb1t',
      order: 34,
    },
    'ネガフィルムの貸与，ポジフィルムの貸与': {
      id: 'ネガフィルムの貸与，ポジフィルムの貸与',
      name: {
        ja: 'ネガフィルムの貸与，ポジフィルムの貸与',
        en: 'rental of film negatives，rental of reversal film',
      },
      code: '-IK8',
      order: 35,
    },
    'おもちゃの貸与，遊園地用機械器具の貸与，遊戯用器具の貸与': {
      id: 'おもちゃの貸与，遊園地用機械器具の貸与，遊戯用器具の貸与',
      name: {
        ja: 'おもちゃの貸与，遊園地用機械器具の貸与，遊戯用器具の貸与',
        en: 'toy rental，rental of amusement machines and apparatus，rental of game machines and apparatus',
      },
      code: 'JJIE',
      order: 36,
    },
    '書画の貸与': {
      id: '書画の貸与',
      name: {
        ja: '書画の貸与',
        en: 'rental of paintings and calligraphic works',
      },
      code: 'AH0t',
      order: 37,
    },
    '写真の撮影': {
      id: '写真の撮影',
      name: {
        ja: '写真の撮影',
        en: 'photography',
      },
      code: 'NJI9',
      order: 38,
    },
    '通訳，翻訳': {
      id: '通訳，翻訳',
      name: {
        ja: '通訳，翻訳',
        en: 'language interpretation，translation',
      },
      code: 'ptAX',
      order: 39,
    },
    'カメラの貸与': {
      id: 'カメラの貸与',
      name: {
        ja: 'カメラの貸与',
        en: 'rental of cameras',
      },
      code: 'l1-2',
      order: 40,
    },
  },
  42: {
    '気象情報の提供': {
      id: '気象情報の提供',
      name: {
        ja: '気象情報の提供',
        en: 'providing meteorological information',
      },
      code: 'pxYr',
      order: 1,
    },
    '建築物の設計，測量': {
      id: '建築物の設計，測量',
      name: {
        ja: '建築物の設計，測量',
        en: 'architectural design，surveying',
      },
      code: 'SgJF',
      order: 2,
    },
    '地質の調査': {
      id: '地質の調査',
      name: {
        ja: '地質の調査',
        en: 'geological surveys or research',
      },
      code: 'ZDjk',
      order: 3,
    },
    '機械・装置若しくは器具（これらの部品を含む。）又はこれらの機械等により構成される設備の設計': {
      id: '機械・装置若しくは器具（これらの部品を含む。）又はこれらの機械等により構成される設備の設計',
      name: {
        ja: '機械・装置若しくは器具（これらの部品を含む。）又はこれらの機械等により構成される設備の設計',
        en: 'designing of machines、 apparatus、 instruments [including their parts] or systems composed of such machines、 apparatus and instruments',
      },
      code: 'd7pn',
      order: 4,
    },
    'デザインの考案': {
      id: 'デザインの考案',
      name: {
        ja: 'デザインの考案',
        en: 'design services',
      },
      code: '0D28',
      order: 5,
    },
    '電子計算機のプログラムの設計・作成又は保守': {
      id: '電子計算機のプログラムの設計・作成又は保守',
      name: {
        ja: '電子計算機のプログラムの設計・作成又は保守',
        en: 'computer software design、 computer programming、 or maintenance of computer software',
      },
      code: 'KyOa',
      order: 6,
    },
    '電子計算機・自動車その他その用途に応じて的確な操作をするためには高度の専門的な知識・技術又は経験を必要とする機械の性能・操作方法等に関する紹介及び説明': {
      id: '電子計算機・自動車その他その用途に応じて的確な操作をするためには高度の専門的な知識・技術又は経験を必要とする機械の性能・操作方法等に関する紹介及び説明',
      name: {
        ja: '電子計算機・自動車その他その用途に応じて的確な操作をするためには高度の専門的な知識・技術又は経験を必要とする機械の性能・操作方法等に関する紹介及び説明',
        en: 'technological advice relating to computers、 automobiles and industrial machines',
      },
      code: 'gEMz',
      order: 7,
    },
    '医薬品・化粧品又は食品の試験・検査又は研究': {
      id: '医薬品・化粧品又は食品の試験・検査又は研究',
      name: {
        ja: '医薬品・化粧品又は食品の試験・検査又は研究',
        en: 'testing、 inspection or research of pharmaceuticals、 cosmetics or foodstuffs',
      },
      code: 'wwHe',
      order: 8,
    },
    '建築又は都市計画に関する研究，公害の防止に関する試験又は研究，電気に関する試験又は研究，土木に関する試験又は研究': {
      id: '建築又は都市計画に関する研究，公害の防止に関する試験又は研究，電気に関する試験又は研究，土木に関する試験又は研究',
      name: {
        ja: '建築又は都市計画に関する研究，公害の防止に関する試験又は研究，電気に関する試験又は研究，土木に関する試験又は研究',
        en: 'research on building construction or city planning，testing or research on prevention of pollution，testing or research on electricity，testing or research on civil engineering',
      },
      code: 'V2ig',
      order: 9,
    },
    '農業・畜産又は水産に関する試験・検査又は研究': {
      id: '農業・畜産又は水産に関する試験・検査又は研究',
      name: {
        ja: '農業・畜産又は水産に関する試験・検査又は研究',
        en: 'testing、 inspection or research on agriculture、 livestock breeding or fisheries',
      },
      code: 'MOjB',
      order: 10,
    },
    '機械器具に関する試験又は研究': {
      id: '機械器具に関する試験又は研究',
      name: {
        ja: '機械器具に関する試験又は研究',
        en: 'testing or research on machines、 apparatus and instruments',
      },
      code: 'kCVy',
      order: 11,
    },
    '計測器の貸与': {
      id: '計測器の貸与',
      name: {
        ja: '計測器の貸与',
        en: 'rental of measuring apparatus',
      },
      code: 'BeaE',
      order: 12,
    },
    '電子計算機の貸与，電子計算機用プログラムの提供': {
      id: '電子計算機の貸与，電子計算機用プログラムの提供',
      name: {
        ja: '電子計算機の貸与，電子計算機用プログラムの提供',
        en: 'rental of computers，providing computer programs on data networks',
      },
      code: '13Qv',
      order: 13,
    },
    '理化学機械器具の貸与': {
      id: '理化学機械器具の貸与',
      name: {
        ja: '理化学機械器具の貸与',
        en: 'rental of laboratory apparatus and instruments',
      },
      code: 'EAl8',
      order: 14,
    },
    '望遠鏡の貸与': {
      id: '望遠鏡の貸与',
      name: {
        ja: '望遠鏡の貸与',
        en: 'rental of telescopes',
      },
      code: 'VMcA',
      order: 15,
    },
    '製図用具の貸与': {
      id: '製図用具の貸与',
      name: {
        ja: '製図用具の貸与',
        en: 'rental of technical drawing instruments',
      },
      code: 'x-4W',
      order: 16,
    },
  },
  43: {
    '宿泊施設の提供': {
      id: '宿泊施設の提供',
      name: {
        ja: '宿泊施設の提供',
        en: 'providing temporary accommodation',
      },
      code: 'ooJ5',
      order: 1,
    },
    '宿泊施設の提供の契約の媒介又は取次ぎ': {
      id: '宿泊施設の提供の契約の媒介又は取次ぎ',
      name: {
        ja: '宿泊施設の提供の契約の媒介又は取次ぎ',
        en: 'accommodation bureaux services [hotels、 boarding houses]',
      },
      code: '4HQX',
      order: 2,
    },
    '飲食物の提供': {
      id: '飲食物の提供',
      name: {
        ja: '飲食物の提供',
        en: 'providing foods and beverages',
      },
      code: 'RSJ5',
      order: 3,
    },
    '動物の宿泊施設の提供': {
      id: '動物の宿泊施設の提供',
      name: {
        ja: '動物の宿泊施設の提供',
        en: 'boarding for animals',
      },
      code: 'IF3B',
      order: 4,
    },
    '保育所における乳幼児の保育': {
      id: '保育所における乳幼児の保育',
      name: {
        ja: '保育所における乳幼児の保育',
        en: 'preschooler and infant care at daycare centers',
      },
      code: 'B4l-',
      order: 5,
    },
    '高齢者用入所施設の提供（介護を伴うものを除く。）': {
      id: '高齢者用入所施設の提供（介護を伴うものを除く。）',
      name: {
        ja: '高齢者用入所施設の提供（介護を伴うものを除く。）',
        en: 'retirement home services',
      },
      code: 'diEs',
      order: 6,
    },
    '会議室の貸与，展示施設の貸与': {
      id: '会議室の貸与，展示施設の貸与',
      name: {
        ja: '会議室の貸与，展示施設の貸与',
        en: 'rental of meeting rooms，rental of facilities for exhibitions',
      },
      code: '_Z4U',
      order: 7,
    },
    '布団の貸与，まくらの貸与，毛布の貸与': {
      id: '布団の貸与，まくらの貸与，毛布の貸与',
      name: {
        ja: '布団の貸与，まくらの貸与，毛布の貸与',
        en: 'rental of Futon and quilts，rental of pillows，rental of blankets',
      },
      code: 'g_UA',
      order: 8,
    },
    '家庭用電気式ホットプレートの貸与，家庭用電気トースターの貸与，家庭用電子レンジの貸与': {
      id: '家庭用電気式ホットプレートの貸与，家庭用電気トースターの貸与，家庭用電子レンジの貸与',
      name: {
        ja: '家庭用電気式ホットプレートの貸与，家庭用電気トースターの貸与，家庭用電子レンジの貸与',
        en: 'rental of electric hot plates for household purposes，rental of electric toasters for household purposes，rental of microwave ovens for household purposes',
      },
      code: 'UW7B',
      order: 9,
    },
    '業務用加熱調理機械器具の貸与，業務用調理台の貸与，業務用流し台の貸与': {
      id: '業務用加熱調理機械器具の貸与，業務用調理台の貸与，業務用流し台の貸与',
      name: {
        ja: '業務用加熱調理機械器具の貸与，業務用調理台の貸与，業務用流し台の貸与',
        en: 'rental of cooking equipment for industrial purposes，rental of kitchen sinks incorporating integrated worktops for commercial use，rental of kitchen sinks for commercial use',
      },
      code: 'muAk',
      order: 10,
    },
    '家庭用加熱器（電気式のものを除く。）の貸与，家庭用調理台の貸与，家庭用流し台の貸与，食器の貸与': {
      id: '家庭用加熱器（電気式のものを除く。）の貸与，家庭用調理台の貸与，家庭用流し台の貸与，食器の貸与',
      name: {
        ja: '家庭用加熱器（電気式のものを除く。）の貸与，家庭用調理台の貸与，家庭用流し台の貸与，食器の貸与',
        en: 'rental of non-electric cooking heaters for household purposes，rental of kitchen sinks incorporating integrated worktops for household purposes，rental of kitchen sinks for household purposes，rental of dishes',
      },
      code: 's9Ef',
      order: 11,
    },
    'カーテンの貸与，家具の貸与，壁掛けの貸与，敷物の貸与': {
      id: 'カーテンの貸与，家具の貸与，壁掛けの貸与，敷物の貸与',
      name: {
        ja: 'カーテンの貸与，家具の貸与，壁掛けの貸与，敷物の貸与',
        en: 'rental of curtains，rental of furniture，rental of wall hangings，rental of floor coverings',
      },
      code: 'Mm1U',
      order: 12,
    },
    'おしぼりの貸与，タオルの貸与': {
      id: 'おしぼりの貸与，タオルの貸与',
      name: {
        ja: 'おしぼりの貸与，タオルの貸与',
        en: 'rental of wet wipes，rental of towels',
      },
      code: 'Q76j',
      order: 13,
    },
  },
  44: {
    '美容，理容': {
      id: '美容，理容',
      name: {
        ja: '美容，理容',
        en: 'beauty salon services，barbershops',
      },
      code: 'keLJ',
      order: 1,
    },
    '入浴施設の提供': {
      id: '入浴施設の提供',
      name: {
        ja: '入浴施設の提供',
        en: 'providing bath houses',
      },
      code: '08Mr',
      order: 2,
    },
    '庭園樹の植樹，庭園又は花壇の手入れ，肥料の散布': {
      id: '庭園樹の植樹，庭園又は花壇の手入れ，肥料の散布',
      name: {
        ja: '庭園樹の植樹，庭園又は花壇の手入れ，肥料の散布',
        en: 'garden tree planting，garden or flower bed care，fertilizer spreading',
      },
      code: 'DWvr',
      order: 3,
    },
    '雑草の防除': {
      id: '雑草の防除',
      name: {
        ja: '雑草の防除',
        en: 'weed killing',
      },
      code: 'Qo0b',
      order: 4,
    },
    '有害動物の防除（農業・水産養殖業・園芸又は林業に関するものに限る。）': {
      id: '有害動物の防除（農業・水産養殖業・園芸又は林業に関するものに限る。）',
      name: {
        ja: '有害動物の防除（農業・水産養殖業・園芸又は林業に関するものに限る。）',
        en: 'vermin exterminating [for agriculture、 aquaculture、 horticulture or forestry]',
      },
      code: 'clso',
      order: 5,
    },
    '景観の設計': {
      id: '景観の設計',
      name: {
        ja: '景観の設計',
        en: 'landscape design',
      },
      code: 'PRMn',
      order: 6,
    },
    'あん摩・マッサージ及び指圧，カイロプラクティック，きゅう，柔道整復，整体，はり治療': {
      id: 'あん摩・マッサージ及び指圧，カイロプラクティック，きゅう，柔道整復，整体，はり治療',
      name: {
        ja: 'あん摩・マッサージ及び指圧，カイロプラクティック，きゅう，柔道整復，整体，はり治療',
        en: 'massage and therapeutic Shiatsu massage，chiropractic，moxibustion，treatment for dislocated joints、 sprain、 bone fractures [judo-seifuku]，bodywork therapy，acupuncture',
      },
      code: 'aJAR',
      order: 7,
    },
    '医業，医療情報の提供，健康診断，歯科医業，調剤': {
      id: '医業，医療情報の提供，健康診断，歯科医業，調剤',
      name: {
        ja: '医業，医療情報の提供，健康診断，歯科医業，調剤',
        en: 'medical practice，providing medical information，physical examination，dentistry services，preparation and dispensing of medications',
      },
      code: 'Xlqr',
      order: 8,
    },
    '栄養の指導': {
      id: '栄養の指導',
      name: {
        ja: '栄養の指導',
        en: 'dietary and nutritional guidance',
      },
      code: 'lW9j',
      order: 9,
    },
    '動物の飼育，動物の治療，動物の美容': {
      id: '動物の飼育，動物の治療，動物の美容',
      name: {
        ja: '動物の飼育，動物の治療，動物の美容',
        en: 'animal breeding，veterinary services，beautification for animals',
      },
      code: 'XJA1',
      order: 10,
    },
    '介護': {
      id: '介護',
      name: {
        ja: '介護',
        en: 'rest home services',
      },
      code: '61Oc',
      order: 11,
    },
    '植木の貸与': {
      id: '植木の貸与',
      name: {
        ja: '植木の貸与',
        en: 'rental of potted plants',
      },
      code: 'SsoU',
      order: 12,
    },
    '農業用機械器具の貸与': {
      id: '農業用機械器具の貸与',
      name: {
        ja: '農業用機械器具の貸与',
        en: 'farming equipment rental',
      },
      code: 'CGQF',
      order: 13,
    },
    '医療用機械器具の貸与': {
      id: '医療用機械器具の貸与',
      name: {
        ja: '医療用機械器具の貸与',
        en: 'rental of medical apparatus and instruments',
      },
      code: '_lrX',
      order: 14,
    },
    '漁業用機械器具の貸与': {
      id: '漁業用機械器具の貸与',
      name: {
        ja: '漁業用機械器具の貸与',
        en: 'rental of fishing equipment and instruments',
      },
      code: 'FGlN',
      order: 15,
    },
    '美容院用又は理髪店用の機械器具の貸与': {
      id: '美容院用又は理髪店用の機械器具の貸与',
      name: {
        ja: '美容院用又は理髪店用の機械器具の貸与',
        en: 'rental of apparatus and instruments for use in beauty salons or barbers\' shops',
      },
      code: 'oxGZ',
      order: 16,
    },
    '芝刈機の貸与': {
      id: '芝刈機の貸与',
      name: {
        ja: '芝刈機の貸与',
        en: 'rental of lawnmowers',
      },
      code: 'zDAm',
      order: 17,
    },
  },
  45: {
    '金庫の貸与': {
      id: '金庫の貸与',
      name: {
        ja: '金庫の貸与',
        en: 'rental of safes',
      },
      code: 'DH15',
      order: 1,
    },
    '着物の着付け': {
      id: '着物の着付け',
      name: {
        ja: '着物の着付け',
        en: 'kimono dressing assistance',
      },
      code: 'rOD5',
      order: 2,
    },
    'ファッション情報の提供': {
      id: 'ファッション情報の提供',
      name: {
        ja: 'ファッション情報の提供',
        en: 'information relating to fashion coordination services for individuals',
      },
      code: 'D7Pq',
      order: 3,
    },
    '結婚又は交際を希望する者へのパートナーの紹介': {
      id: '結婚又は交際を希望する者へのパートナーの紹介',
      name: {
        ja: '結婚又は交際を希望する者へのパートナーの紹介',
        en: 'partner introduction services for marriage or dating',
      },
      code: 't5_L',
      order: 4,
    },
    '婚礼（結婚披露を含む。）のための施設の提供': {
      id: '婚礼（結婚披露を含む。）のための施設の提供',
      name: {
        ja: '婚礼（結婚披露を含む。）のための施設の提供',
        en: 'providing facilities for wedding ceremonies、 including planning and arranging of wedding ceremonies',
      },
      code: 'fVQu',
      order: 5,
    },
    '葬儀の執行': {
      id: '葬儀の執行',
      name: {
        ja: '葬儀の執行',
        en: 'funerary undertaking',
      },
      code: '-7vT',
      order: 6,
    },
    '墓地又は納骨堂の提供': {
      id: '墓地又は納骨堂の提供',
      name: {
        ja: '墓地又は納骨堂の提供',
        en: 'providing grave spaces or charnel houses',
      },
      code: 'RomG',
      order: 7,
    },
    '工業所有権に関する手続の代理又は鑑定その他の事務，訴訟事件その他に関する法律事務，登記又は供託に関する手続の代理': {
      id: '工業所有権に関する手続の代理又は鑑定その他の事務，訴訟事件その他に関する法律事務，登記又は供託に関する手続の代理',
      name: {
        ja: '工業所有権に関する手続の代理又は鑑定その他の事務，訴訟事件その他に関する法律事務，登記又は供託に関する手続の代理',
        en: 'agencies for procedures relating to industrial property rights、 preparation of expert opinions，agencies for legal procedures relating to lawsuits or other legal issues，agencies for judicial registration or deposits',
      },
      code: '2L8d',
      order: 8,
    },
    '著作権の利用に関する契約の代理又は媒介': {
      id: '著作権の利用に関する契約の代理又は媒介',
      name: {
        ja: '著作権の利用に関する契約の代理又は媒介',
        en: 'agencies for copyright licensing',
      },
      code: 'nxQx',
      order: 9,
    },
    '社会保険に関する手続の代理': {
      id: '社会保険に関する手続の代理',
      name: {
        ja: '社会保険に関する手続の代理',
        en: 'legal services relating to social insurance claims',
      },
      code: 'jsOn',
      order: 10,
    },
    '施設の警備，身辺の警備，雑踏警備': {
      id: '施設の警備，身辺の警備，雑踏警備',
      name: {
        ja: '施設の警備，身辺の警備，雑踏警備',
        en: 'security guarding for facilities，personal bodyguarding，security and safety services for crowds',
      },
      code: 'x2hc',
      order: 11,
    },
    '個人の身元又は行動に関する調査': {
      id: '個人の身元又は行動に関する調査',
      name: {
        ja: '個人の身元又は行動に関する調査',
        en: 'investigation or surveillance services for checking background profiles',
      },
      code: 'k0ez',
      order: 12,
    },
    '占い，身の上相談': {
      id: '占い，身の上相談',
      name: {
        ja: '占い，身の上相談',
        en: 'fortune-telling，non-therapeutic counselling services rendered to meet the needs of individuals',
      },
      code: 'qhnk',
      order: 13,
    },
    'ペットの世話': {
      id: 'ペットの世話',
      name: {
        ja: 'ペットの世話',
        en: 'pet sitting',
      },
      code: '4J71',
      order: 14,
    },
    '乳幼児の保育（施設において提供されるものを除く。）': {
      id: '乳幼児の保育（施設において提供されるものを除く。）',
      name: {
        ja: '乳幼児の保育（施設において提供されるものを除く。）',
        en: 'caring for babies [excluding services provided at facilities]',
      },
      code: 'I4TO',
      order: 15,
    },
    '家事の代行': {
      id: '家事の代行',
      name: {
        ja: '家事の代行',
        en: 'providing non-medical in-home care services for individuals',
      },
      code: 'sDMQ',
      order: 16,
    },
    '後見': {
      id: '後見',
      name: {
        ja: '後見',
        en: 'guardianship services',
      },
      code: 'HKE5',
      order: 17,
    },
    '衣服の貸与': {
      id: '衣服の貸与',
      name: {
        ja: '衣服の貸与',
        en: 'clothing rental',
      },
      code: 'sMm6',
      order: 18,
    },
    '祭壇の貸与': {
      id: '祭壇の貸与',
      name: {
        ja: '祭壇の貸与',
        en: 'rental of altars',
      },
      code: 'hF1I',
      order: 19,
    },
    '火災報知機の貸与，消火器の貸与': {
      id: '火災報知機の貸与，消火器の貸与',
      name: {
        ja: '火災報知機の貸与，消火器の貸与',
        en: 'rental of fire alarms，rental of fire extinguishers',
      },
      code: 'P5G5',
      order: 20,
    },
    '装身具の貸与': {
      id: '装身具の貸与',
      name: {
        ja: '装身具の貸与',
        en: 'rental of personal ornaments for wearing',
      },
      code: 'ddWo',
      order: 21,
    },
  },
};
