import { Ref, ComputedRef, computed } from 'vue';

const consumptionTaxRate = 0.1;

export const usePriceCalculator = ({
  klassCount,
  premiumPlan,
  speedPlan,
  authenticated,
  isPersonal,
  isAttorneyCorporation,
  is10yearRegistration,
}: {
  klassCount: Ref<number> | ComputedRef<number>;
  premiumPlan: Ref<boolean> | ComputedRef<boolean>;
  speedPlan: Ref<boolean> | ComputedRef<boolean>;
  authenticated: Ref<boolean> | ComputedRef<boolean>;
  isPersonal?: Ref<boolean> | ComputedRef<boolean>;
  isAttorneyCorporation?: Ref<boolean> | ComputedRef<boolean>;
  is10yearRegistration?: Ref<boolean> | ComputedRef<boolean>;
}) => {
  const applyUsageFee = computed(() => klassCount.value * 10000);

  const premiumPlanFee = computed(() =>
    premiumPlan.value ? klassCount.value * 9000 : 0,
  );

  const speedPlanFee = computed(() => (speedPlan.value ? 30000 : 0));

  const applyPrintFee = computed(() => 3400 + 8600 * klassCount.value);

  const applyTax = computed(
    () =>
      consumptionTaxRate *
      (applyUsageFee.value + premiumPlanFee.value + speedPlanFee.value),
  );

  const requiresWithHoldingTax = computed(() => {
    // 未ログインは源泉徴収税を考慮せずに算出
    if (!authenticated.value) return false;
    // 出願人種別あるいは弁理士事務所種別が未定義の場合は、源泉徴収税を考慮せずに算出
    if (!isPersonal || !isAttorneyCorporation) return false;
    // 出願人（支払者）が個人の場合は源泉徴収義務者に該当しない
    if (isPersonal.value) return false;
    // 弁理士法人への支払は源泉徴収不要（弁理士個人に支払う報酬が源泉徴収の対象となる）
    if (isAttorneyCorporation.value) return false;
    // 出願人（支払者）が法人かつ、弁理士個人への報酬の支払である場合、源泉徴収対象
    if (!isPersonal.value && !isAttorneyCorporation.value) return true;

    return false;
  });

  const withholdingTax = computed(() => {
    const baseApplyFee =
      applyUsageFee.value + premiumPlanFee.value + speedPlanFee.value;
    return (
      (requiresWithHoldingTax.value ? -1 : 0) *
      Math.floor(baseApplyFee * 0.1021)
    );
  });

  const applySubTotalFee = computed(() => {
    return (
      applyUsageFee.value +
      applyPrintFee.value +
      premiumPlanFee.value +
      (speedPlan.value ? 30000 : 0) +
      applyTax.value +
      withholdingTax.value
    );
  });

  const registrationUsageFee = computed(() => {
    const usageFee = klassCount.value * 15000;
    return Math.floor(
      usageFee -
        Math.floor(usageFee * 0.1021) * (requiresWithHoldingTax.value ? 1 : 0),
    );
  });

  const registrationTax = computed(() =>
    Math.floor(consumptionTaxRate * registrationUsageFee.value),
  );

  const registrationPrintFee = computed(
    () =>
      klassCount.value *
      (is10yearRegistration !== undefined && is10yearRegistration.value
        ? 32900
        : 17200),
  );

  const registrationSubTotalFee = computed(
    () =>
      registrationUsageFee.value +
      registrationTax.value +
      registrationPrintFee.value,
  );

  const totalFee = computed(
    () => applySubTotalFee.value + registrationSubTotalFee.value,
  );

  return {
    applyUsageFee,
    premiumPlanFee,
    speedPlanFee,
    applyPrintFee,
    applyTax,
    withholdingTax,
    applySubTotalFee,
    registrationUsageFee,
    registrationTax,
    registrationPrintFee,
    registrationSubTotalFee,
    totalFee,
  };
};

export type PriceCalculatorStore = ReturnType<typeof usePriceCalculator>;
