<script setup lang="ts">
defineProps<{
  title: string;
  subtitle?: string;
  number?: number;
  hidesDevider?: boolean;
}>();

const emit = defineEmits<{
  (e: 'click:header'): void;
}>();
</script>

<template>
  <div class="bg-white p-6 sm:rounded">
    <div
      class="flex justify-between"
      @click="emit('click:header')"
    >
      <div class="flex items-center">
        <div
          v-if="number"
          class="bg-textBlack mr-2 h-6 w-6 rounded-full text-center font-bold text-white"
        >
          {{ number }}
        </div>
        <div
          v-if="title.length > 0"
          class="text-base font-bold flex items-center gap-x-2"
        >
          <h1>{{ title }}</h1>
          <div class="text-sm font-normal hidden sm:block">
            {{ subtitle }}
          </div>
        </div>
      </div>
      <slot name="header" />
    </div>
    <div
      v-if="!hidesDevider"
      class="my-4 h-[1px] w-full bg-[#e9e9e9]"
    />
    <slot />
  </div>
</template>
