<template>
  <div class="py-4">
    <div
      v-for="(row, index) in rows"
      :key="index"
      :class="index !== rows.length - 1 && 'mb-4'"
    >
      <div class="mb-2 flex items-center">
        <div
          class="material-icons material-symbols-outlined"
          :class="`text-[${row.iconColorCode}]`"
        >
          {{ row.mdiIconName }}
        </div>
        <div class="text-textBlack ml-2 font-semibold tracking-wider">
          {{ row.title }}
        </div>
      </div>
      <div class="text-grayAlpha60 whitespace-pre-line text-sm font-normal">
        {{ row.description }}
      </div>
      <div
        v-if="index === rows.length - 1"
        class="text-grayAlpha60 text-sm font-normal"
      >
        詳しくは
        <a
          href="https://support.cotobox.com/process-similar-trademarks"
          class="text-primary underline"
          target="_blank"
        >
          こちら
        </a>
      </div>
    </div>
    <div
      class="text-grayAlpha60 border-borderGray mt-6 border-t border-solid py-2 text-sm"
    >
      ※
      類似商標なしの場合も、予期せぬ理由などにより、出願後に別途対応が必要になるケースがございます。<br>
      ※「類似商標の確認」をクリックすると、検出された類似商標をご確認いただけます。<br>
      ※
      最終的な判断をするのは特許庁であり、検索結果は登録可否の保証をするものではございません。<br>
      ※
      そのほかの不登録事由については、お申し込み後に担当弁理士が確認をいたしますのでご安心くださいませ。よくある不登録事由は<a
        href="https://support.cotobox.com/about-distinctiveness"
        class="text-primary underline"
        target="_blank"
      >こちら</a>です。
    </div>
  </div>
</template>

<script setup lang="ts">
const rows = [
  {
    title: '類似商標なし',
    mdiIconName: 'check_circle',
    iconColorCode: '#24BA63',
    description:
      '類似商標は検出されませんでした。そのままお申し込みの手続きへ進んでいただけます。',
  },
  {
    title: '類似と思われる商標あり',
    mdiIconName: 'warning',
    iconColorCode: '#FFEC45',
    description:
      'そのままお申し込みいただけますが、出願後に別途対応が必要になるリスクがあります。',
  },
  {
    title: '類似商標あり',
    mdiIconName: 'error',
    iconColorCode: '#dc3645',
    description:
      '似た商標が検出されたため、そのままお申し込みいただけません。\n最終的には登録となるケースもございます。\n専門家との相談（お申し込み）をご希望の場合は「申込内容/料金の確認に進む」ボタンをクリックし、検索結果をサポートチームに送信してください。',
  },
];
</script>
