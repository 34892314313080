import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { createI18n } from 'vue-i18n';
import { createHead } from '@vueuse/head';
import { createGtm } from '@gtm-support/vue-gtm';
import device from './plugins/device';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import './index.css';
import FloatingVue from 'floating-vue';

// よく使うコンポーネントをグローバル登録
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import SecondaryButton from '@/components/common/SecondaryButton.vue';
import TextButton from '@/components/common/TextButton.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import FormTextArea from '@/components/common/FormTextArea.vue';
import BaseCheckbox from '@/components/common/BaseCheckbox.vue';
import FormItemWrapper from '@/components/common/FormItemWrapper.vue';
import SectionBlock from '@/components/common/SectionBlock.vue';
import DescriptionList from '@/components/common/DescriptionListItem.vue';
import Breadcrumb from '@/components/common/Breadcrumb.vue';
import Tabs from '@/components/common/Tabs.vue';
import Modal from '@/components/common/Modal.vue';
import TheOverlayLoading from '@/components/common/TheOverlayLoading.vue';
import { createPinia } from 'pinia';

const i18n = createI18n({
  legacy: false,
  locale: 'ja',
  fallbackLocale: 'ja',
});

const head = createHead();

const options: PluginOptions = {
  position: POSITION.BOTTOM_CENTER,
  timeout: 4000,
};

const gtm = createGtm({
  id: 'GTM-TMNP3G',
  // @ts-ignore
  vueRouter: router,
});

export default () =>
  createApp(App)
    .component('PrimaryButton', PrimaryButton)
    .component('SecondaryButton', SecondaryButton)
    .component('TextButton', TextButton)
    .component('FormTextInput', FormTextInput)
    .component('FormTextArea', FormTextArea)
    .component('BaseCheckbox', BaseCheckbox)
    .component('FormItemWrapper', FormItemWrapper)
    .component('SectionBlock', SectionBlock)
    .component('DescriptionList', DescriptionList)
    .component('Breadcrumb', Breadcrumb)
    .component('Tabs', Tabs)
    .component('Modal', Modal)
    .component('TheOverlayLoading', TheOverlayLoading)
    .use(createPinia())
    .use(router)
    .use(i18n)
    .use(head)
    .use(gtm)
    .use(device)
    .use(Toast, options)
    .use(FloatingVue)
    .mount('#app');
