<template>
  <div class="text-left text-sm">
    <DescriptionListItem
      v-for="item in items"
      :key="item.label"
      class="mb-2"
      :label="item.label"
    >
      <div class="text-grayAlpha80 flex-1">
        {{ item.value }}
      </div>
    </DescriptionListItem>
    <DescriptionListItem :label="t('amount')">
      <Price
        class="text-grayAlpha80"
        :price="props.amount"
      />
    </DescriptionListItem>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Price from '@/components/common/Price.vue';
import DescriptionListItem from '@/components/common/DescriptionListItem.vue';
import type { AccountType } from '@/composables/useBankAccountStore';

export type DestinationBankAccountProps = {
  bankName: string;
  branchName: string;
  accountType: AccountType;
  accountNumber: string;
  accountHolderName: string;
  amount: number;
};

const props = defineProps<DestinationBankAccountProps>();

const items = computed<
  {
    label: string;
    value: string;
  }[]
>(() => [
  { label: t('bankName'), value: props.bankName },
  { label: t('branchName'), value: props.branchName },
  { label: t('accountType'), value: accountTypeText.value },
  { label: t('accountNumber'), value: props.accountNumber },
  { label: t('accountHolderName'), value: props.accountHolderName },
]);

const { t } = useI18n({
  messages: {
    ja: {
      bankName: '銀行',
      branchName: '支店',
      accountType: '口座種別',
      savingAccount: '普通預金',
      currentAccount: '当座預金',
      accountNumber: '口座番号',
      accountHolderName: '口座名義',
      amount: '振込金額',
    },
    en: {
      bankName: 'Bank Name',
      branchName: 'Branch Name',
      accountType: 'Account Type',
      savingAccount: 'Saving Account',
      currentAccount: 'Current Account',
      accountNumber: 'Account Number',
      accountHolderName: 'Account Holder Name',
      amount: 'Amount',
    },
    zh: {
      bankName: '银行名称',
      branchName: '分行名称',
      accountType: '账户类型',
      savingAccount: '储蓄账户',
      currentAccount: '活期账户',
      accountNumber: '账户号码',
      accountHolderName: '账户持有人姓名',
      amount: '金额',
    },
  },
});

const accountTypeText = computed<string>(() => {
  switch (props.accountType) {
  case 'saving':
    return t('savingAccount');
  case 'current':
    return t('currentAccount');
  default:
    console.error(`Invalid accountType: ${props.accountType}`);
    return '';
  }
});
</script>
