<template>
  <div>
    <PriceGroup
      class="!border-primary bg-primaryOrange5 mb-4"
      title="出願費用"
      note="今回のお支払い金額です"
      :default-expands="true"
      :total="applySubTotalFee"
      :items="[
        { label: applyUsageLabel, price: applyUsageFee },
        { label: '審査スピードアップ', price: speedPlanFee },
        { label: '出願時消費税', price: applyTax },
        { label: '特許印紙代（出願）', price: applyPrintFee },
        { label: '源泉徴収税', price: withholdingTax },
      ]"
    />
    <PriceGroup
      class="mb-4"
      title="登録費用"
      note="特許庁審査後のお支払い金額です"
      :total="registrationSubTotalFee"
      :items="[
        {
          label: `登録手数料（${klassCount}区分）`,
          price: registrationUsageFee,
        },
        { label: '登録時消費税', price: registrationTax },
        { label: '特許印紙代（登録）', price: registrationPrintFee },
      ]"
    >
      <template #header>
        <div class="mt-2 flex items-start justify-between">
          <div class="text-grayAlpha80 text-sm">
            登録年数で変わります。<br>
            詳しくは
            <a
              class="text-primary underline"
              href="https://support.cotobox.com/faq-5years-10years/"
              target="_blank"
            >
              こちら
            </a>
          </div>
          <RadioButtonGroup
            :options="[
              { label: '5年', value: '5years' },
              { label: '10年', value: '10years' },
            ]"
            :model-value="registPeriod"
            @update:model-value="
              ($e) => $emit('update:regist-period', $e as RegistPeriod)
            "
          />
        </div>
      </template>
    </PriceGroup>
    <!-- 合計 -->
    <div class="border-grayAlpha10 rounded border border-solid px-3 py-4">
      <div class="flex items-center justify-between">
        <div class="text-textBlack font-bold tracking-wider">
          合計
        </div>
        <div class="flex items-baseline text-right">
          <div class="text-base font-bold">
            {{ t('price', [totalFee.toLocaleString()]) }}
          </div>
          <div class="text-xs">
            （税込）
          </div>
        </div>
      </div>
      <div class="text-grayAlpha80 mt-2 text-sm">
        出願費用と登録費用の合計です。
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import PriceGroup from '@/components/price/PriceGroup.vue';
import { ApplyFormKey, UserDataKey } from '@/composables/injectionKeys';
import { ApplyFormStore } from '@/composables/useApplyForm';
import { usePriceCalculator } from '@/composables/usePriceCalculator';
import { UserDataStore } from '@/composables/useUserStore';
import RadioButtonGroup from '@/components/common/RadioButtonGroup.vue';

type RegistPeriod = '5years' | '10years';

const props = defineProps<{
  registPeriod: RegistPeriod;
}>();

defineEmits<{
  (e: 'update:regist-period', value: RegistPeriod): void;
}>();

const { state: applyFormState, klassCount } = inject(
  ApplyFormKey,
) as ApplyFormStore;

const {
  state: userState,
  authenticated,
  isAttorneyCorporation,
} = inject(UserDataKey) as UserDataStore;

const applyUsageLabel = computed(() => t('applyUsageFee', [klassCount.value]));
const premiumPlan = computed(() => applyFormState.premiumPlan);
const speedPlan = computed(() => applyFormState.speedPlan);
const isPersonal = computed(() => userState.isPersonal);
const is10yearRegistration = computed(() => props.registPeriod === '10years');

const {
  applyUsageFee,
  speedPlanFee,
  applyPrintFee,
  applyTax,
  withholdingTax,
  applySubTotalFee,
  registrationUsageFee,
  registrationPrintFee,
  registrationTax,
  registrationSubTotalFee,
  totalFee,
} = usePriceCalculator({
  klassCount,
  premiumPlan,
  speedPlan,
  authenticated,
  isPersonal,
  isAttorneyCorporation,
  is10yearRegistration,
});

const { t } = useI18n({
  messages: {
    ja: {
      price: '{0}円',
      applicationTitle: '出願費用',
      applyUsageFee: '出願手数料（{0}区分）',
      premiumPlanFee: '安心フルサポート\n（{0}区分）',
      speedPlanFee: '審査スピードアップ\n（早期審査）',
      applyPrintFee: '特許印紙代（出願）',
      applyTax: '出願時消費税',
      withholdingTax: '源泉徴収税',
      applicationTotal: '出願費用合計 {0}円',
      outOfScope: '対象外',
      registrationTitle: '登録費用',
      registrationUsageFee: '登録手数料',
      registrationPrintFee: '特許印紙代（登録）',
      registrationTotal: '登録費用合計 {0}円',
      total: '総合計 {0}円',
      asterisk: '※',
    },
    en: {
      price: '¥{0}',
      applicationTitle: 'When making a wish',
      applyUsageFee: 'Service charge ({0} classification)',
      premiumPlanFee: 'Full support for peace of mind\n({0} classification)',
      speedPlanFee: 'Speeding Up Screening\n(Early screening)',
      applyPrintFee: 'License paper generation',
      applyTax: 'consumption tax (incl. sales tax, VAT, excise duty, etc.)',
      withholdingTax: 'tax withholding at the source',
      applicationTotal: 'Total {0} yen',
      outOfScope: 'not subject (to)',
      registrationTitle: 'At registration',
      registrationUsageFee: 'Service charge (tax included)',
      registrationPrintFee: 'License paper generation',
      registrationTotal: 'Total {0} yen',
      total: 'Total {0} yen',
      asterisk: '※',
    },
    zh: {
      price: '¥{0}',
      applicationTitle: '出願時',
      applyUsageFee: '服务费（{0}类）',
      premiumPlanFee: '全面支持，让人放心\n({0}分类)',
      speedPlanFee: '加快筛查速度\n(提前放映)',
      applyPrintFee: '特許印紙代',
      applyTax: '消费税（包括销售税、增值税、消费税等）',
      withholdingTax: '源头预扣税款',
      applicationTotal: '共计{0}。',
      outOfScope: '不包括',
      registrationTitle: '在注册时',
      registrationUsageFee: '服务费（含税）',
      registrationPrintFee: '特許印紙代',
      registrationTotal: '共计{0}。',
      total: '共计{0}。',
      asterisk: '',
    },
  },
});
</script>
