<template>
  <div
    class="text-primary flex cursor-pointer items-center"
    @click="onClick"
  >
    <span class="material-icons"> help </span>
    <span class="ml-1 font-semibold">
      {{ title }}
    </span>
  </div>
</template>

<script setup lang="ts">
interface Props {
  title: string;
}

defineProps<Props>();

interface Emits {
  (e: 'click'): void;
}

const emit = defineEmits<Emits>();

function onClick () {
  emit('click');
}
</script>
