<template>
  <Carousel
    v-bind="{
      itemsToShow: 1,
      itemsToScroll: 1,
      snapAlign: 'center',
      autoplay: 2000,
    }"
    :breakpoints="{
      700: {
        itemsToShow: 7,
        itemsToScroll: 7,
        snapAlign: 'center',
        autoplay: 4000,
      },
    }"
    :wrap-around="true"
  >
    <Slide
      v-for="src in IMG_SRC_LIST"
      :key="src"
    >
      <div
        class="border-borderGray box-border h-20 w-full border border-solid p-4"
      >
        <div
          class="h-full w-full bg-contain bg-center bg-no-repeat"
          :style="{
            backgroundImage: `url(${`https://cotobox.com/wp-content/themes/cotobox/images/lp/${src}`})`,
          }"
        />
      </div>
    </Slide>
    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script setup lang="ts">
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

const IMG_SRC_LIST = [
  'corp_logo_paypay.png',
  'corp_logo_macloud.jpg',
  'corp_logo_toburealestate.jpg',
  'corp_logo_plaid.png',
  'corp_logo_2.png',
  'corp_logo_4.png',
  'corp_logo_3.svg',
  'wabun_b_logo_color_cs.png',
  'corp_logo_1.png',
  'corp_logo_amptalk.png',
] as const;
</script>
