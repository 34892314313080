import { goodsServices } from '@/constants/goodsServices';
import { ref, computed } from 'vue';
import { KlassId, GoodsServiceId, GoodsServiceIds } from '@/types';
import { APIClientInterface } from '@/api/APIClientInterface';
import { klasses } from '@/constants/klasses';

export type KlassFilterType = 'none' | 'category' | 'AI';
export type KlassCategory = 'all' | 'goods' | 'service';

export const useKlassFilter = (apiClient: APIClientInterface) => {
  // 全て/商品/サービスでの絞り込み選択状態
  const category = ref<KlassCategory>('all');

  // 全て/商品/サービスでの絞り込みの結果
  const categoryResult = computed<GoodsServiceIds>(() => {
    const goodsServiceIds = (Object.keys(goodsServices) as KlassId[]).reduce(
      (p, klassId) => {
        return {
          ...p,
          [klassId]: Object.keys(goodsServices[klassId]) as GoodsServiceId[],
        };
      },
      {} as GoodsServiceIds,
    );

    if (category.value === 'goods') {
      const numbers = Array.from({ length: 34 }, (_, i) => i + 1);
      return numbers.reduce((p, klassNumber) => {
        const klassId = `${klassNumber}` as KlassId;
        return {
          ...p,
          [klassId]: Object.keys(goodsServices[klassId]) as GoodsServiceId[],
        };
      }, {} as GoodsServiceIds);
    } else if (category.value === 'service') {
      const numbers = Array.from({ length: 11 }, (_, i) => i + 35);
      return numbers.reduce((p, klassNumber) => {
        const klassId = `${klassNumber}` as KlassId;
        return {
          ...p,
          [klassId]: Object.keys(goodsServices[klassId]) as GoodsServiceId[],
        };
      }, {} as GoodsServiceIds);
    } else {
      return goodsServiceIds;
    }
  });

  // AI絞り込みで入力されたテキスト
  const queryText = ref('');

  // AI絞り込みAPIの結果を格納
  const rawQueryResult = ref<string[]>([]);

  // AI絞り込みAPIの結果を区分&商品役務IDの型として格納
  const queryResult = computed<GoodsServiceIds>(() => {
    const result = {} as GoodsServiceIds;
    rawQueryResult.value.forEach((goodsServiceId) => {
      klasses.forEach((klass) => {
        const klassId = klass.value;
        const klassGoodsServices = goodsServices[klassId];
        if (Object.keys(klassGoodsServices).includes(goodsServiceId)) {
          if (klassId in result) {
            result[klassId]!.push(goodsServiceId);
          } else {
            result[klassId] = [goodsServiceId] as GoodsServiceId[];
          }
        }
      });
    });
    return result;
  });

  // AI絞り込みAPI結果待ち
  const loading = ref(false);

  // AI絞り込みAPI実行
  async function executeAIFilter () {
    loading.value = true;
    const { data } = await apiClient.filterGoodsServices(queryText.value);
    loading.value = false;
    if (data) {
      rawQueryResult.value = data;
      return true;
    }
    return false;
  }

  return {
    category,
    categoryResult,
    queryText,
    queryResult,
    loading,
    executeAIFilter,
  };
};

export type KlassFilterStore = ReturnType<typeof useKlassFilter>;
