import {
  APIClientInterface,
  SavedSearchQueryListItem,
  TrademarkAdminStatus,
  TrademarkApplication,
} from '@/api/APIClientInterface';
import { reactive, computed } from 'vue';
import { ApplicationStatus, statuses } from '@/constants/applicationStatuses';

export type ApplicationListType =
  | 'submitted'
  | 'accepted'
  | 'cancelled'
  | 'saved';

export const useApplicationList = (apiClient: APIClientInterface) => {
  const state = reactive<{
    selectedListType: ApplicationListType;
    submittedTrademarks: TrademarkApplication[];
    acceptedTrademarks: TrademarkApplication[];
    cancelledTrademarks: TrademarkApplication[];
    savedTrademarks: SavedSearchQueryListItem[];
  }>({
    selectedListType: 'submitted',
    submittedTrademarks: [],
    acceptedTrademarks: [],
    cancelledTrademarks: [],
    savedTrademarks: [],
  });

  const selectedList = computed(() => {
    switch (state.selectedListType) {
    case 'submitted':
      return state.submittedTrademarks;
    case 'accepted':
      return state.acceptedTrademarks;
    case 'saved':
      return state.savedTrademarks;
    case 'cancelled':
      return state.cancelledTrademarks;
    default:
      return state.submittedTrademarks;
    }
  });

  async function fetchTrademarkApplications () {
    const trademarks = await apiClient.fetchTrademarkApplications();
    trademarks.forEach((trademark) => {
      if (
        [0, 1, 2, 3].includes(
          applicationStatusWithTrademarkAdminStatus(trademark.status.admin)
            .step,
        )
      ) {
        state.submittedTrademarks.push(trademark);
      } else if (
        [4, 10, 11, 12].includes(
          applicationStatusWithTrademarkAdminStatus(trademark.status.admin)
            .step,
        )
      ) {
        state.acceptedTrademarks.push(trademark);
      } else if (
        [20].includes(
          applicationStatusWithTrademarkAdminStatus(trademark.status.admin)
            .step,
        )
      ) {
        state.cancelledTrademarks.push(trademark);
      }
    });
    const saved = await apiClient.fetchSavedSearchQueryList();
    state.savedTrademarks = [...saved];
  }

  const applicationStatusWithTrademarkAdminStatus: (
    adminStatus: TrademarkAdminStatus,
  ) => ApplicationStatus = (adminStatus) => {
    const status = statuses.find((e) => e.admin === adminStatus);
    if (typeof status === 'undefined') {
      return {
        admin: 'その他',
        user: { ja: 'その他', en: 'Other', zh: 'Other' },
        step: 0,
      };
    }
    return status;
  };

  async function getSavedTrademark (savedSearchQueryId: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...queries } =
      await apiClient.fetchSavedSearchQuery(savedSearchQueryId);
    return queries;
  }

  async function deleteSavedTrademark (savedSearchQueryId: string) {
    await apiClient.deleteSavedSearchQuery(savedSearchQueryId);

    const index = state.savedTrademarks
      .map((e) => e.id)
      .indexOf(savedSearchQueryId);
    if (index !== -1) {
      state.savedTrademarks.splice(index, 1);
    }
  }

  return {
    state,
    selectedList,
    fetchTrademarkApplications,
    applicationStatusWithTrademarkAdminStatus,
    getSavedTrademark,
    deleteSavedTrademark,
  };
};

export type ApplicationListStore = ReturnType<typeof useApplicationList>;
