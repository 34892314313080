
<template>
  <div class="text-base whitespace-pre-line px-2 py-4 text-sm">
    <div class="flex flex-col gap-y-2 border-borderGray border-b border-solid pb-4 mb-4">
      <div>
        <div class="flex gap-x-2">
          <div>Q.</div>
          <div>商標のよみがなは指定できるの？</div>
        </div>
        <div class="flex gap-x-2">
          <div>A.</div>
          <div>よみがな(称呼)は、出願後に特許庁が判断するので指定できません。</div>
        </div>
      </div>
      <div>
        ・
        <a
          href="https://support.cotobox.com/pronunciation"
          target="_blank"
          rel="noopener"
          class="text-primary underline"
        >
          よみがな(称呼)は、指定できますか？
        </a>
      </div>
    </div>
    <div class="flex flex-col gap-y-2">
      <div>
        <div class="flex gap-x-2">
          <div>Q.</div>
          <div>よみがなも保護したいとき、どうすればいい？</div>
        </div>
        <div class="flex gap-x-2">
          <div>A.</div>
          <div>
            使用している商標を出願するのが原則ですが、 漢字や英語で読み方が複数ある場合、真似されたくない読みをカタカナなどで出願することを検討してみましょう。
            <div class="pt-4">
              例えば、商標が「aser-t」で「アサート」と読んでほしい場合。アザートやアセルトと読むことができますよね。
              出願人が「これはアサートと読むので、アサートを保護したい」と考えるのであれば、カナ表記「アサート」でも商標出願する必要があるでしょう。
            </div>
          </div>
        </div>
      </div>
      <div>
        ・
        <a
          href="https://support.cotobox.com/faq-wordmark-notation"
          target="_blank"
          rel="noopener"
          class="text-primary underline"
        >
          文字商標の表記はなににすべき？
        </a>
      </div>
    </div>
  </div>
</template>
