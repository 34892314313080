<template>
  <header
    class="relative w-screen pt-[10px]"
    style="background: linear-gradient(45deg, #222, #444)"
  >
    <div class="mx-auto my-0 grid h-full w-[1191px]">
      <!-- ロゴ -->
      <div
        class="col-start-1 col-end-2 row-start-1 row-end-2 flex items-center text-white"
      >
        <a
          class="block h-[26px] w-[160px]"
          href="/"
        >
          <img src="@/assets/images/icons/logo.svg">
        </a>
        <small class="ml-6 whitespace-nowrap text-sm tracking-wider text-white">
          {{ t('titleSmall') }}
        </small>
      </div>
      <!-- 電話番号 -->
      <template v-if="!route.path.startsWith('/app/mypage/applications')">
        <div
          class="col-start-2 col-end-3 row-start-1 row-end-2 flex items-center justify-end text-white"
        >
          <div
            class="grid gap-y-1"
            style="grid-template-columns: 28px 1fr"
          >
            <i
              class="material-icons material-symbols-outlined col-start-1 col-end-2 row-start-1 row-end-2 mr-1 text-white"
            >phone</i>
            <a
              class="col-start-2 col-end-3 row-start-1 row-end-2 text-white"
              href="tel:05088809640"
            >
              050-8880-9640
            </a>
            <span
              class="col-start-1 col-end-3 row-start-2 row-end-3 text-sm text-white"
            >
              {{ t('businessHours') }}
            </span>
          </div>
          <!-- 未ログインなら資料ダウンロード設置 -->
          <a
            v-if="!authenticated"
            href="/app/brochure_requests/new"
            class="ml-4"
          >
            <SecondaryButton title="資料ダウンロード" />
          </a>
        </div>
      </template>
      <!-- 左メニュー -->
      <HeaderDesktopNav />
      <!-- 右メニュー -->
      <nav
        class="col-start-2 col-end-3 row-start-2 row-end-3 flex self-end justify-self-end text-white"
      >
        <!-- ログイン済の場合 -->
        <HeaderDesktopMenuAuthenticated v-if="authenticated" />
        <!-- 未ログインの場合 -->
        <HeaderDesktopMenuUnauthenticated
          v-else
          class="py-2"
        />
      </nav>
    </div>
  </header>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
// 状態管理
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
// コンポーネント
import HeaderDesktopNav from '@/components/header/HeaderDesktopNav.vue';
import HeaderDesktopMenuAuthenticated from '@/components/header/HeaderDesktopMenuAuthenticated.vue';
import HeaderDesktopMenuUnauthenticated from '@/components/header/HeaderDesktopMenuUnauthenticated.vue';

const route = useRoute();
const { t } = useI18n({
  messages: {
    ja: {
      titleSmall: 'オンライン商標登録サービス「コトボックス」',
      businessHours: '平日 10:00〜12:00 14:00〜17:00',
      signUp: '無料で利用（会員登録）',
      primer: '商標入門ガイド',
      about: 'Cotoboxとは？',
      price: '料金',
      cases: '導入事例',
      search: '商標を検索・申込',
      signIn: 'ログイン',
      brochure: '資料ダウンロード',
      mypage: 'マイページ',
      support: 'よくあるご質問',
      contact: 'お問合せ',
      setting: '設定',
    },
    en: {
      titleSmall: 'Online Trademark Registration Service "Cotobox"',
      businessHours: 'Weekdays 10am-5pm',
      signUp: 'Sign Up',
      primer: 'Trademark Primer',
      about: 'What\'s Cotobox',
      price: 'Pricing',
      cases: 'Case Studies',
      search: 'Search trademark',
      signIn: 'Sign in',
      brochure: 'Brochure',
      mypage: 'Mypage',
      support: 'Support',
      contact: 'Contact',
      setting: 'Setting',
    },
    zh: {
      titleSmall: '在线商标注册服务「Cotobox」',
      businessHours: '平日上午10点至下午5点',
      signUp: '注册新账户',
      primer: '商标入门',
      about: '什么是Cotobox',
      price: '价格',
      cases: '案例研究',
      search: '搜索商标',
      signIn: '登录',
      brochure: '手册',
      mypage: '首页',
      support: '支持',
      contact: '联系我们',
      setting: '设置',
    },
  },
});

const { authenticated } = inject(UserDataKey) as UserDataStore;
</script>
