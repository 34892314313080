<template>
  <div class="py-4 pr-4">
    <!-- 電話 -->
    <div class="mb-8 flex items-center">
      <div class="mr-6 w-80">
        <div class="text-grayAlpha80 mb-1 text-base font-semibold">
          Cotoboxの操作方法/商標制度全般/<br>お申し込みまでのご質問
        </div>
        <div class="text-grayAlpha60 text-sm">
          カスタマーサポートにお問い合わせください。
        </div>
      </div>
      <div>
        <div class="text-grayAlpha80 mb-2 flex items-center">
          <div class="material-icons material-symbols-outlined mr-1">
            phone
          </div>
          <a
            href="tel:05088809640"
            class="hover:text-grayAlpha60 hover:underline"
          >050-8880-9640</a>
        </div>
        <div class="text-grayAlpha80 ml-7 text-xs">
          平日10:00〜17:00
        </div>
        <div class="text-grayAlpha80 ml-7 text-xs">
          ※12:00〜14:00を除く
        </div>
      </div>
    </div>
    <!-- 弁理士メッセージ -->
    <div class="mb-8 flex items-center">
      <div class="mr-6 w-80">
        <div class="text-grayAlpha80 mb-1 text-base font-semibold">
          ご依頼済みの案件に関するご質問
        </div>
        <div class="text-grayAlpha60 text-sm">
          弁理士メッセージから担当者へご連絡ください。<br>
          ※
          追加オプション料金のお支払いにより、電話対応も可能です。提携の担当弁理士へお問い合わせください。
        </div>
      </div>
      <div>
        <SecondaryButton
          title="弁理士メッセージへ"
          :disabled="false"
          @click="onMessageButtonClick"
        />
      </div>
    </div>
    <!-- よくあるご質問 -->
    <div>
      <div class="text-grayAlpha80 mb-1 text-base font-semibold">
        よくあるご質問
      </div>
      <div class="text-grayAlpha60 text-sm">
        ご利用ガイド・FAQは<a
          class="text-primary underline"
          href="https://support.cotobox.com/"
          target="_blank"
        >こちら</a>です。
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

interface Emits {
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();

const router = useRouter();

function onMessageButtonClick () {
  router.push('/app/mypage');
  emit('close');
}
</script>
