<template>
  <div
    :style="{ backgroundImage: `url(${src})` }"
    class="border-borderGray rounded-sm border border-solid bg-white bg-contain bg-center bg-no-repeat"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import HatsunaLogo from '@/assets/images/logos/logo_hatsuna.jpg';
import KXLogo from '@/assets/images/logos/logo_kx.jpg';
import AuthenseLogo from '@/assets/images/logos/logo_authense.png';

const props = defineProps<{
  attorneyOfficeId: number;
}>();

const src = computed(() => {
  switch (props.attorneyOfficeId) {
  case 1:
    return HatsunaLogo;
  case 3:
    return KXLogo;
  case 4:
    return AuthenseLogo;
  default:
    return '';
  }
});
</script>
