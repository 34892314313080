import axios from 'axios';
import { defineStore } from 'pinia';
import { NotFoundError } from '@/types/error';

export interface Item {
  type: string;
  name: string;
  numberOfClasses: string;
  taxRate: string;
  amount: number;
}

export interface Payment {
  id: number;
  applicationId: number;
  paymentStatus: string;
  paymentStatusName: string;
  paymentMethod: string;
  succeededAt: string;
  translatedPaymentType: string;
  total: string;
  serviceCharge: number;
  tax: number;
  memo: string;
  stripeInvoiceNo: string;
  stripeDueDate: string;
  stripeInvoicePdfUrl: string;
  isValidExportReceipt: boolean;
  createdAt: string;
  items: Item[];
}

export const usePaymentStore = defineStore({
  id: 'paymentStore',
  state: () => ({
    payments: [] as Array<Payment>,
    currentId: 0 as number,
    current: {} as Payment,
  }),
  getters: {
    isIPaymentExists: (state) => {
      for (const p of state.payments) {
        if (p.paymentStatus === 'success') {
          continue;
        }
        for (const i of p.items) {
          if (i.type === 'registration_5yr_second' || i.type === 'renewal_registration_5yr') {
            return true;
          }
        }
      }
      return false;
    },
  },
  actions: {
    async fetch (appId: number) {
      this.$reset();

      try {
        const headers = {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        };

        const response = await axios.get(
          `/app/mypage/applications/${appId}/payments.json`,
          headers,
        );

        response.data.forEach((payment) => {
          const items: Item[] = payment.items.map((item: Item) => {
            return {
              type: item.type,
              name: item.name,
              numberOfClasses: item.numberOfClasses,
              taxRate: item.taxRate,
              amount: item.amount,
            };
          });

          this.payments.push({
            id: payment.id,
            applicationId: payment.applicationId,
            paymentStatus: payment.paymentStatus,
            paymentStatusName: payment.paymentStatusName,
            paymentMethod: payment.payment,
            succeededAt: payment.succeededAt,
            translatedPaymentType: payment.translatedPaymentType,
            total: payment.total,
            serviceCharge: payment.serviceCharge,
            tax: payment.tax,
            memo: payment.memo,
            stripeInvoiceNo: payment.stripeInvoiceNo,
            stripeDueDate: payment.stripeDueDate,
            stripeInvoicePdfUrl: payment.stripeInvoicePdfUrl,
            isValidExportReceipt: payment.isValidExportReceipt,
            createdAt: payment.createdAt,
            items,
          });
        });
      } catch (e) {
        if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
          throw new NotFoundError();
        }
        throw e;
      }
    },
    async fetchPayment () {
      this.current = this.payments.find(
        (payment) => payment.id === this.currentId,
      ) as Payment;
    },
  },
});
