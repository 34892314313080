<script setup lang="ts">
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplicationListKey } from '@/composables/injectionKeys';
import { ApplicationListStore } from '@/composables/useApplicationList';

const { t: globalT } = useI18n({ useScope: 'global' });

const { t } = useI18n({
  messages: {
    ja: {
      title: {
        submitted: '出願申込した商標がまだありません',
        accepted: '登録された商標がまだありません',
        saved: '出願を検討している商標がまだありません',
        cancelled: 'キャンセル・失効した商標はありません',
      },
      description: {
        submitted: '{0}で、商標を検索してみましょう。',
        accepted: '',
        saved: '{0}で、商標を検索してみましょう。',
        cancelled: '',
      },
    },
    en: {
      title: {
        submitted: 'No trademark has been applied for yet.',
        accepted: 'No registered trademarks yet.',
        saved: 'No trademarks for which you are considering applying.',
        cancelled: 'No cancelled or expired trademarks.',
      },
      description: {
        submitted: 'Search for a trademark on {0}.',
        accepted: '',
        saved: 'Search for a trademark on {0}.',
        cancelled: '',
      },
    },
    zh: {
      title: {
        submitted: '尚未申请任何商标',
        accepted: '尚无注册商标',
        saved: '没有正在考虑申请的商标',
        cancelled: '没有被取消或过期的商标',
      },
      description: {
        submitted: '请从{0}开始搜索您的商标。',
        accepted: '',
        saved: '请从{0}开始搜索您的商标。',
        cancelled: '',
      },
    },
  },
});

const { state } = inject(ApplicationListKey) as ApplicationListStore;
</script>

<template>
  <div
    class="border-x-[1px] border-b border-solid border-[#ccc] bg-white px-4 py-10"
  >
    <div
      class="mx-auto mb-4 mt-6 text-center text-2xl font-bold leading-8 tracking-wider sm:leading-none"
    >
      {{ t(`title.${state.selectedListType}`) }}
    </div>
    <div class="mx-auto my-0 text-center leading-6 sm:leading-none">
      <i18n-t :keypath="`description.${state.selectedListType}`">
        <router-link
          class="text-blue"
          to="/app/search/"
        >
          {{ globalT('common.trademarkType.text') }}
        </router-link>
      </i18n-t>
    </div>
  </div>
</template>
