<template>
  <div class="flex h-[300px] w-[162px] flex-col items-stretch justify-between">
    <div
      class="data-[cotobox=true]:text-primary mb-2 text-center font-bold"
      :data-cotobox="actor.name === 'Cotobox'"
    >
      {{ actor.name }}
    </div>
    <div
      class="h-[160px] bg-contain bg-center bg-no-repeat"
      :style="{ backgroundImage: `url(${actor.image})` }"
    />
    <div
      class="border-grayAlpha20 mt-2 border border-solid p-4 text-sm tracking-wider"
    >
      {{ actor.description }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ActorAttorneyImage from '@/assets/images/creatives/actor-attorney.svg';
import ActorCotoboxImage from '@/assets/images/creatives/actor-cotobox.svg';
import ActorJpoImage from '@/assets/images/creatives/actor-jpo.svg';
import ActorYouImage from '@/assets/images/creatives/actor-you.svg';

const props = defineProps({
  actorName: {
    type: String,
    required: true,
    validator: (value: string) =>
      ['attorney', 'cotobox', 'jpo', 'you'].includes(value),
  },
});

const actor = computed(
  (): { name: string; image: string; description: string } => {
    switch (props.actorName) {
    case 'attorney':
      return {
        name: '提携弁理士',
        image: ActorAttorneyImage,
        description: 'あなたと契約し、特許庁へ出願手続きを行う',
      };
    case 'cotobox':
      return {
        name: 'Cotobox',
        image: ActorCotoboxImage,
        description: '商標検索、書類作成支援、ステータス管理、メッセージ機能',
      };
    case 'jpo':
      return {
        name: '特許庁',
        image: ActorJpoImage,
        description: '出願受付、審査、設定登録広報発行',
      };
    case 'you':
      return {
        name: 'あなた',
        image: ActorYouImage,
        description: '担当弁理士と契約して出願を依頼',
      };
    default:
      console.error(`Invalid actorName: ${props.actorName}`);
      return {
        name: '',
        image: '',
        description: '',
      };
    }
  },
);
</script>
