<template>
  <div class="mb-3 bg-white p-4 sm:rounded sm:p-6 md:shadow-md">
    <h2 class="hidden text-base font-bold sm:inline">
      {{ t('registrationCard.header') }}
    </h2>
    <div class="my-3 hidden h-[1px] w-full bg-[#e9e9e9] sm:block" />
    <div class="flex">
      <div class="mb-2 w-28 text-sm font-bold">
        {{ t('registrationCard.recaMailReqTypeName') }}
      </div>
      <div class="text-left text-sm">
        <div class="flex items-center">
          {{ applicationStore.recaMailReqTypeName }}
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="mb-2 w-28 text-sm font-bold">
        {{ t('registrationCard.registrationCard') }}
      </div>
      <div class="text-left text-sm">
        <div
          v-if="applicationStore.isPdfDownload"
          class="flex items-center"
        >
          <span class="material-symbols-outlined mr-1">receipt_long</span>
          <span class="underline"><a
            :href="applicationStore.pdfFileUrl"
            target="_blank"
          >{{
            t('registrationCard.download')
          }}</a></span>
        </div>
        <div v-else>
          -
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="mb-2 w-28 text-sm font-bold">
        {{ t('registrationCard.address') }}
      </div>
      <div
        v-if="mailingAddressStore.id !== null"
        class="text-left text-sm"
      >
        {{ `〒${mailingAddressStore.zipcode}` }}
        {{ `${mailingAddressStore.prefecture}${mailingAddressStore.city}` }}
        <br>
        {{ mailingAddressStore.street }}
      </div>
      <div v-else>
        -
      </div>
    </div>
    <div class="flex">
      <div class="mb-2 w-28 text-sm font-bold">
        {{ t('registrationCard.mailStatus') }}
      </div>
      <div class="text-left text-sm">
        {{ blankToHyphen(applicationStore.mailStatus) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { blankToHyphen } from '@/lib/util/stringUtils';
import { useApplicationStore } from '@/composables/useApplicationStore';
import { useMailingAddressStore } from '@/composables/useMailingAddressStore';
import { useI18n } from 'vue-i18n';
import { ja } from '@/i18n/pages/app/applications/ja';

const applicationStore = useApplicationStore();
const mailingAddressStore = useMailingAddressStore();
const { t } = useI18n({ messages: ja });
</script>
