<template>
  <router-view />
</template>

<script setup lang="ts">
import { inject, provide } from 'vue';
import {
  APIClientKey,
  TrademarkSearchStoreKey,
  KlassSelectStoreKey,
} from '@/composables/injectionKeys';
import { useTrademarkSearch } from '@/composables/useTrademarkSearch';
import { useKlassSelect } from '@/composables/useKlassSelect';
import { APIClientInterface } from '@/api/APIClientInterface';

const apiClient = inject(APIClientKey) as APIClientInterface;
const klassSelectStore = useKlassSelect();
const trademarkSearchStore = useTrademarkSearch(
  apiClient,
  klassSelectStore.klassesForSearch,
  klassSelectStore.klassesForSearchGoodsServiceIds,
);
provide(KlassSelectStoreKey, klassSelectStore);
provide(TrademarkSearchStoreKey, trademarkSearchStore);
</script>
