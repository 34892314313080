<template>
  <div>
    <div
      class="text-grayAlpha80 mb-6 text-center text-sm font-bold tracking-wider sm:text-xl"
    >
      商標登録・無料相談には会員登録が必要です
    </div>
    <!-- 入力フォーム -->
    <div
      class="mb-6 box-border rounded-2xl bg-white px-4 py-4 sm:mx-auto sm:w-[640px] sm:px-8 sm:py-6"
    >
      <div class="text-grayAlpha80 mb-4 text-2xl font-bold tracking-wider">
        無料会員登録
      </div>
      <!-- メールアドレス入力欄 -->
      <FormTextInput
        v-model="state.email"
        name="email"
        type="email"
        placeholder="example@cotobox.com"
        autocomplete="email"
        :title="t('email')"
        :shows-required-label="false"
        :required="true"
        :shows-error="state.showsEmailError"
        :error-message="state.showsEmailError ? t('emailError') : ''"
        @blur="validate"
      />
      <!-- 次へ -->
      <PrimaryButton
        :title="t('next')"
        class="mt-2 w-full"
        :disabled="disabled"
        @click="next"
      />
      <div class="bg-borderGray mb-4 mt-4 h-[1px] w-full" />
      <!-- すでにアカウントをお持ちの方 -->
      <a href="/app/login">
        <SecondaryButton
          :title="t('alreadyHaveAccount')"
          class="w-full"
        />
      </a>
    </div>
    <!-- 会員登録のメリット・出願までの流れ -->
    <div
      class="box-border flex flex-col gap-y-6 rounded-2xl bg-white px-4 py-4 data-[mobile=true]:flex-col-reverse sm:mx-auto sm:w-[640px] sm:px-8 sm:py-6"
      :data-mobile="$device.isMobile"
    >
      <!-- 出願までの流れ -->
      <div>
        <div class="mb-2 font-bold tracking-wider">
          出願までの流れ
        </div>
        <!-- PC -->
        <template v-if="$device.isDesktop">
          <div class="flex items-center">
            <div
              v-for="(item, index) in flowDescriptions"
              :key="index"
              class="flex items-center"
            >
              <div
                class="bg-bgGray border-bgGray data-[border=true]:border-grayAlpha80 data-[white=true]:border-borderGray rounded border-2 border-solid p-2 text-sm font-bold data-[white=true]:bg-white"
                :data-border="index === 0"
                :data-white="index === flowDescriptions.length - 1"
              >
                {{ item }}
              </div>
              <div
                v-if="index < flowDescriptions.length - 1"
                class="material-icons"
              >
                chevron_right
              </div>
            </div>
          </div>
        </template>
        <!-- スマホ -->
        <template v-else>
          <div>
            <div
              v-for="(item, index) in flowDescriptions"
              :key="index"
              class="flex flex-col items-center"
            >
              <div
                class="bg-bgGray border-bgGray data-[border=true]:border-grayAlpha80 data-[white=true]:border-borderGray w-full rounded border-2 border-solid p-2 text-center text-sm font-bold data-[white=true]:bg-white"
                :data-border="index === 0"
                :data-white="index === flowDescriptions.length - 1"
              >
                {{ item }}
              </div>
              <div
                v-if="index < flowDescriptions.length - 1"
                class="material-icons text-borderGray"
              >
                keyboard_arrow_down
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- 会員登録のメリット -->
      <div>
        <div class="mb-2 font-bold tracking-wider">
          会員登録のメリット
        </div>
        <div class="bg-primaryOrange5 grid gap-y-1 rounded p-4">
          <CheckListItem
            v-for="text in [
              '見積書がダウンロードできる',
              '検討中の商標案を保存して一覧管理できる',
              '商標コーディネーターとの相談を優先受付',
            ]"
            :key="text"
            :text="text"
            :color="'primary'"
            style="align-items: start !important"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
// 状態管理
import { SignUpKey } from '@/composables/injectionKeys';
import { SignUpStore } from '@/composables/useSignUp';
// ライブラリ
import { useToast } from 'vue-toastification';
import { COOKIE_NAME } from '@/lib/util';
import { setCookie } from '@/lib/util/cookie';
import CheckListItem from '@/features/top/components/CheckListItem.vue';
import { useHead } from '@vueuse/head';

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});

const { t } = useI18n({
  locale: 'ja',
  messages: {
    ja: {
      title: 'メールアドレスを入力してください。',
      email: 'メールアドレス',
      emailError: 'メールアドレスの形式が間違っています。',
      accountExistsError: 'すでに登録されているメールアドレスです。',
      mailFailError:
        '認証メールを送信できませんでした。正しいメールアドレスであることをご確認ください。',
      next: '次へ進む',
      alreadyHaveAccount: 'すでにアカウントをお持ちの方はこちら',
    },
    en: {
      title: 'Enter your email.',
      email: 'Email',
      emailError: 'Please enter a valid email address.',
      accountExistsError: 'The email has already been registered.',
      mailFailError:
        'The authentication email could not be sent.\nPlease make sure that you have the correct email address.',
      next: 'Next',
      alreadyHaveAccount: 'Already have an account',
    },
    zh: {
      title: '请输入你的电子邮件地址。',
      email: '电子邮件地址',
      emailError: '请输入一个有效的电子邮件地址。',
      accountExistsError: '您的电子邮件地址已被注册',
      mailFailError: '我们无法发送验证邮件。请确保你有正确的电子邮件地址。',
      next: '转到下一个',
      alreadyHaveAccount: '已经有了一个账户？',
    },
  },
});

const {
  state,
  validateEmail: validate,
  mailSendDisabled: disabled,
  sendConfirmationMail,
} = inject(SignUpKey) as SignUpStore;

const toast = useToast();
const route = useRoute();
const router = useRouter();

async function next () {
  const error = await sendConfirmationMail();
  if (error === 'already-exists') {
    return toast.error(t('accountExistsError'));
  } else if (error) {
    return toast.error(t('mailFailError'));
  }

  // 認証コード成功時のみCookieに保存
  setCookie({
    cookieName: COOKIE_NAME.SIGN_UP_EMAIL,
    value: state.email,
    maxAge: 900,
  });

  router.push({
    name: 'SignUpCodePage',
    query: {
      ...route.query,
    },
  });
}

const flowDescriptions = [
  '会員登録',
  '商標検索・申込',
  '弁理士に相談',
  '特許庁に出願',
  '登録 🎉',
];
</script>
