<template>
  <div>
    <FormTextInput
      class="mb-4"
      :title="t('password')"
      type="password"
      name="password"
      autocomplete="off"
      :placeholder="t('placeholders.password')"
      :shows-error="showsError"
      :model-value="modelValue"
      @update:model-value="setPassword"
    />
    <div class="text-sm">
      {{ t('passwordStrength') }}
    </div>
    <PasswordStrengthMeter
      class="mx-0 mb-5 mt-2 max-w-none"
      :password="modelValue"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import PasswordStrengthMeter from '@/components/signup/PasswordStrengthMeter.vue';

defineProps<{
  modelValue: string;
  showsError: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:model-value', value: string): void;
}>();

function setPassword (value: string) {
  emit('update:model-value', value);
}

const { t } = useI18n({
  messages: {
    ja: {
      password: 'パスワード',
      passwordStrength: 'パスワード強度',
      placeholders: {
        password: '8文字以上で、大文字と小文字をそれぞれ1文字以上含めて入力してください。',
      },
    },
    en: {
      password: 'Password',
      passwordStrength: 'Password Strength',
      placeholders: {
        password: 'at least 8 characters, including at least one uppercase letter and one lowercase letter',
      },
    },
    zh: {
      password: '密码',
      passwordStrength: '密码强度',
      placeholders: {
        password: '请输入至少8个字符的密码，并且至少包含一个大写字母和一个小写字母',
      },
    },
  },
});
</script>
