<template>
  <span
    class="material-symbols-outlined mr-1 !text-2xl"
    :class="statusIcon.color"
  >
    {{ statusIcon.status }}
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  status: { type: String, default: '' },
});

// initial: 決済待ち
// success: 決済済み
// failure: 決済失敗
// pending: 検討中
// authorized: 承認済
// refund: 返金
// making: ユーザーへの公開前 ※ユーザー表示なし
// rewash_success: 洗替
// partial_refund: 一部返金
// prepare: 準備中 ※ユーザー表示なし
// waiting_bank_transfer: 銀行振込待ち
// refund_account_input: 返金口座入力待ち
// void_invoice: 無効の請求書
const statusIcon = computed(() => {
  if (props.status === 'initial') {
    return { status: 'check_circle', color: 'text-red100' };
  }
  if (props.status === 'success' || props.status === 'rewash_success') {
    return { status: 'check_circle', color: 'text-green100' };
  }
  if (props.status === 'failure') {
    return { status: 'priority_high', color: 'text-red100' };
  }
  if (props.status === 'refund' || props.status === 'partial_refund') {
    return { status: 'undo', color: 'text-green100' };
  }
  if (props.status === 'waiting_bank_transfer') {
    return { status: 'hourglass_top', color: 'text-red100' };
  }
  if (props.status === 'refund_account_input') {
    return { status: 'pending', color: 'text-red100' };
  }
  if (props.status === 'void_invoice') {
    return { status: 'block', color: 'text-grayAlpha60' };
  }
  return { status: 'pending', color: 'text-textBlack' };
});
</script>
