<template>
  <div
    v-if="isKlassesEmpty"
    class="px-4 pb-10 pt-6 sm:mx-auto sm:w-[480px] sm:pb-20 sm:pt-10"
  >
    <div class="mb-6 text-sm tracking-wider">
      申込ページの有効期限が切れました。<br>
      大変恐れ入りますが、もう一度商標の検索からやり直していただきますようお願いいたします。
    </div>
    <PrimaryButton
      title="検索画面へ戻る"
      class="w-full"
      @click="backToSearchPage"
    />
  </div>
  <Original v-else />
</template>

<script setup lang="ts">
import { provide, inject, onMounted, computed } from 'vue';
import {
  APIClientKey,
  ApplyFormKey,
  KlassSelectStoreKey,
  TrademarkSearchStoreKey,
} from '@/composables/injectionKeys';
import { useApplyForm } from '@/composables/useApplyForm';
import { APIClientInterface } from '@/api/APIClientInterface';
import Original from '@/components/originalPattern/regist_trademark.vue';
import { KlassSelectStore } from '@/composables/useKlassSelect';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import { useRoute, useRouter } from 'vue-router';

const apiClient = inject(APIClientKey) as APIClientInterface;

const { klassesForSubmit } = inject(KlassSelectStoreKey) as KlassSelectStore;

const {
  trademarkType,
  standardCharacter,
  inputtedYomi,
  imageDataURL,
  imageToken,
  existsText,
  imageText,
  imageYomi,
} = inject(TrademarkSearchStoreKey) as TrademarkSearchStore;

const applyForm = useApplyForm({
  trademarkType: trademarkType.value,
  ...(trademarkType.value === 'text' && {
    standardCharacter: standardCharacter.value,
  }),
  ...(trademarkType.value === 'image' &&
    existsText.value && { imageText: imageText.value }),
  inputtedYomi:
    trademarkType.value === 'text' ? inputtedYomi.value : imageYomi.value,
  ...(trademarkType.value === 'image' && { imageDataURL: imageDataURL.value }),
  ...(trademarkType.value === 'image' && { imageToken: imageToken.value }),
  klassesForSubmit: klassesForSubmit.value,
  apiClient,
});

provide(ApplyFormKey, applyForm);

const isKlassesEmpty = computed(
  () => Object.keys(klassesForSubmit.value).length === 0,
);

onMounted(() => {
  // 申込画面でリロードされ、選択中の区分などの状態がクリアされてしまった場合、検索からやり直してもらうようユーザーに促す
  if (isKlassesEmpty.value) {
    // 検索画面へのリンク付きのエラー画面を表示
  }
});

const route = useRoute();
const router = useRouter();

function backToSearchPage () {
  router.push({
    name: 'SearchPage',
    query: route.query,
  });
}
</script>
