<template>
  <div>
    <div class="items-start sm:flex">
      <!-- 入力フォーム -->
      <div class="sm:flex-1">
        <!-- 商標情報入力 -->
        <SectionBlock
          title="商標情報の入力"
          :number="1"
          :hides-devider="true"
          class="mb-8"
        >
          <template #header>
            <HelpLink
              title="商標とは"
              @click="showsHelpTrademark = true"
            />
          </template>
          <template #default>
            <Tabs
              class="border-borderGray -ml-6 mt-4 w-[calc(100%_+_48px)] border-b border-solid pl-6"
              :model-value="isImageSearch"
              :options="['文字商標', 'ロゴ商標']"
              @update:model-value="onTrademarkTypeChange"
            />
            <div class="mt-6">
              <TextTrademarkForm
                v-if="trademarkType === 'text'"
                :shows-help-pronunciation="showsHelpPronunciation"
                @update:shows-help-pronunciation="updateShowsHelpPronunciation"
              />
              <ImageTrademarkForm
                v-if="trademarkType === 'image'"
                :shows-help-pronunciation="showsHelpPronunciation"
                @update:shows-help-pronunciation="updateShowsHelpPronunciation"
              />
            </div>
          </template>
        </SectionBlock>
        <!-- 区分選択 -->
        <SectionBlock
          title="区分の選択"
          subtitle="(まずは、AI絞り込みで検索)"
          :number="2"
          :hides-devider="true"
          class="pb-10"
        >
          <template #header>
            <div class="hidden sm:block">
              <HelpLink
                title="区分とは？区分に悩んだらコチラ"
                @click="showsHelpKlass = true"
              />
            </div>
          </template>
          <template #default>
            <template v-if="$device.isDesktop">
              <Tabs
                class="border-borderGray -ml-6 mt-4 w-[calc(100%_+_48px)] border-b border-solid pl-6"
                :model-value="isAIFilter"
                :options="['全て', 'AI絞り込み']"
                @update:model-value="onKlassSelectTypeChange"
              />
              <KlassSelectAll v-if="isAIFilter === 0" />
              <KlassSelectAISearch
                v-if="isAIFilter === 1"
                class="mt-6"
              />
            </template>
            <template v-if="$device.isMobile">
              <div class="mt-6">
                <ul
                  v-if="klassIdsForSearch.length"
                  class="mb-4 list-disc pl-4"
                >
                  <li
                    v-for="(text, index) in selectedKlasses"
                    :key="index"
                    class="text-grayAlpha60 mb-1 text-sm"
                  >
                    {{ text }}
                  </li>
                </ul>
                <button
                  class="text-primary border-primary w-full rounded border border-solid py-4 text-center text-sm font-bold"
                  @click="showsMobileKlassSelect = true"
                >
                  {{ klassIdsForSearch.length ? '変更' : '選択する' }}
                </button>
              </div>
            </template>
          </template>
        </SectionBlock>
      </div>
      <!-- 次へ進む -->
      <SectionBlock
        class="mt-8 shadow-md sm:sticky sm:top-4 sm:ml-8 sm:mt-0 sm:w-[478px]"
        title="商標情報の確認"
        :number="3"
        :hides-devider="true"
      >
        <div class="text-grayAlpha60 mb-8 mt-4 text-sm">
          次の画面で類似商標の有無を確認できます。
        </div>
        <PrimaryButton
          class="w-full"
          title="次へ進む"
          sub-title="商標を入力し、区分を選択した上で"
          :disabled="disabled"
          @click="onSearchButtonClick"
        />
      </SectionBlock>
    </div>
    <!-- モバイルのみ: 区分選択モーダル -->
    <ModalMobileFullScreen
      :is-visible="showsMobileKlassSelect && $device.isMobile"
      @close="showsMobileKlassSelect = false"
    >
      <div class="flex w-full justify-center py-6">
        <button
          class="material-icons material-symbols-outlined absolute left-4"
          @close="showsMobileKlassSelect = false"
        >
          arrow_back_ios
        </button>
        <div
          class="text-grayAlpha80 text-center text-base font-semibold tracking-wider"
        >
          区分を選択してください
        </div>
      </div>
      <MobileKlassSelect
        class="h-screen w-screen"
        @close="showsMobileKlassSelect = false"
      />
    </ModalMobileFullScreen>
    <!-- ヘルプモーダル: 商標とは -->
    <Modal
      :is-visible="showsHelpTrademark"
      @close="showsHelpTrademark = false"
    >
      <div class="px-6 pb-8 pt-6">
        <div
          class="border-borderGray border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
        >
          商標とは
        </div>
        <HelpContentTrademark />
      </div>
    </Modal>
    <!-- ヘルプモーダル: よみがなとは -->
    <Modal
      :is-visible="showsHelpPronunciation"
      @close="updateShowsHelpPronunciation(false)"
    >
      <div class="p-6">
        <div
          class="border-borderGray border-b border-solid font-semibold tracking-wider"
        >
          <div class="flex gap-x-2">
            <span class="material-icons text-primary"> warning </span>
            Cotobox検索ページの「よみがな」や「ロゴ内の文字」欄は、検索補助のためにご入力いただいています。
          </div>
        </div>
        <HelpContentPronunciation />
      </div>
    </Modal>
    <!-- ヘルプモーダル: 区分とは -->
    <Modal
      :is-visible="showsHelpKlass"
      @close="showsHelpKlass = false"
    >
      <div class="px-6 pb-8 pt-6">
        <div
          class="border-borderGray border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
        >
          区分とは／どの区分かわからないとき
        </div>
        <HelpContentKlass />
      </div>
    </Modal>
    <!-- 読み込み中 -->
    <TheOverlayLoading
      v-if="processing"
      message="類似商標を検索しています。少々お待ちください。"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// 状態管理
import {
  TrademarkSearchStoreKey,
  KlassSelectStoreKey,
} from '@/composables/injectionKeys';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
import { KlassSelectStore } from '@/composables/useKlassSelect';
// コンポーネント
import TextTrademarkForm from '@/components/search/TextTrademarkForm.vue';
import ImageTrademarkForm from '@/components/search/ImageTrademarkForm.vue';
import KlassSelectAll from '@/components/search/KlassSelectAll.vue';
import KlassSelectAISearch from '@/components/search/KlassSelectAISearch.vue';
import HelpLink from '@/components/common/HelpLink.vue';
import HelpContentTrademark from '@/components/search/HelpContentTrademark.vue';
import HelpContentKlass from '@/components/search/HelpContentKlass.vue';
import TheOverlayLoading from '@/components/common/TheOverlayLoading.vue';
import ModalMobileFullScreen from '@/components/common/ModalMobileFullScreen.vue';
import MobileKlassSelect from '@/components/search/MobileKlassSelect.vue';
// ライブラリ
import { useToast } from 'vue-toastification';
import HelpContentPronunciation from '@/components/search/HelpContentPronunciation.vue';

const route = useRoute();
const router = useRouter();
const toast = useToast();
const showsHelpTrademark = ref(false);
const showsHelpKlass = ref(false);
const showsMobileKlassSelect = ref(false);
const showsHelpPronunciation = ref(false);

const {
  trademarkType,
  setTrademarkType,
  standardCharacter,
  imageDataURL,
  imageToken,
  existsText,
  imageText,
  queryParameter,
  executeTrademarkSearch,
} = inject(TrademarkSearchStoreKey) as TrademarkSearchStore;

const { klassesForSearch, klassIdsForSearch, klassesForSearchHash } = inject(
  KlassSelectStoreKey,
) as KlassSelectStore;

const selectedKlasses = computed(() => {
  return klassIdsForSearch.value.map((klassId) => {
    const goodsServiceIds = klassesForSearch.value[klassId]!;
    return `第${klassId}類 ${goodsServiceIds.join('、')}`;
  });
});

const isImageSearch = computed(() => (trademarkType.value === 'text' ? 0 : 1));

// 文字商標とロゴ商標切り替え時
function onTrademarkTypeChange (index: number) {
  if (index === 0) {
    setTrademarkType('text');
  } else if (index === 1) {
    setTrademarkType('image');
  }
}

const isAIFilter = ref(0);

// 区分選択を、すべてとAI絞り込み切り替え時
function onKlassSelectTypeChange (index: number) {
  isAIFilter.value = index;
}

const updateShowsHelpPronunciation = (newValue) => {
  showsHelpPronunciation.value = newValue;
};

const processing = ref(false);

// 次へ進むボタン押下時
async function onSearchButtonClick () {
  // 検索実行
  processing.value = true;

  await executeTrademarkSearch().catch((e) => {
    // 使用不可の文字などのエラー
    processing.value = false;
    throw toast.error(e.message);
  });

  processing.value = false;

  // 検索条件をパラメータにセットすることで結果画面へ遷移
  router.push({
    path: route.path,
    query: {
      ...queryParameter.value,
      ...(klassesForSearchHash.value && {
        selected_simgroup_ids: klassesForSearchHash.value,
      }),
    },
  });
}

const disabled = computed(() => {
  const noKlassesSelected = !Object.keys(klassesForSearch.value).length;

  if (noKlassesSelected) {
    return true;
  }

  if (trademarkType.value === 'text') {
    return textTrademarkDisabled.value;
  } else if (trademarkType.value === 'image') {
    return imageTrademarkDisabled.value;
  }

  return false;
});

const textTrademarkDisabled = computed(() => {
  if (!standardCharacter.value) {
    return true;
  }

  return false;
});

const imageTrademarkDisabled = computed(() => {
  if (!imageDataURL.value) {
    return true;
  }

  if (imageDataURL.value && !imageToken.value) {
    return true;
  }

  if (existsText.value && !imageText.value) {
    return true;
  }

  return false;
});
</script>
