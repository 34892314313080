export const en = {
  en: {
    amountTitle: 'Payment amount',
    amountValue: '¥{0}',
    cardNumber: 'CARD NUMBER',
    expires: 'EXPERATION DATE',
    cvc: 'CVC',
    card: 'Credit Cards',
    bankTransfer: 'Bank transfer',
    submit: 'PAY NOW',
    submitBank: 'Pay by invoice (bank transfer)',
    description:
      '・クレジットカードでのお支払いはStripe社のシステムを利用しております。\n・ご登録いただいたクレジットカード情報はStripe社にて管理され当社では情報を保持しないため安心してご利用いただけます。',
    last4Digits: 'last 4 digit',
    payWithAnotherCard: 'Pay with other card',
    bank_description: {
      line1: '<b>Transfer fees</b> are the responsibility of the customer.',
      line2: 'Please transfer the money within 7 days.',
      line3:
        'Please check the body of the e-mail sent after your application for the account number of the bank account to which the money will be transferred.',
    },
    errors: {
      notFoundStripeAccount:
        'Failed to get Stripe account: Please reload and try again. {0}',
      pleaseReflesh:
        'Please reload the screen and re-enter the information. {0}',
      pleaseRefleshAndTurnOffAd:
        'Please reload the screen and re-enter the information.\n' +
        'Please turn off ad-blocking plug-ins. {0}',
      apiConnectionError:
        'Could not connect to the payment system. Please make sure you are connected to the network. {0}',
      apiError:
        'There is a temporary failure in the payment system. We apologize for the inconvenience, but please wait some time and make payment again. {0}',
      cardError: 'Payment failed. Please check that your card is valid. {0}',
      rateLimitError:
        'An error occurred because multiple payments were requested at one time. Please wait a few minutes and try again. {0}',
      validationError:
        'Please verify that the card information you entered is correct. {0}',
      other:
        'An error occurred in the payment. Please contact us via chat. {0}',
    },
    processing: 'Payment is being processed. Please do not leave this page.',
    complete: 'Payment has been completed.',
    completeBank:
      'We have sent you an email with our bank transfer account. please transfer the money within 7 days.',
  },
};
