<template>
  <ul class="bg-white p-2">
    <NotificationListItem
      v-for="(item, index) in notificationsStore.notifications"
      :key="item.id"
      :item="item"
      :data-is-first="index === 0"
      :data-is-last="index === notificationsStore.notifications.length - 1"
      class="border-borderGray cursor-pointer border-b border-solid data-[is-last=true]:border-b-0 data-[is-first=false]:pt-4"
      @click="$router.push(`/app/notifications/${item.id}`)"
    />
  </ul>
</template>

<script setup lang="ts">
import NotificationListItem from './NotificationListItem.vue';
import { useNotificationsStore } from '../stores/notifications.store';
import { inject, onMounted } from 'vue';
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';

const { state: userState, loadUser } = inject(UserDataKey) as UserDataStore;

// お知らせ状態管理を初期化
const notificationsStore = useNotificationsStore();
// お知らせ状態管理に、弁理士事務所情報を渡す
notificationsStore.attorneyOfficeId = userState.attorneyOffice
  ? userState.attorneyOffice.id
  : null;

onMounted(async () => {
  await loadUser();
  notificationsStore.attorneyOfficeId = userState.attorneyOffice
    ? userState.attorneyOffice.id
    : null;
});
</script>
