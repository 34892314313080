export class HTTPError extends Error {
  status?: number;
  constructor (status?: number, message?: string) {
    super(message);
    this.name = 'HTTPError';
    this.status = status;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }
  }
}

export class UnauthorizedError extends Error {
  constructor (message?: string) {
    super(message);
    this.name = 'UnauthorizedError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnauthorizedError);
    }
  }
}

export class NotFoundError extends Error {
  constructor (message?: string) {
    super(message);
    this.name = 'NotFoundError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError);
    }
  }
}

export class PermissionDeniedError extends Error {
  constructor (message?: string) {
    super(message);
    this.name = 'PermissionDeniedError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PermissionDeniedError);
    }
  }
}

export class AlreadyExistsError extends Error {
  constructor (message?: string) {
    super(message);
    this.name = 'AlreadyExistsError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AlreadyExistsError);
    }
  }
}

export class SendEmailError extends Error {
  constructor (message?: string) {
    super(message);
    this.name = 'SendEmailError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SendEmailError);
    }
  }
}

export class TokenWrongError extends Error {
  constructor (message?: string) {
    super(message);
    this.name = 'TokenWrongError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenWrongError);
    }
  }
}

export class ExpiredError extends Error {
  constructor (message?: string) {
    super(message);
    this.name = 'ExpiredError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ExpiredError);
    }
  }
}

export class UnknownError extends Error {
  constructor (message?: string) {
    super(message);
    this.name = 'UnknownError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnknownError);
    }
  }
}

export class StripePaymentError extends Error {
  constructor (message?: string) {
    super(message);
    this.name = 'StripePaymentError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnknownError);
    }
  }
}
