import { Maybe } from '../types';

export function setCookie (props: {
  cookieName: COOKIE_NAME;
  value: string;
  maxAge: number;
}) {
  const { cookieName, value, maxAge } = props;
  const cookieValue = encodeURIComponent(value);
  window.document.cookie = `${cookieName}=${cookieValue}; max-age=${maxAge}; path=/;`;
}

export function getCookieValue (cookieName: COOKIE_NAME): Maybe<string> {
  const cookieRow = window.document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`));
  if (!cookieRow) return null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, ...value] = cookieRow.split('=');
  const decodedValue = decodeURIComponent(value.join('='));

  return decodedValue;
}

export function deleteCookie (cookieName: COOKIE_NAME) {
  window.document.cookie = `${cookieName}=; max-age=0`;
}

export enum COOKIE_NAME {
  SIGN_UP_EMAIL = 'sign_up_email',
  SIGN_UP_CONFIRMATION_TOKEN = 'sign_up_confirmation_token',
  SIGN_UP_COMPANY_NAME = 'sign_up_company_name',
  SIGN_UP_IS_PERSONAL = 'sign_up_is_personal',
  SIGN_UP_LAST_NAME = 'sign_up_last_name',
  SIGN_UP_LAST_NAME_KANA = 'sign_up_last_name_kana',
  SIGN_UP_FIRST_NAME = 'sign_up_first_name',
  SIGN_UP_FIRST_NAME_KANA = 'sign_up_first_name_kana',
  SIGN_UP_INDUSTRY = 'sign_up_industry',
  SIGN_UP_PHONE_NUMBER = 'sign_up_phone_number',
  SIGN_UP_LANGUAGE = 'sign_up_language',
  ALREADY_SENT_SEARCH_RESULT_URLS = 'already_sent_search_result_urls',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_TERM = 'utm_term',
  UTM_CONTENT = 'utm_content',
}
