<template>
  <!-- 概要 -->
  <div class="mb-3 bg-white p-6 shadow-md sm:rounded">
    <!-- 商標情報 -->
    <h2 class="text-base font-bold">
      {{ t('applicationInfo.header') }}
    </h2>
    <div class="my-3 h-[1px] w-full bg-[#e9e9e9]" />
    <div class="flex">
      <div class="mb-2 w-24 text-sm font-bold">
        {{ t('applicationInfo.standardCharacter') /* 商標 */ }}
      </div>
      <div
        v-if="applicationStore.trademarkType === 'text_type'"
        class="text-left text-sm"
      >
        {{ applicationStore.standardCharacter }}
      </div>
      <div
        v-if="applicationStore.trademarkType === 'image_type'"
        class="mb-2 h-[80px]"
      >
        <img
          class="border-grayAlpha20 h-[80px] w-[80px] cursor-pointer border border-solid object-contain shadow-md hover:shadow-xl"
          :src="applicationStore.imageUrl"
          @click="openImage(applicationStore.imageUrl)"
        >
      </div>
    </div>
    <div class="flex">
      <div class="mb-2 w-24 text-sm font-bold">
        {{ t('applicationInfo.statusName') /* ステータス */ }}
      </div>
      <div class="text-left text-sm">
        {{ applicationStore.statusName }}
      </div>
    </div>
    <div class="flex">
      <div class="mb-2 w-24 text-sm font-bold">
        {{ t('applicationInfo.referenceNumber') /* 整理番号 */ }}
      </div>
      <div class="text-left text-sm">
        {{ applicationStore.referenceNumber }}
      </div>
    </div>
    <div class="mb-4 flex">
      <!-- 特許事務所 -->
      <div class="w-24 text-sm font-bold">
        {{ t('applicationInfo.attorneyOfficeName') }}
      </div>
      <div class="text-left text-sm">
        {{ blankToHyphen(applicationStore.attorneyOfficeName) }}
      </div>
    </div>
    <SecondaryButton
      class="text-grayAlpha60 h-10 w-full"
      title="詳細を見る"
      @click="isTmModal = !isTmModal"
    />
  </div>
  <!-- ./概要 -->

  <!-- 詳細Modal -->
  <Modal
    :is-visible="isTmModal"
    @close="isTmModal = !isTmModal"
  >
    <ApplicationInfoModal />
  </Modal>
  <!-- ./詳細Modal -->
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useApplicationStore } from '@/composables/useApplicationStore';
import { blankToHyphen } from '@/lib/util/stringUtils';
import ApplicationInfoModal from './ApplicationInfoModal.vue';
import { useI18n } from 'vue-i18n';
import { ja } from '@/i18n/pages/app/applications/ja';

const applicationStore = useApplicationStore();
const isTmModal = ref(false);
const { t } = useI18n({ messages: ja });

const openImage = (url: string) => {
  window.open(url);
};
</script>
