<template>
  <div class="bg-bgGray">
    <div
      class="px-4 pb-6 pt-6 sm:mx-auto sm:w-[1191px] sm:px-0 sm:pb-20 sm:pt-10"
    >
      <Breadcrumb
        class="mb-4 px-4 sm:px-6"
        :items="[{ title: 'Cotobox商標検索', routeName: 'SearchPage' }]"
      />
      <TimelineStepperSection />
      <!-- 検索入力画面 -->
      <InputPageContent
        v-if="!shouldShowResultPage"
        class="mt-8"
      />
      <div
        v-else
        class="mt-8"
      >
        <!-- 戻る -->
        <div class="mb-8 flex justify-start">
          <router-link to="/app/search/">
            <div class="text-grayAlpha60 flex items-center">
              <div class="material-icons material-symbols-outlined">
                arrow_back_ios
              </div>
              <div>検索条件を変更する</div>
            </div>
          </router-link>
        </div>
        <!-- 検索結果画面 -->
        <ResultPageContent />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onMounted } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
// 状態管理
import {
  KlassSelectStoreKey,
  TrademarkSearchStoreKey,
} from '@/composables/injectionKeys';
import { KlassSelectStore } from '@/composables/useKlassSelect';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
// 型定義
import { KlassId } from '@/types';
import { goodsServices } from '@/constants/goodsServices';
// コンポーネント
import TimelineStepperSection from '@/components/search/TimelineStepperSection.vue';
import InputPageContent from '@/components/search/InputPageContent.vue';
import ResultPageContent from '@/components/search/ResultPageContent.vue';
// ライブラリ
import { useHead } from '@vueuse/head';

// headタグのメタ情報設定
useHead({
  title: '商標検索 | オンライン商標登録サービスのCotobox（コトボックス）',
  link: [{ rel: 'canonical', href: 'https://cotobox.com/app/search/' }],
  script: [
    {
      type: 'application/ld+json',
      children: `
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Cotobox",
              "item": "https://cotobox.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "商標検索",
              "item": "https://cotobox.com/app/search/"
            }
          ]
        }
      `,
    },
  ],
});

const route = useRoute();

const {
  trademarkType,
  setTrademarkType,
  setTrademarkText,
  setInputtedYomi,
  setImageYomi,
  loadImageDataURL,
  setExistsText,
  progress,
  executeTrademarkSearch,
} = inject(TrademarkSearchStoreKey) as TrademarkSearchStore;

const { selectForSearch, selectForSubmit } = inject(
  KlassSelectStoreKey,
) as KlassSelectStore;

onMounted(async () => {
  restoreStateFromURLQuery();
  executeSearchIfNeeded();
});

/** URLにパラメータが含まれている場合、検索入力画面ではなく検索結果画面を表示する */
const shouldShowResultPage = computed(() => {
  const existsStandardCharacter = 'standard_character' in route.query;
  const existsYomi = 'yomi' in route.query;
  const existsSelectedSimgroupIds = 'selected_simgroup_ids' in route.query;
  const existsImageToken = 'image_token' in route.query;

  if (trademarkType.value === 'text') {
    return existsStandardCharacter && existsYomi && existsSelectedSimgroupIds;
  } else if (trademarkType.value === 'image') {
    return existsImageToken && existsSelectedSimgroupIds;
  }

  return false;
});

/** URLパラメータから商標やよみなどの入力内容を復元する */
function restoreStateFromURLQuery () {
  if (route.query.image_token) {
    setTrademarkType('image');
    loadImageDataURL(route.query.image_token.toString());
    setExistsText(!!route.query.standard_character);
  }

  if (route.query.standard_character) {
    setTrademarkText(route.query.standard_character.toString());
  }

  if (route.query.yomi) {
    if (route.query.image_token) {
      setImageYomi(route.query.yomi.toString());
    } else {
      setInputtedYomi(route.query.yomi.toString());
    }
  }

  if (route.query.selected_simgroup_ids) {
    const codes = (route.query.selected_simgroup_ids as string).match(
      /.{6}/g,
    ) as RegExpMatchArray;
    codes.forEach((code) => {
      const klassId = String(Number(code.slice(0, 2))) as KlassId;
      const goodsServiceIds = Object.keys(goodsServices[klassId]);
      const goodsServiceId = goodsServiceIds.find(
        (goodsServiceId) =>
          goodsServices[klassId][goodsServiceId]?.code === code.slice(2),
      );
      if (goodsServiceId) {
        selectForSearch(klassId, goodsServiceId);
        selectForSubmit(klassId, goodsServiceId);
      }
    });
  }
}

function executeSearchIfNeeded () {
  const shouldExecuteSearch =
    (trademarkType.value === 'text' && route.query.standard_character) ||
    (trademarkType.value === 'image' && route.query.image_token);
  if (shouldExecuteSearch && progress.value !== 'completed') {
    executeTrademarkSearch();
  }
}
</script>
