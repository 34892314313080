<template>
  <div
    class="bg-primaryOrange5 back py-10 sm:py-16"
    style="background: #383d51"
  >
    <div class="px-4 sm:mx-auto sm:w-[1121px] sm:px-0">
      <div class="flex flex-col justify-center sm:mb-14 sm:flex-row sm:gap-x-8">
        <EnterpriseDescriptionCard
          v-for="item in items.slice(0, 2)"
          :key="item.title"
          :item="item"
          class="mb-6 sm:mb-0 sm:w-[365px]"
          style="padding: 20px"
        />
      </div>
      <div class="flex flex-col justify-center sm:mb-14 sm:flex-row sm:gap-x-8">
        <EnterpriseDescriptionCard
          v-for="item in items.slice(2, 4)"
          :key="item.title"
          :item="item"
          class="mb-6 sm:mb-0 sm:w-[365px]"
          style="padding: 20px"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VisualAndDescriptionProps } from './VisualAndDescriptionCard.vue';
import EnterpriseDescriptionCard from './EnterpriseDescriptionCard.vue';
import Img01 from '@/assets/images/mocks/ent-flow-sub-1.png';
import Img02 from '@/assets/images/mocks/ent-flow-sub-3.png';
import Img03 from '@/assets/images/mocks/ent-flow-sub-4.png';
import Img04 from '@/assets/images/mocks/ent-flow-sub-2.png';

const items: VisualAndDescriptionProps[] = [
  {
    src: Img01,
    title: '',
    description: '"商標管理に専任の担当なんて置けないよ"',
  },
  {
    src: Img02,
    title: '',
    description: '"専門性が高く、業務が属人化するのが心配"',
  },
  {
    src: Img03,
    title: '',
    description: '"エクセル管理は運用が面倒だけど、専門ツール導入の予算はない"',
  },
  {
    src: Img04,
    title: '',
    description:
      '"商標権は多く保有しているが、弁理士に丸投げなので、何をしていいかわからない"',
  },
];
</script>
