/* istanbul ignore file */

import { InjectionKey } from 'vue';
import { TrademarkSearchStore } from './useTrademarkSearch';
import { KlassListStore } from './useKlassList';
import { KlassFilterStore } from './useKlassFilter';
import { KlassSelectStore } from './useKlassSelect';
import { ApplyFormStore } from './useApplyForm';
import { PriceCalculatorStore } from './usePriceCalculator';
import { SignUpStore } from './useSignUp';
import { UserDataStore } from './useUserStore';
import { ApplicationListStore } from './useApplicationList';
import { APIClientInterface } from '@/api/APIClientInterface';

export const TrademarkSearchStoreKey: InjectionKey<TrademarkSearchStore> =
  Symbol('TrademarkSearchStore');
export const KlassListStoreKey: InjectionKey<KlassListStore> =
  Symbol('KlassListStore');
export const KlassFilterStoreKey: InjectionKey<KlassFilterStore> =
  Symbol('KlassFilterStore');
export const KlassSelectStoreKey: InjectionKey<KlassSelectStore> =
  Symbol('KlassSelectStore');
export const ApplyFormKey: InjectionKey<ApplyFormStore> =
  Symbol('ApplyFormStore');
export const PriceCalculatorKey: InjectionKey<PriceCalculatorStore> = Symbol(
  'PriceCalculatorStore',
);
export const SignUpKey: InjectionKey<SignUpStore> = Symbol('SignUpStore');
export const UserDataKey: InjectionKey<UserDataStore> = Symbol('UserDataStore');
export const ApplicationListKey: InjectionKey<ApplicationListStore> = Symbol(
  'ApplicationListStore',
);

export const APIClientKey: InjectionKey<APIClientInterface> =
  Symbol('APIClientInterface');
export const IsTestKey: InjectionKey<boolean> = Symbol('IsTest');
