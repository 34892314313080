<script setup lang="ts">
import { computed, inject, onMounted } from 'vue';
import { TrademarkApplication } from '@/api/APIClientInterface';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ApplicationListKey } from '@/composables/injectionKeys';
import {
  ApplicationListStore,
  ApplicationListType,
} from '@/composables/useApplicationList';
import MyTrademarkTableNoContents from '@/components/myTrademark/MyTrademarkTableNoContents.vue';
import MyTrademarkTableRowDesktop from '@/components/myTrademark/MyTrademarkTableRowDesktop.vue';
import MyTrademarkTableRowSavedDesktop from '@/components/myTrademark/MyTrademarkTableRowSavedDesktop.vue';

const route = useRoute();

const { state, selectedList } = inject(
  ApplicationListKey,
) as ApplicationListStore;

const selectedApplications = computed(
  () => selectedList.value as TrademarkApplication[],
);

const { t } = useI18n({
  messages: {
    ja: {
      tabs: {
        application: '出願',
        registered: '権利発生済',
        saved: '検討中',
        cancelled: 'キャンセル・失効',
      },
      trademarkInfo: '商標内容',
      trademark: '商標',
      referenceNumber: '整理番号',
      applicationNumber: '出願番号',
      applicationDate: '出願日',
      registrationNumber: '登録番号',
      registrationDate: '登録日',
      applicationProgress: {
        title: '申請状況',
        step1: '出願準備',
        step2: '出願',
        step3: '特許庁審査',
        step4: '登録',
      },
      registrationProgress: {
        title: '権利状況',
      },
      message: 'メッセージ',
      klass: '区分',
      savedDate: '保存日',
    },
    en: {
      tabs: {
        application: 'Application',
        registered: 'Registered',
        saved: 'Saved',
        cancelled: 'Cancelled',
      },
      trademarkInfo: 'Trademark',
      trademark: 'Trademark',
      referenceNumber: 'Ref Num',
      applicationNumber: 'Application Num',
      applicationDate: 'Application Date',
      registrationNumber: 'Registration Num',
      registrationDate: 'Registration Date',
      applicationProgress: {
        title: 'Progress',
        step1: 'Preparing',
        step2: 'Application',
        step3: 'Examination',
        step4: 'Registration',
      },
      registrationProgress: {
        title: 'Progress',
      },
      message: 'Message',
      klass: 'Class',
      savedDate: 'Saved Date',
    },
    zh: {
      tabs: {
        application: '应用',
        registered: '已经批准',
        saved: '正在审议中',
        cancelled: '取消了',
      },
      trademarkInfo: '商标',
      trademark: '商标',
      referenceNumber: '参考号',
      applicationNumber: '申请号',
      applicationDate: '申报日期',
      registrationNumber: '注册号',
      registrationDate: '注册日期',
      applicationProgress: {
        title: '申请状态',
        step1: '申请的准备',
        step2: '应用',
        step3: '审查',
        step4: '注册',
      },
      registrationProgress: {
        title: '权利状况',
      },
      message: '聊天',
      klass: '分类',
      savedDate: '保存日期',
    },
  },
});

const tabItems: {
  title: string;
  mdiIconName: string;
  tabName: ApplicationListType;
  onSelect: () => void;
  isActive: () => boolean;
}[] = [
  {
    title: t('tabs.application'),
    mdiIconName: 'edit',
    tabName: 'submitted',
    onSelect: () => (state.selectedListType = 'submitted'),
    isActive: () => state.selectedListType === 'submitted',
  },
  {
    title: t('tabs.registered'),
    mdiIconName: 'done',
    tabName: 'accepted',
    onSelect: () => (state.selectedListType = 'accepted'),
    isActive: () => state.selectedListType === 'accepted',
  },
  {
    title: t('tabs.saved'),
    mdiIconName: 'save',
    tabName: 'saved',
    onSelect: () => (state.selectedListType = 'saved'),
    isActive: () => state.selectedListType === 'saved',
  },
  {
    title: t('tabs.cancelled'),
    mdiIconName: 'delete',
    tabName: 'cancelled',
    onSelect: () => (state.selectedListType = 'cancelled'),
    isActive: () => state.selectedListType === 'cancelled',
  },
];

onMounted(() => {
  switch (route.query.status) {
  case 'submitted':
    state.selectedListType = 'submitted';
    break;
  case 'accepted':
    state.selectedListType = 'accepted';
    break;
  case 'cancelled':
    state.selectedListType = 'cancelled';
    break;
  case 'saved':
    state.selectedListType = 'saved';
    break;
  default:
    state.selectedListType = 'submitted';
  }
});
</script>

<template>
  <div class="mx-auto my-0 w-[1078px]">
    <div class="border-b border-solid border-[#ccc]">
      <ul class="flex">
        <li
          v-for="(tabItem, index) in tabItems"
          :key="index"
          class="mr-2 h-10 cursor-pointer border-l border-r border-t border-solid border-[#ccc] last:mr-0"
          @click="tabItem.onSelect"
        >
          <div
            class="px-4"
            :class="
              tabItem.isActive()
                ? 'h-[42px] bg-[#fff] font-bold text-[#222]'
                : 'h-[39px] bg-[#eee] text-[#999]'
            "
          >
            <div
              class="flex items-center whitespace-nowrap pt-3 text-sm tracking-wider"
            >
              <span class="material-icons material-symbols-outlined mr-1">{{
                tabItem.mdiIconName
              }}</span>
              <span>{{ tabItem.title }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="grid gap-x-4 gap-y-2 border-b border-l border-r border-solid border-[#ccc] px-8 pt-3 text-xs tracking-wider"
      style="
        grid-template-columns: 80px 100px 110px 120px 90px 90px 90px 90px 100px;
      "
    >
      <template v-if="state.selectedListType === 'submitted'">
        <div class="col-start-1 col-end-5 row-start-1 row-end-2 font-bold">
          {{ t('trademarkInfo') }}
        </div>
        <div class="col-start-5 col-end-11 row-start-1 row-end-2 font-bold">
          {{ t('applicationProgress.title') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('trademark') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('referenceNumber') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('applicationNumber') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('applicationDate') }}
        </div>
        <div class="row-start-2 row-end-3 w-20">
          {{ t('applicationProgress.step1') }}
        </div>
        <div class="row-start-2 row-end-3 w-20">
          {{ t('applicationProgress.step2') }}
        </div>
        <div class="row-start-2 row-end-3 w-20">
          {{ t('applicationProgress.step3') }}
        </div>
        <div class="row-start-2 row-end-3 w-20">
          {{ t('applicationProgress.step4') }}
        </div>
        <div class="row-start-2 row-end-3 text-center">
          {{ t('message') }}
        </div>
      </template>
      <template v-if="state.selectedListType === 'accepted'">
        <div class="col-start-1 col-end-5 row-start-1 row-end-2 font-bold">
          {{ t('trademarkInfo') }}
        </div>
        <div class="col-start-5 col-end-11 row-start-1 row-end-2 font-bold">
          {{ t('applicationProgress.title') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('trademark') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('referenceNumber') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('registrationNumber') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('registrationDate') }}
        </div>
        <div class="col-start-5 col-end-9 row-start-2 row-end-3">
          {{ t('registrationProgress.title') }}
        </div>
        <div class="row-start-2 row-end-3 text-center">
          {{ t('message') }}
        </div>
      </template>
      <template v-if="state.selectedListType === 'saved'">
        <div class="col-start-1 col-end-5 row-start-1 row-end-2 font-bold">
          {{ t('trademarkInfo') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('trademark') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('klass') }}
        </div>
        <div class="col-start-3 col-end-7 row-start-2 row-end-3">
          {{ t('savedDate') }}
        </div>
      </template>
      <template v-if="state.selectedListType === 'cancelled'">
        <div class="col-start-1 col-end-5 row-start-1 row-end-2 font-bold">
          {{ t('trademarkInfo') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('trademark') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('referenceNumber') }}
        </div>
        <div class="row-start-2 row-end-3">
          {{ t('applicationNumber') }}
        </div>
      </template>
    </div>
    <div>
      <template v-if="state.selectedListType !== 'saved'">
        <template v-if="selectedApplications.length">
          <MyTrademarkTableRowDesktop
            v-for="application in selectedApplications"
            :key="application.id"
            :application="application"
          />
        </template>
        <MyTrademarkTableNoContents v-else />
      </template>
      <template v-else>
        <div
          v-if="state.savedTrademarks.length"
          id="savedList"
        >
          <MyTrademarkTableRowSavedDesktop
            v-for="savedApplication in state.savedTrademarks"
            :key="savedApplication.id"
            :saved-item="savedApplication"
          />
        </div>
        <MyTrademarkTableNoContents v-else />
      </template>
    </div>
  </div>
</template>
