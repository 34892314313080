<template>
  <div class="border-grayAlpha10 rounded border border-solid p-3">
    <div class="border-borderGray mb-4 border-b border-solid pb-4">
      <div class="flex items-center justify-between">
        <div class="text-grayAlpha80 font-bold tracking-wider">
          {{ title }}
        </div>
        <div
          class="text-grayAlpha80 text-right text-xl font-bold tracking-wider"
        >
          {{ totalText }}
        </div>
      </div>
      <div
        v-if="note"
        class="text-grayAlpha80 mt-2 text-sm"
      >
        {{ note }}
      </div>
      <slot name="header" />
    </div>
    <button
      v-if="disableExpands !== true"
      class="flex w-full cursor-pointer items-center justify-between"
      @click="expands = !expands"
    >
      <div class="text-grayAlpha80 text-sm font-bold">
        内訳
      </div>
      <div class="material-icons text-grayAlpha80">
        {{ expands ? 'expand_less' : 'expand_more' }}
      </div>
    </button>
    <div
      v-if="expands || disableExpands"
      class="mt-3"
    >
      <PriceGroupDetailRow
        v-for="item in items"
        :key="item.label"
        :label="item.label"
        :price="item.price"
        class="mb-2"
      />
      <slot name="items" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import PriceGroupDetailRow, {
  PriceGroupDetailRowProps,
} from '@/components/price/PriceGroupDetailRow.vue';

const props = defineProps<{
  title: string;
  total: number;
  items: PriceGroupDetailRowProps[];
  note?: string;
  disableExpands?: boolean;
  defaultExpands?: boolean;
}>();

const totalText = computed(() => `${props.total.toLocaleString()}円`);
const expands = ref(props.defaultExpands || false);
</script>
