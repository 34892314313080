<template>
  <div>
    <FormTextInput
      class="mb-8"
      :title="t('companyName')"
      name="company_name"
      :placeholder="t('placeholders.companyName')"
      autocomplete="organization"
      :disabled="isPersonal"
      :required="!isPersonal"
      :shows-error="showsError"
      :model-value="companyName"
      @update:model-value="setCompanyName"
      @blur="$emit('blur')"
    />
    <div class="-mt-4 flex cursor-pointer items-center">
      <BaseCheckbox
        id="is_personal"
        name="is_personal"
        :model-value="isPersonal"
        @update:model-value="setIsPersonal"
      />
      <label
        for="is_personal"
        class="ml-[6px] cursor-pointer text-sm"
      >
        {{ t('isPersonal') }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

defineProps<{
  companyName: string;
  isPersonal: boolean;
  showsError: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:companyName', value: string): void;
  (e: 'update:isPersonal', value: boolean): void;
  (e: 'blur'): void;
}>();

function setCompanyName (value: string) {
  emit('update:companyName', value);
}

function setIsPersonal (value: boolean) {
  emit('update:isPersonal', value);
}

const { t } = useI18n({
  messages: {
    ja: {
      companyName: '法人名',
      isPersonal: '個人 / 個人事業主のため、法人名はない',
      placeholders: {
        companyName: 'cotobox株式会社',
      },
    },
    en: {
      companyName: 'Corporate Name',
      isPersonal: 'No Corporate Name because I\'m an individual',
      placeholders: {
        companyName: 'cotobox Inc.',
      },
    },
    zh: {
      companyName: '法人名称',
      isPersonal: '个人/个体户，无法人名称',
      placeholders: {
        companyName: 'cotobox株式会社',
      },
    },
  },
});
</script>
