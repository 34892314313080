<template>
  <div class="flex items-center">
    <div
      class="material-icons"
      :class="colorClass"
    >
      {{ iconName }}
    </div>
    <div class="text-grayAlpha80 ml-1 text-sm font-bold tracking-wider">
      {{ text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { RegistrationStatus } from '@/api/APIClientInterface';
import { TrademarkType } from '@/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  registrationStatus: RegistrationStatus;
  trademarkType: TrademarkType;
}>();

const modifiedRegistrationStatus = computed<RegistrationStatus>(() => {
  if (props.trademarkType === 'text') {
    return props.registrationStatus;
  } else {
    return props.registrationStatus === 'unregistered'
      ? 'unregistered'
      : 'potentially_registered';
  }
});

const colorClass = computed<string>(() => {
  switch (modifiedRegistrationStatus.value) {
  case 'registered':
    return 'text-[#dc3645]';
  case 'potentially_registered':
    return 'text-[#FFEC45]';
  case 'unregistered':
    return 'text-[#24BA63]';
  default:
    console.error(
      `Unexpected registration status: ${modifiedRegistrationStatus.value}`,
    );
    return '';
  }
});

const iconName = computed<string>(() => {
  switch (modifiedRegistrationStatus.value) {
  case 'registered':
    return 'error';
  case 'potentially_registered':
    return 'warning';
  case 'unregistered':
    return 'check_circle';
  default:
    console.error(
      `Unexpected registration status: ${modifiedRegistrationStatus.value}`,
    );
    return '';
  }
});

const text = computed<string>(() => {
  switch (modifiedRegistrationStatus.value) {
  case 'registered':
    return t('registered');
  case 'potentially_registered':
    return t('potentiallyRegistered');
  case 'unregistered':
    return t('unregistered');
  default:
    console.error(
      `Unexpected registration status: ${modifiedRegistrationStatus.value}`,
    );
    return '';
  }
});

const { t } = useI18n({
  messages: {
    ja: {
      registered: '類似の商標あり',
      potentiallyRegistered: '類似と思われる商標あり',
      unregistered: '類似の商標なし',
    },
    en: {
      registered: 'Possible rejection',
      potentiallyRegistered: 'Challengeable',
      unregistered: 'Possible registration',
    },
    zh: {
      registered: '有可能被拒绝。',
      potentiallyRegistered: '可挑战',
      unregistered: '登记的可能性。',
    },
  },
});
</script>
