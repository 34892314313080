export const ja = {
  ja: {
    amountTitle: 'お支払い額',
    amountValue: '{0}円',
    cardNumber: 'カード番号',
    expires: '有効期限',
    cvc: 'セキュリティコード',
    card: 'クレジットカード',
    bankTransfer: '銀行振込',
    submit: '決済する',
    submitBank: '請求書払いする（銀行振込）',
    description:
      '・クレジットカードでのお支払いはStripe社のシステムを利用しております。\n・ご登録いただいたクレジットカード情報はStripe社にて管理され当社では情報を保持しないため安心してご利用いただけます。\n・費用は出願を代理する提携弁理士事務所が受領します。',
    last4Digits: '下4桁',
    payWithAnotherCard: '別のカードで支払う',
    bank_description: {
      line1: '<b>振込手数料</b>はお客様の負担でお願いいたします。',
      line2: '7日以内にお振込をお願いいたします。',
      line3:
        '振込先の口座番号はお申し込み後に送信するメール本文をご確認ください。',
    },
    errors: {
      notFoundStripeAccount:
        'Stripeアカウント取得失敗: 恐れ入りますが画面をリロードして再度ご入力ください。{0}',
      pleaseReflesh: '恐れ入りますが画面をリロードして再度ご入力ください。{0}',
      pleaseRefleshAndTurnOffAd:
        '恐れ入りますが画面をリロードして再度ご入力ください。\n広告ブロックのプラグインはOFFにしてください。{0}',
      apiConnectionError:
        '決済システムと接続できませんでした。ネットワークに接続されていることをご確認ください。{0}',
      apiError:
        '決済システムで一時的に障害が発生しています。恐れ入りますが、時間を置いて再度決済をお願いします。{0}',
      cardError:
        '決済に失敗しました。カードが有効であることをご確認ください。{0}',
      rateLimitError:
        '一度に複数の決済がリクエストされたためエラーになりました。時間を置いて再度決済をお願いします。{0}',
      validationError: '入力したカード情報が正しいことをご確認ください。{0}',
      other:
        '決済でエラーが発生しました。恐れ入りますがチャットにてお問い合わせください。{0}',
    },
    processing: '決済処理中です。このページを離れないでください。',
    complete:
      '支払いが完了いたしました。引き続き手続きを進めますのでしばらくお待ちください。',
    completeBank:
      '振込口座をメールに送信いたしました。7日以内にお振込ください。',
  },
};
