<template>
  <div>
    <div class="mb-2 flex items-center">
      <div class="text-sm font-normal tracking-wider">
        {{ title }}
      </div>
      <div
        v-if="showsRequiredLabel && required"
        class="bg-primary ml-1 rounded-sm p-1 text-xs text-white"
      >
        {{ t('common.required') }}
      </div>
    </div>
    <slot class="w-full" />
    <div
      v-if="showsError"
      class="text-errorRed py-1 text-sm"
    >
      {{ errorMessage }}
    </div>
    <div
      v-if="description"
      class="mt-2 text-sm"
    >
      {{ description }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n({ useScope: 'global' });
defineProps<{
  title: string;
  required: boolean;
  showsRequiredLabel: boolean;
  showsError: boolean;
  errorMessage?: string;
  description?: string;
}>();
</script>
