PasswordStrengthMete
<template>
  <div :class="`${$options.name}__strength-meter`">
    <div
      :class="`${$options.name}__strength-meter--fill`"
      :data-score="passwordStrength"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import zxcvbn from 'zxcvbn';

export default defineComponent({
  name: 'Password',
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const password = computed(() => props.password);

    const passwordStrength = computed(() => {
      return password.value ? zxcvbn(password.value).score : null;
    });

    return {
      passwordStrength,
    };
  },
});
</script>

<style lang="scss" scoped>
.Password {
  &__strength-meter {
    position: relative;
    height: 3px;
    background: #ddd;
    margin: 10px auto 20px;
    border-radius: 3px;

    &:before,
    &:after {
      content: '';
      height: inherit;
      background: transparent;
      display: block;
      border-color: #fff;
      border-style: solid;
      border-width: 0 5px 0 5px;
      position: absolute;
      width: 20%;
      z-index: 10;
    }

    &:before {
      left: 20%;
    }

    &:after {
      right: 20%;
    }

    &--fill {
      background: transparent;
      height: inherit;
      position: absolute;
      width: 0;
      border-radius: inherit;
      transition:
        width 0.5s ease-in-out,
        background 0.25s;

      &[data-score='0'] {
        background: darkred;
        width: 20%;
      }

      &[data-score='1'] {
        background: orangered;
        width: 40%;
      }

      &[data-score='2'] {
        background: orange;
        width: 60%;
      }

      &[data-score='3'] {
        background: yellowgreen;
        width: 80%;
      }

      &[data-score='4'] {
        background: green;
        width: 100%;
      }
    }
  }
}
</style>
