<template>
  <div class="sm:w-[544px]">
    <!-- ヘッダー -->
    <div
      class="border-borderGray relative flex items-center border-b border-solid px-4 py-6 sm:px-10"
    >
      <!-- タイトル -->
      <div
        class="absolute left-4 right-4 mx-auto text-center font-bold tracking-wider sm:left-10 sm:right-10"
      >
        {{ headerTitle }}
      </div>
      <!-- 戻る -->
      <NavigationBackButton
        v-if="showsBackButton"
        class="absolute left-4 sm:left-10"
        @click="onBack"
      />
    </div>
    <!-- ボディ -->
    <SignUpFormEmail
      v-if="currentStep === 1"
      @next="signUpStore.state.currentStep = 2"
    />
    <SignUpFormCode
      v-if="currentStep === 2"
      @next="signUpStore.state.currentStep = 3"
    />
    <SignUpFormProfile
      v-if="currentStep === 3"
      @complete="onComplete"
    />
  </div>
</template>

<script setup lang="ts">
import { provide, inject, computed } from 'vue';
import { APIClientInterface } from '@/api/APIClientInterface';
// 状態管理
import { APIClientKey, SignUpKey } from '@/composables/injectionKeys';
import { useSignUp } from '@/composables/useSignUp';
// コンポーネント
import SignUpFormEmail from '@/components/signup/SignUpFormEmail.vue';
import SignUpFormCode from '@/components/signup/SignUpFormCode.vue';
import SignUpFormProfile from '@/components/signup/SignUpFormProfile.vue';
import NavigationBackButton from '@/components/common/NavigationBackButton.vue';
// ライブラリ
import { useToast } from 'vue-toastification';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const apiClient = inject(APIClientKey) as APIClientInterface;
const signUpStore = useSignUp(apiClient);
provide(SignUpKey, signUpStore);

const currentStep = computed(() => signUpStore.state.currentStep);

const headerTitle = computed(() => {
  if ([3].includes(currentStep.value)) {
    return 'アカウント情報を入力してください';
  } else if ([4].includes(currentStep.value)) {
    return 'ご登録ありがとうございました！';
  } else {
    return 'アカウント登録';
  }
});

const showsBackButton = computed(() => {
  return currentStep.value === 2;
});

function onBack () {
  if (showsBackButton.value) {
    const target = currentStep.value - 1;
    signUpStore.state.currentStep = target as 1 | 2 | 3;
  }
}

const toast = useToast();

function onComplete () {
  toast.success('アカウント登録が完了しました！');
  emit('close');
}
</script>
