<template>
  <div :class="`${$options.name}`">
    <div :class="`${$options.name}__inner`">
      <p :class="`${$options.name}__title`">
        {{ t('title') }}
      </p>
      <div :class="`${$options.name}__survey`">
        <p :class="`${$options.name}__surveyTitle`">
          {{ t('applyTime.title') }}
        </p>
        <RadioButtonGroup
          :class="`${$options.name}__surveyItem`"
          :options="applyTimeOptions"
          :value="applyTimeOptionsValue"
          @update:model-value="onApplyTimeChange"
        />
      </div>
      <p :class="`${$options.name}__startButtonHelperText`">
        {{ t('start.helperText') }}
      </p>
      <!-- <div v-if="variantRef === '1'" :class="`${$options.name}__recommendMobile`">
        <div :class="`${$options.name}__recommendMobileTitle`">商標出願の申込はPCでの操作をおすすめします</div>
        <div :class="`${$options.name}__recommendMobileDescription`">カスタマーサポートの案内を受けながら出願情報をミスなく確認いただくために、スマホから登録された方もPCでの作業をおすすめいたします。</div>
      </div> -->
      <PrimaryButton
        :title="t('start.title')"
        :class="`${$options.name}__startButton`"
        :disabled="false"
        @click="redirectToReferer"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeUnmount, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import RadioButtonGroup, {
  RadioButtonGroupOption,
} from '@/components/common/BaseRadioButtonGroup.vue';
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import { useRoute, useRouter } from 'vue-router';
import ChannelService from '@/lib/channelService';
import { useSignUp } from '@/composables/useSignUp';
import { APIClientKey } from '@/composables/injectionKeys';
import { APIClientInterface } from '@/api/APIClientInterface';

export default defineComponent({
  name: 'SignUpThanksPage',
  components: {
    RadioButtonGroup,
    PrimaryButton,
  },
  setup () {
    const { t } = useI18n({
      locale: 'ja',
      messages: {
        ja: {
          title: 'ご登録ありがとうございます',
          applyTime: {
            title: 'よろしければ、商標のご希望出願時期を教えてください。',
            option0: '今すぐ',
            option1: '1週間以内',
            option2: '1ヶ月以内',
            option3: '2-3ヶ月以内',
            option4: '1年以内',
            option5: '特に予定は決めていない',
          },
          start: {
            title: 'Cotoboxの利用を始める',
            helperText:
              'さっそく、検討中のネーミングやロゴについて、類似商標がないかを検索してみましょう。',
          },
        },
        en: {
          title: 'Thanks for registering!',
          applyTime: {
            title:
              'Please let me know when you plan to register your trademark.',
            option0: 'Now',
            option1: 'Within a week',
            option2: 'Witnin a month',
            option3: 'Within 2 to 3 months',
            option4: 'Within a year',
            option5: 'Not decided',
          },
          start: {
            title: 'Start using Cotobox',
            helperText:
              'You can search for a trademark that can be registered.\nYou can also apply for a trademark application.',
          },
        },
        zh: {
          title: '谢谢您的注册',
          applyTime: {
            title: '请告诉我们你计划何时注册你的商标',
            option0: '现在',
            option1: '一周内',
            option2: '一个月内',
            option3: '2至3个月内',
            option4: '一年内',
            option5: '未决定',
          },
          start: {
            title: '开始使用Cotobox',
            helperText:
              '通过搜索与你正在考虑的商标相似的商标，开始吧。\n你也可以申请商标申请。',
          },
        },
      },
    });

    const applyTimeOptions: RadioButtonGroupOption[] = [];
    let index = 0;
    while (index < 6) {
      applyTimeOptions.push({
        label: t(`applyTime.option${index}`),
        value: t(`applyTime.option${index}`, 'ja'),
      });
      index++;
    }

    const applyTimeOptionsValue = ref('');

    function onApplyTimeChange (motivation: string) {
      applyTimeOptionsValue.value = motivation;
      ChannelService.updateUser(
        {
          profile: {
            motivation,
          },
          // @ts-ignore
        },
        (error, _user) => {
          if (error) {
            console.error(error);
          }
        },
      );
    }

    const route = useRoute();
    const router = useRouter();

    function redirectToReferer () {
      const redirectTo = route.query.redirect_to as string;
      const { redirect_to, ...rest } = route.query;
      if (redirect_to) {
        router.push({
          path: redirectTo,
          query: rest,
        });
      } else {
        router.push('/app/search/');
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload);

    onBeforeUnmount(async () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      await onBeforeUnload();
    });

    const apiClient = inject(APIClientKey) as APIClientInterface;
    const { sendSignupThanksMail } = useSignUp(apiClient);

    async function onBeforeUnload () {
      sendSignupThanksMail(plannedApplyTime(applyTimeOptionsValue.value));
    }

    function plannedApplyTime (applyTimeOptionsValueJa: string) {
      switch (applyTimeOptionsValueJa) {
      case '今すぐ':
        return 'now';
      case '1週間以内':
        return '1week';
      case '1ヶ月以内':
        return '1month';
      case '2-3ヶ月以内':
        return 'a_few_months';
      case '1年以内':
        return '1year';
      case '特に予定は決めていない':
        return 'undecided';
      default:
        return 'unanswered';
      }
    }

    return {
      t,
      applyTimeOptions,
      applyTimeOptionsValue,
      onApplyTimeChange,
      redirectToReferer,
    };
  },
});
</script>

<style lang="scss" scoped>
.SignUpThanksPage {
  &__inner {
    @include pc {
      width: 1160px;
      margin: 0 auto;
    }
    @include sp {
      padding: 0 16px;
    }
  }

  &__title {
    text-align: center;
    margin: 40px 0 40px;
    font-weight: 700;
    letter-spacing: 1.2px;

    @include pc {
      font-size: 24px;
    }
    @include sp {
      font-size: 20px;
    }
  }

  &__survey {
    margin: 0 auto 24px;
    border: 1px solid #eee;

    @include pc {
      width: 900px;
      padding: 24px 16px;
    }
    @include sp {
      padding: 24px 16px;
    }
  }

  &__surveyTitle {
    text-align: center;
    margin: 0 0 16px;

    @include pc {
    }
    @include sp {
    }
  }

  &__surveyItem {
    @include pc {
      justify-content: center;
    }
    @include sp {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 8px;
    }
  }

  &__startButton {
    display: block;
    margin: 24px auto 60px;
    height: 50px;

    @include pc {
      width: 260px;
    }
    @include sp {
      width: 260px;
    }
  }

  &__startButtonHelperText {
    text-align: center;
    white-space: pre-line;
    line-height: 22px;

    @include pc {
    }
    @include sp {
    }
  }

  &__recommendMobile {
    background-color: #fff4f1;
    padding: 20px 26px;
    margin: 24px auto 24px;
    @include pc {
      width: 600px;
    }
  }

  &__recommendMobileTitle {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.2px;
    @include sp {
      line-height: 22px;
    }
  }

  &__recommendMobileDescription {
    margin: 8px 0 0;
    white-space: pre-wrap;
    line-height: 19px;
  }
}
</style>
