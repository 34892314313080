<template>
  <div class="bg-white p-6">
    <div
      v-for="(actor, index) in actors"
      :key="index"
      class=""
    >
      <!-- 登場人物 -->
      <div class="">
        <div
          class="data-[cotobox=true]:text-primary mb-2 text-center font-bold"
          :data-cotobox="actor.name === 'Cotobox'"
        >
          {{ actor.name }}
        </div>
        <div
          class="h-[160px] bg-contain bg-center bg-no-repeat data-[cotobox=true]:bg-cover"
          :data-cotobox="actor.name === 'Cotobox'"
          :style="{ backgroundImage: `url(${actor.image})` }"
        />
        <div
          class="border-grayAlpha20 mt-6 whitespace-pre-line border border-solid p-4 text-center text-sm tracking-wider"
        >
          {{ actor.description }}
        </div>
      </div>
      <!-- 矢印 -->
      <div
        v-if="index < actors.length - 1"
        class="mx-auto my-4 flex w-full items-center justify-center gap-x-4"
      >
        <div class="flex items-center gap-x-2">
          <div class="w-36 text-right text-sm tracking-wider">
            {{ relations[index][0] }}
          </div>
          <div
            v-if="relations[index][0].length"
            class="h-10 w-4 bg-contain bg-center bg-no-repeat"
            :style="{ backgroundImage: `url(${ArrowUpImage})` }"
          />
        </div>
        <div class="flex flex-row-reverse items-center gap-x-2">
          <div class="w-36 text-left text-sm tracking-wider">
            {{ relations[index][1] }}
          </div>
          <div
            v-if="relations[index][1]"
            class="h-10 w-4 bg-contain bg-center bg-no-repeat"
            :style="{ backgroundImage: `url(${ArrowDownImage})` }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ActorAttorneyImage from '@/assets/images/creatives/actor-attorney.svg';
import ActorCotoboxImage from '@/assets/images/creatives/actor-cotobox.svg';
import ActorJpoImage from '@/assets/images/creatives/actor-jpo.svg';
import ActorYouImage from '@/assets/images/creatives/actor-you.svg';
import ArrowUpImage from '@/assets/images/creatives/arrow-up.svg';
import ArrowDownImage from '@/assets/images/creatives/arrow-down.svg';

const actors = [
  {
    name: 'あなた',
    image: ActorYouImage,
    description: '担当弁理士と契約して出願を依頼',
  },
  {
    name: 'Cotobox',
    image: ActorCotoboxImage,
    description: '商標検索、書類作成支援\nステータス管理、メッセージ機能',
  },
  {
    name: '提携弁理士',
    image: ActorAttorneyImage,
    description: 'あなたと契約し\n特許庁へ出願手続きを行う',
  },
  {
    name: '特許庁',
    image: ActorJpoImage,
    description: '出願受付、審査、設定登録広報発行',
  },
];

const relations = [
  ['出願情報入力', 'メッセージ受取'],
  ['商標情報', 'メッセージ送信'],
  ['', '手続き'],
];
</script>
