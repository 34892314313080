import { klasses } from '@/constants/klasses';
import { KlassId } from '@/types';
import {
  GoodsServices,
  GoodsService,
  goodsServices,
} from '@/constants/goodsServices';

export const getGoodsServicesWithGoodsServiceIdArray = (
  goodsServiceIdArray: string[],
) => {
  const results = {} as GoodsServices;
  goodsServiceIdArray.forEach((goodsServiceId) => {
    klasses.forEach((klass) => {
      const klassId: KlassId = klass.value;
      const klassGoodsServices = goodsServices[klass.value] as {
        [x: string]: GoodsService;
      };
      Object.keys(klassGoodsServices).forEach((key) => {
        if (key === goodsServiceId) {
          if (typeof results[klassId] === 'undefined') {
            results[klassId] = {} as { [x: string]: GoodsService };
          }
          results[klassId][goodsServiceId] =
            goodsServices[klassId][goodsServiceId];
        }
      });
    });
  });
  return results;
};

export const getGoodsServicesHash = (goodsServiceIdArray: string[]) => {
  const goodsService =
    getGoodsServicesWithGoodsServiceIdArray(goodsServiceIdArray);
  return (Object.keys(goodsService) as KlassId[])
    .reduce((p, klassId) => {
      const goodsServiceIdsForKlass = Object.keys(goodsService[klassId]);
      const klassIdString = `00${klassId}`.slice(-2);
      return [
        ...p,
        ...goodsServiceIdsForKlass.map(
          (goodsServiceId) =>
            `${klassIdString}${goodsService[klassId][goodsServiceId]!.code}`,
        ),
      ];
    }, [] as string[])
    .join('');
};
