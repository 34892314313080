<template>
  <div class="bg-bgGray py-10 sm:py-16 sm:pb-[60px]">
    <SectionHeader
      class="sm:pb-14"
      text="Cotobox商標管理クラウドで解決"
    />
    <div class="mb-10 text-center sm:pb-14">
      これまで商標管理は、専門性が高く、高額な管理ツールを導入するか弁理士に管理を任せるしか<br>ありませんでした。Cotoboxはこれまで45,000を超えるお客さまの商標出願をサポートしてきまし<br>た。その中で寄せられた商標の活用についてのご要望に応えるべく、<br>
      Cotobox商標管理クラウドとしてリリースします。
    </div>
    <div class="px-4 sm:mx-auto sm:w-[1121px] sm:px-0">
      <template v-if="$device.isDesktop">
        <EnterpriseFlowRow
          v-for="(item, index) in items"
          :key="index"
          :left-text="[1, 3, 5].includes(index)"
          :exists-image="true"
          :number="index + 1"
          :title="item.title"
          :description="item.description"
          :tasks="item.tasks"
          :image="item.image"
          :image2="item.image2"
          class="mb-12"
        />
      </template>
      <template v-if="$device.isMobile">
        <TopPageFlowRowMobile
          v-for="(item, index) in items"
          :key="index"
          :exists-image="index !== 3"
          :number="index + 1"
          :title="item.title"
          :description="item.description"
          :tasks="item.tasks.slice(1)"
          class="mb-12"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import SectionHeader from './SectionHeader.vue';
import EnterpriseFlowRow from './EnterpriseFlowRow.vue';
import TopPageFlowRowMobile from './TopPageFlowRowMobile.vue';
import Img01 from '@/assets/images/mocks/ent-flow-1.png';
import ImgSub01 from '@/assets/images/mocks/ent-flow-sub-1.png';
import Img02 from '@/assets/images/mocks/ent-flow-2.png';
import ImgSub02 from '@/assets/images/mocks/ent-flow-sub-2.png';
import Img03 from '@/assets/images/mocks/ent-flow-3.png';
import ImgSub03 from '@/assets/images/mocks/ent-flow-sub-3.png';
import Img04 from '@/assets/images/mocks/ent-flow-4.png';

type TopPageFlowItem = {
  title: string;
  description: string;
  tasks: string[];
  image?: string;
  image2?: string;
};

const items: TopPageFlowItem[] = [
  {
    title: 'クラウドで案件一元管理',
    description:
      'SaaSサービスなのでIT対応は不要、最短当日から利用可能です。各案件の情報や対応履歴が全てオンライン上に集約できるので、エクセルでの案件管理は不要、商標権の更新期限の抜け漏れもありません。',
    tasks: [],
    image: Img01,
    image2: ImgSub01,
  },
  {
    title: 'AIアシストメニューですぐ使える',
    description:
      '網羅的にメニューされた業務フォローに沿って、今必要な対応を自動でオススメ。アシストに従えば初任者・兼務者でもすぐに即戦力化できます。必要な場合にはサービス上から提携弁理士に依頼が可能です。',
    tasks: [],
    image: Img02,
    image2: ImgSub02,
  },
  {
    title: 'AI商標モニタリングができる',
    description: `自社の商標に対する侵害状況や他社の類似出願状況など、任意の設定で常時監視し、自動で侵害検知・ダッシュボードでの確認が可能です。
※海外の商標侵害モニタリングについても一部対応が可能です。詳しくは担当までお問合せください。`,
    tasks: [],
    image: Img03,
    image2: ImgSub03,
  },
  {
    title: '出願も簡単・お安く',
    description: `Cotoboxを使って、日本や外国にそのまま商標出願が可能。業界最安値水準の出願・登録手数料でスピーディーに出願が可能になります。
外国への商標出願対象国は、100カ国以上です。`,
    tasks: [],
    image: Img04,
    image2: ImgSub02,
  },
];
</script>
