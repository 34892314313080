<template>
  <div class="flex items-center">
    <BaseCheckbox
      id="checkbox__userTermsAgreed"
      :name="'rule_confirmed'"
      :model-value="modelValue"
      @update:model-value="onChange"
    />
    <div class="ml-2 text-sm tracking-wider">
      <i18n-t keypath="agreement.text">
        <a
          href="/terms/"
          target="_blank"
          class="text-primary cursor-pointer underline"
        >
          {{ t('agreement.terms') }}
        </a>
        <a
          href="/privacy/"
          target="_blank"
          class="text-primary cursor-pointer underline"
        >
          {{ t('agreement.privacy') }}
        </a>
      </i18n-t>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void;
}>();

function onChange (value: boolean) {
  emit('update:model-value', value);
}

const { t } = useI18n({
  messages: {
    ja: {
      agreement: {
        text: '{0}及び{1}に同意します。',
        terms: '利用規約',
        privacy: 'プライバシーポリシー',
      },
    },
    en: {
      agreement: {
        text: 'I agree with {0} and {1}.',
        terms: 'Terms of service',
        privacy: 'Privacy policy',
      },
    },
    zh: {
      agreement: {
        text: '同意{0}和{1}',
        terms: '使用条款',
        privacy: '隐私政策',
      },
    },
  },
});
</script>
