<template>
  <div class="text-grayAlpha80 flex cursor-pointer items-center">
    <div class="material-icons">
      arrow_back_ios
    </div>
    <div class="ml-1 font-bold">
      戻る
    </div>
  </div>
</template>
