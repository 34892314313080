<template>
  <div class="pb-6">
    <div
      class="flex items-center px-4 pt-6"
      @click="onKlassSelect"
    >
      <div class="flex-1">
        <div
          class="text-grayAlpha80 mb-1 text-base font-semibold tracking-wider"
        >
          {{ `${klassId}類 ${klassTitle}` }}
        </div>
        <div
          class="text-grayAlpha80 whitespace-pre-line text-sm font-normal tracking-wide"
        >
          {{ klassDescription }}
        </div>
      </div>
      <div class="text-grayAlpha80 w-10">
        <span
          class="material-icons material-symbols-outlined block w-full text-center"
        >
          {{ expanded ? 'expand_less' : 'expand_more' }}
        </span>
      </div>
    </div>
    <div
      v-if="expanded"
      class="mt-2 overflow-hidden px-4"
      :class="`${expanded ? 'h-auto' : 'h-0'}`"
    >
      <KlassSelectGoodsServiceRowVue
        v-for="goodsService in goodsServices"
        :key="goodsService.code"
        :klass-id="klassId"
        :goods-service-id="goodsService.id"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { KlassId } from '@/types';
import { klasses } from '@/constants/klasses';
import { KlassListStoreKey } from '@/composables/injectionKeys';
import { KlassListStore } from '@/composables/useKlassList';
import { KlassFilterType } from '@/composables/useKlassFilter';
import KlassSelectGoodsServiceRowVue from '@/components/search/KlassSelectGoodsServiceRow.vue';
import { GoodsService } from '@/constants/goodsServices';

const { allList, listFromSource } = inject(KlassListStoreKey) as KlassListStore;

interface Props {
  klassId: KlassId;
  expanded: boolean;
  filterType: KlassFilterType;
}

const props = defineProps<Props>();
const klassId = computed(() => props.klassId);
const filterType = computed(() => props.filterType);

interface Emits {
  (e: 'select:klass', value: KlassId): void;
}

const emit = defineEmits<Emits>();

const expanded = computed(() => props.expanded);

const klassTitle = computed(() => {
  const index = Number(props.klassId) - 1;
  return klasses[index].name.ja;
});

const klassDescription = computed(() => {
  const index = Number(props.klassId) - 1;
  return klasses[index].description;
});

const goodsServices = computed(() => {
  if (filterType.value === 'none') {
    return allList.value[klassId.value]! as {
      [goodsServiceId: string]: GoodsService;
    };
  } else {
    return listFromSource.value[klassId.value]! as {
      [goodsServiceId: string]: GoodsService;
    };
  }
});

function onKlassSelect () {
  emit('select:klass', props.klassId);
}
</script>
