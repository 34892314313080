<template>
  <div
    class="border-borderGray rounded-lg border border-solid bg-white text-base"
  >
    <button
      class="flex w-full cursor-pointer items-center justify-between p-5"
      @click="expands = !expands"
    >
      <span class="flex items-center font-bold">
        <slot name="question" />
      </span>
      <span class="material-icons">
        {{ expands ? 'expand_less' : 'expand_more' }}
      </span>
    </button>
    <div
      v-if="expands"
      class="px-5"
    >
      <div
        class="border-borderGray whitespace-pre-wrap border-t border-solid py-5"
      >
        <slot name="answer" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const expands = ref(false);
</script>
