<script setup lang="ts">
import * as countries from 'i18n-iso-countries';
import counteriesJaJson from 'i18n-iso-countries/langs/ja.json';
import counteriesEnJson from 'i18n-iso-countries/langs/en.json';
import counteriesZhJson from 'i18n-iso-countries/langs/zh.json';
import BaseSelect from '@/components/common/BaseSelect.vue';
import { useI18n } from 'vue-i18n';

interface Props {
  modelValue: string;
}

defineProps<Props>();

interface Emits {
  (e: 'update:model-value', value: string): void;
}

const emit = defineEmits<Emits>();

const { locale } = useI18n<object, 'ja' | 'en' | 'zh'>({ useScope: 'global' });

countries.registerLocale(counteriesJaJson);
countries.registerLocale(counteriesEnJson);
countries.registerLocale(counteriesZhJson);

const countryNames = countries.getNames(locale.value);
const options = Object.keys(countryNames).map((value) => ({
  label: countryNames[value],
  value,
}));

function onChange (value: string) {
  emit('update:model-value', value);
}
</script>

<template>
  <BaseSelect
    :model-value="modelValue || 'JP'"
    :options="options"
    @update:model-value="onChange"
  />
</template>
