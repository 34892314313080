<template>
  <div class="bg-bgGray min-h-screen">
    <div
      class="flex h-16 w-screen items-center px-6"
      style="
        background: linear-gradient(45deg, rgb(34, 34, 34), rgb(68, 68, 68));
      "
    >
      <a
        class="block h-[26px] w-[160px]"
        href="/"
      >
        <img src="@/assets/images/icons/logo.svg">
      </a>
    </div>
    <div class="px-4 sm:mx-auto sm:w-[1160px]">
      <router-view class="pb-20 pt-6 sm:pt-10" />
    </div>
    <!-- 処理中 -->
    <TheOverlayLoading v-if="loading" />
  </div>
</template>

<script setup lang="ts">
import { computed, provide, inject, onMounted } from 'vue';
import { APIClientInterface } from '@/api/APIClientInterface';
import {
  APIClientKey,
  SignUpKey,
  UserDataKey,
} from '@/composables/injectionKeys';
import { useSignUp } from '@/composables/useSignUp';
import { useRouter } from 'vue-router';
import { COOKIE_NAME, getCookieValue } from '@/lib/util';
import { UserDataStore } from '@/composables/useUserStore';

const router = useRouter();
const loading = computed(() => signUp.state.loading);
const apiClient = inject(APIClientKey) as APIClientInterface;
const signUp = useSignUp(apiClient);
provide(SignUpKey, signUp);

const { authenticated, loadUser } = inject(UserDataKey) as UserDataStore;

onMounted(async () => {
  // 未ログインの場合はログインチェック
  // 初期ロードが未完了で未ログイン状態になっているだけかもしれないため
  if (!authenticated.value) {
    await loadUser();
  }
  // ログイン状態の場合はマイページに遷移する
  if (authenticated.value) {
    return router.push({ name: 'mypage' });
  }

  restoreStateOrRedirect();
});

/** ブラウザリロードで直接アクセスされるケースもあるので、直前のメールアドレス等の復元を試みて、不可であれば会員登録の最初にリダイレクト */
function restoreStateOrRedirect () {
  if (!restoreEmail()) {
    return router.push('/app/users/sign_up');
  }

  if (restoreConfirmationToken()) {
    restoreInputtedValues();
    return router.push('/app/users/sign_up/form');
  } else {
    return router.push('/app/users/sign_up/code');
  }
}

/**
 * 入力されたメールアドレスを状態管理に復元する
 * @returns 状態管理にメールアドレスが存在する状態にできた場合は`true`、そうでなければ`false`
 */
function restoreEmail (): boolean {
  if (signUp.state.email) return true;

  const restoredEmail = getCookieValue(COOKIE_NAME.SIGN_UP_EMAIL);
  if (restoredEmail) {
    signUp.state.email = restoredEmail;
    return true;
  } else {
    return false;
  }
}

/**
 * 入力された認証コードを状態管理に復元する
 * @returns 状態管理に認証コードが存在する状態にできた場合は`true`、そうでなければ`false`
 */
function restoreConfirmationToken (): boolean {
  if (signUp.state.confirmationToken) return true;

  const restoredConfirmationToken = getCookieValue(
    COOKIE_NAME.SIGN_UP_CONFIRMATION_TOKEN,
  );
  if (restoredConfirmationToken) {
    signUp.state.confirmationToken = restoredConfirmationToken;
    return true;
  } else {
    return false;
  }
}

/**
 * 入力途中の入力項目を復元する
 */
function restoreInputtedValues () {
  signUp.state.companyName =
    getCookieValue(COOKIE_NAME.SIGN_UP_COMPANY_NAME) || '';
  signUp.state.isPersonal =
    getCookieValue(COOKIE_NAME.SIGN_UP_IS_PERSONAL) === 'true';
  signUp.state.lastName = getCookieValue(COOKIE_NAME.SIGN_UP_LAST_NAME) || '';
  signUp.state.firstName = getCookieValue(COOKIE_NAME.SIGN_UP_FIRST_NAME) || '';
  signUp.state.phoneNumber =
    getCookieValue(COOKIE_NAME.SIGN_UP_PHONE_NUMBER) || '';
  signUp.state.industry = getCookieValue(COOKIE_NAME.SIGN_UP_INDUSTRY) || '';
  signUp.state.language = getCookieValue(COOKIE_NAME.SIGN_UP_LANGUAGE) || 'ja';
}
</script>
