<template>
  <BaseButton
    class="flex h-8 w-32 items-center justify-center rounded text-xs font-bold"
    :class="disabled ? 'border-0 bg-[#bbb]' : ''"
    :style="
      disabled
        ? 'box-shadow: 0px 1px 2px rgb(80 60 60 / 12%), 0px -1px 2px rgb(80 60 60 / 12%);'
        : 'box-shadow: 0px 0px 4px rgb(0 0 0 / 30%);'
    "
    :disabled="disabled"
    :title="title"
    @click="$emit('click')"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseButton from '@/components/common/BaseButton.vue';

export default defineComponent({
  name: 'DefaultButton',
  components: {
    BaseButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
});
</script>
