<template>
  <div class="bg-bgGray pb-40 pt-6 sm:pb-48 sm:pt-10">
    <div class="px-4 sm:mx-auto sm:w-[940px] sm:px-0">
      <Stepper
        class="mb-8 w-full sm:mx-auto sm:w-80"
        :steps="['出願依頼申し込み', '料金支払い']"
        :current-step="paymentType === 'credit' ? 3 : 2"
      />
      <div
        v-if="paymentType === 'credit'"
        class="border-borderGray mb-4 rounded-lg border border-solid bg-white px-4 py-6"
      >
        <div
          class="text-grayAlpha60 text-center text-xl font-bold tracking-wider"
        >
          お申し込みありがとうございます
        </div>
      </div>
      <!-- メインセクション -->
      <div
        class="border-borderGray mb-12 rounded-lg border border-solid bg-white px-4 py-6 sm:mb-4 sm:px-10 sm:pb-10"
      >
        <div class="mb-6 text-center text-2xl font-semibold tracking-widest">
          {{ paymentType === 'credit' ? t('applyCompletion') : t('payByBank') }}
        </div>
        <div class="text-grayAlpha60 whitespace-pre-line text-center text-sm">
          <div v-if="paymentType === 'credit'">
            {{ t('applyCompletionMemoCredit') }}
          </div>
          <div v-else-if="paymentType === 'bank'">
            {{ t('applyCompletionMemoBank') }}
          </div>
        </div>
        <template v-if="state.attorneyOffice && state.attorneyOffice.id">
          <div
            class="text-grayAlpha80 mb-2 mt-10 text-center font-semibold sm:text-left"
          >
            {{ t('assignedAttorney') }}
          </div>
          <AssignedAttorneyOffice
            class="mx-auto h-14 w-52 sm:mx-0"
            :attorney-office-id="state.attorneyOffice.id"
          />
        </template>
        <!-- 口座情報 -->
        <template v-if="paymentType === 'bank'">
          <div class="mb-2 mt-2 items-baseline justify-between sm:flex">
            <div class="items-center sm:flex">
              <div
                class="text-grayAlpha80 text-center font-semibold sm:text-left"
              >
                {{ t('paymentDestination') }}
              </div>
              <div
                class="text-grayAlpha60 mt-1 text-left text-sm sm:ml-2 sm:mt-0"
              >
                {{ t('paymentDestinationMemo') }}
              </div>
            </div>
            <div class="text-red100 mt-2 text-right font-bold sm:mt-0">
              {{ `${t('expires')}${bankAccount.bankPaymentExpiresDate}` }}
            </div>
          </div>
          <DestinationBankAccount
            class="border-primary bg-primaryOrange5 rounded border border-solid px-4 py-4 sm:px-6"
            :bank-name="bankAccount.bankName"
            :branch-name="bankAccount.branchName"
            :account-type="bankAccount.accountType"
            :account-number="bankAccount.accountNumber"
            :account-holder-name="bankAccount.accountHolderName"
            :amount="bankAccount.amount"
          />
          <div class="text-grayAlpha80 mt-2 text-sm">
            {{ t('payBankMemo') }}
          </div>
          <a
            :href="bankAccount.invoicePdfUrl"
            target="_blank"
          >
            <div class="mt-2 flex justify-end">
              <SecondaryButton title="請求書をダウンロード" />
            </div>
          </a>
        </template>
        <!-- 今後のフロー -->
        <div
          class="text-grayAlpha80 mb-6 mt-10 text-center font-semibold sm:text-left"
        >
          {{ t('flowAfterApply') }}
        </div>
        <FlowAfterApply :payment-type="paymentType" />
        <div class="flex justify-center">
          <div class="mt-6 flex flex-col-reverse sm:mt-10 sm:flex-row">
            <a
              href="https://support.cotobox.com/process-application-cotobox"
              target="_blank"
            >
              <SecondaryButton
                :title="t('viewFlow')"
                class="mt-2 w-full sm:mr-6 sm:mt-0 sm:w-80"
              />
            </a>
            <RouterLink to="/app/mypage">
              <PrimaryButton
                :title="t('viewApply')"
                class="w-full sm:w-72"
              />
            </RouterLink>
          </div>
        </div>
      </div>
      <!-- FAQ -->
      <AccordionFAQ class="mb-4">
        <template #question>
          <span>Q 支払い明細や領収書はもらえますか？</span>
        </template>
        <template #answer>
          マイページのお支払い情報にて、明細が記載された領収書をダウンロードいただけます。<br>
          詳細は<a
            class="text-primary underline"
            href="https://support.cotobox.com/process-receipt"
            target="_blank"
          >こちら</a>をご確認くださいませ。
        </template>
      </AccordionFAQ>
      <AccordionFAQ class="mb-4">
        <template #question>
          <span>Q このあとは何をすればいいですか？</span>
        </template>
        <template #answer>
          <span v-if="paymentType === 'bank'">
            銀行振り込みをご選択の場合は、料金のお振込をお願いいたします。<br>
            <br>
            お支払い完了後は、
          </span>
          担当の提携弁理士からのご連絡をお待ちくださいませ。<br>
          通常1営業日以内にお届けいたします。<br>
          <br>
          弁理士からの連絡は、マイページ内の商標ごとの専用ページのメッセージ機能（弁理士メッセージ）に届きます。<br>
          なお、新規の弁理士メッセージが届いた際には、ご登録のメールアドレスに通知されます。<br>
          <strong class="font-bold">
            大事なご案内のため、弁理士メッセージ、および通知メールのお受け取りを必ずご確認いただきますようお願いいたします。
          </strong>
          <br>
          <br>
          お申し込み後の流れについての詳細は、<a
            class="text-primary underline"
            href="https://support.cotobox.com/process-application-cotobox"
            target="_blank"
          >こちら</a>をご参照くださいませ。
        </template>
      </AccordionFAQ>
      <AccordionFAQ class="mb-4">
        <template #question>
          <span>Q 準備するものはありますか？</span>
        </template>
        <template #answer>
          通常は、書類（身分証や証明書）などをご用意いただくことはありません。<br>
          <br>
          ただし、このあとの担当の提携弁理士とのやりとりにおいては、どうしても専門用語が多く出てしまいます。<br>
          あらかじめ<a
            class="text-primary underline"
            href="https://support.cotobox.com/process-application-cotobox"
            target="_blank"
          >お申し込み後の流れ</a>や、<a
            class="text-primary underline"
            href="https://support.cotobox.com/word"
            target="_blank"
          >商標登録の用語集</a>をご確認いただくことをおすすめいたします。
        </template>
      </AccordionFAQ>
      <AccordionFAQ>
        <template #question>
          <span>Q 申し込み内容を間違えていた／キャンセルしたい</span>
        </template>
        <template #answer>
          出願書類の最終決定前であれば、出願内容の変更・キャンセルが可能です。<br>
          <br>
          特許庁の提出前に出願書類の最終チェックを行っていただきますので、その内容にご同意いただく前にご申告ください。<br>
          ご申告は、<a
            class="text-primary underline"
            href="/app/mypage"
            target="_blank"
          >マイページ</a>の弁理士メッセージにて、担当者弁理士へ直接ご連絡ください。
        </template>
      </AccordionFAQ>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import FlowAfterApply from '@/components/applyCompletion/FlowAfterApply.vue';
import AssignedAttorneyOffice from '@/components/applyCompletion/AssignedAttorneyOffice.vue';
import AccordionFAQ from '@/components/applyCompletion/AccordionFAQ.vue';
import DestinationBankAccount from '@/components/applyCompletion/DestinationBankAccount.vue';
import Stepper from '@/components/common/Stepper.vue';
import { RouterLink, useRoute } from 'vue-router';
import { UserDataStore } from '@/composables/useUserStore';
import { UserDataKey } from '@/composables/injectionKeys';
import {
  useBankAccountStore,
  BankAccount,
} from '@/composables/useBankAccountStore';

const route = useRoute();
const paymentId = Number(route.params.id);
type PaymentType = 'credit' | 'bank';

const paymentType = ref<PaymentType>('credit');

const bankAccountStore = useBankAccountStore();
bankAccountStore.getBankAccount(paymentId);
const bankAccount = computed((): BankAccount => {
  return bankAccountStore.bankAccount;
});
function isPaymentType (value: string): value is PaymentType {
  return ['credit', 'bank'].includes(value);
}

onMounted(() => {
  const payment_type = route.query.payment_type as string;
  if (payment_type && isPaymentType(payment_type)) {
    paymentType.value = payment_type;
  }
});

const { state } = inject(UserDataKey) as UserDataStore;

const { t } = useI18n({
  messages: {
    ja: {
      payByBank: '銀行振込を行いましょう',
      applyCompletion: '出願自体はまだ完了していません',
      applyCompletionMemoCredit:
        'お申し込み後は、担当の提携弁理士と書類確認作業が必要です。\nこのあとのお手続きについて、マイページ内の専用ページ（弁理士メッセージ）に担当者から連絡が届きますのでお待ちくださいませ。\nご連絡はアカウント情報のメールアドレスに通知されますので、必ずご確認ください。',
      applyCompletionMemoBank:
        'ご登録のメールアドレスへ請求書をお送りしました。\nメール本文記載の口座番号に振込をお願いします。\n\n振込確認後、担当の提携弁理士と書類確認作業が必要です。\nご連絡はアカウント情報のメールアドレスに通知されますので、必ずご確認ください。',
      assignedAttorney: '担当する提携事務所',
      paymentDestination: '支払い先',
      paymentDestinationMemo:
        '登録メール・マイページからも振込先情報の確認ができます。',
      expires: '期限：',
      payBankMemo:
        '銀行振込後、登録されたメールアドレス宛に担当の弁理士から受任通知のメールが送信されます。',
      downloadInvoice: '請求書をダウンロード',
      flowAfterApply: '今後のフロー',
      viewFlow: '今後の流れを確認する（よくあるご質問）',
      viewApply: 'お申し込み内容の確認(マイページ)',
    },
    en: {
      payByBank: 'Make a bank transfer',
      applyCompletion: 'The application itself is not yet complete',
      applyCompletionMemoCredit:
        'The application is currently complete. Your application will be complete when the following flow is completed.',
      applyCompletionMemoBank:
        'The application is currently complete. Your application will be complete when the following flow is completed.',
      assignedAttorney: 'AssignedAttorney',
      paymentDestination: 'Payment Destination',
      paymentDestinationMemo:
        'You can also check your payee information from your registration email and My Page',
      expires: 'Due date:',
      payBankMemo:
        'After the bank transfer, an email notification of acceptance will be sent to the registered email address from the patent attorney in charge.',
      downloadInvoice: 'Download invoice',
      flowAfterApply: 'Future flow',
      viewFlow: 'Details of the flow after your invoice',
      viewApply: 'Review your application (My Page)',
    },
    zh: {
      payByBank: '进行银行转账',
      applyCompletion: '应用程序本身还没有完成',
      applyCompletionMemoCredit:
        '该申请目前已经完成, 当以下流程完成后，你的申请将是完整的',
      applyCompletionMemoBank:
        '该申请目前已经完成, 当以下流程完成后，你的申请将是完整的',
      assignedAttorney: 'AssignedAttorney',
      paymentDestination: '付款目的地',
      paymentDestinationMemo:
        '你也可以从你的注册邮件和MyPage中查看你的收款人信息',
      expires: '到期日：',
      payBankMemo:
        '银行转账后，负责的专利代理人将向注册的电子邮件地址发送接受通知',
      downloadInvoice: '下载发票',
      flowAfterApply: '未来流',
      viewFlow: '你的发票后的流程细节',
      viewApply: 'Review your application (My Page)',
    },
  },
});
</script>
