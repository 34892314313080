export const zh = {
  zh: {
    amountTitle: '支付金额',
    amountValue: '{0}日元',
    cardNumber: 'CARD NUMBER',
    expires: 'EXPERATION DATE',
    cvc: 'CVC',
    card: '信用卡',
    bankTransfer: '银行转账',
    submit: 'PAY NOW',
    submitBank: '用发票付款（银行转账）',
    description:
      '我们使用Stripe系统进行信用卡支付。\n您提供的信用卡信息由Stripe管理，我们不保留。',
    last4Digits: 'last 4 digit',
    payWithAnotherCard: '用另一张卡支付',
    bank_description: {
      line1: '客户负责支付<b>转帐费用</b>。',
      line2: '请在7天内转账。',
      line3: '请查看你申请后发出的电子邮件的正文，以了解应转入的账户号码。',
    },
    errors: {
      notFoundStripeAccount:
        '获取Stripe账户失败：请重新加载屏幕并再次尝试。{0}',
      pleaseReflesh: '请重新加载屏幕并重新输入信息。{0}',
      pleaseRefleshAndTurnOffAd:
        '请重新加载屏幕并重新输入信息。\n请关闭广告拦截插件。{0}',
      apiConnectionError: '无法连接到支付系统。请检查您是否已连接到网络。{0}',
      apiError:
        '支付系统有一个临时故障。我们很抱歉，但请等待一些时间，并重新进行付款。{0}',
      cardError: '支付失败。请检查该卡是否有效。{0}',
      rateLimitError:
        '发生错误是因为同时申请了几笔付款。请等待一段时间并重新付款。{0}',
      validationError: '请检查输入的银行卡信息是否正确。{0}',
      other: '付款发生了错误。请通过聊天与我们联系。{0}',
    },
    processing: '您的付款正在处理中。 请不要离开这个页面。',
    complete: '付款已完成。',
    completeBank: '用于转账的银行账户已发送至您的电子邮件；请在 7 天内转账。',
  },
};
