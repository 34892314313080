<template>
  <div
    ref="root"
    :class="`${$options.name}`"
  >
    <div
      ref="overlay"
      :class="`${$options.name}__overlay`"
    >
      <div :class="`${$options.name}__content`">
        <div
          :class="`${$options.name}__indicator`"
          :style="{ backgroundImage: `url('${Loading}')` }"
        />
        <p
          v-if="message"
          :class="`${$options.name}__message`"
        >
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, onMounted, onBeforeUnmount } from 'vue';
import Loading from '@/assets/images/icons/loading.gif';

export default defineComponent({
  name: 'TheOverlayLoading',
  props: {
    message: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup () {
    const root = ref(null) as Ref<null | HTMLElement>;

    onMounted(() => {
      if (root.value) {
        document.body.appendChild(root.value);
      }
    });

    onBeforeUnmount(() => {
      if (root.value && document.body.contains(root.value)) {
        document.body.removeChild(root.value);
      }
    });

    return {
      root,
      Loading,
    };
  },
});
</script>

<style lang="scss" scoped>
.TheOverlayLoading {
  position: fixed;
  display: table;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;

  @include pc {
    width: 1000px;
    bottom: 0;
  }

  @include sp {
    width: 100vw;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 6;
    transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &__content {
    display: table;
    position: absolute;
    top: calc(50vh - 60px);
    left: 0;
    right: 0;
    width: 100vw;
    margin: auto;
    z-index: 13;
  }

  &__indicator {
    height: 40px;
    background-color: transparent;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__message {
    color: #fff;
    margin-top: 16px;
    text-align: center;
  }
}
</style>
