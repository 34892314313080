<template>
  <div class="flex items-center justify-between">
    <div class="text-grayAlpha80 text-sm tracking-wider">
      {{ label }}
    </div>
    <div
      class="text-grayAlpha80 text-right text-base font-semibold tracking-wider"
    >
      {{ priceText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

export type PriceGroupDetailRowProps = {
  label: string;
  price: number;
};

const props = defineProps<PriceGroupDetailRowProps>();
const priceText = computed(() => `${props.price.toLocaleString()}円`);
</script>
