<script setup lang="ts">
import { inject, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplicationListKey } from '@/composables/injectionKeys';
import { ApplicationListStore } from '@/composables/useApplicationList';
import { TrademarkApplication } from '@/api/APIClientInterface';

interface Props {
  application: TrademarkApplication;
}

const props = defineProps<Props>();

const { state, applicationStatusWithTrademarkAdminStatus } = inject(
  ApplicationListKey,
) as ApplicationListStore;

const { locale } = useI18n<object, 'ja' | 'en' | 'zh'>({ useScope: 'global' });

const { t } = useI18n({
  messages: {
    ja: {
      status0: '出願準備中',
      status1: '弁理士承諾待ち',
      status2: '弁理士作業中',
      status3: 'ご確認ください',
      status4: '提出準備中',
      status5: '要確認',
      status6: '拒絶対応申込前',
      status7: 'お支払い待ち',
      status8: '作業中',
      status9: '登録待ち',
      status10: '特許庁審査中',
      status11: '特許庁から通知',
      status12: '権利取得済み',
      status13: '表示変更',
      status14: '権利移転',
      status15: '更新管理',
      status16: '申込前',
      status17: '提出準備中',
      replyRequired: '要返信',
    },
    en: {
      status0: 'Preparing',
      status1: 'Waiting',
      status2: 'In progress',
      status3: 'Please check',
      status4: 'Preparing',
      status5: 'Confirm',
      status6: 'Apply for rejection',
      status7: 'Waiting payment',
      status8: 'In progress',
      status9: 'Waiting Regitration',
      status10: 'Under examination',
      status11: 'Notice from JPO',
      status12: 'Registered',
      status13: 'Change',
      status14: 'Transfer',
      status15: 'Update',
      status16: 'Not yet',
      status17: 'Preparing',
      replyRequired: 'Reply required',
    },
    zh: {
      status0: '准备中的申请',
      status1: '正在等待专利律师的批准',
      status2: '工作中的专利律师',
      status3: '请检查',
      status4: '准备提交',
      status5: '需要确认',
      status6: '拒绝申请前',
      status7: '等待付款',
      status8: '正在进行的工作',
      status9: '等待登记',
      status10: '正在接受专利局的审查',
      status11: '由专利局通知',
      status12: '获得的',
      status13: '显示改变',
      status14: '权利的转让',
      status15: '更新管理',
      status16: '应用前',
      status17: '准备提交',
      replyRequired: '需要答复',
    },
  },
});

const trademarkRightStatusLabel = computed(() => {
  switch (
    applicationStatusWithTrademarkAdminStatus(props.application.status.admin)
      .step
  ) {
  case 10:
    return t('status13');
  case 11:
    return t('status14');
  case 12:
    return t('status15');
  default:
    return '';
  }
});
</script>

<script lang="ts">
export default {
  name: 'MyTrademarkTableRowDesktop',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<template>
  <a
    :href="`/app/mypage/applications/${application.id}`"
    :class="`${$options.name}`"
    :data-trademark-text="application.standardCharacter"
  >
    <div :class="`${$options.name}__trademarkColumn`">
      <div
        :class="`${$options.name}__trademarkThumbnail`"
        :style="{
          backgroundImage:
            application.trademarkType === 'image'
              ? `url(${application.imageURL})`
              : 'none',
        }"
      >
        {{
          application.trademarkType === 'text'
            ? application.standardCharacter
            : ''
        }}
      </div>
      <div :class="`${$options.name}__trademarkHelperText`">
        {{ application.status.user }}
      </div>
    </div>
    <template v-if="state.selectedListType === 'submitted'">
      <div :class="`${$options.name}__referenceNumberColumn`">
        {{ application.referenceNumber || t('status0') }}
      </div>
      <div :class="`${$options.name}__applyNumberColumn`">
        {{ application.applicationNumber || t('status0') }}
      </div>
      <div :class="`${$options.name}__applyDateColumn`">
        {{ application.submissionDate || t('status0') }}
      </div>
      <div :class="[`${$options.name}__statusColumn`, '_step1']">
        <div
          v-if="
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .step === 0
          "
        >
          {{
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .user[locale]
          }}
        </div>
        <i
          v-else-if="
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .step > 0
          "
          class="material-icons material-symbols-outlined text-primary text-xl"
        >
          panorama_fish_eye
        </i>
        <div
          v-else
          class="_inactivated"
        />
      </div>
      <div :class="[`${$options.name}__statusColumn`, '_step2']">
        <div
          v-if="
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .step === 1
          "
        >
          {{
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .user[locale]
          }}
        </div>
        <i
          v-else-if="
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .step > 1
          "
          class="material-icons material-symbols-outlined text-primary text-xl"
        >
          panorama_fish_eye
        </i>
        <div
          v-else
          class="_inactivated"
        />
      </div>
      <div :class="[`${$options.name}__statusColumn`, '_step3']">
        <div
          v-if="
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .step === 2
          "
        >
          {{
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .user[locale]
          }}
        </div>
        <i
          v-else-if="
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .step > 2
          "
          class="material-icons material-symbols-outlined text-primary text-xl"
        >
          panorama_fish_eye
        </i>
        <div
          v-else
          class="_inactivated"
        />
      </div>
      <div :class="[`${$options.name}__statusColumn`, '_step4']">
        <div
          v-if="
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .step === 3
          "
        >
          {{
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .user[locale]
          }}
        </div>
        <i
          v-else-if="
            applicationStatusWithTrademarkAdminStatus(application.status.admin)
              .step > 3
          "
          class="material-icons material-symbols-outlined text-primary text-xl"
        >
          panorama_fish_eye
        </i>
        <div
          v-else
          class="_inactivated"
        />
      </div>
      <div :class="`${$options.name}__messageColumn`">
        <a
          :href="`/app/mypage/applications/${application.id}/inquiries`"
          :class="`${$options.name}__messageIcon`"
          target="_blank"
        >
          <i
            class="material-icons material-symbols-outlined absolute text-[#383D51]"
          >email</i>
        </a>
        <div
          v-if="application.existsUnrepliedMessages"
          :class="`${$options.name}__messageIconBadge`"
        >
          {{ t('replyRequired') }}
        </div>
      </div>
    </template>
    <template v-if="state.selectedListType === 'accepted'">
      <div :class="`${$options.name}__referenceNumberColumn`">
        {{ application.referenceNumber || '' }}
      </div>
      <div :class="`${$options.name}__applyNumberColumn`">
        {{ application.registrationNumber || '' }}
      </div>
      <div :class="`${$options.name}__applyDateColumn`">
        {{ application.registrationDate || '' }}
      </div>
      <div :class="`${$options.name}__trademarkRightStatusColumn`">
        <div :class="`${$options.name}__trademarkRightStatusContent`">
          <span :class="`${$options.name}__trademarkRightStatusTitle`">
            {{ t('status12') }}
          </span>
          <span :class="`${$options.name}__trademarkRightStatusInfo`">
            <span :class="`${$options.name}__trademarkRightStatusLabel`">{{
              trademarkRightStatusLabel
            }}</span>
            <span :class="`${$options.name}__trademarkRightStatusIcon`" />
            <span :class="`${$options.name}__trademarkRightStatusDescription`">
              {{
                applicationStatusWithTrademarkAdminStatus(
                  application.status.admin,
                ).user[locale]
              }}
            </span>
          </span>
        </div>
      </div>
      <div :class="`${$options.name}__messageColumn`">
        <a
          :href="`/app/mypage/applications/${application.id}/inquiries`"
          :class="`${$options.name}__messageIcon`"
          target="_blank"
        >
          <i
            class="material-icons material-symbols-outlined absolute text-[#383D51]"
          >email</i>
        </a>
        <div
          v-if="application.existsUnrepliedMessages"
          :class="`${$options.name}__messageIconBadge`"
        >
          {{ t('replyRequired') }}
        </div>
      </div>
    </template>
    <template v-if="state.selectedListType === 'saved'" />
    <template v-if="state.selectedListType === 'cancelled'">
      <div :class="`${$options.name}__referenceNumberColumn`">
        {{ application.referenceNumber || '' }}
      </div>
      <div :class="`${$options.name}__applyNumberColumn`">
        {{ application.applicationNumber || '' }}
      </div>
    </template>
  </a>
</template>

<style lang="scss" scoped>
.MyTrademarkTableRowDesktop {
  display: grid;
  grid-template-columns: 80px 100px 110px 120px 90px 90px 90px 90px 100px;
  column-gap: 16px;
  padding: 16px 32px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  letter-spacing: 0.6px;

  &:hover {
    background-color: #fff4f1;
  }

  &__trademarkColumn {
    display: flex;
    align-items: center;
    display: block;
    grid-row: 1/2;
  }

  &__trademarkThumbnail {
    border: 1px solid #eee;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    word-break: break-all;

    @include pc {
      width: 80px;
      height: 80px;
    }

    @include sp {
      width: 56px;
      height: 56px;
    }
  }

  &__trademarkHelperText {
    color: $primary-color;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.6px;
    text-align: center;
    margin-top: 4px;
  }

  &__referenceNumberColumn {
    display: flex;
    align-items: center;
    grid-row: 1/2;
    color: #2962ff;
    font-size: 14px;
    justify-content: left;
  }

  &__applyNumberColumn {
    display: flex;
    align-items: center;
    grid-row: 1/2;
    color: #555;
    font-size: 14px;
    justify-content: left;
    white-space: nowrap;
  }

  &__applyDateColumn {
    display: flex;
    align-items: center;
    grid-row: 1/2;
    color: #555;
    font-size: 14px;
    justify-content: left;
  }

  &__statusColumn {
    grid-row: 1/2;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-right: 0;
    }

    .mdi {
      font-size: 20px;
    }

    .mdi-circle-outline {
      color: $primary-color;
    }

    ._inactivated {
      width: 14px;
      height: 2px;
      background-color: #ccc;
    }

    ._action {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  &__trademarkRightStatusColumn {
    display: flex;
    align-items: center;
    grid-column: 5/9;
    grid-row: 1/2;
  }

  &__trademarkRightStatusContent {
    display: flex;
    align-items: center;
    background-color: #eee;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    padding: 0 12px;
  }

  &__trademarkRightStatusTitle {
    color: #999;
    font-weight: bold;
    letter-spacing: 0.6px;
  }

  &__trademarkRightStatusInfo {
    margin-left: 120px;
  }

  &__trademarkRightStatusLabel {
    color: #555;
    font-weight: bold;
    margin-right: 16px;
  }

  &__trademarkRightStatusIcon {
    margin-right: 6px;
  }

  &__trademarkRightStatusDescription {
    color: #222;
  }

  &__messageColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row: 1/2;
    justify-content: center;
  }

  &__messageIcon {
    border-radius: 20px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 24px;
    margin-bottom: 4px;

    .mdi-email {
      position: absolute;
      color: #383d51;
    }
  }

  &__messageIconBadge {
    white-space: nowrap;
  }
}
</style>
