<template>
  <teleport to="body">
    <div
      v-show="isVisible"
      id="modal"
      class="bg-grayAlpha60 fixed bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center"
      @click="onClose"
    />
    <div
      v-show="isVisible"
      class="fixed bottom-0 left-6 right-6 top-0 m-auto table max-w-[90vw] rounded-lg bg-white sm:left-0 sm:right-0 sm:max-w-[620px]"
    >
      <button
        class="material-icons absolute -top-8 right-0 font-bold text-white"
        @click="onClose"
      >
        close
      </button>
      <slot />
    </div>
  </teleport>
</template>

<script setup lang="ts">
interface Props {
  isVisible: boolean;
}

defineProps<Props>();

interface Emits {
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();

function onClose () {
  emit('close');
}
</script>
