<template>
  <div class="mx-auto sm:w-4/5">
    <div class="flex items-center">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="mr-2 last:mr-0"
        :style="{ width: `calc(100% / ${steps.length})` }"
      >
        <div
          class="text-grayAlpha80 mb-6 text-center text-base font-semibold tracking-wider"
        >
          {{ step.title }}
        </div>
        <div
          class="mx-auto h-10 w-10 rounded-full border-solid border-[#c4c4c4]"
          :class="
            step.type === 'current'
              ? 'bg-primary border-0'
              : step.type === 'done'
                ? 'border-0 bg-[#514C4C]'
                : 'border'
          "
        />
        <div class="relative h-1">
          <div
            v-if="index !== steps.length - 1"
            class="absolute left-[calc(50%_+_30px)]"
            :class="`w-[calc(100%_-_60px)]`"
          >
            <div
              v-if="step.nextText !== undefined && $device.isDesktop"
              class="absolute bottom-[20px] mb-2 flex h-[28px] w-full"
            >
              <div
                class="bg-primary w-full py-1 text-center text-sm font-bold text-white"
              >
                {{ speedPlan ? '2〜3ヶ月' : '5〜9ヶ月' }}
              </div>
              <div
                class="bg-primary border-l-primary border-y-[14px] border-l-[14px] border-solid border-y-[#f4f4f4]"
              />
            </div>
            <div class="absolute bottom-[19px] h-[1px] w-full bg-[#c4c4c4]" />
          </div>
        </div>
        <div
          class="mt-4 text-center text-base font-semibold tracking-wider"
          :class="step.type === 'current' ? 'text-primary' : 'text-[#666]'"
        >
          {{
            step.price === undefined
              ? '  '
              : step.title === '商標登録'
                ? registrationSubTotalFeeText
                : step.price
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { ApplyFormKey, UserDataKey } from '@/composables/injectionKeys';
import { ApplyFormStore } from '@/composables/useApplyForm';
import { usePriceCalculator } from '@/composables/usePriceCalculator';
import { UserDataStore } from '@/composables/useUserStore';

type RegistPeriod = '5years' | '10years';

const props = defineProps<{
  registPeriod: RegistPeriod;
}>();

const { state: applyFormState, klassCount } = inject(
  ApplyFormKey,
) as ApplyFormStore;

const {
  state: userData,
  authenticated,
  isAttorneyCorporation,
} = inject(UserDataKey) as UserDataStore;

const premiumPlan = computed(() => applyFormState.premiumPlan);
const speedPlan = computed(() => applyFormState.speedPlan);
const isPersonal = computed(() => userData.isPersonal);
const is10yearRegistration = computed(() => props.registPeriod === '10years');

const { applySubTotalFee, registrationSubTotalFee } = usePriceCalculator({
  klassCount,
  premiumPlan,
  speedPlan,
  authenticated,
  isPersonal,
  isAttorneyCorporation,
  is10yearRegistration,
});

const registrationSubTotalFeeText = computed(
  () => `${registrationSubTotalFee.value.toLocaleString()}円`,
);

const steps = computed(() => {
  return [
    { title: '商標検索', nextText: undefined, price: '無料', type: 'done' },
    {
      title: '商標出願',
      nextText: speedPlan.value ? '2〜3ヶ月' : '5〜9ヶ月',
      price: `${applySubTotalFee.value.toLocaleString()}円`,
      type: 'current',
    },
    {
      title: '商標登録',
      nextText: undefined,
      price: registrationSubTotalFeeText.value,
      type: 'other',
    },
  ];
});
</script>
