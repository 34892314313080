<template>
  <div class="sm:grid sm:grid-cols-2 sm:grid-rows-1 sm:gap-x-4">
    <FormTextInput
      title="商標"
      name="standard-character"
      placeholder="例：Cotobox"
      :required="false"
      :shows-required-label="false"
      :shows-error="false"
      :model-value="standardCharacter"
      @update:model-value="setTrademarkText"
    />
    <FormTextInput
      class="mt-4 sm:mt-0"
      title="よみがな(権利範囲の対象ではありません)"
      name="yomi"
      placeholder="例：ことぼっくす"
      :required="false"
      :shows-required-label="false"
      :shows-error="false"
      :model-value="inputtedYomi"
      @update:model-value="setInputtedYomi"
    >
      <template #default>
        <div class="ml-1 h-[20px] mt-[-2px]">
          <HelpLink
            title=""
            @click="updateShowsHelpPronunciation(true)"
          />
        </div>
      </template>
    </FormTextInput>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import { TrademarkSearchStoreKey } from '@/composables/injectionKeys';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
import HelpLink from '@/components/common/HelpLink.vue';

defineProps({
  showsHelpPronunciation: Boolean,
});

const emit = defineEmits(['update:showsHelpPronunciation']);
const updateShowsHelpPronunciation = (value: boolean) => emit('update:showsHelpPronunciation', value);

const { standardCharacter, inputtedYomi, setTrademarkText, setInputtedYomi } =
  inject(TrademarkSearchStoreKey) as TrademarkSearchStore;
</script>
