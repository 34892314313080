<template>
  <div
    id="payemnt-detail-body"
    class="px-5 py-5"
  >
    <!-- ヘッダー -->
    <div
      class="border-borderGray mb-4 border-b border-solid px-1 py-2 text-2xl font-bold font-semibold tracking-wider text-black"
    >
      {{ t('payment.title') }}
    </div>
    <!-- 支払ステータス -->
    <div>
      <div class="mb-4 flex items-center">
        <PaymentStatusIcon :status="payment.paymentStatus" />
        <div class="font-base mr-2 font-bold">
          {{ payment.paymentStatusName }}
        </div>
        <div class="text-grayAlpha60 text-sm">
          {{ payment.succeededAt }}
        </div>
      </div>

      <!-- 費用項目 -->
      <table class="w-full table-auto">
        <thead>
          <tr class="border-b border-solid">
            <th>{{ t('payment.item.name') }}</th>
            <th>{{ t('payment.item.class') }}</th>
            <th>{{ t('payment.item.taxRate') }}</th>
            <th class="text-right">
              {{ t('payment.item.amount') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in payment.items"
            :key="key"
            class="border-grayAlpha10 border-b border-solid"
          >
            <td class="py-2">
              {{ item.name }}
            </td>
            <td class="py-2">
              {{ item.numberOfClasses }}
            </td>
            <td class="py-2">
              {{ item.taxRate }}
            </td>
            <td class="py-2 text-right">
              ¥ {{ item.amount.toLocaleString() }}
            </td>
          </tr>
          <tr class="">
            <td class="py-2" />
            <td class="py-2" />
            <td class="border-grayAlpha10 border-b-2 border-solid py-2">
              {{ t('payment.tax') }}
            </td>
            <td
              class="border-grayAlpha10 border-b-2 border-solid py-2 text-right"
            >
              ¥ {{ payment.tax.toLocaleString() }}
            </td>
          </tr>
          <tr class="border-b font-bold">
            <td class="py-2" />
            <td class="py-2" />
            <td class="py-2">
              {{ t('payment.total') }}
            </td>
            <td class="py-2 text-right">
              ¥ {{ payment.total.toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>
      <!-- 銀行振込先 -->
      <div
        v-if="payment.paymentStatus !== 'success' && payment.stripeInvoiceNo"
        class="mb-2 flex flex-col"
      >
        <div class="mb-2 w-full font-bold">
          {{ t('payment.bankHeader') }}
        </div>
        <div
          class="bg-primaryOrange5 border-primary w-full rounded border-2 border-solid p-4"
        >
          <div class="flex">
            <!-- 銀行名 -->
            <div class="w-24">
              {{ t('payment.bankName') }}
            </div>
            <div>
              {{ bankAccountStore.bankName }}
            </div>
          </div>
          <div class="flex">
            <!-- 支店名 -->
            <div class="w-24">
              {{ t('payment.branchName') }}
            </div>
            <div>
              {{ bankAccountStore.branchName }}
            </div>
          </div>
          <div class="flex">
            <!-- 口座の書類 -->
            <div class="w-24">
              {{ t('payment.accountType') }}
            </div>
            <!-- 普通預金口座 -->
            <div>
              {{ t('payment.savingAccount') }}
            </div>
          </div>
          <div class="flex">
            <!-- 口座番号 -->
            <div class="w-24">
              {{ t('payment.accountNumber') }}
            </div>
            <div>
              {{ bankAccountStore.accountNumber }}
            </div>
          </div>
          <div class="flex">
            <!-- 口座名義人 -->
            <div class="w-24">
              {{ t('payment.accountHolderName') }}
            </div>
            <div>
              {{ bankAccountStore.accountHolderName }}
            </div>
          </div>
          <div class="text-red100 flex font-bold">
            <!-- 振込期限 -->
            <div class="w-24">
              {{ t('payment.bankPaymentExpiresDate') }}
            </div>
            <div>
              {{ bankAccountStore.bankPaymentExpiresDate }}
            </div>
          </div>
        </div>
        <div class="mt-1 text-sm">
          ※ {{ t('payment.note') }}
        </div>
      </div>
    </div>
    <div class="flex justify-end pt-8">
      <PrimaryButton
        v-if="payment.stripeInvoiceNo"
        :title="t('payment.invoice')"
        class="mr-4"
        :material-icon-name="'download'"
        @click="handleInvoiceDownload"
      />
      <PrimaryButton
        :title="t('payment.receipt')"
        :material-icon-name="'download'"
        :disabled="!payment.isValidExportReceipt"
        @click="handleReceiptDownload"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePaymentStore } from '@/composables/usePaymentStore';
import { useBankAccountStore } from '@/composables/useBankAccountStore';
import { useI18n } from 'vue-i18n';
import { ja } from '@/i18n/pages/app/applications/ja';
import PaymentStatusIcon from '@/components/pages/app/applications/PaymentStatusIcon.vue';

const paymentStore = usePaymentStore();
const payment = paymentStore.current;
const bankAccountStore = useBankAccountStore();
const { t } = useI18n({ messages: ja });

if (payment.stripeInvoiceNo) {
  bankAccountStore.getBankAccount(payment.id);
}

// 領収書ダウンロード
const handleReceiptDownload = () => {
  const appId = payment.applicationId;
  const id = payment.id;
  const url = `/app/mypage/applications/${appId}/payments/${id}/export_receipt_pdf.pdf`;
  window.open(url, '_blank');
};

// 請求書ダウンロード
const handleInvoiceDownload = () => {
  window.open(bankAccountStore.invoicePdfUrl, '_blank');
};
</script>
<style scoped>
/* tailwind の sm:w-[480px] が効かないのでとりあえず対処 */
@media screen and (max-width: 640px) {
  #payemnt-detail-body {
    width: 340px;
  }
}
@media screen and (min-width: 640px) {
  #payemnt-detail-body {
    width: 480px;
  }
}
</style>
