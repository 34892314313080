<template>
  <div>
    <div class="h-10 whitespace-pre-line text-center text-sm tracking-wider">
      {{ description }}
    </div>
    <div
      class="h-14 w-full bg-contain bg-center bg-no-repeat"
      :class="bgImage"
    />
  </div>
</template>

<script setup lang="ts">

const props = defineProps({
  direction: {
    type: String,
    required: true,
    validator: (value: string) => ['left', 'right'].includes(value),
  },
  description: {
    type: String,
    required: true,
  },
});

const bgImage = `bg-${props.direction}-arrow`;
</script>

<style scoped>
.bg-left-arrow {
  background-image: url('@/assets/images/creatives/arrow-left.svg');
}
.bg-right-arrow {
  background-image: url('@/assets/images/creatives/arrow-right.svg');
}
</style>
