<template>
  <nav
    class="col-start-1 col-end-2 row-start-2 row-end-3 flex self-end text-white"
  >
    <router-link
      :to="{ name: 'SearchPage' }"
      :class="
        getLinkClass({ routeName: 'SearchPage', currentRouteName: $route.name })
      "
    >
      {{ t('search') }}
    </router-link>
    <router-link
      :to="{ name: 'PlanPage' }"
      :class="
        getLinkClass({ routeName: 'PlanPage', currentRouteName: $route.name })
      "
    >
      {{ t('price') }}
    </router-link>
    <a
      :href="/testmonials/"
      :class="aLinkClass"
    >
      {{ t('cases') }}
    </a>
    <a
      :href="/primer/"
      :class="aLinkClass"
    >
      {{ t('primer') }}
    </a>
    <router-link
      :to="{ name: 'EnterprisePlanPage' }"
      :class="
        getLinkClass({
          routeName: 'EnterprisePlanPage',
          currentRouteName: $route.name,
        })
      "
    >
      {{ t('enterprise') }}
    </router-link>
  </nav>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const getLinkClass = ({
  routeName,
  currentRouteName,
}: {
  routeName: string;
  currentRouteName: string | symbol | null | undefined;
}) => {
  return routeName === currentRouteName
    ? currentPageRouteLinkClass
    : routeLinkClass;
};

const { t } = useI18n({
  messages: {
    ja: {
      primer: '商標入門ガイド',
      about: 'Cotoboxとは？',
      price: '料金シミュレーション',
      cases: '導入事例',
      search: '商標を検索・申し込み',
      enterprise: '商標管理クラウド',
    },
    en: {
      primer: 'Trademark Primer',
      about: 'What\'s Cotobox',
      price: 'Pricing',
      cases: 'Case Studies',
      search: 'Search trademark',
      enterprise: 'enterprise plan',
    },
    zh: {
      primer: '商标入门',
      about: '什么是Cotobox',
      price: '价格',
      cases: '案例研究',
      search: '搜索商标',
      enterprise: '商标管理云',
    },
  },
});

const aLinkClass =
  'block no-underline text-white whitespace-nowrap border-b-[3px] border-solid border-[transparent] py-2 px-5 text-sm tracking-widest';
const baseRouteLinkClass =
  'block no-underline text-white whitespace-nowrap border-b-[3px] border-solid py-2 px-5 text-sm tracking-widest';
const routeLinkClass = `${baseRouteLinkClass} border-[transparent]`;
const currentPageRouteLinkClass = `${baseRouteLinkClass} border-primary`;
</script>
