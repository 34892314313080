<template>
  <div class="bg-bgGray py-10 sm:py-16 sm:pb-[60px]">
    <SectionHeader
      class="mb-6 sm:pb-14"
      text="導入事例"
    />
    <div
      v-if="$device.isDesktop"
      class="mx-auto grid grid-cols-3 grid-rows-2 gap-x-8 gap-y-12 px-0 sm:w-[1121px]"
    >
      <TopPageCaseCard
        v-for="item in cases"
        :key="item.title"
        :item="item"
      />
    </div>
    <div v-if="$device.isMobile">
      <Carousel
        :autoplay="2000"
        :wrap-around="true"
      >
        <Slide
          v-for="item in cases"
          :key="item.title"
        >
          <TopPageCaseCard
            class="mx-11 w-full"
            :item="item"
          />
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script setup lang="ts">
import SectionHeader from './SectionHeader.vue';
import TopPageCaseCard, { TopPageCase } from './TopPageCaseCard.vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

const cases: TopPageCase[] = [
  {
    thumbnail:
      'https://cotobox.com/wp-content/themes/cotobox/images/lp/case_paypay.jpg',
    title: '経験豊富な提携弁理士が対応してくれるため、安心して出願ができます',
    description:
      'PayPay株式会社は、キャッシュレス決済サービスを提供しています。\n超ペイペイ祭というキャンペーンで、「ペイペ」というキャラクターの起用を検討している過程で、このキャラクターを知財で保護するため、商標出願することにしました。\nCotoboxでは、不明点をチャットで聞きながら迅速に手続を進められ、また、従来の特許事務所に依頼した場合のコストと比較してリーズナブルな料金が提示されているのも魅力でした。',
    href: 'https://cotobox.com/testmonials/paypay/',
  },
  {
    thumbnail:
      'https://cotobox.com/wp-content/uploads/2023/05/unique-cotobox-e1684133510226.png',
    title: 'アイデンティティであるネーミングを大切にしたい',
    description:
      '「ユニークキャリア」は、キャリアや転職に特化したウェブメディアです。\nCotoboxではスムーズに商標出願の申し込みができ、ストレスがありませんでした。忙しい人にこそおすすめです。',
    href: 'https://cotobox.com/testmonials/uniquecareer/',
  },
  {
    thumbnail: 'https://cotobox.com/wp-content/uploads/2023/05/amptalk.png',
    title: '相談から登録までスムーズに行うことができました',
    description:
      'これまでの経験により「商標を登録する」ことは当たり前でした。\nCotoboxでは不明点もチャットですぐに対応してもらえたので、相談から登録までスムーズに行うことができました。',
    href: 'https://cotobox.com/testmonials/amptalk/',
  },
  {
    thumbnail: 'https://cotobox.com/wp-content/uploads/2021/04/2.png',
    title: '【10ヶ月かかる商標登録が2ヶ月で】差別化の為の農法を商標登録',
    description:
      '商標取得に10ヶ月程かかるところが、早期審査制度を利用して2ヶ月くらいで取得できました。\nオンラインの為、万が一類似した商標が先行して取得されていた場合、取得ができないのではないか...？といった不安もありましたが、そもそも弁理士さんに調査を頼むとそれだけで高額な料金が発生します。\n今回スムーズに取得ができたので、チャットの相談機能等は利用しなかったのですが、そういったサポート体制の充実も踏まえ安心して出願ができました。',
    href: 'https://cotobox.com/testmonials/harvest-earth/',
  },
  {
    thumbnail:
      'https://cotobox.com/wp-content/uploads/2021/03/7f77967499ec98656f31e6e1af45590c.png',
    title: '【工数9割減を実感】小さいからこそ大切に育てていきたい',
    description:
      '実は、以前は全部自分で商標出願から登録までやったのですが、とても面倒でした。\n自分で出願すると代行費用はかかりませんが、特許庁に行ったり、出願の区分を調べたりなど、トータルでかかる時間と労力を考えると、お金を支払ってもコストの削減になったと感じています。\n特に、時間や工数という点では9割近く削減が出来ました。',
    href: 'https://cotobox.com/testmonials/beauty-harmony/',
  },
  {
    thumbnail:
      'https://cotobox.com/wp-content/themes/cotobox/images/lp/case05.png',
    title: 'サポートチームと提携弁理士の対応スピードがとても速くてびっくりです',
    description:
      '「ガジェット通信」というWEBメディアを運営しています。\n社内で商標登録をしようという動きがあり、今回私が担当となりCotoboxを利用しました。\n商標の詳しい知識はありませんでしたが、Cotoboxはサポートチームの対応スピードがとても速く、小さな疑問を都度解決しながら進めることができました。\n無事権利化でき、商標登録証を受け取った時は、この商標は我々のものなのだという実感が湧きました。',
  },
];
</script>
