<template>
  <div class="relative flex items-center justify-between">
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="flex w-32 flex-col items-center"
    >
      <div
        class="z-10 flex h-8 w-8 items-center justify-center rounded-full border-solid font-bold"
        :class="getOrderClass({ order: index, currentStep })"
      >
        <div
          v-if="index + 1 < currentStep"
          class="material-icons"
        >
          done
        </div>
        <div v-else>
          {{ index + 1 }}
        </div>
      </div>
      <div
        :data-active="index + 1 === currentStep"
        class="mt-2 text-sm tracking-wider"
        :class="index + 1 === currentStep ? 'font-bold' : ''"
      >
        {{ step }}
      </div>
    </div>
    <template v-for="(_, index) in steps">
      <div
        v-if="index + 1 < steps.length"
        :key="index"
        class="bg-grayAlpha80 absolute left-16 top-4 h-0.5 w-[calc(100%_-_128px)]"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  steps: string[];
  currentStep: number;
}>();
const getOrderClass = ({ order, currentStep }) => {
  if (order + 1 === currentStep || order + 1 < currentStep) {
    return 'bg-grayAlpha80 text-white';
  } else {
    return 'border-grayAlpha80 border-2 bg-white';
  }
};
</script>
