<template>
  <nav class="flex items-center">
    <!-- よくあるご質問 -->
    <a
      href="https://support.cotobox.com/"
      target="_blank"
      class="hover:text-borderGray flex items-center px-4 py-2"
    >
      <span class="material-icons material-symbols-outlined">
        description
      </span>
      <span class="ml-1 text-sm font-semibold leading-4 tracking-wider">
        よくあるご質問
      </span>
    </a>
    <!-- ログイン -->
    <a
      href="/app/login"
      class="text-primary px-4 py-2 text-sm font-semibold leading-4 tracking-wider"
    >
      ログイン
    </a>
    <!-- 会員登録 -->
    <PrimaryButton
      title="会員登録"
      class="py-2 pl-4"
      @click="$router.push('/app/users/sign_up/')"
    />
  </nav>
</template>
