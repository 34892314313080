<script setup lang="ts">
import { inject } from 'vue';
import { ApplicationListKey } from '@/composables/injectionKeys';
import { ApplicationListStore } from '@/composables/useApplicationList';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import { SavedSearchQueryListItem } from '@/api/APIClientInterface';
import { HTTPError, UnauthorizedError } from '@/types/error';
import { getGoodsServicesHash } from '@/composables/useKlassFunctions';

interface Props {
  savedItem: SavedSearchQueryListItem;
}

defineProps<Props>();

const router = useRouter();
const toast = useToast();

const { getSavedTrademark, deleteSavedTrademark } = inject(
  ApplicationListKey,
) as ApplicationListStore;

async function onRowClick (savedSearchQueryId: string) {
  try {
    const savedTrademark = await getSavedTrademark(savedSearchQueryId);
    const {
      trademarkType,
      standardCharacter,
      yomi,
      imageText,
      imageToken,
      goodsServiceIdArray,
    } = savedTrademark;
    const selected_simgroup_ids = getGoodsServicesHash(goodsServiceIdArray);

    if (trademarkType === 'text') {
      router.push({
        name: 'SearchPage',
        query: {
          ...(standardCharacter && { standard_character: standardCharacter }),
          ...(yomi && { yomi }),
          ...(goodsServiceIdArray.length && { selected_simgroup_ids }),
        },
      });
    } else {
      router.push({
        name: 'ImageSearchPage',
        query: {
          ...(imageToken && { image_token: imageToken }),
          ...(imageText && { standard_character: imageText }),
          ...(yomi && { yomi }),
          ...(goodsServiceIdArray.length && { selected_simgroup_ids }),
        },
      });
    }
  } catch (e) {
    if (e instanceof UnauthorizedError) {
      location.href = '/app/login';
    } else if (e instanceof HTTPError) {
      toast.error(`${e.status} ${e.message}`);
    }
  }
}

async function onDeleteButtonClick (savedSearchQueryId: string) {
  try {
    await deleteSavedTrademark(savedSearchQueryId);
  } catch (e) {
    if (e instanceof UnauthorizedError) {
      location.href = '/app/login';
    } else if (e instanceof HTTPError) {
      toast.error(`${e.status} ${e.message}`);
    }
  }
}
</script>

<template>
  <div
    class="grid gap-4 border-x-[1px] border-b border-solid border-[#ccc] p-4 text-xs"
    style="grid-template-columns: 56px 100px 1fr 40px"
    :data-trademark-text="savedItem.standardCharacter"
    @click="onRowClick(savedItem.id)"
  >
    <div class="row-start-1 row-end-2">
      <div
        class="flex h-14 w-14 items-center justify-center break-all rounded-lg border border-solid border-[#eee] bg-[#eee] bg-contain bg-center bg-no-repeat text-sm font-bold"
        :style="{
          backgroundImage:
            savedItem.trademarkType === 'image'
              ? `url(${savedItem.imageURL})`
              : 'none',
        }"
      >
        {{
          savedItem.trademarkType === 'text' ? savedItem.standardCharacter : ''
        }}
      </div>
    </div>
    <div
      class="row-start-1 row-end-2 flex items-center justify-start text-xs text-[#555]"
    >
      {{ savedItem.klassIds.join(', ') }}
    </div>
    <div
      class="row-start-1 row-end-2 flex items-center justify-start text-xs text-[#555]"
    >
      {{ savedItem.createdDate }}
    </div>
    <div class="flex flex-col items-end justify-center">
      <button
        class="flex h-10 w-10 items-center justify-center rounded-[20px] bg-[#eee] text-2xl"
        @click.stop="onDeleteButtonClick(savedItem.id)"
      >
        <i
          class="material-icons material-symbols-outlined absolute text-[#383d51]"
        >delete</i>
      </button>
    </div>
  </div>
</template>
