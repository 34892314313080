<template>
  <div class="flex items-start">
    <div class="mr-2 w-4">
      <BaseCheckbox
        :id="`submit-${similarResult.goodsServiceId}`"
        :name="`submit-${similarResult.goodsServiceId}`"
        :model-value="checked"
        @update:model-value="onChange"
      />
    </div>
    <div class="ml-2 flex-1">
      <div class="text-sm font-semibold tracking-wider">
        {{ `第${similarResult.klassId}類 ${similarResult.goodsServiceId}` }}
      </div>
      <div class="mt-2">
        <div class="items-end justify-between sm:flex">
          <RegistrationStatusText
            :registration-status="similarResult.registrationStatus"
            :trademark-type="trademarkType"
          />
          <div
            v-if="similarResult.registrationStatus !== 'unregistered'"
            class="text-primary mt-2 flex cursor-pointer items-center text-sm tracking-wide sm:mt-0"
            @click="expanded = !expanded"
          >
            <div>類似商標の確認</div>
            <div class="material-icons material-symbols-outlined">
              {{ expanded ? 'expand_less' : 'expand_more' }}
            </div>
          </div>
        </div>
        <div
          v-if="expanded"
          class="mt-2"
        >
          <div
            v-if="trademarkType === 'image'"
            class="col-start-2 col-end-3 flex flex-wrap sm:col-end-5"
          >
            <a
              v-for="similarImageTrademark in similarResult.similarImageTrademarks"
              :key="similarImageTrademark.applicationNumber"
              :href="`/app/applications/${similarImageTrademark.applicationNumber}`"
              :style="{
                backgroundImage: `url(${similarImageTrademark.imagePath})`,
              }"
              :data-applicationnumber="similarImageTrademark.applicationNumber"
              target="_blank"
              class="hover:bg-bgGray mb-2 mr-2 inline-block h-20 w-20 cursor-pointer whitespace-nowrap rounded-lg border border-solid border-[#999] bg-white bg-contain bg-center bg-no-repeat"
            />
          </div>
          <div
            v-if="
              trademarkType === 'text' ||
                (trademarkType === 'image' && existsText)
            "
            class="col-start-2 col-end-3 flex flex-wrap sm:col-end-5"
          >
            <a
              v-for="similarTextTrademark in similarResult.similarTextTrademarks"
              :key="similarTextTrademark.applicationNumber"
              :href="`/app/applications/${similarTextTrademark.applicationNumber}`"
              target="_blank"
              class="hover:bg-bgGray mb-2 mr-2 inline-flex cursor-pointer items-center justify-center whitespace-nowrap rounded-2xl border border-solid border-[#999] bg-white px-4 py-1 text-sm font-bold text-[#616366]"
            >
              <span>
                {{ similarTextTrademark.name }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, computed, ref } from 'vue';
// 状態管理
import {
  KlassSelectStoreKey,
  TrademarkSearchStoreKey,
} from '@/composables/injectionKeys';
import { KlassSelectStore } from '@/composables/useKlassSelect';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
// 型定義
import { KlassId } from '@/types';
import {
  RegistrationStatus,
  SimilarImageTrademark,
  SimilarTextTrademark,
} from '@/api/APIClientInterface';
// コンポーネント
import BaseCheckbox from '@/components/common/BaseCheckbox.vue';
import RegistrationStatusText from './RegistrationStatusText.vue';

interface Props {
  similarResult: {
    klassId: KlassId;
    goodsServiceId: string;
    registrationStatus: RegistrationStatus;
    similarTextTrademarks?: SimilarTextTrademark[] | undefined;
    similarImageTrademarks?: SimilarImageTrademark[] | undefined;
  };
}

const props = defineProps<Props>();

const { trademarkType, existsText } = inject(
  TrademarkSearchStoreKey,
) as TrademarkSearchStore;
const { klassesForSubmit, selectForSubmit, deselectForSubmit } = inject(
  KlassSelectStoreKey,
) as KlassSelectStore;

const expanded = ref(false);

const checked = computed(() => {
  const { klassId, goodsServiceId } = props.similarResult;
  const goodsServiceIds = klassesForSubmit.value[klassId];
  if (!goodsServiceIds) {
    return false;
  }
  return goodsServiceIds.includes(goodsServiceId);
});

function onChange () {
  const { klassId, goodsServiceId } = props.similarResult;
  if (!checked.value) {
    selectForSubmit(klassId, goodsServiceId);
  } else {
    deselectForSubmit(klassId, goodsServiceId);
  }
}
</script>
