<template>
  <div class="bg-bgGray py-10 sm:py-16 sm:pb-[60px]">
    <SectionHeader
      class="mb-10 sm:pb-14"
      text="料金"
    />
    <div class="px-4 sm:mx-auto sm:w-[1121px] sm:px-0">
      <div class="items-center justify-between sm:flex">
        <div class="items-center sm:flex">
          <!-- イラスト -->
          <div v-if="$device.isDesktop" />
          <!-- 最低料金 -->
          <div class="sm:w-[365px]">
            <!-- 56,700円 -->
            <div
              class="text-grayAlpha80 flex items-end justify-center sm:justify-start"
            >
              <div class="text-6xl font-semibold">
                56,700
              </div>
              <div class="ml-2 font-semibold">
                円
              </div>
              <div>（税込）</div>
              <div class="font-semibold">
                〜
              </div>
            </div>
            <!-- 説明文 -->
            <div class="text-grayAlpha80 mt-6 text-sm">
              区分数やオプションのご利用、登録年数により料金は変動します。
            </div>
          </div>
        </div>
        <!-- 出願費用 + 登録費用 -->
        <div class="mt-6 sm:mt-0">
          <div class="flex flex-col items-center sm:flex-row">
            <MinimumPriceDescriptionCard
              class="w-full sm:w-[252px]"
              title="出願費用"
              description="出願に必要な費用。お申込時にお支払い。"
              :minimum-pice="23000"
            />
            <div
              class="material-icons text-grayAlpha20 my-4 font-bold sm:mx-4 sm:my-0"
              :style="{ fontSize: '40px' }"
            >
              add
            </div>
            <MinimumPriceDescriptionCard
              class="w-full sm:w-[252px]"
              title="登録費用"
              description="商標の審査通過後、登録の際にお支払い。"
              :minimum-pice="33700"
            />
          </div>
          <div class="text-grayAlpha60 mt-6 text-xs leading-5 sm:mt-2">
            ※ 上記は特許印紙代を含む金額です。<br>
            ※
            1回の審査で登録を受けられなかった場合など、追加費用が発生する可能性がございます。
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <PrimaryButton
          title="料金をシミュレーションする"
          class="mt-10 h-12 w-full sm:w-[304px]"
          @click="showsPriceSimulatorModal = true"
        />
      </div>
    </div>
    <!-- 料金シミュレーターモーダル -->
    <Modal
      :is-visible="showsPriceSimulatorModal && $device.isDesktop"
      @close="showsPriceSimulatorModal = false"
    >
      <div class="sm:px-14 sm:py-14">
        <PriceSimulator />
      </div>
    </Modal>
    <ModalMobileFullScreen
      :is-visible="showsPriceSimulatorModal && $device.isMobile"
      @close="showsPriceSimulatorModal = false"
    >
      <div class="h-screen w-screen overflow-scroll">
        <div class="mt-2 flex justify-end">
          <button
            class="material-icons text-grayAlpha60 mr-2"
            @click="showsPriceSimulatorModal = false"
          >
            close
          </button>
        </div>
        <PriceSimulator />
      </div>
    </ModalMobileFullScreen>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import SectionHeader from '@/components/top/SectionHeader.vue';
import MinimumPriceDescriptionCard from './MinimumPriceDescriptionCard.vue';
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import Modal from '@/components/common/Modal.vue';
import PriceSimulator from './PriceSimulator.vue';
import { useRoute } from 'vue-router';
import ModalMobileFullScreen from '@/components/common/ModalMobileFullScreen.vue';

const showsPriceSimulatorModal = ref(false);
const route = useRoute();
onMounted(() => {
  if (route.query.simulator === 'open') {
    showsPriceSimulatorModal.value = true;
  }
});
</script>
