<template>
  <div class="bg-bgGray">
    <div
      class="px-4 pb-10 pt-6 sm:mx-auto sm:w-[1191px] sm:px-0 sm:pb-20 sm:pt-10"
    >
      <Breadcrumb
        class="mb-4 px-4 sm:px-6"
        :items="[{ title: '費用', routeName: 'PlanPage' }]"
      />
      <SectionBlock
        class="mb-6 mt-4"
        title="費用"
        :hides-devider="false"
      >
        <div class="text-grayAlpha80 mb-6 text-sm">
          費用は、特許庁への出願時に指定する区分数や、オプションの有無などで変動します。<br>
          お支払いのタイミングは、お申し込み時（A.出願費用）と、特許庁での審査期間を経て登録となった時（B.登録費用）の、通常2回です。
        </div>
        <div class="items-start sm:flex sm:w-[698px]">
          <div class="flex items-center sm:w-52">
            <div class="mr-4 tracking-wider">
              区分数
            </div>
            <!-- 区分数選択 -->
            <div
              class="border-borderGray flex items-center rounded border border-solid"
            >
              <div class="w-20 pl-2 text-base tracking-wider">
                {{ `${klassCount}区分` }}
              </div>
              <div class="flex flex-col">
                <button
                  class="material-icons material-symbols-outlined block cursor-pointer pb-1 pl-4 pr-4 pt-3"
                  style="font-size: 20px; line-height: 12px"
                  :disabled="klassCount === 45"
                  @click="klassCount += 1"
                >
                  arrow_drop_up
                </button>
                <button
                  class="material-icons material-symbols-outlined block cursor-pointer pb-3 pl-4 pr-4 pt-1"
                  style="font-size: 20px; line-height: 12px"
                  :disabled="klassCount === 1"
                  @click="klassCount -= 1"
                >
                  arrow_drop_down
                </button>
              </div>
            </div>
          </div>
          <!-- 区分説明 -->
          <div
            class="bg-primaryOrange5 mt-4 p-4 text-sm sm:ml-4 sm:mt-0 sm:flex-1"
          >
            <div class="mb-2 font-bold">
              区分についてのよくあるご質問はこちら
            </div>
            <ul class="list-disc pl-4">
              <li>
                <a
                  class="text-primary hover:text-primaryOrange60 block underline"
                  href="https://support.cotobox.com/classification"
                  target="_blank"
                >
                  区分とは？
                </a>
              </li>
              <li>
                <a
                  class="text-primary hover:text-primaryOrange60 block underline"
                  href="https://support.cotobox.com/classification-notconfident"
                  target="_blank"
                >
                  選んだ区分に自信がありませんが、大丈夫ですか？
                </a>
              </li>
              <li>
                <a
                  class="text-primary hover:text-primaryOrange60 block underline"
                  href="https://cotobox.com/primer/trademark-class/"
                  target="_blank"
                >
                  商標の区分とは&nbsp;&nbsp;～45種類を全部解説します
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- 料金シミュレーター -->
        <div class="mt-10 pb-6">
          <div class="border-borderGray border border-solid sm:w-[700px]">
            <!-- ヘッダー行 -->
            <div class="bg-bgGray flex h-12 items-center">
              <div
                class="text-grayAlpha80 border-borderGray flex-1 border-r border-solid py-4 text-center text-sm font-medium tracking-wider sm:w-[520px]"
              >
                内訳
              </div>
              <div
                class="text-grayAlpha80 w-[120px] py-4 text-center text-sm font-medium tracking-wider sm:w-[180px]"
              >
                金額
              </div>
            </div>
            <PriceSimulatorRowVue
              title="出願手数料"
              :price="applyUsageFee"
            />
            <!-- スピードオプション -->
            <div
              class="border-borderGray flex items-center border-b border-solid"
            >
              <div
                class="border-borderGray h-full flex-1 border-r border-solid pl-4 sm:w-[520px]"
              >
                <div class="cursor-pointer py-4 pr-4">
                  <div class="block cursor-pointer">
                    <div class="mb-4 flex items-center">
                      <BaseCheckbox
                        id="additional_options[speed_plan]"
                        v-model="speedPlan"
                        name="additional_options[speed_plan]"
                      />
                      <label
                        for="additional_options[speed_plan]"
                        class="ml-[14px] cursor-pointer whitespace-pre-line text-base font-normal sm:whitespace-nowrap"
                      >
                        {{ t('speedPlanTitle') }}
                      </label>
                    </div>
                    <label
                      for="additional_options[speed_plan]"
                      class="cursor-pointer"
                    >
                      <div
                        class="mt-4 whitespace-pre-line text-sm font-normal leading-6"
                      >
                        <i18n-t keypath="speedPlanDescription1">
                          <a
                            class="text-primary underline"
                            target="_blank"
                            href="https://support.cotobox.com/option-speedup-examination"
                          >
                            {{ t('anchorText') }}
                          </a>
                        </i18n-t>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="box-border h-full w-[120px] px-2 py-2 sm:w-[180px]">
                <div
                  class="flex h-full items-center justify-end py-1 text-right text-base font-semibold tracking-wider"
                >
                  {{ `${speedPlanFee.toLocaleString()}円` }}
                </div>
              </div>
            </div>
            <PriceSimulatorRowVue
              title="出願時消費税"
              :price="applyTax"
            />
            <PriceSimulatorRowVue
              title="特許印紙代（出願）"
              :price="applyPrintFee"
            />
            <!-- 出願費用合計 -->
            <div
              class="border-borderGray bg-primaryOrange5 flex h-16 items-center border-b border-solid"
            >
              <div
                class="border-borderGray h-full flex-1 border-r border-solid pl-4 sm:w-[520px]"
              >
                <div
                  class="text-textBlack flex h-full items-center text-left font-semibold tracking-wider"
                >
                  A. 出願費用合計
                </div>
              </div>
              <div class="box-border h-full w-[120px] px-2 py-2 sm:w-[180px]">
                <div
                  class="flex h-full items-center justify-end py-1 text-right text-base font-semibold tracking-wider"
                >
                  {{ `${applySubTotalFee.toLocaleString()}円` }}
                </div>
              </div>
            </div>
            <PriceSimulatorRowVue
              title="登録手数料"
              :price="registrationUsageFee"
            />
            <PriceSimulatorRowVue
              title="登録時消費税"
              :price="registrationTax"
            />
            <div class="border-borderGray border-b border-solid">
              <div class="flex items-center">
                <div
                  class="border-borderGray box-border h-full flex-1 border-r border-solid px-2 py-2 sm:w-[520px]"
                >
                  <div
                    class="text-textBlack flex items-center text-left tracking-wider"
                  >
                    特許印紙代（登録）
                  </div>
                  <div class="text-grayAlpha80 mb-2 text-sm">
                    登録年数により変動します。詳しくは<a
                      href="https://support.cotobox.com/faq-5years-10years"
                      target="_blank"
                      class="text-primary underline"
                    >こちら</a>
                  </div>
                  <BaseRadioButtonGroupVue
                    :options="is10yearRegistrationOptions"
                    :value="is10yearRegistrationOptionsValue"
                    @update:model-value="onIs10yearRegistrationChange"
                  />
                </div>
                <div class="box-border h-full w-[120px] px-2 py-2 sm:w-[180px]">
                  <div
                    class="flex h-full items-center justify-end py-1 text-right text-base font-semibold tracking-wider"
                  >
                    {{ `${registrationPrintFee.toLocaleString()}円` }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 登録費用合計 -->
            <div
              class="border-borderGray bg-primaryOrange5 flex h-16 items-center border-b border-solid"
            >
              <div
                class="border-borderGray h-full flex-1 border-r border-solid pl-4 sm:w-[520px]"
              >
                <div
                  class="text-textBlack flex h-full items-center text-left font-semibold tracking-wider"
                >
                  B. 登録費用合計
                </div>
              </div>
              <div class="box-border h-full w-[120px] px-2 py-2 sm:w-[180px]">
                <div
                  class="flex h-full items-center justify-end py-1 text-right text-base font-semibold tracking-wider"
                >
                  {{ `${registrationSubTotalFee.toLocaleString()}円` }}
                </div>
              </div>
            </div>
            <!-- 合計 -->
            <div class="bg-primaryOrange5 flex h-16 items-center">
              <div
                class="border-borderGray h-full flex-1 border-r border-solid pl-4 sm:w-[520px]"
              >
                <div
                  class="text-primary flex h-full items-center text-left font-semibold tracking-wider"
                >
                  A+B 総合計
                </div>
              </div>
              <div class="box-border h-full w-[120px] px-2 py-2 sm:w-[180px]">
                <div
                  class="text-primary flex h-full items-center justify-end py-1 text-right text-base font-semibold tracking-wider"
                >
                  {{ `${totalFee.toLocaleString()}円` }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-grayAlpha60 text-sm">
          ※
          利用者自ら商標登録出願書類を作成することを支援するソフトウェアを提供しています。経済産業省グレーゾーン解消制度の活用事業。<br>
          ※
          パラリーガルは、弁理士の補助業務、例えば依頼案件の準備的な聞き取りを行います。<br>
          ※
          手続補正指令・拒絶理由通知があった場合、意見書の作成は55,000円（税込）から、手続補正書の作成は11,000円（税込）からとなります。<br>
          ただし、拒絶理由の数や内容等により、上記費用は増減する場合があります。<br>
          ※ 弁理士料金は源泉徴収の対象となります。
        </div>
        <div class="border-borderGray mt-4 border-t border-solid">
          <router-link to="/app/search/">
            <PrimaryButton
              title="商標出願の申し込みをする"
              class="mb-4 mt-6"
            />
          </router-link>
          <div class="text-grayAlpha80 text-sm tracking-wider">
            Cotoboxの類似商標の検索画面に移動します。検索で類似商標の有無を確認した後、お申し込みに進みます。<br>
            お見積書の発行もこちらです。<br>
            初めての方でも、チャットや電話でのサポートを受けながらお申し込みができますので、ご安心ください。
          </div>
        </div>
      </SectionBlock>
      <SectionBlock
        class="mb-6"
        title="お支払い方法"
        :hides-devider="false"
      >
        <div class="items-center sm:flex">
          <div class="mr-4">
            <div class="text-grayAlpha80 mb-6">
              クレジットカード、または銀行振込をご利用いただけます。<br>
              ※
              プリペイドカード（またはギフトカード）によるお支払いは非推奨です。
            </div>
            <div class="text-textBlack mb-1 font-bold">
              クレジットカード
            </div>
            <div class="text-grayAlpha80 mr-6">
              ご利用できるクレジットカードは、<br class="hidden sm:block">
              VISA・Mastercard・American Express・JCB・Discover
              Card・ダイナースクラブです。<br>
              なお、カード番号などのクレジットカード情報は、社内で一切保存をしておりません。
            </div>
            <div class="text-textBlack mb-1 mt-4 font-bold">
              銀行振込
            </div>
            <div class="text-grayAlpha80 mr-6">
              請求書発行後、7日以内にお支払いください。※振込手数料はご負担ください。
            </div>
          </div>
          <div
            :style="{ backgroundImage: `url(${CardBrandsImage})` }"
            class="h-16 w-full bg-contain bg-center bg-no-repeat sm:w-[480px]"
          />
        </div>
      </SectionBlock>
    </div>
  </div>
  <!-- ヘルプモーダル: 区分とは -->
  <Modal
    :is-visible="showsHelpKlass"
    @close="showsHelpKlass = false"
  >
    <div class="px-6 pb-8 pt-6">
      <div
        class="border-borderGray border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
      >
        区分とは
      </div>
      <HelpContentKlass />
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import { usePriceCalculator } from '@/composables/usePriceCalculator';
import Modal from '@/preview/Modal.vue';
import HelpContentKlass from '@/components/search/HelpContentKlass.vue';
import PriceSimulatorRowVue from '@/components/price/PriceSimulatorRow.vue';
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import { useI18n } from 'vue-i18n';
import CardBrandsImage from '@/assets/images/icons/cardBrands.png';
import BaseRadioButtonGroupVue, {
  RadioButtonGroupOption,
} from '@/components/common/BaseRadioButtonGroup.vue';
import { useHead } from '@vueuse/head';

useHead({
  title: 'オンライン商標登録の費用 | オンライン商標登録サービスのCotobox（コトボックス）',
  link: [{ rel: 'canonical', href: 'https://cotobox.com/plan/' }],
  script: [
    {
      type: 'application/ld+json',
      children: `
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Cotobox",
              "item": "https://cotobox.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "オンライン商標登録の費用",
              "item": "https://cotobox.com/plan/"
            }
          ]
        }
      `,
    },
  ],
});

const showsHelpKlass = ref(false);
const klassCount = ref(1);
const premiumPlan = ref(false);
const speedPlan = ref(false);

const is10yearRegistration = ref(false);
const is10yearRegistrationOptions: RadioButtonGroupOption[] = [
  { label: '5年', value: '0' },
  { label: '10年', value: '1' },
];
const is10yearRegistrationOptionsValue = computed(() =>
  is10yearRegistration.value ? '1' : '0',
);
function onIs10yearRegistrationChange (value: string) {
  const boolValue = value === '1';
  is10yearRegistration.value = boolValue;
}

const { state, isAttorneyCorporation } = inject(UserDataKey) as UserDataStore;

// 料金シミュレーターはいかなる場合も源泉は控除されない
const authenticated = ref(false);
const isPersonal = computed(() =>
  authenticated.value ? state.isPersonal : false,
);

const {
  applyUsageFee,
  speedPlanFee,
  applyTax,
  applyPrintFee,
  applySubTotalFee,
  registrationUsageFee,
  registrationTax,
  registrationPrintFee,
  registrationSubTotalFee,
  totalFee,
} = usePriceCalculator({
  klassCount,
  premiumPlan,
  speedPlan,
  authenticated,
  isPersonal,
  isAttorneyCorporation,
  is10yearRegistration,
});

const { t } = useI18n({
  messages: {
    ja: {
      premiumPlanTitle: '安心フルサポート \n1区分あたり9,000円(税別)',
      premiumPlanDescription1text:
        '電話やオンラインメッセージにて{0}進めたい方向けのオプションです。',
      premiumPlanDescription1strong: '専門家（弁理士）に質問しながら',
      premiumPlanDescription2:
        'こんな方におすすめ！\n' +
        '・選択した区分で合っているか不安\n' +
        '・電話で対応してほしい\n' +
        '・文字で登録するかロゴで登録するか迷っている\n',
      speedPlanTitle: '審査スピードアップ \n1商標あたり30,000円(税別)',
      speedPlanDescription:
        '早期審査を利用すれば、わずか2〜3ヶ月で審査結果がでます。\nご利用には、所定の要件を満たす必要があります。要件は{0}をご覧ください。\nなお、通常の審査期間は、出願から約6〜10ヶ月です。',
      speedPlanDescription1:
        '通常5〜9ヶ月かかる審査を2〜3ヶ月で行うオプションです。特許庁の早期審査制度を利用します。\nご利用には、{0}を満たす必要があります。\n要件に該当するかわからない場合、まずはオプションを付けずにお申し込みください。お申し込み後にオプションを追加することも可能です。',
      anchorText: '所定の要件',
    },
    en: {
      premiumPlanTitle: 'Anshin Full Support (9,000 yen per category)',
      speedPlanTitle: 'Speed up screening (30,000 yen)',
      speedPlanDescription:
        'If you take advantage of the early screening process, you will receive the results in just 2 to 3 months.\n' +
        'To take advantage of this service, you must meet certain requirements. Please see {0} for requirements.\n' +
        'The normal examination period is approximately 4 to 9 months from the filing date.',
      anchorText:
        'this way (direction close to the speaker or towards the speaker)',
    },
    zh: {
      premiumPlanTitle: '安全全面支持（每类9,000日元）',
      speedPlanTitle: '加速检查（30,000日元）',
      speedPlanDescription:
        '如果你使用早期筛查程序，你可以在短短两到三个月内得到结果。\n' +
        '要利用这项服务，你需要满足某些要求。要求见{0}。\n' +
        '正常情况下，审查期从申请起约为4-9个月。',
      anchorText: '这样(方向靠近扬声器或朝向扬声器)',
    },
  },
});
</script>
