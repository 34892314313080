<template>
  <div :class="`${$options.name}`">
    <Stepper
      class="mb-8 w-full sm:mx-auto sm:w-80"
      :steps="[t('step1'), t('step2')]"
      :current-step="1"
    />
    <p :class="`${$options.name}__description`">
      <i18n-t
        keypath="text"
        tag="p"
      >
        <strong>{{ t('strong') }}</strong>
      </i18n-t>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Stepper from '@/components/common/Stepper.vue';

export default defineComponent({
  name: 'ApplyFormHeader',
  components: {
    Stepper,
  },
  setup () {
    const { t } = useI18n({
      messages: {
        ja: {
          step1: '出願依頼申し込み',
          step2: '申し込み完了',
          text: '出願依頼後、{0}があります。\n区分数が変更になる場合、返金または追加料金のご案内をいたします。',
          strong: '提携の弁理士により区分修正の機会',
        },
        en: {
          step1: 'Application Request Application',
          step2: 'Completion of application',
          text:
            'After the application request, there are {0}.\n' +
            'If the number of classifications changes, you will be notified of a refund or additional fee.',
          strong:
            'Opportunity to amend the classification by our affiliated patent attorneys',
        },
        zh: {
          step1: '申请书申请书',
          step2: '完成申请',
          text: '在应用请求之后，有{0}个\n如果类别的数量发生变化，将提供退款或额外费用。',
          strong: '有机会由合伙人专利律师修改分类。',
        },
      },
    });

    return {
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
.ApplyFormHeader {
  &__stepper {
    @include pc {
      margin: 40px auto 64px;
    }

    @include sp {
      margin: 24px auto 64px;
    }
  }

  &__description {
    text-align: center;
    font-weight: 600;
    margin-bottom: 40px;
    white-space: pre-line;

    @include pc {
      font-size: 20px;
      line-height: 29px;
    }

    @include sp {
      font-size: 16px;
      line-height: 22px;
    }

    p {
      text-align: center;
    }

    strong {
      color: $primary-color;
    }
  }
}
</style>
