<template>
  <BaseSelect
    :model-value="modelValue"
    :options="options"
    @update:model-value="onChange"
    @blur="$emit('blur')"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseSelect from '@/components/common/BaseSelect.vue';

export default defineComponent({
  name: 'IndustrySelect',
  components: {
    BaseSelect,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue', 'blur'],
  setup (props, { emit }) {
    const { t, locale, messages } = useI18n<object, 'ja' | 'en' | 'zh'>({
      locale: 'ja',
      messages: {
        ja: {
          cloudservice: 'クラウドサービス・オンラインビジネス',
          online_media: 'オンラインメディア・アフェリエイト事業',
          game: 'ゲーム・音楽・エンターテイメント・イベント',
          software: 'サイト制作・ソフトウェア開発',
          it: 'IT業（SI・IT商社・ITコンサルティング）',
          ec: 'EC事業・通販・小売・卸売',
          finance: '金融・証券・保険',
          management: '不動産・住宅・建築',
          professional: '経営コンサル・人材・士業、事業管理請負',
          advertisement: '広告・販売促進・出版・デザイン',
          food_service: '外食・理容・美容・フィットネス・エステ',
          education: '教育・資格・介護・福祉・医療',
          travel: '旅行・宿泊',
          ceremonial_occasions: '冠婚葬祭・占い・警備・結婚相談',
          physical_distribution: '物流・運送・旅客・倉庫',
          infrastructure: 'インフラ・エネルギー',
          food: '食品・飲料・農林水産',
          machine: '機械・自動車・半導体',
          home_electronics: '家電・電子機器・精密機器・OA機器',
          stationary: '文具・事務用品・家具',
          apparel: 'アパレル・アクセサリー・ジュエリー',
          cosmetic: '化粧品・医薬品・日用品・サニタリー',
          chemical: '化学・鉄鋼・製紙・素材',
          others: 'その他',
        },
        en: {
          cloudservice: 'Cloud service・Online business',
          online_media: 'Online media・Affiliate business',
          game: 'Game・Music・Entertainment・Event',
          software: 'Website construction・Software development',
          it: 'IT business(SI, IT trading company, IT consulting)',
          ec: 'E - commerce business, Mail order, Retail, Wholesale',
          finance: 'Finance, Securities, Insurance',
          management: 'Real estate, Housing, Construction',
          professional:
            'Management consulting, Human Resources, Professionals, Business management contract',
          advertisement: 'Advertisement, Sales promotion, Publishing, Design',
          food_service:
            'Food service, Hairdressing, Beauty, Fitness, Beauty salon',
          education:
            'Education, License, Nursing care, Welfare, Medical treatment',
          travel: 'Travel, Lodging',
          ceremonial_occasions:
            'Ceremonial occasions, Fortune telling, Guard, Marriage counseling',
          physical_distribution:
            'Physical distribution, Transportation, Passenger, Warehouse',
          infrastructure: 'Infrastructure, Energy',
          food: 'Food, Drink, Agricultural, forestry and fishery',
          machine: 'Machine, Automobile, Semiconductors',
          home_electronics:
            'Home electronics, Electronic devices, Precision devices, OA equipments',
          stationary: 'Stationary, Office supply, Furniture',
          apparel: 'Apparel, Accessory, Jewelry',
          cosmetic: 'Cosmetic, Medicament, Daily commodity, Sanitary',
          chemical: 'Chemicals, Steel, Papermaking, Material',
          others: 'Others',
        },
        zh: {
          cloudservice: '云服务，在线业务',
          online_media: '新媒体，网络广告营销业务',
          game: '游戏，音乐，娱乐，商演',
          software: '网站制作，软件开发',
          it: 'IT行业（SI，IT贸易，IT咨询）',
          ec: 'EC行业，电商，零售，批发',
          finance: '金融，证券，保险',
          management: '房地产，住宅，建筑',
          professional: '管理咨询，人力资源，文书处理，企业管理',
          advertisement: '广告，促销，出版，设计',
          food_service: '饮食，美发，美容，健身，护理',
          education: '教育，考证，护理，福利，医疗',
          travel: '旅游，住宿',
          ceremonial_occasions: '婚葬嫁娶，占卜，安保，婚恋咨询',
          physical_distribution: '物流，运输，旅客，仓库',
          infrastructure: '基础建设，能源',
          food: '食品，饮料，农林水产',
          machine: '机械，汽车，半导体',
          home_electronics: '家电，电子器械，精密仪器，OA器械',
          stationary: '文具，办公用品，家具',
          apparel: '服装，配饰，珠宝',
          cosmetic: '化妆品，医药，日用品，卫生用品',
          chemical: '化学，钢铁，纸张，材料',
          others: '其他',
        },
      },
    });

    const options = Object.keys(
      // @ts-ignore
      messages.value[locale.value],
    ).map((key) => ({
      label: t(key),
      // @ts-ignore
      value: messages.value.ja[key],
    }));

    function onChange (value: string) {
      emit('update:modelValue', value);
    }

    return {
      t,
      options,
      onChange,
    };
  },
});
</script>
