<template>
  <div class="bg-bgGray">
    <div
      class="flex h-16 w-screen items-center px-6"
      style="
        background: linear-gradient(45deg, rgb(34, 34, 34), rgb(68, 68, 68));
      "
    >
      <a
        class="block h-[26px] w-[160px]"
        href="/"
      >
        <img src="@/assets/images/icons/logo.svg">
      </a>
    </div>
    <div class="px-4 sm:mx-auto sm:w-[1160px]">
      <router-view class="pb-20 pt-6 sm:pt-10" />
    </div>
  </div>
  <div
    class="px-4 pb-6 pt-6 sm:mx-auto sm:w-[1191px] sm:px-0 sm:pb-20 sm:pt-10"
  >
    <Breadcrumb
      class="mb-4 px-4 sm:px-6"
      :items="[{ title: '商標管理クラウド', routeName: 'SearchPage' }]"
    />
  </div>
  <EnterpriseFirstView />
  <EnterpriseSecondSection />
  <EnterpriseFlowList />
  <EnterpriseCompareSection v-if="$device.isDesktop" />
  <EnterpriseNotify />
  <EnterpriseQ />
</template>

<script setup lang="ts">
import EnterpriseFirstView from '@/features/top/components/EnterpriseFirstView.vue';
import EnterpriseSecondSection from '@/features/top/components/EnterpriseSecondSection.vue';
import EnterpriseCompareSection from '@/features/top/components/EnterpriseCompareSection.vue';
import EnterpriseQ from '@/features/top/components/EnterpriseQ.vue';
import EnterpriseNotify from '@/features/top/components/EnterpriseNotify.vue';
import EnterpriseFlowList from '@/features/top/components/EnterpriseFlowList.vue';
import { useHead } from '@vueuse/head';

useHead({
  title: '商標管理クラウド | オンライン商標登録サービスのCotobox（コトボックス）',
  link: [{ rel: 'canonical', href: 'https://cotobox.com/enterprise_plan/' }],
  script: [
    {
      type: 'application/ld+json',
      children: `
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Cotobox",
              "item": "https://cotobox.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "商標管理クラウド",
              "item": "https://cotobox.com/enterprise_plan/"
            }
          ]
        }
      `,
    },
  ],
});
</script>
