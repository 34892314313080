import { Ref, ref, computed, ComputedRef } from 'vue';
import { KlassId } from '@/types';
import {
  GoodsServices,
  GoodsService,
  goodsServices,
} from '@/constants/goodsServices';

/** 例: `電子計算機のプログラムの設計・作成又は保守` */
export type GoodsServiceId = string;

/** 例: `{ 9: ['消防車', '眼鏡'], 42: ['電子計算機のプログラムの設計・作成又は保守'] }` */
export type GoodsServiceIds = {
  [key in KlassId]?: GoodsServiceId[];
};

/** 例: `{ 1: { '化学品': { id: '化学品', name: { ja: '化学品', en: 'chemicals' }, code: '1-QB', order: 1 } }` */
export type PartialGoodsServices = {
  [key in KlassId]?: {
    [key in GoodsServiceId]?: GoodsService;
  };
};

/**
 * 検索画面の区分一覧のデータソース
 * @note 通常は`allList`が区分一覧のデータソース。絞り込む場合は、AI絞り込み検索のAPIで得られた商品役務を`source`にセットすると、`listFromSource`に絞り込み後の区分リストが反映されている
 */
export const useKlassList = () => {
  // 全ての区分一覧
  const allList: ComputedRef<GoodsServices> = computed(() => {
    return (Object.keys(goodsServices) as KlassId[]).reduce((p, klassId) => {
      const klassGoodsServices = goodsServices[klassId] as {
        [goodsServiceId: GoodsServiceId]: GoodsService;
      };
      return {
        ...p,
        [klassId]: Object.keys(klassGoodsServices)
          .map((goodsServiceId) => {
            return klassGoodsServices[goodsServiceId] as GoodsService;
          })
          .sort((a, b) => {
            return a.order - b.order;
          }),
      };
    }, {} as GoodsServices);
  });

  /**
   * 区分リストの絞り込み条件としての商品役務
   * @usage { 1: ['化学品', '肥料'], 9: ['電池'] }
   */
  const source: Ref<GoodsServiceIds> = ref({});

  // 変数`source`にセットされた区分商品役務IDの区分一覧
  const listFromSource: ComputedRef<PartialGoodsServices> = computed(() => {
    return (Object.keys(source.value) as KlassId[]).reduce((p, klassId) => {
      const klassGoodsServiceIds = source.value[klassId] as GoodsServiceId[];
      return {
        ...p,
        [klassId]: klassGoodsServiceIds
          .map((goodsServiceId) => {
            const klassGoodsServices = goodsServices[klassId]!;
            const goodService = klassGoodsServices[goodsServiceId]!;
            return goodService;
          })
          .sort((a, b) => {
            return a.order - b.order;
          }),
      };
    }, {} as PartialGoodsServices);
  });

  return {
    allList,
    source,
    listFromSource,
  };
};

export type KlassListStore = ReturnType<typeof useKlassList>;
