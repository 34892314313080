import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import SignUpEmailPage from '@/components/pages/app/users/sign_up/email.vue';
import SignUpCodePage from '@/components/pages/app/users/sign_up/code.vue';
import SignUpFormPage from '@/components/pages/app/users/sign_up/form.vue';
import SignUpThanksPage from '@/components/pages/app/users/sign_up/thanks.vue';
import SearchPage from '@/components/pages/app/search.vue';
import RegistTrademarkPage from '@/components/pages/app/regist_trademark.vue';
import ThanksPage from '@/components/pages/app/thanks.vue';
import MyPage from '@/components/pages/app/mypage/index.vue';
import Application from '@/components/pages/app/applications/index.vue';
import PlanPage from '@/components/pages/plan.vue';
import EnterprisePlanPage from '@/components/pages/enterprise_plan.vue';
import TopPage from '@/components/pages/index.vue';
import NotificationsListPage from '@/components/pages/app/notifications/index.vue';
import NotificationsDetailPage from '@/components/pages/app/notifications/_id.vue';
import ChannelService from '@/lib/channelService';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'TopPage',
    component: TopPage,
  },
  {
    path: '/app/users/sign_up',
    name: 'SignUpEmailPage',
    component: SignUpEmailPage,
  },
  {
    path: '/app/users/sign_up/code',
    name: 'SignUpCodePage',
    component: SignUpCodePage,
  },
  {
    path: '/app/users/sign_up/form',
    name: 'SignUpFormPage',
    component: SignUpFormPage,
  },
  {
    path: '/app/users/sign_up/thanks',
    name: 'SignUpThanksPage',
    component: SignUpThanksPage,
  },
  {
    path: '/app/search',
    name: 'SearchPage',
    component: SearchPage,
  },
  {
    path: '/app/regist_trademark',
    name: 'RegistTrademarkPage',
    component: RegistTrademarkPage,
  },
  {
    path: '/app/thanks/:id',
    name: 'ThanksPage',
    component: ThanksPage,
  },
  {
    path: '/app/mypage',
    name: 'mypage',
    component: MyPage,
  },
  {
    path: '/app/mypage/applications/:id',
    name: 'application',
    component: Application,
  },
  {
    path: '/plan',
    name: 'PlanPage',
    component: PlanPage,
  },
  {
    path: '/enterprise_plan',
    name: 'EnterprisePlanPage',
    component: EnterprisePlanPage,
  },
  {
    path: '/app/notifications',
    name: 'NotificationsListPage',
    component: NotificationsListPage,
  },
  {
    path: '/app/notifications/:id',
    name: 'NotificationsDetailPage',
    component: NotificationsDetailPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from) {
    if (to.path === from.path) {
      return;
    }
    return { top: 0 };
  },
});

router.afterEach((_to, _next) => {
  ChannelService.showChannelButton();
});

export { router };
