<template>
  <TopPageFirstView />
  <div class="py-4 text-center text-sm tracking-wider">
    <strong>50,000社以上</strong>の企業・大学様にご利用いただいています。
  </div>
  <TopPageClientLogos />
  <TopPageFeaturesSection />
  <TopPageFlow />
  <ConversionSection />
  <TopPagePriceSummerySection />
  <TopPageServiceListSection />
  <CompetitorCompareSection v-if="$device.isDesktop" />
  <!-- TODO: 75%削減 -->
  <ConversionSection />
  <TopPageCasesSection />
  <TopPageSchemeSection />
  <TopPageLetsSignUpSection v-if="!authenticated" />
</template>

<script setup lang="ts">
import TopPageFirstView from '@/features/top/components/TopPageFirstView.vue';
import TopPageFeaturesSection from '@/features/top/components/TopPageFeaturesSection.vue';
// import TopPageClientLogos from '@/components/top/TopPageClientLogos.vue'
import TopPageClientLogos from '@/features/top/components/TopPageClientLogos.vue';
import TopPageFlow from '@/features/top/components/TopPageFlow.vue';
import TopPagePriceSummerySection from '@/features/top/components/TopPagePriceSummerySection.vue';
import TopPageServiceListSection from '@/features/top/components/TopPageServiceListSection.vue';
import CompetitorCompareSection from '@/features/top/components/CompetitorCompareSection.vue';
import TopPageCasesSection from '@/features/top/components/TopPageCasesSection.vue';
import ConversionSection from '@/features/top/components/ConversionSection.vue';
import TopPageSchemeSection from '@/features/top/components/TopPageSchemeSection.vue';
import TopPageLetsSignUpSection from '@/features/top/components/TopPageLetsSignUpSection.vue';
import { inject } from 'vue';
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import { useHead } from '@vueuse/head';

useHead({
  title: 'オンラインで商標登録するならCotobox（コトボックス）',
  link: [{ rel: 'canonical', href: 'https://cotobox.com/' }],
  script: [
    {
      type: 'application/ld+json',
      children: `
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Cotobox",
              "item": "https://cotobox.com/"
            },
          ]
        }
      `,
    },
  ],
});

const { authenticated } = inject(UserDataKey) as UserDataStore;
</script>
