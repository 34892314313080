import axios from 'axios';
import { defineStore } from 'pinia';

export interface MailingAddress {
  id: number;
  tmAppApplicantInfoId: number;
  businessType: string;
  companyName: string;
  isPersonal: boolean;
  lastName: string;
  firstName: string;
  prefecture: string;
  zipcode: string;
  city: string;
  street: string;
  phoneNumber: string;
}

export const useMailingAddressStore = defineStore({
  id: 'mailingAddressStore',
  state: (): MailingAddress => ({
    id: 0,
    tmAppApplicantInfoId: 0,
    businessType: '',
    isPersonal: false,
    companyName: '',
    lastName: '',
    firstName: '',
    prefecture: '',
    zipcode: '',
    city: '',
    street: '',
    phoneNumber: '',
  }),
  getters: {
    fullAddress: (state) => {
      return `${state.prefecture}${state.city}${state.street}`;
    },
  },
  actions: {
    async getAdddess (appId: number) {
      const headers = {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      };
      const responce = await axios.get(
        `/app/mypage/applications/${appId}/tm_mailing_addresses.json`,
        headers,
      );

      Object.assign(this, responce.data);
    },
    async postAddress (appId: number) {
      const option = {
        tm_mailing_address: {
          business_type: this.businessType,
          company_name: this.companyName,
          isCompany: !this.isPersonal,
          last_name: this.lastName,
          first_name: this.firstName,
          zipcode: this.zipcode,
          address_prefecture: this.prefecture,
          address_city: this.city,
          address_street: this.street,
          phone_number: this.phoneNumber,
        },
      };
      await axios.patch(
        `/app/mypage/applications/${appId}/tm_mailing_addresses.json`,
        option,
      );
    },
  },
});
