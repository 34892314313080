<template>
  <div class="bg-primaryOrange5 py-10 sm:py-16">
    <SectionHeader
      text="Cotoboxの特徴"
      class="mb-14"
    />
    <div class="px-4 sm:mx-auto sm:w-[1121px] sm:px-0">
      <div class="flex flex-col justify-center sm:mb-14 sm:flex-row sm:gap-x-8">
        <VisualAndDescriptionCard
          v-for="item in items.slice(0, 2)"
          :key="item.title"
          :item="item"
          class="mb-6 sm:mb-0 sm:h-[351px] sm:w-[365px]"
        />
      </div>
      <div class="flex flex-col justify-between sm:flex-row sm:gap-x-8">
        <VisualAndDescriptionCard
          v-for="item in items.slice(2, 5)"
          :key="item.title"
          :item="item"
          class="mb-6 sm:mb-0 sm:h-[351px] sm:w-[365px]"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SectionHeader from '@/components/top/SectionHeader.vue';
import VisualAndDescriptionCard, {
  VisualAndDescriptionProps,
} from './VisualAndDescriptionCard.vue';
import CreativeDocCheck from '@/assets/images/creatives/docCheck.svg';
import CreativeReasonablePrice from '@/assets/images/creatives/reasonablePrice.svg';
import CreativeChatSupport from '@/assets/images/creatives/chatSupport.svg';
import CreativeMultipleFiles from '@/assets/images/creatives/multipleFiles.svg';

const items: VisualAndDescriptionProps[] = [
  {
    src: CreativeDocCheck,
    title: '手軽に出願',
    description:
      'フォームへの入力だけで、Cotobox提携の専門家へ出願依頼ができます。オンラインで全ての手続きが完結。',
  },
  {
    src: CreativeReasonablePrice,
    title: 'リーズナブルな費用',
    description:
      '簡易調査ができる商標検索は無料です。最新のAI技術の活用によりコストを削減し、手数料の低価格化も実現しました。',
  },
  {
    src: CreativeChatSupport,
    title: 'チャット相談',
    description:
      'サポートチームへの相談は無料です。操作方法や専門家に質問すべきか迷うご質問は、いつでもご連絡ください。最短1分で回答します。',
  },
  {
    src: CreativeMultipleFiles,
    title: 'まとめて商標管理',
    description:
      '登録商標や手続きの状況は、マイページでまとめて管理。更新時期が近づくとメール通知が届くので安心です。',
  },
  {
    src: CreativeMultipleFiles,
    title: 'スピーディーな出願',
    description:
      'Cotoboxならお申し込み完了まで最短3分、特許庁への出願は最短1営業日で完了。準備が簡単なので、すきま時間を有効活用できます。',
  },
];
</script>
