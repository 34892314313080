<template>
  <div class="px-4 pb-6 pt-3 sm:px-10 sm:py-6">
    <!-- 注意書き -->
    <div class="bg-primaryOrange5 p-4">
      <div class="text-sm">
        お申し込み内容の確認には、アカウント登録（無料）が必要です。登録済みの方は<a
          href="/app/login"
          class="text-primary underline"
        >こちら</a>からログインしてください。アカウント登録後は、以下の機能もご利用いただけます。
      </div>
      <ul class="mt-2 list-disc pl-4 text-sm tracking-wider">
        <li>見積書の発行</li>
        <li>検索条件をマイページに保存</li>
      </ul>
    </div>
    <!-- メールアドレス入力欄 -->
    <FormTextInput
      v-model="state.email"
      class="mt-4"
      :title="t('email')"
      name="email"
      type="email"
      placeholder="example@cotobox.com"
      autocomplete="email"
      :shows-error="state.showsEmailError"
      :error-message="state.showsEmailError ? t('emailError') : ''"
      @blur="validate"
    />
    <!-- 次へ -->
    <PrimaryButton
      :title="t('next')"
      class="mt-2 w-full"
      :disabled="disabled"
      @click="next"
    />
    <div class="bg-borderGray mb-4 mt-4 h-[1px] w-full" />
    <!-- すでにアカウントをお持ちの方 -->
    <a href="/app/login">
      <SecondaryButton
        :title="t('alreadyHaveAccount')"
        class="w-full"
      />
    </a>
    <!-- 処理中 -->
    <TheOverlayLoading v-if="processing" />
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
// 状態管理
import { SignUpKey } from '@/composables/injectionKeys';
import { SignUpStore } from '@/composables/useSignUp';
// ライブラリ
import { useToast } from 'vue-toastification';

const emit = defineEmits<{
  (e: 'next'): void;
}>();

const { t } = useI18n({
  locale: 'ja',
  messages: {
    ja: {
      email: 'メールアドレス',
      emailError: 'メールアドレスの形式が間違っています。',
      accountExistsError: 'すでに登録されているメールアドレスです。',
      mailFailError:
        '認証メールを送信できませんでした。正しいメールアドレスであることをご確認ください。',
      next: '次へ',
      alreadyHaveAccount: 'すでにアカウントをお持ちの方はこちら',
    },
    en: {
      email: 'Email',
      emailError: 'Please enter a valid email address.',
      accountExistsError: 'The email has already been registered.',
      mailFailError:
        'The authentication email could not be sent.\nPlease make sure that you have the correct email address.',
      next: 'Next',
      alreadyHaveAccount: 'Already have an account',
    },
    zh: {
      email: '电子邮件地址',
      emailError: '请输入一个有效的电子邮件地址。',
      accountExistsError: '您的电子邮件地址已被注册',
      mailFailError: '我们无法发送验证邮件。请确保你有正确的电子邮件地址。',
      next: '转到下一个',
      alreadyHaveAccount: '已经有了一个账户？',
    },
  },
});

const {
  state,
  validateEmail: validate,
  mailSendDisabled: disabled,
  sendConfirmationMail,
} = inject(SignUpKey) as SignUpStore;

const processing = ref(false);
const toast = useToast();

async function next () {
  processing.value = true;
  const error = await sendConfirmationMail();
  processing.value = false;

  if (error === 'already-exists') {
    return toast.error(t('accountExistsError'));
  } else if (error) {
    return toast.error(t('mailFailError'));
  }

  emit('next');
}
</script>
