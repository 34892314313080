<template>
  <div>
    <div class="mb-2 text-left text-base font-bold">
      {{ title }}
    </div>
    <textarea
      class="border-borderGray w-full resize-y overflow-auto rounded-sm border border-solid px-3 py-2 text-base"
      :style="{ height: `${height}px` }"
      :placeholder="placeholder"
      :value="modelValue"
      @input="onChange"
    />
  </div>
</template>

<script setup lang="ts">
interface Props {
  title: string;
  modelValue: string;
  placeholder: string;
  height: number;
}

defineProps<Props>();

interface Emits {
  (e: 'update:model-value', value: string): void;
}

const emit = defineEmits<Emits>();

function onChange (e: Event) {
  emit('update:model-value', (e.target as HTMLTextAreaElement).value);
}
</script>
