<template>
  <div class="bg-bgGray">
    <div
      class="py-6 sm:px-0 sm:px-4 sm:pb-20 sm:pt-10 md:mx-auto md:w-[1191px]"
    >
      <!-- 戻る -->
      <div class="mb-3 ml-2 flex justify-start">
        <router-link to="/app/mypage">
          <!-- マイページ（商標一覧） へ戻る -->
          <div class="text-grayAlpha60 flex items-center">
            <div class="material-icons material-symbols-outlined">
              arrow_back_ios
            </div>
            <div>{{ t('back') }}</div>
          </div>
        </router-link>
      </div>
      <!-- ./戻る -->
      <!-- 本体 -->
      <div class="bg-white px-0 md:flex md:justify-around md:bg-transparent">
        <!-- left -->
        <div class="mr-6 hidden w-1/3 md:block">
          <!-- 商標情報 -->
          <ApplicationInfo />
          <!-- お支払い情報 -->
          <div
            class="invisible mb-2 bg-white p-6 shadow-md sm:rounded md:visible"
          >
            <h2 class="text-base font-bold">
              {{ t('payment.title') }}
            </h2>
            <div class="my-3 h-[1px] w-full bg-[#e9e9e9]" />
            <!-- 支払い -->
            <PaymentSummary />
            <!-- ./支払い -->
          </div>
          <!-- 登録証 -->
          <div
            v-if="
              applicationStore.recaMailReqType === 'pdf_only' ||
                applicationStore.recaMailReqType === 'mail'
            "
          >
            <RegistrationCard />
          </div>
        </div>
        <!-- ./left -->
        <!-- right -->
        <div class="w-full pb-2 sm:w-2/3">
          <!-- メッセージ 入力エリア-->
          <h2 class="hidden pb-2 text-2xl font-bold sm:inline-block">
            {{ t('attorneyMessage') }}
          </h2>
          <span class="hidden sm:inline text-grayAlpha60 ml-2">（{{ t('tabs.note') }}）</span>
          <Tabs
            class="mt-4 pl-2 sm:hidden"
            :model-value="tabNo"
            :options="tabOptions"
            @update:model-value="handleTabChange"
          />
          <!-- メッセージ -->
          <div v-if="tabNo === 0">
            <div class="text-sm text-grayAlpha60 ml-3 mt-2 sm:hidden">
              ※ {{ t('tabs.note') }}
            </div>
            <div class="bg-white mb-3 px-2 mb-2 pt-0 shadow-md sm:rounded sm:p-6">
              <FormTextArea
                v-model="inquiriesStore.$state.message"
                :placeholder="'弁理士へのメッセージはここに入力して送信してください。'"
                :height="180"
                :title="''"
              />
              <div class="flex justify-between">
                <div class="flex flex-col sm:flex-row">
                  <SecondaryButton
                    class="text-grayAlpha60 mb-1 mr-2 h-11 w-32"
                    title="ファイルを選択"
                    :disabled="false"
                    @click="onSelectedFile"
                  />
                  <div
                    v-if="inquiriesStore.attachment"
                    class="flex items-center"
                  >
                    <div class="text-grayAlpha60 mr-2 text-sm">
                      {{ inquiriesStore.attachment?.name }}
                    </div>
                    <div
                      class="material-icons text-grayAlpha20 cursor-pointer"
                      @click="inquiriesStore.attachment = null"
                    >
                      close
                    </div>
                  </div>
                </div>
                <input
                  ref="fileSelect"
                  type="file"
                  accept="image/png,image/jpeg, application/zip, application/pdf, text/plain, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  @change="handleAddAttachment"
                >
                <PrimaryButton
                  class="h-11 w-32"
                  title="送信する"
                  :disabled="isMessageDisabled"
                  @click="handleSendMessage"
                />
              </div>
            </div>
            <Message />
          </div>
          <!-- 商標 -->
          <div v-if="tabNo === 1">
            <ApplicationInfoModal />
          </div>
          <!-- 支払い -->
          <div v-if="tabNo === 2">
            <PaymentSummary />
          </div>
          <!-- 登録証 -->
          <div v-if="tabNo === 3">
            <div v-if="applicationStore.recaMailReqType !== 'none'">
              <RegistrationCard />
            </div>
            <div v-else>
              <div class="bg-white py-4 pl-8 text-sm">
                特許庁へ登録後に確認いただけます。
              </div>
            </div>
          </div>
        </div>
        <!-- ./right -->
      </div>
      <!-- ./本体 -->
    </div>
  </div>
  <!-- 支払詳細 -->
  <Modal
    :is-visible="isPaymentModal"
    @close="isPaymentModal = !isPaymentModal"
  >
    <PaymentDetailModal />
  </Modal>
  <!-- スピナ -->
  <TheOverlayLoading
    v-if="processing"
    :message="t('processing')"
  />
</template>
<script setup lang="ts">
import { ref, inject, computed, onMounted } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import { useInquiriesStore } from '@/composables/useInquiriesStore';
import { useApplicationStore } from '@/composables/useApplicationStore';
import { usePaymentStore } from '@/composables/usePaymentStore';
import { useMailingAddressStore } from '@/composables/useMailingAddressStore';
import PaymentDetailModal from './PaymentDetailModal.vue';
import ApplicationInfo from './ApplicationInfo.vue';
import RegistrationCard from './RegistrationCard.vue';
import Message from './Message.vue';
import ApplicationInfoModal from './ApplicationInfoModal.vue';
import PaymentSummary from './PaymentSummary.vue';
import ChannelService from '@/lib/channelService';
import { useI18n } from 'vue-i18n';
import { ja } from '@/i18n/pages/app/applications/ja';
import { NotFoundError } from '@/types/error';

const isPaymentModal = ref(false);
const route = useRoute();
const applicationId = Number(route.params.id);
const inquiriesStore = useInquiriesStore();
const applicationStore = useApplicationStore();
const paymentStore = usePaymentStore();
const mailingAddressStore = useMailingAddressStore();
const fileSelect = ref<HTMLInputElement | null>(null);
const tabNo = ref(0);
const processing = ref(false);
const toast = useToast();
const { t } = useI18n({ messages: ja });

// ユーザー情報
const { loadUser } = inject(UserDataKey) as UserDataStore;

onMounted(async () => {
  processing.value = true;

  try {
    // ユーザー情報
    const loadError = await loadUser();
    if (loadError) {
      window.location.href = '/app/login';
    }
    // 商標情報取得
    await applicationStore.getAppApplication(applicationId);
    // 支払い情報
    await paymentStore.fetch(applicationId);
    // メッセージ取得
    await inquiriesStore.getInquiries(applicationId);
    // 郵送先住所
    await mailingAddressStore.getAdddess(applicationId);

    ChannelService.hideChannelButton();
  } catch (e) {
    if (e instanceof NotFoundError) {
      window.location.href = '/sorry/404.html';
    } else {
      toast.error(t('systemError'), { timeout: 15000 });
    }
  } finally {
    processing.value = false;
  }
});

// メッセージTextArea活性非活性
const isMessageDisabled = computed(() => {
  if (inquiriesStore.message.trim()) {
    return false;
  }
  return true;
});

// タブオプション
const tabOptions = computed(() => {
  if (
    applicationStore.recaMailReqType === 'pdf_only' ||
    applicationStore.recaMailReqType === 'mail'
  ) {
    return [
      t('tabs.attorneyMessage'),
      t('tabs.tm'),
      t('tabs.payment'),
      t('tabs.registrationCard'),
    ];
  }
  return [t('tabs.attorneyMessage'), t('tabs.tm'), t('tabs.payment')];
});

// スマホのみ: タブ変更
const handleTabChange = (index: number) => {
  tabNo.value = index;
};

// メッセージ送信
const handleSendMessage = async () => {
  processing.value = true;
  try {
    await inquiriesStore.postMessage(applicationId);

    toast.success(t('postMessage'), { timeout: 15000 });
  } catch {
    toast.error(t('systemError'), { timeout: 15000 });
  } finally {
    processing.value = false;
  }
};

// メッセージに添付ファイルを追加
function handleAddAttachment (e) {
  const files = e.target.files || e.dataTransfer.files;

  if (!files.length) return;

  inquiriesStore.$state.attachment = files[0];
}

// メッセージへの添付ファイルを選択
function onSelectedFile () {
  fileSelect.value?.click();
}
</script>

<style lang="scss" scoped>
input[type='file'] {
  display: none;
}
</style>
