<template>
  <select
    class="border-borderGray h-[50px] w-full cursor-pointer rounded border border-solid bg-white px-3 py-3 text-base leading-4 outline-none"
    :name="name || ''"
    :autocomplete="autocomplete || 'off'"
    :value="modelValue"
    @change="onChange"
    @blur="$emit('blur')"
  >
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script setup lang="ts">
type SelectOption = {
  label: string;
  value: string;
};

defineProps<{
  modelValue: string;
  options: SelectOption[];
  name?: string;
  autocomplete?: string;
}>();

const emit = defineEmits<{
  (e: 'update:model-value', value: string): void;
  (e: 'blur'): void;
}>();

function onChange (e: Event) {
  const value = (e.target as HTMLSelectElement).value;
  emit('update:model-value', value);
}
</script>
