<template>
  <div>
    <div class="items-start sm:flex">
      <SectionBlock
        class="flex-1"
        title="検索した商標"
        :hides-devider="true"
      >
        <template #header>
          <HelpLink
            title="検索結果に関して"
            @click="showsHelpSearchResult = true"
          />
        </template>
        <template #default>
          <div class="mt-4">
            <!-- 入力された検索条件 -->
            <div class="border-borderGray border-b border-solid pb-6">
              <!-- ロゴ画像 -->
              <div
                v-if="trademarkType === 'image' && imageDataURL"
                class="border-grayAlpha20 mb-4 h-32 w-32 border border-solid bg-contain bg-center bg-no-repeat"
                :style="{ backgroundImage: `url(${imageDataURL})` }"
              />
              <div
                v-if="
                  trademarkType === 'text' ||
                    (trademarkType === 'image' && existsText)
                "
                class="grid-cols-2 gap-x-2 sm:grid"
              >
                <!-- 商標/ロゴ内の文字 -->
                <div>
                  <div class="text-grayAlpha20 mb-2 text-sm">
                    {{ trademarkType === 'text' ? '商標' : '文字' }}
                  </div>
                  <FormTextInput
                    :model-value="
                      trademarkType === 'text' ? standardCharacter : imageText
                    "
                    :disabled="true"
                  />
                </div>
                <!-- よみがな -->
                <div class="mt-4 sm:mt-0">
                  <div class="text-grayAlpha20 mb-2 text-sm">
                    よみがな(権利範囲の対象ではありません)
                  </div>
                  <FormTextInput
                    :model-value="
                      trademarkType === 'text' ? inputtedYomi : imageYomi
                    "
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
            <!-- 検索結果（類似商標のリスト） -->
            <div class="mt-4 pb-2 font-bold tracking-wider">
              検索結果
            </div>
            <template v-if="progress === 'completed'">
              <template v-if="trademarkType === 'text'">
                <SearchResultListRow
                  v-for="similarResult in textSearchResultList"
                  :key="similarResult.goodsServiceId"
                  class="bg-bgGray border-borderGray mb-2 rounded border border-solid p-4"
                  :similar-result="similarResult"
                />
              </template>
              <template v-if="trademarkType === 'image'">
                <SearchResultListRow
                  v-for="similarResult in imageSearchResultList"
                  :key="similarResult.goodsServiceId"
                  class="bg-bgGray border-borderGray mb-2 rounded border border-solid p-4"
                  :similar-result="similarResult"
                />
              </template>
            </template>
            <!-- 検索結果未取得ならローディング。検索結果URL直アクセス時を想定 -->
            <template v-else>
              <div
                class="h-10 w-10 bg-contain bg-center bg-no-repeat"
                :style="{ backgroundImage: `url(${LoadingGif})` }"
              />
            </template>
          </div>
        </template>
      </SectionBlock>
      <!-- 申込へ -->
      <div class="mt-8 sm:sticky sm:top-4 sm:ml-8 sm:mt-0 sm:w-[478px]">
        <SectionBlock
          class="shadow-md"
          title="申し込みへ"
          :hides-devider="true"
        >
          <NextPageNavigator />
        </SectionBlock>
        <div class="text-grayAlpha60 mb-10 mt-4 whitespace-pre-line text-sm">
          <div class="mb-1">
            ※
            お申し込み後、類似以外の不登録事由も含め、Cotobox提携弁理士（商標の専門家）がチェックする機会がございます。
          </div>
          <div class="mb-1">
            ※
            最終的に登録の判断をするのは特許庁です。本検索結果、および、弊社または提携弁理士が特許庁の審査結果を保証することは出来かねます。
          </div>
          <div>
            ※
            35類の項目を2つ以上選択して出願する場合など、追加費用がかかるケースがございます。詳しくは<a
              class="text-primary underline"
              href="https://support.cotobox.com/additional-cost"
              target="_blank"
            >こちら</a>
          </div>
        </div>
      </div>
    </div>
    <!-- ヘルプモーダル: 検索結果に関して -->
    <Modal
      :is-visible="showsHelpSearchResult"
      @close="showsHelpSearchResult = false"
    >
      <div class="px-6 pb-8 pt-6">
        <div
          class="border-borderGray border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
        >
          検索結果に関して
        </div>
        <HelpContentSearchResult />
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, computed } from 'vue';
// 状態管理
import { TrademarkSearchStoreKey } from '@/composables/injectionKeys';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
// 型定義
import { KlassId } from '@/types';
// コンポーネント
import SearchResultListRow from '@/components/search/SearchResultListRow.vue';
import HelpLink from '@/components/common/HelpLink.vue';
import HelpContentSearchResult from '@/components/search/HelpContentSearchResult.vue';
import NextPageNavigator from '@/components/search/NextPageNavigator.vue';
import LoadingGif from '@/assets/images/loading.gif';

const {
  trademarkType,
  standardCharacter,
  inputtedYomi,
  imageDataURL,
  existsText,
  imageText,
  imageYomi,
  textSearchResults,
  imageSearchResults,
  progress,
} = inject(TrademarkSearchStoreKey) as TrademarkSearchStore;

// 文字商標検索結果
const textSearchResultList = computed(() => {
  return (Object.keys(textSearchResults.value) as KlassId[])
    .map((klassId) => {
      const similarResults = textSearchResults.value[klassId]!;
      return similarResults.map((similarResult) => ({
        klassId,
        ...similarResult,
      }));
    })
    .flat();
});

// ロゴ商標検索結果
const imageSearchResultList = computed(() => {
  return (Object.keys(imageSearchResults.value) as KlassId[])
    .map((klassId) => {
      const similarResults = imageSearchResults.value[klassId]!;
      return similarResults.map((similarResult) => ({
        klassId,
        ...similarResult,
      }));
    })
    .flat();
});

const showsHelpSearchResult = ref(false);
</script>
