<template>
  <div
    class="border-borderGray flex h-11 items-center rounded border border-solid"
  >
    <button
      class="material-icons text-grayAlpha60 data-[disabled=true]:text-grayAlpha20 h-full w-11 cursor-pointer"
      :data-disabled="disableDecrement"
      :disabled="disableDecrement"
      @click="$emit('update:model-value', modelValue - 1)"
    >
      remove
    </button>
    <div class="text-textBlack w-11 text-center">
      {{ modelValue }}
    </div>
    <button
      class="material-icons text-grayAlpha60 data-[disabled=true]:text-grayAlpha20 h-full w-11 cursor-pointer"
      :data-disabled="disableIncrement"
      :disabled="disableIncrement"
      @click="$emit('update:model-value', modelValue + 1)"
    >
      add
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: number;
  min: number;
  max: number;
}>();

defineEmits<{
  (e: 'update:model-value', value: number): void;
}>();

const disableDecrement = computed(() => props.modelValue === props.min);
const disableIncrement = computed(() => props.modelValue === props.max);
</script>
