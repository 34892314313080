<template>
  <div class="bg-bgGray">
    <div
      class="px-4 pb-16 pt-6 sm:mx-auto sm:w-[940px] sm:px-0 sm:pb-20 sm:pt-16"
    >
      <div class="mb-4">
        <router-link to="/app/notifications">
          <BackButton />
        </router-link>
      </div>
      <div
        v-if="notificationItem"
        class="rounded-lg bg-white p-4 sm:px-8 sm:py-6"
      >
        <div class="mb-4 flex items-center justify-between">
          <!-- ロゴ -->
          <div class="sm:w-40">
            <NotificationSourceLogo
              class="mt-1 h-12 w-40 sm:w-full"
              :item="notificationItem"
            />
          </div>
          <!-- 投稿日 -->
          <time class="text-grayAlpha60 text-base">
            {{ notificationItem.createdDate }}
          </time>
        </div>
        <!-- タイトル -->
        <div class="text-grayAlpha80 mb-4 text-xl font-bold sm:text-2xl">
          {{ notificationItem.title }}
        </div>
        <!-- 本文 -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="notificationItem.body" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useNotificationsStore } from '@/features/notification/stores/notifications.store';
import { NotificationItem } from '@/features/notification/types/notification-item.types';
import BackButton from '@/components/common/BackButton.vue';
import NotificationSourceLogo from '@/features/notification/components/NotificationSourceLogo.vue';

const route = useRoute();
const notificationsStore = useNotificationsStore();
const notificationItem = computed<NotificationItem | undefined>(() => {
  return notificationsStore.allNotifications.find(
    (e) => String(e.id) === route.params.id,
  );
});
</script>
