<template>
  <div>
    <KlassListRowVue
      v-for="klassId in klassIds"
      :key="klassId"
      class="mb-2 cursor-pointer rounded border border-solid"
      :class="
        klassIdsForSearch.includes(klassId)
          ? 'border-primary bg-primaryOrange5'
          : 'border-borderGray bg-white sm:hover:bg-[#f4f5f6]'
      "
      :klass-id="klassId"
      :expanded="expandedKlasses.includes(klassId)"
      :filter-type="filterType"
      @select:klass="onKlassRowClick(klassId)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, inject } from 'vue';
// 状態管理
import {
  KlassListStoreKey,
  KlassSelectStoreKey,
} from '@/composables/injectionKeys';
import { KlassListStore } from '@/composables/useKlassList';
import { KlassSelectStore } from '@/composables/useKlassSelect';
// 型定義
import { KlassFilterType } from '@/composables/useKlassFilter';
import { KlassId } from '@/types';
// コンポーネント
import KlassListRowVue from '@/components/search/KlassListRow.vue';

interface Props {
  filterType: KlassFilterType;
}

const props = defineProps<Props>();

const filterType = computed(() => props.filterType);

const { allList, listFromSource } = inject(KlassListStoreKey) as KlassListStore;

const { klassIdsForSearch } = inject(KlassSelectStoreKey) as KlassSelectStore;

const klassIds = computed(() => {
  return Object.keys(
    filterType.value === 'none' ? allList.value : listFromSource.value,
  ) as KlassId[];
});
const expandedKlasses = ref([] as KlassId[]);

function onKlassRowClick (klassId: KlassId) {
  if (expandedKlasses.value.includes(klassId)) {
    const index = expandedKlasses.value.indexOf(klassId);
    expandedKlasses.value.splice(index, 1);
  } else {
    expandedKlasses.value.push(klassId);
  }
}
</script>
