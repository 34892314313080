import { App } from 'vue';

export default {
  install: (app: App) => {
    app.config.globalProperties.$device = {
      isMobile: window.innerWidth < 700,
      isDesktop: window.innerWidth >= 700,
    };
  },
};
