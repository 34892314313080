<template>
  <div class="">
    <ul class="bg-primaryOrange5 mb-8 list-disc p-4 pl-6 text-sm">
      <li class="mb-1">
        法人の場合、法人名は登記上の商号、住所は登記上の本店所在地をご入力ください。
      </li>
      <li class="mb-1">
        個人の場合、住所は「確実に郵便物が届く住所」、氏名は「戸籍上の氏名」をご入力ください。
      </li>
      <li class="mb-1">
        出願人名、住所は公報や特許情報プラットフォームにて公開されます。
      </li>
      <li>
        海外の住所の場合、「国名」で該当する国を選択の上、その国の行政区分に分けて住所をご入力ください。
      </li>
    </ul>
    <div class="mb-8">
      <div class="mb-2 text-base font-semibold">
        {{ t('applicantName') }}
      </div>
      <div class="flex items-center">
        <div>
          {{ applicantName }}
        </div>
        <button
          class="text-primary ml-2 cursor-pointer text-sm leading-[14px] underline"
          @click="showsApplicantNameModal = true"
        >
          {{ t('edit') }}
        </button>
      </div>
    </div>
    <div class="mb-4">
      <div class="mb-2 text-base font-semibold">
        {{ t('address') }}
      </div>
      <AddressForm
        :is-applicant-form="true"
        :shows-required-label="true"
      />
      <div class="bg-primaryOrange5 mt-4 rounded p-2 text-sm">
        <div class="mb-1 flex items-center">
          <div class="material-icons text-grayAlpha20 mr-1">
            warning
          </div>
          <div class="font-bold">
            代理で申込される方へ
          </div>
        </div>
        出願人と本申し込みのご担当者さまが異なる組織に所属し、出願人に代わって手続きを行なう場合、代理である旨を次の［弁理士への連絡事項］にてご申告ください。<br>
        場合により弁理士法に違反する可能性がございますため、お申し込み後に担当弁理士が確認を行います。<br>
        詳しくは<a
          href="https://support.cotobox.com/proxy-application"
          target="_blank"
          class="text-primary underline"
        >こちら</a>
      </div>
    </div>
    <!-- 氏名モーダル -->
    <Modal
      class="w-screen sm:w-[600px]"
      :is-visible="showsApplicantNameModal"
      @close="onUserNameModalClose"
    >
      <div class="px-4 pt-2">
        <div
          class="border-borderGray border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
        >
          {{ t('applicantName') }}
        </div>
        <div class="pb-12 pt-6">
          <UserNameForm />
        </div>
      </div>
    </Modal>
    <!-- 住所入力モーダル -->
    <Modal
      class="w-screen sm:w-[600px]"
      :is-visible="showsApplicantAddressModal"
      @close="showsApplicantAddressModal = false"
    >
      <div class="px-6 pb-12 pt-6">
        <AddressForm
          :is-applicant-form="true"
          :shows-required-label="true"
        />
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, computed } from 'vue';
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import Modal from '@/components/common/Modal.vue';
import UserNameForm from '@/components/common/UserNameForm.vue';
import AddressForm from '@/components/common/AddressForm.vue';

const { t } = useI18n({
  messages: {
    ja: {
      applicantName: '出願人',
      edit: '編集',
      address: '住所・電話番号',
      lookup: '住所を自動入力',
      companyNameEmptyError: '法人名を入力してください。',
      userNameEmptyError: '担当者姓、担当者名を入力してください。',
    },
    en: {
      applicantName: 'Applicant',
      edit: 'Edit',
      address: 'Address・Phone Number',
      lookup: 'Auto fill City/State',
      companyNameEmptyError: 'Please enter your company name.',
      userNameEmptyError: 'Please enter the name of the person in charge.',
    },
    zh: {
      applicantName: '申请人',
      edit: '编辑',
      address: '地址・电话号码',
      lookup: '自动输入你的地址',
      companyNameEmptyError: '请输入法律实体的名称',
      userNameEmptyError: '请输入联系人的姓名',
    },
  },
});

const toast = useToast();

const { state } = inject(UserDataKey) as UserDataStore;

const showsApplicantNameModal = ref(false);

const showsApplicantAddressModal = ref(false);

const applicantName = computed(() =>
  state.isPersonal ? `${state.lastName} ${state.firstName}` : state.companyName,
);

function onUserNameModalClose () {
  if (!state.lastName.length || !state.firstName.length) {
    return toast.error(t('userNameEmptyError'));
  }
  if (!state.isPersonal && !state.companyName.length) {
    return toast.error(t('companyNameEmptyError'));
  }
  if (state.isPersonal) {
    state.companyName = '';
  }
  showsApplicantNameModal.value = false;
}
</script>
