<template>
  <footer :class="`${$options.name}`">
    <div :class="`${$options.name}__content`">
      <div :class="`${$options.name}__menuGroups`">
        <div :class="`${$options.name}__menuGroup`">
          <p :class="`${$options.name}__menuGroupTitle`">
            {{ t('service') }}
          </p>
          <div :class="`${$options.name}__menuGroupBody`">
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="/app/search/"
            >{{
              t('search')
            }}</a>
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="/plan"
            >{{
              t('price')
            }}</a>
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="/flow"
            >{{
              t('flow')
            }}</a>
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="/testmonials/"
            >{{
              t('cases')
            }}</a>
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="https://support.cotobox.com/"
            >{{ t('faq') }}</a>
          </div>
        </div>
        <div :class="`${$options.name}__menuGroup`">
          <p :class="`${$options.name}__menuGroupTitle`">
            {{ t('media') }}
          </p>
          <div :class="`${$options.name}__menuGroupBody`">
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="/primer/"
            >{{
              t('primer')
            }}</a>
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="https://corp.cotobox.com/news"
            >{{ t('news') }}</a>
          </div>
        </div>
        <div :class="`${$options.name}__menuGroup`">
          <p :class="`${$options.name}__menuGroupTitle`">
            {{ t('company') }}
          </p>
          <div :class="`${$options.name}__menuGroupBody`">
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="https://corp.cotobox.com/company"
            >{{ t('company') }}</a>
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="/commercial"
            >{{
              t('commercial')
            }}</a>
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="/privacy"
            >{{
              t('privacy')
            }}</a>
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="/terms"
            >{{
              t('terms')
            }}</a>
            <a
              :class="`${$options.name}__menuLinkItem`"
              href="/app/contact"
            >{{
              t('contact')
            }}</a>
          </div>
        </div>
        <div :class="`${$options.name}__menuGroup`">
          <div :class="`${$options.name}__logo`">
            <img src="@/assets/images/icons/logo.svg">
          </div>
          <div :class="`${$options.name}__shareButtons`">
            <a
              href="https://twitter.com/CotoboxJP"
              target="_blank"
            ><img
              :class="`${$options.name}__shareButton`"
              src="@/assets/images/icons/twitter.svg"
            ></a>
            <a
              href="https://www.facebook.com/cotobox.jp/"
              target="_blank"
            ><img
              :class="`${$options.name}__shareButton`"
              src="@/assets/images/icons/facebook.svg"
            ></a>
          </div>
          <div :class="`${$options.name}__ismsLogo`">
            <img
              src="@/assets/images/logos/isms.png"
              alt="ISMS認証"
            >
          </div>
        </div>
      </div>
      <p :class="`${$options.name}__copyright`">
        Copyright 2019 cotobox Inc. All rights Reserved.
      </p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'DefaultFooter',
  setup () {
    const { t } = useI18n({
      locale: 'ja',
      messages: {
        ja: {
          service: 'サービス',
          search: '商標検索',
          price: '費用',
          flow: '仕組み',
          cases: '導入企業の声',
          faq: 'よくあるご質問',
          trademarks: '商標一覧',
          media: 'メディア',
          primer: '商標入門',
          news: 'ニュース',
          terms: '利用規約',
          contact: 'お問い合わせ',
          company: '会社概要',
          commercial: '特定商取引法に基づく表記',
          privacy: 'プライバシーポリシー',
        },
        en: {
          service: 'Service',
          search: 'Trademark Search',
          price: 'Pricing',
          flow: 'How it work',
          cases: 'Client testmonials',
          faq: 'FAQ',
          media: 'Media',
          primer: 'Trademark Introduction',
          news: 'News',
          terms: 'Terms of service',
          contact: 'Contact us',
          company: 'Company Information',
          commercial:
            'Notation based on the Specified Commercial Transaction Act',
          privacy: 'Privacy policy',
        },
        zh: {
          service: '服务',
          search: '检索商标',
          price: '费用',
          flow: '使用流程',
          cases: '导入Cotobox的企业之声',
          faq: '常见问题',
          media: '媒体',
          primer: '商标介绍',
          news: '新闻',
          terms: '使用条款',
          contact: '联系我们',
          company: '公司信息',
          commercial: '基于《特定商业交易法》的记号  ',
          privacy: '隐私政策',
        },
      },
    });

    return {
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
.DefaultFooter {
  background-color: #555555;
  color: #ffffff;

  @include pc {
    padding: 48px 0 96px;
  }
  @include sp {
    padding: 48px 16px 96px;
  }

  &__content {
    @include pc {
      width: 860px;
      margin: 0 auto;
    }
  }

  &__menuGroups {
    @include pc {
      display: flex;
    }
  }

  &__menuGroup {
    @include pc {
      flex: 1;
    }

    @include sp {
      margin-bottom: 24px;
    }
  }

  &__menuGroupTitle {
    color: #e7e7e7;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__menuLinkItem {
    display: block;
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }
  }

  &__logo {
    width: 178px;
    height: 48px;
    margin-bottom: 24px;

    @include pc {
      margin-bottom: 8px;
    }

    @include sp {
      margin: 40px 0 8px;
    }
  }

  &__shareButtons {
    display: flex;

    @include pc {
      margin-bottom: 16px;
    }

    @include sp {
      margin: 0px 0 16px;
    }
  }

  &__shareButton {
    margin-right: 8px;
  }

  &__ismsLogo {
    width: 140px;
    height: 74px;
    object-fit: cover;
    margin-bottom: 24px;
  }

  &__copyright {
    text-align: center;
    color: #e7e7e7;
    font-size: 14px;

    @include pc {
      margin: 80px 0 0;
    }

    @include sp {
      margin: 40px 0 0;
    }
  }
}
</style>
