<template>
  <nav class="flex items-center">
    <!-- メニュー -->
    <div
      v-for="item in rightMenuItems"
      :key="item.title"
      class="flex items-center pb-3 pt-2"
    >
      <div
        class="flex cursor-pointer items-center border-r border-solid border-white px-4 hover:text-[#ffffff99]"
        @click="item.onClick"
      >
        <div class="material-icons material-symbols-outlined mr-1 text-sm">
          {{ item.mdiIconName }}
        </div>
        <div class="text-sm font-semibold tracking-wider">
          {{ item.title }}
        </div>
      </div>
    </div>
    <!-- マイページ ドロップダウンメニュー -->
    <VDropdown
      :distance="-5"
      auto-hide
    >
      <div class="pb-3 pt-2">
        <div
          class="flex cursor-pointer items-center pl-4 hover:text-[#ffffff99]"
        >
          <div class="text-sm font-semibold tracking-wider">
            {{ `${userData.lastName}${userData.firstName}` }}
          </div>
          <div class="material-icons material-symbols-outlined">
            expand_more
          </div>
        </div>
      </div>
      <template #popper>
        <div class="w-52 p-4">
          <div
            v-for="item in accountMenuItems"
            :key="item.title"
            class="text-grayAlpha80 hover:text-grayAlpha60 flex cursor-pointer items-center py-2"
            @click="handleAccountMenuItemClick(item)"
          >
            <div class="material-icons material-symbols-outlined mr-2">
              {{ item.mdiIconName }}
            </div>
            <div class="text-sm font-semibold">
              {{ item.title }}
            </div>
          </div>
        </div>
      </template>
    </VDropdown>
    <!-- お問合せモーダル -->
    <Modal
      :is-visible="showsContactModal"
      @close="showsContactModal = false"
    >
      <div class="px-6 pb-8 pt-6">
        <div
          class="border-borderGray border-b border-solid py-2 text-base font-semibold tracking-wider text-black"
        >
          {{ t('contact') }}
        </div>
        <HelpContentContact @close="showsContactModal = false" />
      </div>
    </Modal>
  </nav>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
// 状態管理
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
// コンポーネント
import HelpContentContact from '@/components/header/HelpContentContact.vue';
import { hideAllPoppers } from 'floating-vue';
import 'floating-vue/dist/style.css';

const router = useRouter();

const handleAccountMenuItemClick = (item: AccountMenuItem) => {
  item.onClick();
  hideAllPoppers();
};

const { state: userData } = inject(UserDataKey) as UserDataStore;

const { t } = useI18n({
  messages: {
    ja: {
      titleSmall: 'オンライン商標登録サービス「コトボックス」',
      businessHours: '平日 10:00〜12:00 14:00〜17:00',
      signUp: '無料で利用（会員登録）',
      primer: '商標入門ガイド',
      about: 'Cotoboxとは？',
      price: '料金',
      cases: '導入事例',
      search: '商標を検索・申込',
      signIn: 'ログイン',
      brochure: '資料ダウンロード',
      mypage: 'マイページ',
      support: 'よくあるご質問',
      contact: 'お問合せ',
      setting: '設定',
    },
    en: {
      titleSmall: 'Online Trademark Registration Service "Cotobox"',
      businessHours: 'Weekdays 10am-5pm',
      signUp: 'Sign Up',
      primer: 'Trademark Primer',
      about: 'What\'s Cotobox',
      price: 'Pricing',
      cases: 'Case Studies',
      search: 'Search trademark',
      signIn: 'Sign in',
      brochure: 'Brochure',
      mypage: 'Mypage',
      support: 'Support',
      contact: 'Contact',
      setting: 'Setting',
    },
    zh: {
      titleSmall: '在线商标注册服务「Cotobox」',
      businessHours: '平日上午10点至下午5点',
      signUp: '注册新账户',
      primer: '商标入门',
      about: '什么是Cotobox',
      price: '价格',
      cases: '案例研究',
      search: '搜索商标',
      signIn: '登录',
      brochure: '手册',
      mypage: '首页',
      support: '支持',
      contact: '联系我们',
      setting: '设置',
    },
  },
});

const rightMenuItems = [
  {
    title: t('support'),
    mdiIconName: 'help',
    onClick: () => {
      window.open('https://support.cotobox.com/');
    },
  },
  {
    title: t('contact'),
    mdiIconName: 'call',
    onClick: () => {
      showsContactModal.value = true;
    },
  },
];

interface AccountMenuItem {
  title: string;
  mdiIconName: string;
  onClick: () => void;
}

const accountMenuItems: AccountMenuItem[] = [
  {
    title: t('mypage'),
    mdiIconName: 'person',
    onClick: () => {
      router.push('/app/mypage');
    },
  },
  {
    title: t('setting'),
    mdiIconName: 'settings',
    onClick: () => {
      location.href = '/app/users/edit';
    },
  },
];

const showsContactModal = ref(false);
</script>
