<script setup lang="ts">
import { computed, inject, onMounted } from 'vue';
import SmallTabs from '@/components/common/SmallTabs.vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ApplicationListKey } from '@/composables/injectionKeys';
import {
  ApplicationListStore,
  ApplicationListType,
} from '@/composables/useApplicationList';
import MyTrademarkTableRowMobile from '@/components/myTrademark/MyTrademarkTableRowMobile.vue';
import MyTrademarkTableRowSavedMobile from '@/components/myTrademark/MyTrademarkTableRowSavedMobile.vue';
import MypageApplicationTableNoContents from '@/components/myTrademark/MyTrademarkTableNoContents.vue';
import { TrademarkApplication } from '@/api/APIClientInterface';

const route = useRoute();

const { state, selectedList } = inject(
  ApplicationListKey,
) as ApplicationListStore;

const selectedApplications = computed(
  () => selectedList.value as TrademarkApplication[],
);

const { t } = useI18n({
  messages: {
    ja: {
      tabs: {
        application: '出願',
        registered: '権利発生済',
        saved: '検討中',
        cancelled: 'キャンセル・失効',
      },
      trademarkInfo: '商標内容',
      trademark: '商標',
      referenceNumber: '整理番号',
      applicationNumber: '出願番号',
      applicationDate: '出願日',
      registrationNumber: '登録番号',
      registrationDate: '登録日',
      applicationProgress: {
        title: '申請状況',
        step1: '出願準備',
        step2: '出願',
        step3: '特許庁審査',
        step4: '登録',
      },
      registrationProgress: {
        title: '権利状況',
      },
      message: 'メッセージ',
      klass: '区分',
      savedDate: '保存日',
    },
    en: {
      tabs: {
        application: 'Application',
        registered: 'Registered',
        saved: 'Saved',
        cancelled: 'Cancelled',
      },
      trademarkInfo: 'Trademark',
      trademark: 'Trademark',
      referenceNumber: 'Ref Num',
      applicationNumber: 'Application Num',
      applicationDate: 'Application Date',
      registrationNumber: 'Registration Num',
      registrationDate: 'Registration Date',
      applicationProgress: {
        title: 'Progress',
        step1: 'Preparing',
        step2: 'Application',
        step3: 'Examination',
        step4: 'Registration',
      },
      registrationProgress: {
        title: 'Progress',
      },
      message: 'Message',
      klass: 'Class',
      savedDate: 'Saved Date',
    },
    zh: {
      tabs: {
        application: '应用',
        registered: '已经批准',
        saved: '正在审议中',
        cancelled: '取消了',
      },
      trademarkInfo: '商标',
      trademark: '商标',
      referenceNumber: '参考号',
      applicationNumber: '申请号',
      applicationDate: '申报日期',
      registrationNumber: '注册号',
      registrationDate: '注册日期',
      applicationProgress: {
        title: '申请状态',
        step1: '申请的准备',
        step2: '应用',
        step3: '审查',
        step4: '注册',
      },
      registrationProgress: {
        title: '权利状况',
      },
      message: '聊天',
      klass: '分类',
      savedDate: '保存日期',
    },
  },
});

const tabItems: {
  title: string;
  mdiIconName: string;
  tabName: ApplicationListType;
  onSelect: () => void;
  isActive: () => boolean;
}[] = [
  {
    title: t('tabs.application'),
    mdiIconName: 'Edit',
    tabName: 'submitted',
    onSelect: () => (state.selectedListType = 'submitted'),
    isActive: () => state.selectedListType === 'submitted',
  },
  {
    title: t('tabs.registered'),
    mdiIconName: 'Edit',
    tabName: 'accepted',
    onSelect: () => (state.selectedListType = 'accepted'),
    isActive: () => state.selectedListType === 'accepted',
  },
  {
    title: t('tabs.saved'),
    mdiIconName: 'Edit',
    tabName: 'saved',
    onSelect: () => (state.selectedListType = 'saved'),
    isActive: () => state.selectedListType === 'saved',
  },
  {
    title: t('tabs.cancelled'),
    mdiIconName: 'Edit',
    tabName: 'cancelled',
    onSelect: () => (state.selectedListType = 'cancelled'),
    isActive: () => state.selectedListType === 'cancelled',
  },
];

onMounted(() => {
  switch (route.query.status) {
  case 'submitted':
    state.selectedListType = 'submitted';
    break;
  case 'accepted':
    state.selectedListType = 'accepted';
    break;
  case 'cancelled':
    state.selectedListType = 'cancelled';
    break;
  case 'saved':
    state.selectedListType = 'saved';
    break;
  default:
    state.selectedListType = 'submitted';
  }
});
</script>

<template>
  <div class="m-0 w-screen pt-6">
    <div class="px-4 pb-6">
      <SmallTabs :tab-items="tabItems" />
    </div>
    <div
      class="grid gap-x-4 gap-y-2 border-x-[1px] border-b border-solid border-[#ccc] px-4 py-3 text-xs tracking-tighter"
      style="grid-template-columns: 56px 100px 60px 1fr"
    >
      <template v-if="state.selectedListType === 'submitted'">
        <div>{{ t('trademark') }}</div>
        <div>{{ t('referenceNumber') }}</div>
        <div>{{ t('applicationProgress.title') }}</div>
        <div class="text-right">
          メッセージ
        </div>
      </template>
      <template v-if="state.selectedListType === 'accepted'">
        <div>{{ t('trademark') }}</div>
        <div>{{ t('referenceNumber') }}</div>
        <div>{{ t('registrationProgress.title') }}</div>
        <div class="text-right">
          メッセージ
        </div>
      </template>
      <template v-if="state.selectedListType === 'saved'">
        <div>{{ t('trademark') }}</div>
        <div>{{ t('klass') }}</div>
        <div>{{ t('savedDate') }}</div>
        <div class="text-right">
          削除
        </div>
      </template>
      <template v-if="state.selectedListType === 'cancelled'">
        <div>{{ t('trademark') }}</div>
        <div>{{ t('referenceNumber') }}</div>
        <div>{{ t('applicationNumber') }}</div>
      </template>
    </div>
    <div>
      <template v-if="state.selectedListType !== 'saved'">
        <template v-if="selectedApplications.length">
          <MyTrademarkTableRowMobile
            v-for="application in selectedApplications"
            :key="application.id"
            :application="application"
          />
        </template>
        <MypageApplicationTableNoContents v-else />
      </template>
      <template v-else>
        <template v-if="state.savedTrademarks.length">
          <MyTrademarkTableRowSavedMobile
            v-for="savedApplication in state.savedTrademarks"
            :key="savedApplication.id"
            :saved-item="savedApplication"
          />
        </template>
        <MypageApplicationTableNoContents v-else />
      </template>
    </div>
  </div>
</template>
