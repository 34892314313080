<template>
  <div class="grid grid-cols-2 gap-4">
    <FormItemWrapper
      class="col-start-1 col-end-3"
      :title="t('country')"
      :required="true"
      :shows-required-label="showsRequiredLabel"
      :shows-error="false"
    >
      <CountrySelect v-model="state.address.country" />
    </FormItemWrapper>
    <template v-if="state.address.country === 'JP'">
      <FormTextInput
        v-model="state.address.zipcode"
        :title="t('postalCode')"
        :required="true"
        :shows-required-label="showsRequiredLabel"
        :shows-error="false"
        name="postalCode"
        placeholder="100-0001"
        autocomplete="postal-code"
        @blur="onPostalCodeBlur"
      />
      <div class="flex items-end justify-start">
        <SecondaryButton
          :title="t('lookup')"
          :disabled="false"
          @click="lookupAddress"
        />
      </div>
    </template>
    <div v-if="state.address.country === 'JP'">
      <FormItemWrapper
        :title="
          state.address.country === 'JP' ? t('prefecture') : t('addressLevel1')
        "
        :shows-required-label="showsRequiredLabel"
        :required="true"
        :shows-error="false"
      />
      <PrefectureSelect
        v-model="state.address.prefecture"
        name="prefecture"
      />
    </div>
    <FormTextInput
      v-else
      v-model="state.address.prefecture"
      :title="
        state.address.country === 'JP' ? t('prefecture') : t('addressLevel1')
      "
      :required="true"
      :shows-required-label="showsRequiredLabel"
      :shows-error="false"
      name="prefecture"
      placeholder="東京都"
      autocomplete="address-level1"
    />
    <FormTextInput
      v-model="state.address.city"
      :title="state.address.country === 'JP' ? t('city') : t('addressLevel2')"
      :required="true"
      :shows-required-label="showsRequiredLabel"
      :shows-error="false"
      name="city"
      placeholder="千代田区"
      autocomplete="address-level2"
    />
    <FormTextInput
      v-model="state.address.street"
      class="col-start-1 col-end-3"
      :title="state.address.country === 'JP' ? t('street') : t('addressLevel3')"
      :required="true"
      :shows-required-label="showsRequiredLabel"
      :shows-error="false"
      name="street"
      placeholder="千代田1-1"
      autocomplete="address-level3"
    />
    <FormTextInput
      v-model="state.phoneNumber"
      class="col-start-1 col-end-3"
      :title="
        isApplicantForm ? t('applicantFormPhoneNumber') : t('phoneNumber')
      "
      :required="true"
      :shows-required-label="showsRequiredLabel"
      :shows-error="false"
      name="phoneNumber"
      placeholder="0312345678"
      autocomplete="tel-national"
    />
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import CountrySelect from '@/components/common/CountrySelect.vue';
import PrefectureSelect from '@/components/common/PrefectureSelect.vue';
import FormItemWrapper from './FormItemWrapper.vue';

const { t } = useI18n({
  messages: {
    ja: {
      applicantName: '出願人',
      edit: '編集',
      address: '住所・電話番号',
      lookup: '住所を自動入力',
      country: '国名',
      postalCode: '郵便番号',
      prefecture: '都道府県',
      city: '市区町村',
      street: '丁目・番地、建物名、部屋番号',
      phoneNumber: '電話番号',
      applicantFormPhoneNumber: '担当者の連絡先電話番号',
      addressLevel1: '県・州・省など',
      addressLevel2: '市・区など',
      addressLevel3: '通り・町・番地など',
    },
    en: {
      applicantName: 'Applicant',
      edit: 'Edit',
      address: 'Address・Phone Number',
      lookup: 'Auto fill City/State',
      country: 'Country',
      postalCode: 'Postal Code',
      prefecture: 'Prefecture',
      city: 'City',
      street: 'Street',
      phoneNumber: 'Phone Number',
      applicantFormPhoneNumber: 'Phone Number',
    },
    zh: {
      applicantName: '申请人',
      edit: '编辑',
      address: '地址・电话号码',
      lookup: '自动输入你的地址',
      country: '国名',
      postalCode: '邮政编号',
      prefecture: '城市名',
      city: '区名',
      street: '街道名・小区名・房间号',
      phoneNumber: '电话号码',
      applicantFormPhoneNumber: '电话号码',
    },
  },
});

interface Props {
  isApplicantForm: boolean;
  showsRequiredLabel: boolean;
}

defineProps<Props>();

const { state, lookupAddress } = inject(UserDataKey) as UserDataStore;

function onPostalCodeBlur () {
  const withoutHyphen = state.address.zipcode.replace('-', '');
  if (withoutHyphen.length === 7) {
    lookupAddress();
  }
}
</script>
