<template>
  <Modal
    :is-visible="props.showsPaymentModal"
    @close="handleClose"
  >
    <div class="px-4 py-4 sm:w-[380px] md:w-[480px]">
      <!-- ヘッダー -->
      <div
        class="border-borderGray mb-1 border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
      >
        {{ paymentStore.current.translatedPaymentType }}
      </div>
      <!-- 支払い総額 -->
      <div class="py-4">
        <div
          class="flex justify-between border-2 border-2 border-solid border-black px-2 py-2 text-lg text-lg font-bold font-bold"
        >
          <div>{{ t('amountTitle') }}</div>
          <div v-if="paymentStore.current.total">
            {{
              t('amountValue', [paymentStore.current?.total.toLocaleString()])
            }}
          </div>
        </div>
      </div>
      <!-- カード・銀行選択 -->
      <div class="mb-6 flex justify-around">
        <div
          class="border-[#ccc] align-center mr-2 flex w-3/5 cursor-pointer rounded border border-solid bg-white px-3 py-2 shadow-md"
          :class="{ 'text-grayAlpha60': isBankSelect }"
          @click="selectCard"
        >
          <div class="flex items-center">
            <span class="material-icons mr-1">credit_card</span><span class="text-lg">{{ t('card') }}</span>
          </div>
        </div>
        <div
          class="border-[#ccc] align-center flex w-3/5 cursor-pointer rounded border border-solid bg-white px-3 py-2 shadow-md"
          :class="{ 'text-grayAlpha60': !isBankSelect }"
          @click="selectBank"
        >
          <div class="flex items-center">
            <span class="material-icons mr-1">account_balance</span><span class="text-lg">{{ t('bankTransfer') }}</span>
          </div>
        </div>
      </div>
      <!-- ./ カード・銀行選択 -->
      <!-- カード -->
      <div
        class="my-6"
        :class="{ hidden: isBankSelect }"
      >
        <template v-if="!isUseRegisteredCard">
          <StripeElements
            v-if="stripeLoaded"
            v-slot="{ elements }"
            ref="elms"
            :stripe-key="stripeKey"
            :instance-options="{ stripeAccount: props.stripeAccountId }"
          >
            <div class="mb-4 flex items-center">
              <div class="w-2/5 text-sm font-bold">
                {{ t('cardNumber') }}
              </div>
              <StripeElement
                ref="card"
                type="cardNumber"
                :elements="elements"
                class="border-[#ccc] h-10 w-3/5 rounded border border-solid bg-white px-3 py-2 shadow-md"
              />
            </div>
            <div class="mb-4 flex items-center">
              <div class="w-2/5 text-sm font-bold">
                {{ t('expires') }}
              </div>
              <StripeElement
                ref="cardExpiry"
                type="cardExpiry"
                :elements="elements"
                class="border-[#ccc] h-10 w-3/5 rounded border border-solid bg-white px-3 py-2 shadow-md"
              />
            </div>
            <div class="mb-4 flex items-center">
              <div class="w-2/5 text-sm font-bold">
                {{ t('cvc') }}
              </div>
              <StripeElement
                ref="cardCvc"
                type="cardCvc"
                :elements="elements"
                class="border-[#ccc] h-10 w-3/5 rounded border border-solid bg-white px-3 py-2 shadow-md"
              />
            </div>
          </StripeElements>
        </template>
        <div
          v-else
          class="mb-4 flex align-middle text-sm"
        >
          <img
            v-if="cardBrand === 'amex'"
            class="mr-1 w-8"
            :src="Amex"
          >
          <img
            v-if="cardBrand === 'diners'"
            class="mr-1 w-8"
            :src="Diners"
          >
          <img
            v-if="cardBrand === 'discover'"
            class="mr-1 w-8"
            :src="Discover"
          >
          <img
            v-if="cardBrand === 'jcb'"
            class="mr-1 w-8"
            :src="JCB"
          >
          <img
            v-if="cardBrand === 'mastercard'"
            class="mr-1 w-8"
            :src="MasterCard"
          >
          <img
            v-if="cardBrand === 'visa'"
            class="mr-1 w-8"
            :src="Visa"
          >
          {{ `${cardBrand} ${t('last4Digits')} ${last4Digits}` }}
          <button
            class="text-blue ml-2 underline"
            @click="isUseRegisteredCard = false"
          >
            {{ t('payWithAnotherCard') }}
          </button>
        </div>
        <PrimaryButton
          class="w-full px-4 py-2"
          :title="t('submit')"
          :disabled="false"
          @click="pay"
        />
      </div>
      <!-- 銀行振込 -->
      <div
        class="my-6"
        :class="{ hidden: !isBankSelect }"
      >
        <div class="mb-4 flex items-center">
          <div class="bg-grayAlpha5 p-2">
            <ul>
              <li>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="t('bank_description.line1')" />
              </li>
              <li>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="t('bank_description.line2')" />
              </li>
              <li>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="t('bank_description.line3')" />
              </li>
            </ul>
          </div>
        </div>
        <PrimaryButton
          class="w-full px-4 py-2"
          :title="t('submitBank')"
          :disabled="false"
          @click="payBank"
        />
      </div>
      <div class="whitespace-pre-line text-xs text-gray-500">
        {{ t('description') }}
      </div>
    </div>
    <TheOverlayLoading
      v-if="processing"
      :message="t('processing')"
    />
  </Modal>
</template>
<script setup lang="ts">
import { ref, onMounted, inject, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { StripeElements, StripeElement } from 'vue-stripe-js';
import { UserDataStore } from '@/composables/useUserStore';
import { useToast } from 'vue-toastification';
import { APIClientKey, UserDataKey } from '@/composables/injectionKeys';
import { APIClientInterface } from '@/api/APIClientInterface';
import { usePaymentStore } from '@/composables/usePaymentStore';
import { useI18n } from 'vue-i18n';
import { ja } from '@/i18n/common/StripePaymentModal/ja';
import { en } from '@/i18n/common/StripePaymentModal/en';
import { zh } from '@/i18n/common/StripePaymentModal/zh';
import Amex from '@/assets/images/icons/card_brands/amex.gif';
import Diners from '@/assets/images/icons/card_brands/diners.gif';
import Discover from '@/assets/images/icons/card_brands/discover.gif';
import JCB from '@/assets/images/icons/card_brands/jcb.gif';
import MasterCard from '@/assets/images/icons/card_brands/mastercard.gif';
import Visa from '@/assets/images/icons/card_brands/visa.gif';
import axios from 'axios';
import { useApplicationStore } from '@/composables/useApplicationStore';

const { t } = useI18n({ messages: ja, en, zh });

interface Props {
  stripeAccountId: string;
  showsPaymentModal: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(['onClose']);

const route = useRoute();
const applicationId = Number(route.params.id);
const applicationStore = useApplicationStore();
const paymentStore = usePaymentStore();
const isUseRegisteredCard = ref(false);
const cardBrand = ref('visa');
const last4Digits = ref('');
const isBankSelect = ref(false);
const paymentMethodId = ref('');
const stripeKey = ref(import.meta.env.VITE_STRIPE_KEY);
const stripeLoaded = ref(false);
const card = ref();
const cardExpiry = ref();
const cardCvc = ref();
const elms = ref();
const processing = ref(false);
const toast = useToast();
const apiClient = inject(APIClientKey) as APIClientInterface;

const { state: userState } = inject(UserDataKey) as UserDataStore;

onBeforeMount(async () => {
  stripeLoaded.value = true;
});

onMounted(async () => {
  const {
    paymentMethodId: paymentMethodIdResult,
    cardBrand: cardBrandResult,
    last4Digits: last4DigitsResult,
  } = await apiClient.fetchUserCreditCard();
  if (paymentMethodIdResult) {
    isUseRegisteredCard.value = true;
    paymentMethodId.value = paymentMethodIdResult;
    cardBrand.value = cardBrandResult;
    last4Digits.value = last4DigitsResult;
  }
});

// カード払いを選択
function selectCard () {
  isBankSelect.value = false;
}

// 銀行振込を選択
function selectBank () {
  isBankSelect.value = true;
}

// 支払
async function pay () {
  processing.value = true;

  try {
    if (!isUseRegisteredCard.value) {
      const cardElement = card.value.stripeElement;
      const result = await elms.value.instance.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email: userState.email,
          name: userState.isPersonal
            ? `${userState.lastName} ${userState.firstName}`
            : userState.companyName,
          phone: userState.phoneNumber,
          address: {
            postal_code: userState.address.zipcode,
            country: userState.address.country,
            state: userState.address.prefecture,
            city: userState.address.city,
            line1: userState.address.street,
          },
        },
      });

      if ('error' in result) {
        toast.error(result.error.message);
        return;
      }

      paymentMethodId.value = result.paymentMethod.id;
    }

    const payResult = await axios.post('/app/api/xmas/payments/pay', {
      payment_method_id: paymentMethodId.value,
      payment_id: paymentStore.currentId,
      payment_page: 'mypage',
    });

    // 3Dセキュアの場合
    if (payResult.data.requires_action) {
      const handleStripeJsResult = await elms.value.instance.handleCardAction(
        payResult.data.payment_intent_client_secret,
      );
      if (handleStripeJsResult.error) {
        toast.error(handleStripeJsResult.error.message, { timeout: 20000 });
        return;
      } else {
        await axios.post('/app/api/xmas/payments/pay', {
          payment_intent_id: handleStripeJsResult.paymentIntent.id,
          payment_id: paymentStore.currentId,
          payment_page: 'mypage',
        });
      }
    }

    await paymentStore.fetch(applicationId);
    await applicationStore.getAppApplication(applicationId);
    emit('onClose');

    toast.success(t('complete'), { timeout: 20000 });
  } catch (e: unknown) {
    card.value.stripeElement.clear();
    cardExpiry.value.stripeElement.clear();
    cardCvc.value.stripeElement.clear();
    if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
      toast.error(e.response.data.error, { timeout: 20000 });
      return;
    }

    toast.error(t('systemError'), { timeout: 20000 });
  } finally {
    processing.value = false;
  }
}

// 銀行振込
async function payBank () {
  if (!props.stripeAccountId) {
    return toast.error(t('errors.notFoundStripeAccount'));
  }

  try {
    processing.value = true;

    await axios.post('/app/api/xmas/payments/pay_bank', {
      payment_id: paymentStore.currentId,
    });

    await paymentStore.fetch(applicationId);
    await applicationStore.getAppApplication(applicationId);
    emit('onClose');

    toast.success(t('completeBank'), { timeout: 20000 });
  } finally {
    processing.value = false;
  }
}

function handleClose () {
  emit('onClose');
}
</script>
