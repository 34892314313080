<template>
  <input
    class="text-textBlack h-[50px] w-full text-left"
    :type="type || 'text'"
    :name="name || ''"
    :placeholder="placeholder || ''"
    :autocomplete="autocomplete || ''"
    :disabled="disabled || false"
    :value="modelValue"
    @input="onInput"
    @blur="onBlur"
  >
</template>

<script setup lang="ts">
defineProps<{
  modelValue: string;
  type?: string;
  name?: string;
  placeholder?: string;
  autocomplete?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:model-value', value: string): void;
  (e: 'blur'): void;
}>();

function onInput (e: Event) {
  emit('update:model-value', (e.target as HTMLInputElement).value);
}

function onBlur () {
  emit('blur');
}
</script>
