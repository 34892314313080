<script setup lang="ts">
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import PrimaryButton from '@/components/common/PrimaryButton.vue';

const { t } = useI18n({
  messages: {
    ja: {
      signup: '会員登録',
      about: 'サービスについて',
      search: '商標を検索',
      contact: 'お問合せ',
      login: 'ログイン',
      mypage: 'マイページ',
      editUser: '設定',
      primer: '商標入門ガイド',
      flow: 'Cotoboxとは',
      plan: '料金',
      testmonials: '利用事例',
      faq: 'よくあるご質問',
      contactLong: 'お問い合わせ',
    },
    en: {
      signup: 'Sign Up',
      about: 'What\'s Cotobox',
      search: 'Search trademark',
      contact: 'Contact',
      login: 'Sign in',
      mypage: 'Mypage',
      editUser: 'Setting',
      primer: 'Trademark Primer',
      flow: 'What\'s Cotobox',
      plan: 'Pricing',
      testmonials: 'Case Studies',
      faq: 'FAQ',
      contactLong: 'Contact',
    },
    zh: {
      signup: '注册',
      about: '什么是Cotobox',
      search: '搜索商标',
      contact: '联系我们',
      login: '登录',
      mypage: '铭牌',
      editUser: '设置',
      primer: '商标入门',
      flow: '什么是Cotobox',
      plan: '定价',
      testmonials: '案例研究',
      faq: '常见问题',
      contactLong: '联系我们',
    },
  },
});

const showsPullDownMenu = ref(false);

const menuItems = [
  { title: t('primer'), href: '/primer/' },
  { title: t('flow'), href: '/flow' },
  { title: t('plan'), href: '/plan' },
  { title: t('testmonials'), href: '/testmonials' },
  { title: t('faq'), href: 'https://support.cotobox.com/' },
  { title: t('contactLong'), href: '/app/contact' },
];

const { authenticated } = inject(UserDataKey) as UserDataStore;
</script>

<template>
  <header style="background: linear-gradient(45deg, #222, #444)">
    <div class="grid h-full gap-x-3 gap-y-3 px-4 pt-3">
      <div
        class="col-start-1 col-end-2 row-start-1 row-end-2 flex items-center text-white"
      >
        <a
          class="block h-[23px] w-[140px]"
          href="/"
        >
          <img src="@/assets/images/icons/logo.svg">
        </a>
      </div>
      <div
        class="col-start-2 col-end-3 row-start-1 row-end-2 flex items-center justify-end"
      >
        <PrimaryButton
          v-if="!authenticated"
          :title="t('signup')"
          :disabled="false"
          class="text-sm"
          @click="$router.push('/app/users/sign_up/')"
        />
      </div>
      <div
        class="col-start-1 col-end-3 row-start-2 row-end-3 flex justify-between"
      >
        <button
          class="block whitespace-nowrap border-b-[3px] border-solid border-[transparent] pb-2 pt-1 text-xs text-white"
          @click="showsPullDownMenu = true"
        >
          {{ t('about') }}
        </button>
        <router-link
          class="block whitespace-nowrap pb-2 pt-1 text-xs text-white"
          :class="{
            'border-primary border-b-[3px] border-solid':
              $route.name === 'SearchPage' || $route.name === 'ImageSearchPage',
          }"
          to="/app/search/"
        >
          {{ t('search') }}
        </router-link>
        <a
          class="block whitespace-nowrap border-b-[3px] border-solid border-[transparent] pb-2 pt-1 text-xs text-white"
          href="tel:05088809640"
        >
          {{ t('contact') }}
        </a>
        <a
          v-if="!authenticated"
          class="block whitespace-nowrap border-b-[3px] border-solid border-[transparent] pb-2 pt-1 text-xs text-white"
          href="/app/login"
        >
          {{ t('login') }}
        </a>
        <router-link
          v-if="authenticated"
          to="/app/mypage"
          class="block whitespace-nowrap border-b-[3px] border-solid border-[transparent] pb-2 pt-1 text-xs text-white"
        >
          {{ t('mypage') }}
        </router-link>
        <a
          v-if="authenticated"
          class="block whitespace-nowrap border-b-[3px] border-solid border-[transparent] pb-2 pt-1 text-xs text-white"
          href="/app/users/edit"
        >
          {{ t('editUser') }}
        </a>
      </div>
    </div>
    <div
      v-if="showsPullDownMenu"
      class="bg-bgGray absolute z-10 w-64 pb-4"
      style="box-shadow: 0 1px 5px rgb(0 0 0 / 20%)"
    >
      <button
        class="cursor-pointer px-5 py-3 text-black"
        @click="showsPullDownMenu = false"
      >
        <i class="material-icons material-symbols-outlined">close</i>
      </button>
      <div
        v-for="(item, index) in menuItems"
        :key="index"
        class="border border-solid border-[#fff] px-5 py-3"
      >
        <a
          class="flex h-full w-full items-center justify-between"
          :href="item.href"
        >
          <span class="text-xs text-black">{{ item.title }}</span>
          <i
            class="material-icons material-symbols-outlined text-primary"
            style="font-size: 18px"
          >navigate_next</i>
        </a>
      </div>
    </div>
  </header>
</template>
