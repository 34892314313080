import { KlassId } from '@/types';

export type Klass = {
  name: {
    ja: string;
    en: string;
  };
  value: KlassId;
  description: string;
};

export const klasses: Klass[] = [
  {
    name: {
      ja: '化学品',
      en: 'Chemicals',
    },
    value: '1',
    description: '工業用の化学品、 科学用の化学品、農業用の化学品など',
  },
  {
    name: {
      ja: '塗料・着色料',
      en: 'Paints',
    },
    value: '2',
    description: '印刷用や美術用のペイント（塗料）など',
  },
  {
    name: {
      ja: '洗浄剤、化粧品',
      en: 'Cleaning Substances',
    },
    value: '3',
    description: '洗濯に使用する洗浄剤や、化粧品など',
  },
  {
    name: {
      ja: 'オイル・燃料',
      en: 'Industrial Oils',
    },
    value: '4',
    description: '工業用の油、燃料、固形の潤滑剤など',
  },
  {
    name: {
      ja: '薬剤',
      en: 'Pharmaceuticals',
    },
    value: '5',
    description: '医薬品の大部分、獣医科用剤、栄養補助食品など',
  },
  {
    name: {
      ja: '卑金属、卑金属製品',
      en: 'Base Metals',
    },
    value: '6',
    description:
      '卑金属（＝容易に酸化しやすい金属）の製品、未加工の金属、半加工の金属など',
  },
  {
    name: {
      ja: '工作機械、原動機',
      en: 'Machines',
    },
    value: '7',
    description: '機械、工作機械、原動機など',
  },
  {
    name: {
      ja: '手動工具',
      en: 'Hand Tools',
    },
    value: '8',
    description: '手で使用する工具など',
  },
  {
    name: {
      ja: '電気制御用の機械器具',
      en: 'Computers and Scientific Devices',
    },
    value: '9',
    description:
      '情報処理のための機械器具、磁気媒体に記録されたソフトウェア、ネットワークからダウンロードされたソフト、測量・救命・音響・写真用の機械器具など',
  },
  {
    name: {
      ja: '医療器具',
      en: 'Medical Supplies',
    },
    value: '10',
    description: '医療用機器、医療用品、ゴム製の衛生用品など',
  },
  {
    name: {
      ja: '環境装置(照明・加熱・給水)',
      en: 'Appliances',
    },
    value: '11',
    description: '照明器具、加熱器具、調理用器具、蒸気発生器具など',
  },
  {
    name: {
      ja: '乗物',
      en: 'Vehicles',
    },
    value: '12',
    description: '陸上の乗物、海上の乗物、空中の乗物やその部品など',
  },
  {
    name: {
      ja: '火器',
      en: 'Firearms',
    },
    value: '13',
    description: '銃火器、火薬類、花火など',
  },
  {
    name: {
      ja: '貴金属、宝飾品、時計',
      en: 'Precious Metals',
    },
    value: '14',
    description: '貴金属、合金、貴金属を被覆した製品、時計など',
  },
  {
    name: {
      ja: '楽器',
      en: 'Musical Instruments',
    },
    value: '15',
    description: '楽器、電子楽器など',
  },
  {
    name: {
      ja: '紙、事務用品',
      en: 'Paper Goods',
    },
    value: '16',
    description:
      '紙が原料の印刷物、紙が原料の製品、プラスチックが原料の包装品、文房具など',
  },
  {
    name: {
      ja: 'プラスチック材料',
      en: 'Rubber Products',
    },
    value: '17',
    description: 'プラスチックおよび樹脂、絶縁用のゴム製品、粘着テープなど',
  },
  {
    name: {
      ja: '革製品・旅行品',
      en: 'Leather Goods',
    },
    value: '18',
    description: '革製品、合成革製品、旅行用品、動物の用具など',
  },
  {
    name: {
      ja: '金属以外の建築材料',
      en: 'Building Materials',
    },
    value: '19',
    description:
      'セメント、木材、石材、墓石、標識、窓ガラス、小屋の組み立てセットなど、金属以外の材料など',
  },
  {
    name: {
      ja: '家具、プラスチック製品',
      en: 'Furniture',
    },
    value: '20',
    description: 'プラスチック製品、家具、寝具など',
  },
  {
    name: {
      ja: '台所用品、ガラス・陶器製品',
      en: 'Household Utensils',
    },
    value: '21',
    description: '家庭用品、台所用品、化粧用具など',
  },
  {
    name: {
      ja: '繊維、ひも、網',
      en: 'Ropes and Textile Products',
    },
    value: '22',
    description: 'ロープ製品、帆布製品、 詰物用材料など',
  },
  {
    name: {
      ja: '糸',
      en: 'Yarns and Threads',
    },
    value: '23',
    description: '織物や編物に用いる糸など',
  },
  {
    name: {
      ja: '織物、カバー',
      en: 'Textiles',
    },
    value: '24',
    description: '織物（反物）、織物のカバー製品など',
  },
  {
    name: {
      ja: '服、履物',
      en: 'Clothing',
    },
    value: '25',
    description:
      '洋服、コート、セーター、下着、和服、靴下、帽子など、人が着用するものなど',
  },
  {
    name: {
      ja: '裁縫用品',
      en: 'Lace and Embroidery',
    },
    value: '26',
    description: '貴金属以外の装飾品（ファッション）や裁縫に使用するものなど',
  },
  {
    name: {
      ja: '床敷物、壁掛け',
      en: 'Carpets',
    },
    value: '27',
    description: 'じゅうたん、マット、壁掛けなど',
  },
  {
    name: {
      ja: 'おもちゃ、遊戯・運動用具',
      en: 'Games and Sporting Goods',
    },
    value: '28',
    description: 'ゲーム用品、遊戯器具、運動用具、おもちゃなど',
  },
  {
    name: {
      ja: '動物性・農産物性食品',
      en: 'Meat, Fish, Poultry',
    },
    value: '29',
    description: '加工済の食品、乳製品、植物油脂など',
  },
  {
    name: {
      ja: 'コーヒー、調味料、菓子',
      en: 'Coffee, Flour, Rice',
    },
    value: '30',
    description: 'コーヒー、パン、米、おかし、調味料、テイクアウトの食品など',
  },
  {
    name: {
      ja: '穀物、魚、果実、種',
      en: 'Grains, Agriculture',
    },
    value: '31',
    description:
      '未加工の生鮮野菜や果実、種子や自然の植物や花、生きている動物など',
  },
  {
    name: {
      ja: '飲料、ビール',
      en: 'Beers and Beverages',
    },
    value: '32',
    description: '清涼飲料、ミネラルウォーター、ビールなど',
  },
  {
    name: {
      ja: '酒類',
      en: 'Alcoholic Beverages',
    },
    value: '33',
    description: 'ビール以外のアルコール飲料',
  },
  {
    name: {
      ja: 'たばこ、喫煙用具',
      en: 'Tobacco Products',
    },
    value: '34',
    description: 'たばこ、喫煙に使用する器具など',
  },
  {
    name: {
      ja: '広告、事業、卸売',
      en: 'Advertising and Business Services',
    },
    value: '35',
    description: '広告収入を得る仕事、小売業（仕入れ販売）を行う仕事など',
  },
  {
    name: {
      ja: '金融、保険、不動産',
      en: 'Insurance and Finance Services',
    },
    value: '36',
    description:
      '金融サービス、財政サービス、保険サービス、不動産取引のサービスなど',
  },
  {
    name: {
      ja: '建設、工事、修理',
      en: 'Construction and Repair Services',
    },
    value: '37',
    description:
      '工事サービス、修理サービスなど、人が動いて物理的に技術で解決するものなど',
  },
  {
    name: {
      ja: '電気通信',
      en: 'Telecommunications Services',
    },
    value: '38',
    description:
      '人に対しなにかを伝達・通信するサービスなど通信のプラットフォームを提供しているものなど',
  },
  {
    name: {
      ja: '輸送、保管、旅行の手配',
      en: 'Shipping and Travel Services',
    },
    value: '39',
    description:
      '輸送サービス、輸出入の荷物梱包サービス、旅行の手配サービスなど',
  },
  {
    name: {
      ja: '物品の加工、処理',
      en: 'Material Treatment Services',
    },
    value: '40',
    description:
      '印刷サービス、製本、木材／金属の加工、裁縫、レンタルサービスなど、受託生産サービスなど',
  },
  {
    name: {
      ja: '教育、娯楽、スポーツ、文化',
      en: 'Education and Entertainment Services',
    },
    value: '41',
    description: '教育サービス、芸術／創作／映像サービス、スポーツサービスなど',
  },
  {
    name: {
      ja: '調査、分析、設計、開発',
      en: 'Science and Technology Services',
    },
    value: '42',
    description:
      'エンジニア、プログラマー、デザイナー、化学者、物理学者などによる専門的なサービス',
  },
  {
    name: {
      ja: '飲食、宿泊',
      en: 'Food Services',
    },
    value: '43',
    description:
      '飲食店の経営者、ホテルや旅館の運営、保育所の運営、老人ホームの運営、貸会議室などのサービス',
  },
  {
    name: {
      ja: '医療、美容、農林',
      en: 'Medical and Vet Services',
    },
    value: '44',
    description:
      '人や動物への医療ケア・美容ケア・衛生ケア、医療サービス、園芸・農業・林業のサービス',
  },
  {
    name: {
      ja: '冠婚葬祭、警備、法律事務',
      en: 'Legal and Security Services',
    },
    value: '45',
    description:
      '人的および社会的サービス、人との出会いに関するサービス、士業、冠婚葬祭に関するサービス、警備、など',
  },
];
