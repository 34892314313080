<template>
  <div>
    <div
      class="flex justify-between border-2 border-solid border-black px-4 py-2 text-lg font-bold"
    >
      <div>{{ t('amountTitle') }}</div>
      <div>{{ t('amountValue', [amount.toLocaleString()]) }}</div>
    </div>
    <!-- カード・銀行選択 -->
    <div class="my-6 flex justify-around">
      <div
        class="mr-4 h-10 w-3/5 cursor-pointer rounded border border-solid bg-white px-3 py-2 shadow-md"
        :class="{ 'text-grayAlpha60': isBankSelect }"
        @click="selectCard"
      >
        <div class="flex">
          <span class="material-icons">credit_card</span><span>&nbsp;{{ t('card') }}</span>
        </div>
      </div>
      <div
        class="h-10 w-3/5 cursor-pointer rounded border border-solid bg-white px-3 py-2 shadow-md"
        :class="{ 'text-grayAlpha60': !isBankSelect }"
        @click="selectBank"
      >
        <div class="flex">
          <span class="material-icons">account_balance</span><span>&nbsp;{{ t('bankTransfer') }}</span>
        </div>
      </div>
    </div>
    <!-- 銀行振込 -->
    <div
      class="my-6"
      :class="{ hidden: !isBankSelect }"
    >
      <div class="mb-4">
        <div class="bg-grayAlpha5 p-2">
          <ul class="text-sm">
            <li>
              <span v-text="t('bank_description.line1')" />
            </li>
            <li>
              <span
                class="font-weight-bold"
                v-text="t('bank_description.line2')"
              />
            </li>
            <li>
              <span v-text="t('bank_description.line3')" />
            </li>
            <li>
              <span
                class="text-red100 font-weight-bold"
                v-text="t('bank_description.line4_1')"
              />
              <span v-text="t('bank_description.line4_2')" />
            </li>
          </ul>
        </div>
      </div>
      <PrimaryButton
        class="w-full px-4 py-2"
        :title="t('submitBank')"
        :disabled="false"
        @click="payBank"
      />
    </div>
    <!-- カード -->
    <div
      class="my-6"
      :class="{ hidden: isBankSelect }"
    >
      <template v-if="!alreadyCardRegistered">
        <div class="mb-4 flex items-center">
          <div class="w-2/5 text-sm font-bold">
            {{ t('cardNumber') }}
          </div>
          <div
            id="PaymentInputCardNumber"
            class="h-10 w-3/5 rounded border border-solid bg-white px-3 py-2 shadow-md"
          />
        </div>
        <div class="mb-4 flex items-center">
          <div class="w-2/5 text-sm font-bold">
            {{ t('expires') }}
          </div>
          <div
            id="PaymentInputCardExpiry"
            class="h-10 w-3/5 rounded border border-solid bg-white px-3 py-2 shadow-md"
          />
        </div>
        <div class="mb-4 flex items-center">
          <div class="w-2/5 text-sm font-bold">
            {{ t('cvc') }}
          </div>
          <div
            id="PaymentInputCardCvc"
            class="h-10 w-3/5 rounded border border-solid bg-white px-3 py-2 shadow-md"
          />
        </div>
      </template>
      <div
        v-else
        class="mb-4 flex align-middle text-sm"
      >
        <img
          v-if="cardBrand === 'amex'"
          class="mr-1 w-8"
          :src="Amex"
        >
        <img
          v-if="cardBrand === 'diners'"
          class="mr-1 w-8"
          :src="Diners"
        >
        <img
          v-if="cardBrand === 'discover'"
          class="mr-1 w-8"
          :src="Discover"
        >
        <img
          v-if="cardBrand === 'jcb'"
          class="mr-1 w-8"
          :src="JCB"
        >
        <img
          v-if="cardBrand === 'mastercard'"
          class="mr-1 w-8"
          :src="MasterCard"
        >
        <img
          v-if="cardBrand === 'visa'"
          class="mr-1 w-8"
          :src="Visa"
        >
        {{ `${cardBrand} ${t('last4Digits')} ${last4Digits}` }}
        <button
          class="text-blue ml-2 underline"
          @click="payWithOtherCard"
        >
          {{ t('payWithAnotherCard') }}
        </button>
      </div>
      <PrimaryButton
        class="w-full px-4 py-2"
        :title="t('submit')"
        :disabled="false"
        @click="pay"
      />
    </div>
    <div class="whitespace-pre-line text-xs text-gray-500">
      {{ t('description') }}
    </div>
    <TheOverlayLoading
      v-if="processing"
      :message="t('processing')"
    />
  </div>
</template>

<script setup lang="ts">
import { StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { useStripe } from 'vue-use-stripe';
import { useI18n } from 'vue-i18n';
import { ref, onMounted, nextTick, inject } from 'vue';
import Amex from '@/assets/images/icons/card_brands/amex.gif';
import Diners from '@/assets/images/icons/card_brands/diners.gif';
import Discover from '@/assets/images/icons/card_brands/discover.gif';
import JCB from '@/assets/images/icons/card_brands/jcb.gif';
import MasterCard from '@/assets/images/icons/card_brands/mastercard.gif';
import Visa from '@/assets/images/icons/card_brands/visa.gif';
import {
  APIClientKey,
  ApplyFormKey,
  UserDataKey,
} from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import { ApplyFormStore } from '@/composables/useApplyForm';
import { useToast } from 'vue-toastification';
import { HTTPError, UnauthorizedError, UnknownError, StripePaymentError } from '@/types/error';
import TheOverlayLoading from './TheOverlayLoading.vue';
import { APIClientInterface } from '@/api/APIClientInterface';

interface Props {
  amount: number;
  stripeAccountId: string;
}

interface Emits {
  (e: 'complete', isBankSelect: boolean, paymentId: number): void;
  (e: 'close'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useI18n({
  messages: {
    ja: {
      amountTitle: 'お支払い額',
      amountValue: '{0}円',
      cardNumber: 'カード番号',
      expires: '有効期限',
      cvc: 'セキュリティコード',
      card: 'クレジットカード',
      bankTransfer: '銀行振込',
      submit: '決済する',
      submitBank: '請求書払いで契約する',
      description:
        '・クレジットカードでのお支払いはStripe社のシステムを利用しております。\n・ご登録いただいたクレジットカード情報はStripe社にて管理され当社では情報を保持しないため安心してご利用いただけます。\n・費用は出願を代理する提携弁理士事務所が受領します。',
      last4Digits: '下4桁',
      payWithAnotherCard: '別のカードで支払う',
      bank_description: {
        line1: 'お振込は、請求書の発行から7日以内にお願いいたします。',
        line2: '振込手数料は、お客様のご負担でお願いいたします。',
        line3: '振込先の口座番号は、お申し込み後に送信するメール本文、または、請求書をご確認ください。',
        line4_1: '外国銀行口座からのお振込はできかねます。',
        line4_2: '海外からのお支払いは、クレジットカードのご利用をお願いいたします。',
      },
      errors: {
        notFoundStripeAccount:
          'Stripeアカウント取得失敗: 恐れ入りますが画面をリロードして再度ご入力ください。{0}',
        pleaseReflesh:
          '恐れ入りますが画面をリロードして再度ご入力ください。{0}',
        pleaseRefleshAndTurnOffAd:
          '恐れ入りますが画面をリロードして再度ご入力ください。\n広告ブロックのプラグインはOFFにしてください。{0}',
        apiConnectionError:
          '決済システムと接続できませんでした。ネットワークに接続されていることをご確認ください。{0}',
        apiError:
          '決済システムで一時的に障害が発生しています。恐れ入りますが、時間を置いて再度決済をお願いします。{0}',
        cardError:
          '決済に失敗しました。カードが有効であることをご確認ください。{0}',
        rateLimitError:
          '一度に複数の決済がリクエストされたためエラーになりました。時間を置いて再度決済をお願いします。{0}',
        validationError: '入力したカード情報が正しいことをご確認ください。{0}',
        other:
          '決済でエラーが発生しました。恐れ入りますがチャットにてお問い合わせください。{0}',
      },
      processing: '決済処理中です。このページを離れないでください。',
    },
    en: {
      amountTitle: 'Payment amount',
      amountValue: '¥{0}',
      cardNumber: 'CARD NUMBER',
      expires: 'EXPERATION DATE',
      cvc: 'CVC',
      card: 'Credit Cards',
      bankTransfer: 'Bank transfer',
      submit: 'PAY NOW',
      submitBank: 'Bank transfer(invoice)',
      description:
        '・クレジットカードでのお支払いはStripe社のシステムを利用しております。\n・ご登録いただいたクレジットカード情報はStripe社にて管理され当社では情報を保持しないため安心してご利用いただけます。',
      last4Digits: 'last 4 digit',
      payWithAnotherCard: 'Pay with other card',
      bank_description: {
        line1: 'Please make the payment within 7 days after the invoice is issued.',
        line2: 'The transfer fee shall be borne by the customer.',
        line3: 'Please check the email sent after application or your invoice for the account number for the transfer.',
        line4_1: 'Transfers from foreign bank accounts are not possible.',
        line4_2: 'For payments from overseas, please use a credit card.',
      },
      errors: {
        notFoundStripeAccount:
          'Failed to get Stripe account: Please reload and try again. {0}',
        pleaseReflesh:
          'Please reload the screen and re-enter the information. {0}',
        pleaseRefleshAndTurnOffAd:
          'Please reload the screen and re-enter the information.\n' +
          'Please turn off ad-blocking plug-ins. {0}',
        apiConnectionError:
          'Could not connect to the payment system. Please make sure you are connected to the network. {0}',
        apiError:
          'There is a temporary failure in the payment system. We apologize for the inconvenience, but please wait some time and make payment again. {0}',
        cardError: 'Payment failed. Please check that your card is valid. {0}',
        rateLimitError:
          'An error occurred because multiple payments were requested at one time. Please wait a few minutes and try again. {0}',
        validationError:
          'Please verify that the card information you entered is correct. {0}',
        other:
          'An error occurred in the payment. Please contact us via chat. {0}',
      },
      processing: 'Payment is being processed. Please do not leave this page.',
    },
    zh: {
      amountTitle: '支付金额',
      amountValue: '{0}日元',
      cardNumber: 'CARD NUMBER',
      expires: 'EXPERATION DATE',
      cvc: 'CVC',
      card: '信用卡',
      bankTransfer: '银行转账',
      submit: 'PAY NOW',
      submitBank: '银行转账（发票付款）',
      description:
        '我们使用Stripe系统进行信用卡支付。\n您提供的信用卡信息由Stripe管理，我们不保留。',
      last4Digits: 'last 4 digit',
      payWithAnotherCard: '用另一张卡支付',
      bank_description: {
        line1: '请在发票开具后7天内付款。',
        line2: '转账费用由客户承担。',
        line3: '请检查申请后发送的电子邮件或您的发票以获取转账帐号。',
        line4_1: '无法从外国银行账户转账。',
        line4_2: '对于海外付款，请使用信用卡。',
      },
      errors: {
        notFoundStripeAccount:
          '获取Stripe账户失败：请重新加载屏幕并再次尝试。{0}',
        pleaseReflesh: '请重新加载屏幕并重新输入信息。{0}',
        pleaseRefleshAndTurnOffAd:
          '请重新加载屏幕并重新输入信息。\n请关闭广告拦截插件。{0}',
        apiConnectionError: '无法连接到支付系统。请检查您是否已连接到网络。{0}',
        apiError:
          '支付系统有一个临时故障。我们很抱歉，但请等待一些时间，并重新进行付款。{0}',
        cardError: '支付失败。请检查该卡是否有效。{0}',
        rateLimitError:
          '发生错误是因为同时申请了几笔付款。请等待一段时间并重新付款。{0}',
        validationError: '请检查输入的银行卡信息是否正确。{0}',
        other: '付款发生了错误。请通过聊天与我们联系。{0}',
      },
      processing: '您的付款正在处理中。 请不要离开这个页面。',
    },
  },
});

const { stripe, stripeElements } = useStripe({
  key: import.meta.env.VITE_STRIPE_KEY,
  constructorOptions: {
    stripeAccount: props.stripeAccountId,
  },
});

const { state: userState } = inject(UserDataKey) as UserDataStore;

const { submit } = inject(ApplyFormKey) as ApplyFormStore;

const toast = useToast();
const apiClient = inject(APIClientKey) as APIClientInterface;

let cardNumberElement = null as StripeCardNumberElement | null;
const alreadyCardRegistered = ref(false);
const cardBrand = ref('visa');
const paymentMethodId = ref('');
const last4Digits = ref('');
const processing = ref(false);
const isBankSelect = ref(false);

onMounted(async () => {
  try {
    const {
      paymentMethodId: paymentMethodIdResult,
      cardBrand: cardBrandResult,
      last4Digits: last4DigitsResult,
    } = await apiClient.fetchUserCreditCard();
    if (paymentMethodIdResult) {
      alreadyCardRegistered.value = true;
      paymentMethodId.value = paymentMethodIdResult;
      cardBrand.value = cardBrandResult;
      last4Digits.value = last4DigitsResult;
    } else {
      mountCardForm();
    }
  } catch (error) {
    mountCardForm();
  }
});

function mountCardForm () {
  paymentMethodId.value = '';
  cardBrand.value = '';
  last4Digits.value = '';

  if (stripeElements.value) {
    cardNumberElement = stripeElements.value.create('cardNumber');
    cardNumberElement.mount('#PaymentInputCardNumber');
    stripeElements.value.create('cardExpiry').mount('#PaymentInputCardExpiry');
    stripeElements.value.create('cardCvc').mount('#PaymentInputCardCvc');
  } else {
    toast.error(t('errors.pleaseRefleshAndTurnOffAd'));
  }
}

function payWithOtherCard () {
  alreadyCardRegistered.value = false;
  nextTick(() => mountCardForm());
}

const billing_details = {
  email: userState.email,
  name: userState.isPersonal
    ? `${userState.lastName} ${userState.firstName}`
    : userState.companyName,
  phone: userState.phoneNumber,
  address: {
    postal_code: userState.address.zipcode,
    country: userState.address.country,
    state: userState.address.prefecture,
    city: userState.address.city,
    line1: userState.address.street,
  },
};

// カード払いを選択
function selectCard () {
  isBankSelect.value = false;
}

// 銀行振込を選択
function selectBank () {
  isBankSelect.value = true;
}

async function pay () {
  if (!props.stripeAccountId) {
    return toast.error(t('errors.notFoundStripeAccount'));
  }

  try {
    processing.value = true;
    if (!stripe.value) {
      return toast.error(t('errors.pleaseReflesh'));
    }

    if (!alreadyCardRegistered.value) {
      if (!cardNumberElement) {
        return toast.error(t('errors.pleaseRefleshAndTurnOffAd'));
      }

      const result = await stripe.value.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details,
      });

      if ('error' in result) {
        const error = result.error as StripeError;
        return showsStripeError(error);
      }

      if (typeof result.paymentMethod === 'undefined') {
        return toast.error(t('errors.pleaseReflesh'));
      }

      paymentMethodId.value = result.paymentMethod.id;
    }

    const paymentId = await submit(userState);
    const { requiresAction, paymentIntentClientSecret } = await apiClient.pay({
      paymentId,
      paymentMethodId: paymentMethodId.value,
      idempotencyKey: Date.now(),
    });
    if (requiresAction) {
      const { paymentIntent } = await stripe.value.handleCardAction(
        paymentIntentClientSecret,
      );
      await apiClient.pay({
        paymentId,
        paymentIntentId: paymentIntent?.id,
        idempotencyKey: Date.now(),
      });
    }

    emit('complete', isBankSelect.value, paymentId);
  } catch (e) {
    if (e instanceof StripePaymentError) {
      toast.error(e.message, { timeout: 15000 });
      return;
    }
    if (e instanceof UnauthorizedError) {
      location.href = '/app/login';
      return;
    } else if (e instanceof HTTPError) {
      return toast.error(`${e.status} ${e.message}`);
    } else if (e instanceof UnknownError) {
      return toast.error(e.message);
    }
  } finally {
    processing.value = false;
  }
}

async function payBank () {
  if (!props.stripeAccountId) {
    return toast.error(t('errors.notFoundStripeAccount'));
  }

  try {
    processing.value = true;

    const paymentId = await submit(userState);

    await apiClient.payBank({
      paymentId,
    });

    emit('complete', isBankSelect.value, paymentId);
  } catch (e) {
    if (e instanceof UnauthorizedError) {
      location.href = '/app/login';
      return;
    } else if (e instanceof HTTPError) {
      return toast.error(`${e.status} ${e.message}`);
    } else if (e instanceof UnknownError) {
      return toast.error(e.message);
    }
  } finally {
    processing.value = false;
  }
}

function showsStripeError (error: StripeError) {
  switch (error.type) {
  case 'api_connection_error':
    return toast.error(t('errors.apiConnectionError', [error.type]));
  case 'api_error':
    return toast.error(t('errors.apiError', [error.type]));
  case 'authentication_error':
    return toast.error(t('errors.other', [error.type]));
  case 'card_error':
    return toast.error(t('errors.cardError', [error.type]));
  case 'idempotency_error':
    return toast.error(t('errors.other', [error.type]));
  case 'invalid_request_error':
    return toast.error(t('errors.other', [error.type]));
  case 'rate_limit_error':
    return toast.error(t('errors.rateLimitError', [error.type]));
  case 'validation_error':
    return toast.error(
      error.message || t('errors.validationError', [error.type]),
    );
  default:
    return toast.error(t('errors.other'));
  }
}
</script>

<style lang="scss" scoped></style>
