<template>
  <div class="border-borderGray rounded-2xl border border-solid bg-white">
    <table class="border-collapse">
      <EnterpriseTableHeader
        :widths="widths"
        :columns="columns"
      />
      <tbody>
        <EnterpriseTableRow
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
          :widths="widths"
          :headheader="rowHeadHeaders[rowIndex]"
          :headheaderspan="rowHeadHeaderSpans[rowIndex]"
          :header="rowHeaders[rowIndex]"
          :columns="row"
          :bold-col-index="boldColIndex(rowIndex)"
          class="border-borderGray border-b border-solid data-[devider=true]:border-b-[3px] data-[last=true]:border-b-0 data-[devider=true]:border-[#414446]"
        />
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Maybe } from '@/lib/types';
import EnterpriseTableHeader from './EnterpriseTableHeader.vue';
import EnterpriseTableRow from './EnterpriseTableRow.vue';

const widths = [150, 323, 323, 323, 323];

const columns = computed<string[]>(() => [
  '',
  '',
  'スタータープラン',
  'スタンダードプラン',
  'プレミアムプラン',
]);

const rowHeaders = computed<string[]>(() => [
  '出願前調査',
  '商標出願',
  '早期審査申請',
  '海外出願（マドプロ出願）',
  '海外出願（直接出願、米国・中国）',
  '管理件数',
  '大量案件管理用ダッシュボード',
  '商標インポート（日本）',
  '商標インポート（海外）※ 別途作業費が発生',
  'モニタリング機能',
  '月次商標レポート',
  '特許庁へ情報提供及び異議申立、無効審判請求、不使用取消審判請求',
  '招待可能なユーザ数',
  'ユーザ権限管理（閲覧可能な案件の制御）',
  '監査ログ',
  '専任サポート',
]);

const rowHeadHeaders = computed<string[]>(() => [
  '調査',
  '',
  '',
  '',
  '',
  '商標管理',
  '',
  '',
  '',
  '商標モニタリング',
  '',
  '',
  'その他',
  '',
  '',
  '',
]);

const rowHeadHeaderSpans = [
  5,
  null,
  null,
  null,
  null,
  4,
  null,
  null,
  null,
  3,
  null,
  null,
  4,
  null,
  null,
  null,
];

const rows = computed<Maybe<string | string[]>[][]>(() => [
  ['定価', '一定回数まで無料', '一定回数まで無料'],
  ['定価', '定価', '割引あり'],
  ['定価', '割引あり', '割引あり'],
  ['●', '●', '●'],
  ['-', '●', '●'],
  ['～100件', '無制限', '無制限'],
  ['●', '●', '●'],
  ['-', '●', '●'],
  ['-', '●', '●'],
  ['●', '●', '●'],
  ['-', '●', '●弁理士コメント付き'],
  ['定価', '割引あり', '割引あり'],
  ['無制限', '無制限', '無制限'],
  ['●', '●', '●'],
  ['-', '-', '●'],
  ['-', '●', '●定例会議の開催'],
]);

const boldTextCellss = [
  [[0], [1, 2]],
  [[1], [2]],
  [[2], [1, 2]],
  [[3], [0, 1, 2]],
  [[4], [1, 2]],
  [[5], [1, 2]],
  [[6], [0, 1, 2]],
  [[7], [1, 2]],
  [[8], [1, 2]],
  [[9], [0, 1, 2]],
  [[10], [1, 2]],
  [[11], [1, 2]],
  [[12], [0, 1, 2]],
  [[13], [0, 1, 2]],
  [[14], [2]],
  [[15], [1, 2]],
];

function boldColIndex (rowIndex: number): number[] {
  const cell = boldTextCellss.find((e) => e[0][0] === rowIndex);
  if (cell === undefined) {
    return [];
  }
  return cell[1];
}
</script>
