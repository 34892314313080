<template>
  <div :class="`${$options.name}`">
    <div :class="`${$options.name}__header`">
      {{ title }}
    </div>
    <div :class="`${$options.name}__content`">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContentBox',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.ContentBox {
  &__header {
    background-color: #f2f2f2;
    font-weight: 600;
    border: 1px solid #e7e7e7;
    border-bottom: 0;
    padding: 12px 24px;
  }

  &__content {
    background-color: #fff;
    border: 1px solid #e7e7e7;
  }
}
</style>
