<template>
  <div class="bg-white py-10 sm:py-16 sm:pb-[60px]">
    <SectionHeader
      class="mb-10 px-4 sm:px-0 sm:pb-10"
      text="まずは無料アカウント登録"
    />
    <div class="px-4 sm:mx-auto sm:w-[544px] sm:px-0">
      <div class="text-grayAlpha60 mb-2 text-sm">
        アカウント登録を行うと、下記のサポートを受けられます。
      </div>
      <ul>
        <li
          v-for="(text, index) in supports"
          :key="text"
          class="mb-2 flex items-center"
        >
          <CheckListItem
            :text="text"
            :color="'gray'"
          />
          <div
            v-if="index === 0"
            class="text-grayAlpha60 ml-1 text-xs"
          >
            ※ PCのみ
          </div>
        </li>
      </ul>
      <PrimaryButton
        title="無料で利用開始する"
        class="mt-6 h-12 w-full sm:mx-auto sm:w-[320px]"
        @click="onConversionButtonClick"
      />
    </div>
    <!-- 会員登録モーダル -->
    <Modal
      :is-visible="showsSignUpModal"
      close-button-label="閉じる"
      @close="showsSignUpModal = false"
    >
      <SignUpModalContent @close="showsSignUpModal = false" />
    </Modal>
  </div>
</template>

<script setup lang="ts">
import SectionHeader from '@/components/top/SectionHeader.vue';
import CheckListItem from './CheckListItem.vue';
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import { ref, inject } from 'vue';
import Modal from '@/components/common/Modal.vue';
import SignUpModalContent from '@/components/signup/SignUpModalContent.vue';
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import { useRouter } from 'vue-router';

const supports: string[] = [
  '見積書のダウンロード',
  '『商標基準の改定』や『最新の法律案』の提供',
  '商標関連のニュースをお届け',
  'お役立ち情報満載のセミナーに招待',
];

const showsSignUpModal = ref(false);

const { authenticated } = inject(UserDataKey) as UserDataStore;
const router = useRouter();

function onConversionButtonClick () {
  if (!authenticated.value) {
    showsSignUpModal.value = true;
  } else {
    router.push('/app/search/');
  }
}
</script>
