<template>
  <div :class="`${name}`">
    <div
      v-for="option in options"
      :key="option.value"
      :class="`${name}__inputs`"
    >
      <input
        :id="option.value"
        type="radio"
        :name="option.label"
        :value="option.value"
        :checked="value === option.value"
        @change="onChange"
      >
      <label :for="option.value">
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

export type RadioButtonGroupOption = {
  label: string;
  value: string;
};

const name = 'BaseRadioButtonGroup';

defineProps({
  options: {
    type: Array as PropType<RadioButtonGroupOption[]>,
    required: true,
  },
  value: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const onChange = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value);
};
</script>

<style lang="scss" scoped>
.BaseRadioButtonGroup {
  display: flex;

  &__inputs {
    margin-right: 16px;
  }

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + label {
      @include pc {
        display: flex;
        align-items: center;
      }

      &:before {
        content: '';
        background: #fff;
        border-radius: 100%;
        border: 1px solid #bbbbbb;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        margin-right: 0.5em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + label {
        &:before {
          background-color: $primary-color;
          box-shadow: inset 0 0 0 4px #fff;
          border: 1px solid $primary-color;
        }
      }
    }
    &:focus {
      + label {
        &:before {
          outline: none;
          border-color: $primary-color;
        }
      }
    }
    &:disabled {
      + label {
        &:before {
          box-shadow: inset 0 0 0 4px #fff;
          border-color: darken(#fff, 25%);
          background: darken(#fff, 25%);
        }
      }
    }
    + label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
