<template>
  <div class="p-6">
    <div class="flex flex-col gap-y-6">
      <div class="flex flex-col gap-y-4">
        <div class="font-bold sm:text-xl tracking-wider">
          {{ t('title.message') }}
        </div>
        <div class="flex sm:justify-end">
          <a
            href="https://support.cotobox.com/process-similar-trademarks"
            target="_blank"
            rel="noopener"
            class="text-primary text-sm flex gap-x-1 items-center"
          >
            <span class="material-icons !text-base">help</span>
            <span>{{ t('title.help') }}</span>
          </a>
        </div>
        <hr>
      </div>
      <div class="flex flex-col gap-y-3">
        <div class="text-sm whitespace-pre-line">
          {{ t("description") }}
        </div>
        <div class="bg-primaryOrange5 text-sm p-4 rounded">
          {{ t("callout.message") }}
          <br>
          {{ t("callout.cancel.messageFirst") }}
          <a
            href="https://support.cotobox.com/process-cancel-refund"
            target="_blank"
            rel="noopener"
            class="text-primary"
          >
            {{ t('callout.cancel.linkText') }}
          </a>
          {{ t("callout.cancel.messageLast") }}
        </div>
      </div>
      <div class="flex flex-row-reverse gap-x-2 gap-y-4 flex-wrap mt-4">
        <PrimaryButton
          :title="t('actions.submit')"
          :disabled="loading"
          class="flex-grow"
          @click="onNextButtonClick(true)"
        />
        <SecondaryButton
          :title="t('actions.sendSupport')"
          :disabled="loading"
          class="flex-grow font-normal"
          @click="sendSupport"
        />
        <div
          v-if="loading"
          class="absolute bottom-0 right-20 top-0 my-auto h-6 w-6 bg-contain bg-center bg-no-repeat"
          :style="{ backgroundImage: `url(${LoadingGif})` }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
// コンポーネント
import { useToast } from 'vue-toastification';
import LoadingGif from '@/assets/images/icons/loading.gif';
// 状態管理
import {
  KlassSelectStoreKey,
  TrademarkSearchStoreKey,
  UserDataKey,
} from '@/composables/injectionKeys';
import { KlassSelectStore } from '@/composables/useKlassSelect';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
import { UserDataStore } from '@/composables/useUserStore';
// ライブラリ
import axios from 'axios';
import { COOKIE_NAME, getCookieValue, setCookie } from '@/lib/util';

interface Props {
  onNextButtonClick: (isLittleChanceConfirmed: boolean) => void;
}

defineProps<Props>();

const { t } = useI18n({
  messages: {
    ja: {
      title: {
        message: '類似商標があるため、登録できない場合がございます。',
        help: '検索結果「類似商標あり」とは',
      },
      description:
        '既存商標に類似し、商標登録できない可能性があるものを「類似の商標あり」と表示しています。\n' +
          'お申し込みに不安がある方は、検索結果をサポートチームに送信してみましょう。あなたの登録したい商標の出願リスクをチャットで受け取れます。',
      callout: {
        message: 'まずはお見積もりだけ確認したい方、早速弁理士調査のご依頼をされる方は、右ボタンをクリック。弁理士調査後の出願前キャンセル可能です。',
        cancel: {
          messageFirst: '※詳しくは「',
          linkText: 'キャンセル・返金について',
          messageLast: '」をご覧ください。',
        },
      },
      actions: {
        sendSupport: 'サポートに送信（無料）',
        submit: 'お見積り・内容確認に進む',
      },
    },
    en: {
      title: {
        message: 'There may be cases where registration is not possible due to similar trademarks.',
        help: 'What does "Similar Trademark Found" mean in the search results?',
      },
      description:
        'We display "Similar Trademark Found" for trademarks that are similar to existing ones and may not be registerable.\n' +
          'If you are unsure about your application, try sending the search results to the support team. You can receive chat feedback on the application risks for the trademark you wish to register.',
      callout: {
        message: 'For those who only want to check the estimate or wish to immediately request a patent attorney investigation, click the right button. Cancellation before application is possible after the patent attorney investigation.',
        cancel: {
          messageFirst: '※For details, please see "',
          linkText: 'Cancellation and Refund Policy',
          messageLast: '".',
        },
      },
      actions: {
        sendSupport: 'Send to Support (Free)',
        submit: 'Proceed to Estimate and Review',
      },
    },
    zh: {
      title: {
        message: '由於有相似商標，可能無法註冊。',
        help: '搜索結果中的「相似商標存在」是什麼意思？',
      },
      description:
        '我們顯示「相似商標存在」，以指示與現有商標相似且可能無法註冊的商標。\n' +
          '如果您對申請有疑慮，請嘗試將搜索結果發送給支持團隊。您可以通過聊天接收關於您希望註冊的商標的申請風險反饋。',
      callout: {
        message: '想先確認報價的客戶或馬上要求專利代理調查的客戶，請點擊右側按鈕。專利代理調查後，申請前可以取消。',
        cancel: {
          messageFirst: '※詳情請參見「',
          linkText: '取消和退款政策',
          messageLast: '」取消和退款政策。',
        },
      },
      actions: {
        sendSupport: '發送給支持（免費）',
        submit: '進行報價和內容確認',
      },
    },
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

if (window.dataLayer) {
  window.dataLayer.push({ event: 'Little Chance Search Result' });
}

const loading = ref(false);
const toast = useToast();

const { channelTalkUserId } = inject(UserDataKey) as UserDataStore;
const { klassesForSearchHash } = inject(
  KlassSelectStoreKey,
) as KlassSelectStore;
const {
  trademarkType,
  standardCharacter,
  inputtedYomi,
  imageToken,
  imageText,
  imageYomi,
} = inject(TrademarkSearchStoreKey) as TrademarkSearchStore;

/**
 * CSに送信する検索結果URL
 */
const searchResultUrl = computed(() => {
  const isTextTrademark = trademarkType.value === 'text';
  const standard_character = isTextTrademark
    ? standardCharacter.value
    : imageText.value;
  const yomi = isTextTrademark ? inputtedYomi.value : imageYomi.value;
  const path = '/app/search/';
  const query =
    `?standard_character=${encodeURIComponent(standard_character)}` +
    `${yomi.length ? `&yomi=${yomi}` : ''}` +
    `${
      imageToken.value.length && !isTextTrademark
        ? `&image_token=${imageToken.value}`
        : ''
    }` +
    `${
      klassesForSearchHash.value.length
        ? `&selected_simgroup_ids=${klassesForSearchHash.value}`
        : ''
    }`;

  return `${window.location.origin}${path}${query}`;
});

/**
 * 検索結果URLをCSに送信
 */
async function sendSupport () {
  const cookieValue =
    getCookieValue(COOKIE_NAME.ALREADY_SENT_SEARCH_RESULT_URLS) ?? '';
  const alreadySentUrls = cookieValue.split(',');
  const alreadySent = alreadySentUrls.includes(searchResultUrl.value);
  if (alreadySent) {
    return toast.error(
      'すでにサポートチームに送信済みです。サポートチームからの返信をお待ちくださいませ。',
    );
  }

  loading.value = true;

  try {
    await axios.post('/app/api/xmas/channel_talk/send_search_result_url', {
      channel_talk_user_id: channelTalkUserId.value,
      trademark_type: trademarkType.value,
      url: searchResultUrl.value,
    });

    const value = [...alreadySentUrls, searchResultUrl.value].join(',');
    setCookie({
      cookieName: COOKIE_NAME.ALREADY_SENT_SEARCH_RESULT_URLS,
      value,
      maxAge: 60 * 60 * 24,
    });

    toast.success('送信しました。画面右下のチャットをご覧ください。');
    emit('close');
  } catch (error) {
    toast.error(
      '自動送信エラー: 商標と区分の自動送信に失敗しました。\n恐れ入りますが、現在のURLをコピーしてチャットに貼り付けてください。',
    );
  } finally {
    loading.value = false;
  }
}
</script>
