<script setup lang="ts">
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserDataKey } from '@/composables/injectionKeys';
import { UserDataStore } from '@/composables/useUserStore';
import FormTextInput from '@/components/common/FormTextInput.vue';
import BaseCheckbox from './BaseCheckbox.vue';

const { t } = useI18n({
  messages: {
    ja: {
      companyName: '法人名',
      isPersonal: '個人 / 個人事業主のため、法人名はない',
      lastName: '担当者姓',
      firstName: '担当者名',
      applicantLastName: '出願人姓',
      applicantFirstName: '出願人名',
    },
    en: {
      companyName: 'Corporate Name',
      isPersonal: 'No Corporate Name because I\'m an individual',
      lastName: 'Last name',
      firstName: 'First name',
      applicantLastName: 'Last name',
      applicantFirstName: 'First name',
    },
    zh: {
      companyName: '法人名称',
      isPersonal: '个人/个体户，无法人名称',
      lastName: '姓氏',
      firstName: '名字',
      applicantLastName: '姓氏',
      applicantFirstName: '名字',
    },
  },
});

const { state } = inject(UserDataKey) as UserDataStore;
</script>

<template>
  <div class="grid grid-cols-2 gap-4">
    <FormTextInput
      v-model="state.companyName"
      class="col-start-1 col-end-3"
      name="company_name"
      placeholder="cotobox株式会社"
      autocomplete="organization"
      :title="t('companyName')"
      :required="true"
      :shows-required-label="true"
      :shows-error="false"
      :disabled="state.isPersonal"
    />
    <div
      class="col-start-1 col-end-3 mb-4 mt-1 flex cursor-pointer items-center"
    >
      <BaseCheckbox
        id="checkbox__isPersonal"
        v-model="state.isPersonal"
        name="user[is_personal]"
      />
      <label
        for="checkbox__isPersonal"
        class="ml-2 cursor-pointer text-xs"
      >
        {{ t('isPersonal') }}
      </label>
    </div>
    <FormTextInput
      v-model="state.lastName"
      name="lastName"
      placeholder="cotobox株式会社"
      autocomplete="family-name"
      :title="state.isPersonal ? t('applicantLastName') : t('lastName')"
      :required="true"
      :shows-required-label="true"
      :shows-error="false"
    />
    <FormTextInput
      v-model="state.firstName"
      name="firstName"
      placeholder="cotobox株式会社"
      autocomplete="given-name"
      :title="state.isPersonal ? t('applicantFirstName') : t('firstName')"
      :required="true"
      :shows-required-label="true"
      :shows-error="false"
    />
  </div>
</template>
