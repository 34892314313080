import { GoodsService, goodsServices } from '@/constants/goodsServices';
import { KlassId } from '@/types';
import { ref, computed } from 'vue';
import { GoodsServiceId, GoodsServiceIds } from '@/composables/useKlassList';

export const useKlassSelect = () => {
  // 検索結果に表示する区分商品役務
  const klassesForSearch = ref<GoodsServiceIds>({});

  // 申し込もうとしている区分商品役務
  const klassesForSubmit = ref<GoodsServiceIds>({});

  // 検索対象の商品役務を選択
  function selectForSearch (klassId: KlassId, goodsServiceId: GoodsServiceId) {
    if (klassId in klassesForSearch.value) {
      const array = klassesForSearch.value[klassId] as string[];
      klassesForSearch.value[klassId] = [
        ...new Set([...array, goodsServiceId]),
      ];
    } else {
      klassesForSearch.value[klassId] = [goodsServiceId];
    }

    klassesForSearch.value[klassId]!.sort((a, b) => {
      const klassGoodsServices = goodsServices[klassId];
      const goodsServiceIdA = klassGoodsServices[a] as GoodsService;
      const goodsServiceIdB = klassGoodsServices[b] as GoodsService;
      return goodsServiceIdA.order - goodsServiceIdB.order;
    });
  }

  // 検索対象の商品役務を選択 "011-QB01-b2-42KyOa"のような文字列を渡すと該当する区分が選択された状態になる
  function selectForSearchWithHash (hash: string) {
    const klassGoodsServiceCodes = hash.match(/.{6}/g) as RegExpMatchArray;
    klassGoodsServiceCodes.forEach((klassGoodsServiceCode) => {
      const klassId = String(
        Number(klassGoodsServiceCode.slice(0, 2)),
      ) as KlassId;
      const goodsServiceHash = klassGoodsServiceCode.slice(2);
      const goodsServiceId = Object.keys(goodsServices[klassId]).find(
        (gsId) => {
          const goodsService = goodsServices[klassId][gsId] as GoodsService;
          return goodsService.code === goodsServiceHash;
        },
      );
      if (goodsServiceId) {
        selectForSearch(klassId, goodsServiceId);
      }
    });
  }

  // 検索対象の商品役務の選択を解除
  function deselectForSearch (klassId: KlassId, goodsServiceId: GoodsServiceId) {
    const array = klassesForSearch.value[klassId] as string[];
    const index = array.indexOf(goodsServiceId);
    klassesForSearch.value[klassId]!.splice(index, 1);

    if ((klassesForSearch.value[klassId] as string[]).length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [klassId]: goodsServiceIds, ...rest } = klassesForSearch.value;
      klassesForSearch.value = rest;
    }
  }

  // 検索対象の商品役務のハッシュ(URLパラメーター用)
  const klassesForSearchHash = computed(() => {
    return (Object.keys(klassesForSearch.value) as KlassId[]).reduce(
      (p1, klassId) => {
        const c = klassesForSearch.value[klassId]!;
        const h = c.reduce((p2, goodsServiceId) => {
          const goodsService = goodsServices[klassId][goodsServiceId]!;
          const hash = `${`00${klassId}`.slice(-2)}${goodsService.code}`;
          return `${p2}${hash}`;
        }, '');
        return `${p1}${h}`;
      },
      '',
    );
  });

  // 検索対象の商品役務IDの配列(API用)
  const klassesForSearchGoodsServiceIds = computed(() => {
    return klassIdsForSearch.value
      .map((klassId) => klassesForSearch.value[klassId]!)
      .flat();
  });

  // 申込対象の商品役務を選択
  function selectForSubmit (klassId: KlassId, goodsServiceId: GoodsServiceId) {
    if (klassId in klassesForSubmit.value) {
      const array = klassesForSubmit.value[klassId] as string[];
      klassesForSubmit.value[klassId] = [
        ...new Set([...array, goodsServiceId]),
      ];
    } else {
      klassesForSubmit.value[klassId] = [goodsServiceId];
    }

    klassesForSubmit.value[klassId]!.sort((a, b) => {
      const klassGoodsServices = goodsServices[klassId];
      const goodsServiceIdA = klassGoodsServices[a] as GoodsService;
      const goodsServiceIdB = klassGoodsServices[b] as GoodsService;
      return goodsServiceIdA.order - goodsServiceIdB.order;
    });
  }

  // 申込対象の商品役務の選択を解除
  function deselectForSubmit (klassId: KlassId, goodsServiceId: GoodsServiceId) {
    const array = klassesForSubmit.value[klassId] as string[];
    const index = array.indexOf(goodsServiceId);
    klassesForSubmit.value[klassId]!.splice(index, 1);

    if ((klassesForSubmit.value[klassId] as string[]).length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [klassId]: goodsServiceIds, ...rest } = klassesForSubmit.value;
      klassesForSubmit.value = rest;
    }
  }

  // 検索対象の区分
  const klassIdsForSearch = computed(
    () => Object.keys(klassesForSearch.value) as KlassId[],
  );

  // 申込対象の区分
  const klassIdsForSubmit = computed(
    () => Object.keys(klassesForSubmit.value) as KlassId[],
  );

  return {
    klassesForSearch,
    klassIdsForSearch,
    selectForSearch,
    selectForSearchWithHash,
    deselectForSearch,
    klassesForSearchHash,
    klassesForSearchGoodsServiceIds,
    klassesForSubmit,
    klassIdsForSubmit,
    selectForSubmit,
    deselectForSubmit,
  };
};

export type KlassSelectStore = ReturnType<typeof useKlassSelect>;
