<script setup lang="ts">
import { inject, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplicationListKey } from '@/composables/injectionKeys';
import { ApplicationListStore } from '@/composables/useApplicationList';
import { TrademarkApplication } from '@/api/APIClientInterface';

interface Props {
  application: TrademarkApplication;
}

const props = defineProps<Props>();

const { state, applicationStatusWithTrademarkAdminStatus } = inject(
  ApplicationListKey,
) as ApplicationListStore;

const { t } = useI18n({
  messages: {
    ja: {
      status0: '出願準備中',
      status13: '表示変更',
      status14: '権利移転',
      status15: '更新管理',
      title: '申請状況',
      step1: '出願準備',
      step2: '出願',
      step3: '特許庁審査',
      step4: '登録',
      replyRequired: '要返信',
    },
    en: {
      status0: 'Preparing',
      status13: 'Change',
      status14: 'Transfer',
      status15: 'Update',
      title: 'Progress',
      step1: 'Preparing',
      step2: 'Application',
      step3: 'Examination',
      step4: 'Registration',
      replyRequired: 'Reply required',
    },
    zh: {
      status0: '准备中的申请',
      status13: '显示改变',
      status14: '权利的转让',
      status15: '更新管理',
      title: '申请状态',
      step1: '申请的准备',
      step2: '应用',
      step3: '审查',
      step4: '注册',
      replyRequired: '需要答复',
    },
  },
});

const applyStatusLabel = computed(() => {
  switch (
    applicationStatusWithTrademarkAdminStatus(props.application.status.admin)
      .step
  ) {
  case 0:
    return t('step1');
  case 1:
    return t('step2');
  case 2:
    return t('step3');
  case 3:
    return t('step4');
  default:
    return '';
  }
});

const trademarkRightStatusLabel = computed(() => {
  switch (
    applicationStatusWithTrademarkAdminStatus(props.application.status.admin)
      .step
  ) {
  case 10:
    return t('status.13');
  case 11:
    return t('status.14');
  case 12:
    return t('status.15');
  default:
    return '';
  }
});
</script>

<script lang="ts">
export default {
  name: 'MyTrademarkTableRowMobile',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<template>
  <a
    :href="`/app/mypage/applications/${application.id}`"
    :class="`${$options.name}`"
    :data-trademark-text="application.standardCharacter"
  >
    <div :class="`${$options.name}__trademarkColumn`">
      <div
        :class="`${$options.name}__trademarkThumbnail`"
        :style="{
          backgroundImage:
            application.trademarkType === 'image'
              ? `url(${application.imageURL})`
              : 'none',
        }"
      >
        {{
          application.trademarkType === 'text'
            ? application.standardCharacter
            : ''
        }}
      </div>
    </div>
    <template v-if="state.selectedListType === 'submitted'">
      <div :class="`${$options.name}__referenceNumberColumn`">
        {{ application.referenceNumber || t('status.0') }}
      </div>
      <div :class="`${$options.name}__statusLabelColumn`">
        {{ applyStatusLabel }}
      </div>
      <div :class="`${$options.name}__messageColumn`">
        <a
          :href="`/app/mypage/applications/${application.id}/inquiries`"
          :class="`${$options.name}__messageIcon`"
          target="_blank"
        >
          <i
            class="material-icons material-symbols-outlined absolute text-[#383D51]"
          >email</i>
        </a>
        <div
          v-if="application.existsUnrepliedMessages"
          :class="`${$options.name}__messageIconBadge`"
        >
          {{ t('replyRequired') }}
        </div>
      </div>
    </template>
    <template v-if="state.selectedListType === 'accepted'">
      <div :class="`${$options.name}__referenceNumberColumn`">
        {{ application.referenceNumber || '' }}
      </div>
      <div :class="`${$options.name}__trademarkRightStatusColumn`">
        {{ trademarkRightStatusLabel }}
      </div>
      <div :class="`${$options.name}__messageColumn`">
        <a
          :href="`/app/mypage/applications/${application.id}/inquiries`"
          :class="`${$options.name}__messageIcon`"
          target="_blank"
        >
          <i
            class="material-icons material-symbols-outlined absolute text-[#383D51]"
          >email</i>
        </a>
        <div
          v-if="application.existsUnrepliedMessages"
          :class="`${$options.name}__messageIconBadge`"
        >
          {{ t('replyRequired') }}
        </div>
      </div>
    </template>
    <template v-if="state.selectedListType === 'saved'" />
    <template v-if="state.selectedListType === 'cancelled'">
      <div :class="`${$options.name}__referenceNumberColumn`">
        {{ application.referenceNumber || '' }}
      </div>
      <div :class="`${$options.name}__applyNumberColumn`">
        {{ application.applicationNumber || '' }}
      </div>
    </template>
  </a>
</template>

<style lang="scss" scoped>
.MyTrademarkTableRowMobile {
  display: grid;
  grid-template-columns: 56px 100px 1fr 32px;
  column-gap: 16px;
  padding: 16px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  letter-spacing: 0.6px;

  &:hover {
    background-color: #fff4f1;
  }

  &__trademarkColumn {
    display: flex;
    align-items: center;
    display: block;
    grid-row: 1/2;
  }

  &__trademarkThumbnail {
    width: 56px;
    height: 56px;
    border: 1px solid #eee;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    word-break: break-all;
  }

  &__referenceNumberColumn {
    display: flex;
    align-items: center;
    grid-row: 1/2;
    color: #2962ff;
    font-size: 14px;
    justify-content: left;
    white-space: nowrap;
  }

  &__applyNumberColumn {
    display: flex;
    align-items: center;
    grid-row: 1/2;
    color: #222;
    font-size: 14px;
    justify-content: left;
    white-space: nowrap;
  }

  &__statusLabelColumn {
    display: flex;
    align-items: center;
    grid-row: 1/2;
    color: $primary-color;
    font-size: 12px;
    justify-content: left;
    white-space: nowrap;
  }

  &__trademarkRightStatusColumn {
    display: flex;
    align-items: center;
    grid-row: 1/2;
    color: $primary-color;
    font-size: 12px;
    justify-content: left;
    white-space: nowrap;
  }

  &__messageColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row: 1/2;
    justify-content: center;
  }

  &__messageIcon {
    width: 32px;
    height: 32px;
    font-size: 20px;
    margin-bottom: 4px;
    border-radius: 20px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;

    .mdi-email {
      position: absolute;
      color: #383d51;
    }
  }

  &__messageIconBadge {
    white-space: nowrap;
  }
}
</style>
