<template>
  <!-- 支払 -->
  <div class="mx-3 mt-3 sm:ml-0">
    <div
      v-for="payment in paymentStore.payments"
      :key="payment.id"
      class="mb-4 flex flex-col"
    >
      <!-- 支払ステータス -->
      <div class="flex items-center">
        <PaymentStatusIcon :status="payment.paymentStatus" />
        <div class="font-bold">
          {{ payment.paymentStatusName }}
        </div>
      </div>
      <!-- ./支払いステータス -->
      <div class="flex justify-between">
        <div class="flex flex-col">
          <div class="text-grayAlpha60 w-52 truncate text-sm">
            {{ payment.translatedPaymentType }}
          </div>
          <div class="text-grayAlpha60 w-52 truncate text-sm">
            {{ t('payment.totalWithRaxRate') }}: ¥
            {{ payment.total.toLocaleString() }}
          </div>
        </div>
        <!-- ボタン -->
        <div class="flex">
          <template
            v-if="
              payment.paymentStatus === 'initial' ||
                payment.paymentStatus === 'failure'
            "
          >
            <BaseButton
              class="bg-primary mr-1 flex items-center rounded border p-2 text-sm font-bold text-white hover:bg-[#CF6637]"
              :title="t('payment.pay')"
              :disabled="false"
              @click="onClickOpenPaymentModal(payment.id)"
            />
          </template>
          <SecondaryButton
            class="text-grayAlpha60 flex items-center"
            :title="t('payment.detail')"
            :disabled="false"
            @click="onOpenPaymenDetailModal(payment.id)"
          />
        </div>
        <!-- ./ボタン-->
      </div>
    </div>
  </div>

  <!-- Stripe ダイアログ -->
  <StripePaymentModal
    :shows-payment-modal="isStripeModal"
    :stripe-account-id="applicationStore.stripeUserId"
    @on-close="isStripeModal = false"
  />

  <!-- 支払詳細  ダイアログ -->
  <Modal
    :is-visible="isPaymentModal"
    @close="isPaymentModal = !isPaymentModal"
  >
    <PaymentDetailModal />
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { usePaymentStore } from '@/composables/usePaymentStore';
import { useApplicationStore } from '@/composables/useApplicationStore';
import { useI18n } from 'vue-i18n';
import { ja } from '@/i18n/pages/app/applications/ja';
import StripePaymentModal from '@/components/common/StripePaymentModal.vue';
import PaymentDetailModal from './PaymentDetailModal.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import PaymentStatusIcon from './PaymentStatusIcon.vue';

const applicationStore = useApplicationStore();
const paymentStore = usePaymentStore();
const isStripeModal = ref(false);
const isPaymentModal = ref(false);
const { t } = useI18n({ messages: ja });

// 支払ダイアログ表示
function onClickOpenPaymentModal (paymentId: number) {
  isStripeModal.value = true;
  paymentStore.currentId = paymentId;
  paymentStore.fetchPayment();
}

// 支払詳細ダイアログ
function onOpenPaymenDetailModal (paymenId: number) {
  isPaymentModal.value = !isPaymentModal.value;
  paymentStore.currentId = paymenId;
  paymentStore.fetchPayment();
}
</script>
