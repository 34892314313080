<template>
  <div>
    <div class="mt-6 items-end justify-between sm:flex">
      <div class="flex items-center sm:block">
        <BaseSelect
          class="w-32"
          :options="categoryOptions"
          :model-value="category"
          @update:model-value="onCategorySelect"
        />
        <HelpLink
          v-if="$device.isMobile"
          title="区分とは？区分に悩んだらコチラ"
          class="ml-4"
          @click="showsHelpKlass = true"
        />
      </div>
      <div class="text-grayAlpha60 mt-4 text-lg font-semibold sm:mt-0">
        {{ `選択中の区分数：${klassIdsForSearch.length}区分` }}
      </div>
    </div>
    <KlassListVue
      class="mt-4 sm:bg-white"
      :filter-type="showsOnlyCategoryKlassList ? 'category' : 'none'"
    />
    <!-- ヘルプモーダル: 区分とは -->
    <Modal
      :is-visible="showsHelpKlass"
      @close="showsHelpKlass = false"
    >
      <div class="px-6 pb-8 pt-6">
        <div
          class="border-borderGray border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
        >
          区分とは／どの区分かわからないとき
        </div>
        <HelpContentKlass />
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, provide } from 'vue';
import {
  APIClientKey,
  KlassListStoreKey,
  KlassSelectStoreKey,
} from '@/composables/injectionKeys';
import { useKlassFilter, KlassCategory } from '@/composables/useKlassFilter';
import { APIClientInterface } from '@/api/APIClientInterface';
import { useKlassList } from '@/composables/useKlassList';
import KlassListVue from '@/components/search/KlassList.vue';
import BaseSelect from '@/components/common/BaseSelect.vue';
import { KlassSelectStore } from '@/composables/useKlassSelect';
// コンポーネント
import HelpLink from '@/components/common/HelpLink.vue';
import HelpContentKlass from '@/components/search/HelpContentKlass.vue';

const APIClient = inject(APIClientKey) as APIClientInterface;

const klassListModel = useKlassList();
provide(KlassListStoreKey, klassListModel);

const { klassIdsForSearch } = inject(KlassSelectStoreKey) as KlassSelectStore;

const { category, categoryResult } = useKlassFilter(APIClient);

const categoryOptions = [
  { label: 'すべて', value: 'all' },
  { label: '商品', value: 'goods' },
  { label: 'サービス', value: 'service' },
];

function onCategorySelect (value: string) {
  const selectedCategory = value as KlassCategory;
  category.value = selectedCategory;
  if (selectedCategory !== 'all') {
    klassListModel.source.value = categoryResult.value;
    showsOnlyCategoryKlassList.value = true;
  } else {
    klassListModel.source.value = {};
    showsOnlyCategoryKlassList.value = false;
  }
}

const showsOnlyCategoryKlassList = ref(false);

const showsHelpKlass = ref(false);
</script>
