<template>
  <div>
    {{ priceText }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  price: number;
}>();

const { locale } = useI18n({ useScope: 'global' });

const price = computed<number>(() => props.price);

const priceText = computed<string>(() => {
  if (locale.value === 'ja') {
    return `${price.value.toLocaleString()}円`;
  } else {
    return `¥${price.value.toLocaleString()}`;
  }
});
</script>
