import axios from 'axios';
import { defineStore } from 'pinia';
import { NotFoundError } from '@/types/error';

export interface Inquiry {
  id: number;
  applicationId: number;
  patentAttorneyName: string;
  attorneyOfficeName: string;
  role: string;
  kind: string;
  authorName: string;
  content: string;
  attachments: [{ url: string; name: string }];
  createdAt: string;
}

export const useInquiriesStore = defineStore({
  id: 'inquiriesStore',
  state: () => ({
    inqueiues: [] as Array<Inquiry>,
    message: '' as string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attachment: null as any,
  }),
  getters: {
    inquiries: (state) => {
      return state.inqueiues;
    },
  },
  actions: {
    async getInquiries (appId: number) {
      this.$reset();

      const headers = {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      };

      try {
        const response = await axios.get(
          `/app/mypage/applications/${appId}/inquiries.json`,
          headers,
        );

        response.data.forEach((inquiry) => {
          this.inqueiues.push({
            id: inquiry.id,
            applicationId: inquiry.applicationId,
            patentAttorneyName: inquiry.patentAttorneyName,
            role: inquiry.role,
            attorneyOfficeName: inquiry.attorneyOfficeName,
            kind: inquiry.kind,
            authorName: inquiry.authorName,
            content: inquiry.content,
            attachments: inquiry.attachments,
            createdAt: inquiry.createdAt,
          });
        });
      } catch (e) {
        if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
          throw new NotFoundError();
        }
        throw e;
      }
    },
    async postMessage (applicationId: number) {
      const formData = new FormData();
      formData.append('tm_app_inquiry[content]', this.message);
      formData.append('tm_app_inquiry[attachments][]', this.attachment);

      const option = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      await axios.post(
        `/app/mypage/applications/${applicationId}/inquiries.json`,
        formData,
        option,
      );

      this.getInquiries(applicationId);
    },
    resetAttachment () {
      this.attachment = null;
    },
  },
});
