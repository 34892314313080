<template>
  <div :class="`${$options.name}`">
    <div :class="`${$options.name}__header`">
      {{ t('title') }}
    </div>
    <table>
      <thead>
        <tr>
          <th scope="col">
            {{ t('detail') }}
          </th>
          <th scope="col">
            {{ t('amount') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ t('applyUsageFee', [klassCount]) }}</td>
          <td>{{ t('price', [applyUsageFee.toLocaleString()]) }}</td>
        </tr>
        <tr>
          <td>{{ t('speedPlanFee') }}</td>
          <td>
            {{
              applyFormState.speedPlan
                ? t('price', [speedPlanFee.toLocaleString()])
                : t('outOfScope')
            }}
          </td>
        </tr>
        <tr>
          <td>{{ t('applyPrintFee') }}</td>
          <td>{{ t('price', [applyPrintFee.toLocaleString()]) }}</td>
        </tr>
        <tr>
          <td>{{ t('applyTax') }}</td>
          <td>{{ t('price', [applyTax.toLocaleString()]) }}</td>
        </tr>
        <tr>
          <td>{{ t('withholdingTax') }}</td>
          <td>
            {{
              t('price', [
                withholdingTax === 0 ? '0' : withholdingTax.toLocaleString(),
              ])
            }}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            {{ t('applicationTotal', [applySubTotalFee.toLocaleString()]) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplyFormKey, UserDataKey } from '@/composables/injectionKeys';
import { ApplyFormStore } from '@/composables/useApplyForm';
import { usePriceCalculator } from '@/composables/usePriceCalculator';
import { UserDataStore } from '@/composables/useUserStore';

export default defineComponent({
  name: 'PriceConfirm',
  setup () {
    const { t } = useI18n({
      messages: {
        ja: {
          title: '今回のお支払い金額',
          detail: '詳細',
          amount: '金額',
          price: '{0}円',
          applicationTitle: '出願時',
          applyUsageFee: '手数料（{0}区分）',
          premiumPlanFee: '安心フルサポート\n（{0}区分）',
          speedPlanFee: '審査スピードアップ\n（早期審査）',
          applyPrintFee: '特許印紙代',
          applyTax: '消費税',
          withholdingTax: '源泉徴収税',
          applicationTotal: '合計 {0}円',
          outOfScope: '対象外',
        },
        en: {
          title: 'Amount to be paid this time',
          detail: 'Details',
          amount: 'Amount',
          price: '¥{0}',
          applicationTitle: 'When making a wish',
          applyUsageFee: 'Service charge ({0} classification)',
          premiumPlanFee:
            'Full support for peace of mind\n({0} classification)',
          speedPlanFee: 'Speeding Up Screening\n(Early screening)',
          applyPrintFee: 'License paper generation',
          applyTax: 'consumption tax (incl. sales tax, VAT, excise duty, etc.)',
          withholdingTax: 'tax withholding at the source',
          applicationTotal: 'Total {0} yen',
          outOfScope: 'not subject (to)',
        },
        zh: {
          title: '这次要支付的金额',
          detail: '细节。',
          amount: '金額',
          price: '¥{0}',
          applicationTitle: '出願時',
          applyUsageFee: '服务费（{0}类）',
          premiumPlanFee: '全面支持，让人放心\n({0}分类)',
          speedPlanFee: '加快筛查速度\n(提前放映)',
          applyPrintFee: '特許印紙代',
          applyTax: '消费税（包括销售税、增值税、消费税等）',
          withholdingTax: '源头预扣税款',
          applicationTotal: '共计{0}。',
          outOfScope: '不包括',
        },
      },
    });

    const { state: applyFormState, klassCount } = inject(
      ApplyFormKey,
    ) as ApplyFormStore;

    const {
      state: userData,
      authenticated,
      isAttorneyCorporation,
    } = inject(UserDataKey) as UserDataStore;

    const premiumPlan = computed(() => applyFormState.premiumPlan);
    const speedPlan = computed(() => applyFormState.speedPlan);
    const isPersonal = computed(() => userData.isPersonal);

    const {
      applyUsageFee,
      premiumPlanFee,
      speedPlanFee,
      applyPrintFee,
      applyTax,
      withholdingTax,
      applySubTotalFee,
    } = usePriceCalculator({
      klassCount,
      premiumPlan,
      speedPlan,
      authenticated,
      isPersonal,
      isAttorneyCorporation,
    });

    return {
      t,
      applyFormState,
      klassCount,
      applyUsageFee,
      premiumPlanFee,
      speedPlanFee,
      applyPrintFee,
      applyTax,
      withholdingTax,
      applySubTotalFee,
    };
  },
});
</script>

<style lang="scss" scoped>
.PriceConfirm {
  &__header {
    border: 4px solid $primary-color;
    border-bottom: 0;
    background-color: #fff4f1;
    font-weight: 600;
    padding: 12px 20px;
  }
}

table {
  border-collapse: collapse;
  font-size: 14px;
  border: 4px solid #e6713d;
  border-top: 0;
  background-color: #fff;
  width: 100%;
  margin-bottom: 8px;
  color: #212529;
}

th {
  font-weight: normal;
  color: #555;
  border-top: 1px solid #dee2e6;
  border-bottom: 0;
  font-size: 12px;
  vertical-align: bottom;
  padding: 0.75rem;

  &:first-child {
    border-right: 1px solid #dee2e6;
    padding: 12px 12px 12px 18px;
    width: 65%;
  }
}

tr {
  &:last-child {
    td {
      text-align: right;
      color: $primary-color;
      font-size: 22px;
      font-weight: 600;
    }
  }
}

td {
  border-right: 1px solid #dee2e6;
  padding: 12px 18px 12px 18px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;

  &:nth-child(2) {
    text-align: right;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
