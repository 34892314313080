<template>
  <div class="sm:pb-32">
    <div
      class="box-border rounded-2xl bg-white px-4 pb-16 pt-10 sm:mx-auto sm:mb-0 sm:w-[780px] sm:px-8"
    >
      <div class="mx-auto sm:mt-6 sm:w-[460px]">
        <div
          class="text-center text-lg font-semibold tracking-wider sm:text-2xl"
        >
          アカウント情報をご入力ください
        </div>
        <div class="text-grayAlpha80 mb-6 mt-2 text-center text-sm">
          入力内容は登録後に変更できます。
        </div>
        <form @submit.prevent>
          <UserCompanyNameInput
            class="mb-4"
            :shows-error="showsError"
            :company-name="state.companyName"
            :is-personal="state.isPersonal"
            @update:company-name="setCompanyName"
            @update:is-personal="setIsPersonal"
            @blur="
              setCookie({
                cookieName: COOKIE_NAME.SIGN_UP_COMPANY_NAME,
                value: state.companyName,
                maxAge: 900,
              })
            "
          />
          <!-- 業種 -->
          <FormItemWrapper
            class="mb-4"
            :title="t('industry')"
            :required="true"
            :shows-error="showsError"
          >
            <IndustrySelect
              v-model="state.industry"
              class="h-12"
              @blur="
                setCookie({
                  cookieName: COOKIE_NAME.SIGN_UP_INDUSTRY,
                  value: state.industry,
                  maxAge: 900,
                })
              "
            />
          </FormItemWrapper>
          <!-- 氏名 -->
          <div class="mb-4 grid grid-cols-2 gap-x-6 gap-y-6 sm:gap-y-0">
            <FormTextInput
              v-model="state.lastName"
              :title="t('lastName')"
              name="last_name"
              :placeholder="t('placeholders.lastName')"
              :required="true"
              :shows-error="showsError"
              @blur="
                setCookie({
                  cookieName: COOKIE_NAME.SIGN_UP_LAST_NAME,
                  value: state.lastName,
                  maxAge: 900,
                })
              "
            />
            <FormTextInput
              v-model="state.firstName"
              :title="t('firstName')"
              name="first_name"
              :placeholder="t('placeholders.firstName')"
              :required="true"
              :shows-error="showsError"
              @blur="
                setCookie({
                  cookieName: COOKIE_NAME.SIGN_UP_FIRST_NAME,
                  value: state.firstName,
                  maxAge: 900,
                })
              "
            />
          </div>
          <template v-if="showKanaInput">
            <div class="mb-4 grid grid-cols-2 gap-x-6 gap-y-6 sm:gap-y-0">
              <FormTextInput
                v-model="state.lastNameKana"
                :title="t('lastNameKana')"
                name="last_name_kana"
                :placeholder="t('placeholders.lastNameKana')"
                :required="true"
                :shows-error="showsError"
                @blur="
                  setCookie({
                    cookieName: COOKIE_NAME.SIGN_UP_LAST_NAME_KANA,
                    value: state.lastNameKana,
                    maxAge: 900,
                  })
                "
              />
              <FormTextInput
                v-model="state.firstNameKana"
                :title="t('firstNameKana')"
                name="first_name_kana"
                :placeholder="t('placeholders.firstNameKana')"
                :required="true"
                :shows-error="showsError"
                @blur="
                  setCookie({
                    cookieName: COOKIE_NAME.SIGN_UP_FIRST_NAME_KANA,
                    value: state.firstNameKana,
                    maxAge: 900,
                  })
                "
              />
            </div>
          </template>
          <!-- 電話番号 -->
          <FormTextInput
            v-model="state.phoneNumber"
            class="mb-4"
            :title="t('phoneNumber')"
            name="phone_number"
            autocomplete="tel-national"
            :placeholder="t('placeholders.phoneNumber')"
            :required="true"
            :shows-error="showsError"
            @blur="
              setCookie({
                cookieName: COOKIE_NAME.SIGN_UP_PHONE_NUMBER,
                value: state.phoneNumber,
                maxAge: 900,
              })
            "
          />
          <!-- 言語 -->
          <FormItemWrapper
            class="mb-4"
            :title="t('language')"
            :required="true"
            :shows-error="showsError"
          >
            <BaseSelect
              v-model="registeredUserData.language"
              class="h-12"
              :options="languageOptions"
              @blur="
                setCookie({
                  cookieName: COOKIE_NAME.SIGN_UP_LANGUAGE,
                  value: state.language,
                  maxAge: 900,
                })
              "
            />
          </FormItemWrapper>
          <!-- パスワード -->
          <UserPasswordInput
            v-model="state.password"
            :shows-error="showsError"
          />
        </form>
        <!-- 利用規約 -->
        <UserTermCheckbox
          v-model="state.userTermsAgreed"
          class="flex-1"
        />
        <!-- 登録ボタン -->
        <PrimaryButton
          :title="t('submit')"
          class="mt-6 w-full"
          :disabled="!state.userTermsAgreed"
          @click="onSubmitButtonClick($device.isMobile)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
// 状態管理
import { SignUpKey, UserDataKey } from '@/composables/injectionKeys';
import { SignUpStore } from '@/composables/useSignUp';
import { UserDataStore } from '@/composables/useUserStore';
// コンポーネント
import BaseSelect from '@/components/common/BaseSelect.vue';
import UserCompanyNameInput from '@/components/signup/UserCompanyNameInput.vue';
import IndustrySelect from '@/components/common/IndustrySelect.vue';
import UserPasswordInput from '@/components/signup/UserPasswordInput.vue';
import UserTermCheckbox from '@/components/signup/UserTermCheckbox.vue';
// ライブラリ
import { useToast } from 'vue-toastification';
import ChannelService from '@/lib/channelService';
import { COOKIE_NAME, setCookie, deleteCookie } from '@/lib/util';

const { t } = useI18n({
  messages: {
    ja: {
      email: 'メールアドレス',
      password: 'パスワード',
      contactPerson: '担当者名',
      companyName: '法人名',
      isPersonal: '個人 / 個人事業主のため、法人名はない',
      lastName: '担当者姓',
      firstName: '担当者名',
      lastNameKana: '担当者姓(ふりがな)',
      firstNameKana: '担当者名(ふりがな)',
      phoneNumber: '電話番号',
      industry: '業種',
      language: '言語',
      ja: '日本語',
      en: '英語',
      zh: '中国語',
      passwordStrength: 'パスワード強度',
      agreement: {
        text: '{0}及び{1}に同意します。',
        terms: '利用規約',
        privacy: 'プライバシーポリシー',
      },
      submit: '登録する',
      placeholders: {
        companyName: 'cotobox株式会社',
        lastName: '山田',
        firstName: '太郎',
        lastNameKana: 'やまだ',
        firstNameKana: 'たろう',
        phoneNumber: '03-1234-5678',
        password: '8文字以上で、大文字と小文字をそれぞれ1文字以上含めて入力してください。',
      },
      error: 'エラー',
      contactViaChat: 'お手数ですがチャットでご連絡ください。',
    },
    en: {
      email: 'Email',
      password: 'Password',
      contactPerson: 'Name',
      companyName: 'Corporate Name',
      isPersonal: 'No Corporate Name because I\'m an individual',
      lastName: 'Last name',
      firstName: 'First name',
      phoneNumber: 'Phone Number',
      industry: 'Industry Type',
      language: 'Language',
      ja: 'Japanese',
      en: 'English',
      zh: 'Chinese',
      passwordStrength: 'Password Strength',
      agreement: {
        text: 'I agree with {0} and {1}.',
        terms: 'Terms of service',
        privacy: 'Privacy policy',
      },
      submit: 'Register',
      placeholders: {
        companyName: 'cotobox Inc.',
        lastName: 'Ex: Smith',
        firstName: 'Ex: John',
        phoneNumber: '+81 03-1234-5678',
        password: 'at least 8 characters, including at least one uppercase letter and one lowercase letter',
      },
      error: 'Error',
      contactViaChat: 'Please contact us via chat.',
    },
    zh: {
      email: '电子邮件地址',
      password: '密码',
      contactPerson: '联络人姓名',
      companyName: '法人名称',
      isPersonal: '个人/个体户，无法人名称',
      lastName: '姓氏',
      firstName: '名字',
      phoneNumber: '电话号码',
      industry: '行业',
      language: '语言',
      ja: '日语',
      en: '英语',
      zh: '中文',
      passwordStrength: '密码强度',
      agreement: {
        text: '同意{0}和{1}',
        terms: '使用条款',
        privacy: '隐私政策',
      },
      submit: '注册成为会员',
      placeholders: {
        companyName: 'cotobox株式会社',
        lastName: '',
        firstName: '',
        phoneNumber: '+81 03-1234-5678',
        password: '请输入至少8个字符的密码，并且至少包含一个大写字母和一个小写字母',
      },
      error: '误差',
      contactViaChat: '请通过聊天与我们联系',
    },
  },
});

const languageOptions = [
  { label: t('ja'), value: 'ja' },
  { label: t('en'), value: 'en' },
  { label: t('zh'), value: 'zh' },
];

const { state, submittionDisabled, submit } = inject(SignUpKey) as SignUpStore;

const {
  state: registeredUserData,
  loadUser,
  channelTalkUserId,
} = inject(UserDataKey) as UserDataStore;

const route = useRoute();
const router = useRouter();
const toast = useToast();

const { locale } = useI18n<object, 'ja' | 'en' | 'zh'>({ useScope: 'global' });
const currentLocale = locale.value;
const showKanaInput = currentLocale === 'ja';

const showsError = ref(false);

function setCompanyName (value: string) {
  state.companyName = value;
}

function setIsPersonal (value: boolean) {
  state.isPersonal = value;
  setCookie({
    cookieName: COOKIE_NAME.SIGN_UP_IS_PERSONAL,
    value: value ? 'true' : 'false',
    maxAge: 900,
  });
}

async function onSubmitButtonClick (isMobile: boolean) {
  if (submittionDisabled.value) {
    showsError.value = true;
  }

  if (showKanaInput) {
    const hiraganaRegex = /^[ぁ-んー]*$/;
    const firstNameKana = state.firstNameKana.trim();
    const lastNameKana = state.lastNameKana.trim();
    if (!firstNameKana.length) {
      return toast.error('担当者名のふりがなを入力してください。');
    }
    if (!lastNameKana.length) {
      return toast.error('担当者姓のふりがなを入力してください。');
    }
    if (!hiraganaRegex.test(firstNameKana)) {
      return toast.error('担当者名のふりがなは、ひらがなで入力してください。');
    }
    if (!hiraganaRegex.test(lastNameKana)) {
      return toast.error('担当者姓のふりがなは、ひらがなで入力してください。');
    }
  }

  // TODO: 正規表現を下記のeslint違反にならないよう修正する
  // eslint-disable-next-line no-control-regex
  if (/[^\u0000-\u00ff]/.test(state.phoneNumber)) {
    return toast.error('電話番号は半角数字で入力してください。');
  }

  const lengthCheck = state.password.length >= 8;
  const uppercaseCheck = /[A-Z]/.test(state.password);
  const lowercaseCheck = /[a-z]/.test(state.password);
  if (!lengthCheck) {
    return toast.error('パスワードは8文字以上で設定してください。');
  }
  if (!uppercaseCheck || !lowercaseCheck) {
    return toast.error('パスワードは大文字、小文字を少なくとも1つ含む必要があります。');
  }

  state.channeltalkCustomerId = channelTalkUserId.value;

  const { error: submitError } = await submit();
  if (submitError) {
    return toast.error(`${t('error')} ${t('contactViaChat')}\n${submitError}`);
  }

  const loadError = await loadUser();
  if (loadError) {
    return console.error(loadError);
  }

  const {
    id: userId,
    email,
    firstName,
    lastName,
    phoneNumber,
    companyName,
    industry,
    attorneyOffice,
    language,
    refNumPrefix,
  } = registeredUserData;

  locale.value = language as 'ja' | 'en' | 'zh';
  const deviceWhenSignUp = isMobile ? 'mobile' : 'desktop';

  // チャネルトークの顧客情報を更新
  ChannelService.updateUser(
    {
      language,
      profile: {
        name: `${lastName}${firstName}`,
        email,
        phoneNumber,
        companyName,
        language,
        industry,
        referenceNumPrefix: refNumPrefix,
        purchased: false,
        signUpDate: new Date().getTime(), // チャネルトークの日付型はミリ秒のUNIXタイムで登録
        deviceWhenSignUp,
      },
      // @ts-ignore
    },
    (error, user) => {
      if (error) {
        console.error(error);
      } else {
        channelTalkUserId.value = user.id;
      }
    },
  );

  // Googleタグマネージャの変数にセット
  const now = new Date();
  const month = `00${now.getMonth() + 1}`.slice(-2);
  const day = `00${now.getDate()}`.slice(-2);
  const signUpDate = `${now.getFullYear()}-${month}-${day}`;
  if (window.dataLayer) {
    window.dataLayer.push({
      isLoggedIn: true,
      userId: String(userId),
      email,
      // @ts-ignore
      ...(window.crto_sha256 && {
        // @ts-ignore
        cto_hash_email: window.crto_sha256(email.toLowerCase()),
      }),
      firstName,
      lastName,
      mobileNumber: phoneNumber,
      companyName,
      industry,
      language,
      refNumPrefix,
      purchased: false,
      signUpDate,
      deviceWhenSignUp,
      ...(attorneyOffice && {
        attorneyOfficeId: `${attorneyOffice.id}`,
        attorneyOfficeName: attorneyOffice.name[language],
      }),
    });
  }

  window.dataLayer.push({ event: 'register' });

  deleteCookie(COOKIE_NAME.SIGN_UP_EMAIL);
  deleteCookie(COOKIE_NAME.SIGN_UP_CONFIRMATION_TOKEN);
  deleteCookie(COOKIE_NAME.SIGN_UP_COMPANY_NAME);
  deleteCookie(COOKIE_NAME.SIGN_UP_IS_PERSONAL);
  deleteCookie(COOKIE_NAME.SIGN_UP_LAST_NAME);
  deleteCookie(COOKIE_NAME.SIGN_UP_LAST_NAME_KANA);
  deleteCookie(COOKIE_NAME.SIGN_UP_FIRST_NAME);
  deleteCookie(COOKIE_NAME.SIGN_UP_FIRST_NAME_KANA);
  deleteCookie(COOKIE_NAME.SIGN_UP_INDUSTRY);
  deleteCookie(COOKIE_NAME.SIGN_UP_PHONE_NUMBER);
  deleteCookie(COOKIE_NAME.SIGN_UP_LANGUAGE);

  router.push({
    name: 'SignUpThanksPage',
    query: {
      ...route.query,
    },
  });
}
</script>
