<template>
  <div
    :data-style="itemStyle"
    class="whitespace-pre-wrap rounded-lg border-2 border-solid px-2 py-1 text-center text-sm font-semibold"
    :class="itemClass"
  >
    {{ label }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  label: string;
  itemStyle: 'outline' | 'fill' | 'gray';
}>();
const itemClass = computed(() => {
  switch (props.itemStyle) {
  case 'outline':
    return 'border-grayAlpha80 text-grayAlpha80';
  case 'fill':
    return 'bg-grayAlpha80 text-white';
  case 'gray':
    return 'border-borderGray text-grayAlpha60';
  default:
    console.error(`invalid itemStyle: ${props.itemStyle}`);
    return '';
  }
});
</script>
