<template>
  <div>
    <div
      v-if="title"
      class="mb-2 flex items-center"
    >
      <div class="text-sm font-normal tracking-wider flex sm:items-center">
        {{ title }}
        <slot />
      </div>
      <div
        v-if="showsRequiredLabel"
        class="ml-1 rounded-sm p-1 text-xs"
        :class="
          required ? 'bg-primary text-white' : 'bg-bgGray text-grayAlpha80'
        "
      >
        {{ required ? t('common.required') : t('common.optional') }}
      </div>
    </div>
    <BaseTextInput
      :id="id"
      class="w-full rounded-sm border border-solid px-4 py-4 text-base leading-4 tracking-wider outline-none"
      :class="
        showsError && mergedErrorMessage
          ? 'border-errorRed'
          : 'border-borderGray'
      "
      :type="type"
      :model-value="modelValue"
      :name="name"
      :autocomplete="autocomplete"
      :placeholder="placeholder || ''"
      :disabled="disabled"
      @update:model-value="updateValue"
      @blur="onBlur"
    />
    <div
      v-if="showsError && mergedErrorMessage"
      class="text-errorRed py-1 text-left text-sm"
    >
      {{ mergedErrorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseTextInput from '@/components/common/BaseTextInput.vue';

const { t } = useI18n({ useScope: 'global' });

interface Props {
  modelValue: string;
  id?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  autocomplete?: string;
  title?: string;
  showsRequiredLabel?: boolean;
  required?: boolean;
  showsError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (e: 'update:model-value', value: string): void;
  (e: 'blur'): void;
}

const emit = defineEmits<Emits>();

function updateValue (value: string) {
  emit('update:model-value', value);
}

function onBlur () {
  emit('blur');
}

const modelValue = computed(() => props.modelValue);
const required = computed(() => props.required);
const showsError = computed(() => props.showsError);
const errorMessage = computed(() => props.errorMessage);

const mergedErrorMessage = computed(() => {
  if (!showsError.value) {
    return undefined;
  }
  if (required.value && modelValue.value.length === 0) {
    return '未入力です。';
  }
  return errorMessage.value;
});
</script>
