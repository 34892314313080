<template>
  <div :class="`${$options.name}`">
    <div :class="`${$options.name}__row`">
      <div :class="`${$options.name}__label`">
        {{ t('trademark') }}
      </div>
      <template v-if="trademarkType === 'image'">
        <div
          v-if="trademarkType === 'image'"
          :class="[`${$options.name}__value`, `${$options.name}__image`]"
          :style="{ backgroundImage: `url(${imageDataURL})` }"
        />
      </template>
      <div
        v-if="trademarkType === 'text'"
        :class="`${$options.name}__value`"
      >
        {{ standardCharacter }}
      </div>
    </div>
    <div :class="`${$options.name}__row`">
      <div :class="`${$options.name}__label`">
        {{ t('trademarkType') }}
      </div>
      <div :class="`${$options.name}__value`">
        {{ trademarkType === 'text' ? t('text') : t('image') }}
      </div>
    </div>
    <div :class="`${$options.name}__row`">
      <div :class="`${$options.name}__label`">
        {{ t('klass') }}
      </div>
      <div :class="`${$options.name}__value`">
        <div
          v-for="(klass, index) in selectedKlasses"
          :key="index"
        >
          <div :class="`${$options.name}__klass`">
            <span :class="`${$options.name}__klassNumber`">{{
              klass.klassId
            }}</span>
            <span>{{ klass.klassName }}</span>
          </div>
          <div
            v-for="goodsServiceName in klass.goodsServiceNames"
            :key="goodsServiceName"
          >
            <div :class="`${$options.name}__goodsServiceName`">
              {{ goodsServiceName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplyFormKey } from '@/composables/injectionKeys';
import { ApplyFormStore } from '@/composables/useApplyForm';
import { KlassId } from '@/types';
import { klasses } from '@/constants/klasses';
import { goodsServices } from '@/constants/goodsServices';

export default defineComponent({
  name: 'TrademarkInfo',
  setup () {
    const { t, locale } = useI18n<object, 'ja' | 'en' | 'zh'>({
      messages: {
        ja: {
          trademark: '商標',
          trademarkType: '商標のタイプ',
          text: '文字商標',
          image: 'ロゴ商標',
          klass: '区分',
        },
        en: {
          trademark: 'trademark',
          trademarkType: 'Trademark Type',
          text: 'character trademark',
          image: 'Logo Trademarks',
          klass: 'classification',
        },
        zh: {
          trademark: '商标',
          trademarkType: '商标类型。',
          text: '文字商标',
          image: '徽标和商标',
          klass: '分类',
        },
      },
    });

    const {
      trademarkType,
      standardCharacter,
      imageDataURL,
      imageText,
      klassesForSubmit,
    } = inject(ApplyFormKey) as ApplyFormStore;

    const currentLocale = ['ja', 'en'].includes(locale.value)
      ? (locale.value as 'ja' | 'en')
      : 'en';

    const selectedKlasses = computed(() => {
      return (Object.keys(klassesForSubmit) as KlassId[]).map((klassId) => ({
        klassId,
        klassName: klasses[parseInt(klassId) - 1].name[currentLocale],
        // @ts-ignore
        goodsServiceNames: klassesForSubmit[klassId]!.map(
          (goodsServiceName) =>
            // @ts-ignore
            goodsServices[klassId][goodsServiceName].name[currentLocale],
        ),
      }));
    });

    return {
      t,
      trademarkType,
      standardCharacter,
      imageDataURL,
      imageText,
      selectedKlasses,
    };
  },
});
</script>

<style lang="scss" scoped>
.TrademarkInfo {
  @include pc {
  }
  @include sp {
  }

  &__row {
  }

  &__label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__value {
    font-size: 14px;
    color: #222;
    margin-bottom: 16px;
  }

  &__image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
  }

  &__klass {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__klassNumber {
    margin-right: 14px;
  }

  &__goodsServiceName {
    font-size: 14px;
    font-weight: 200;
    margin-left: 26px;
    margin-bottom: 8px;
  }
}
</style>
