<template>
  <div class="px-4 pb-6 pt-3 sm:px-10 sm:py-6">
    <div
      class="mb-6 text-xl font-bold tracking-widest sm:mb-2 sm:text-center sm:text-2xl"
    >
      {{ t('title') }}
    </div>
    <p
      class="text-grayAlpha60 mb-4 whitespace-pre-line text-center text-sm leading-5 tracking-wider"
    >
      {{ t('description', [state.email]) }}
    </p>
    <FormTextInput
      v-model="state.confirmationToken"
      :title="t('confirmationToken')"
      name="confirmation_token"
      autocomplete="off"
      :shows-required-label="false"
      :required="false"
      :shows-error="false"
    />
    <PrimaryButton
      class="mt-6 h-12 w-full"
      :title="t('next')"
      :disabled="disabled"
      @click="next"
    />
    <TheOverlayLoading v-if="processing" />
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
// 状態管理
import { SignUpKey } from '@/composables/injectionKeys';
import { SignUpStore } from '@/composables/useSignUp';
// ライブラリ
import { useToast } from 'vue-toastification';

const emit = defineEmits<{
  (e: 'next'): void;
}>();

const { t } = useI18n({
  locale: 'ja',
  messages: {
    ja: {
      title: '認証コードを入力してください。',
      description: '{0}\nに届いた6桁の認証コードを入力してください。',
      confirmationToken: '認証コード',
      next: '次へ進む',
      expiredError:
        '認証コードの有効期限が切れています。恐れ入りますが、再度メールアドレスの入力からやり直してください。',
      wrongError:
        '認証コードが間違っています。メールに記載の認証コードをご確認ください。',
    },
    en: {
      title: 'Enter Verification Code',
      description: 'We\'ve sent a verification code to your email.\n{0}',
      confirmationToken: 'Verification Code',
      next: 'Next',
      expiredError:
        'The authentication code has expired. Please try again from entering your email address.',
      wrongError: 'The confirmation code is wrong.',
    },
    zh: {
      title: '请输入你的验证码。',
      description: '请输入发送到{0}的6位数的验证码。',
      confirmationToken: '验证码',
      next: '转到下一个',
      expiredError: '你的验证码已经过期。 请再次输入你的电子邮件地址并重试。',
      wrongError: '验证码不正确',
    },
  },
});

const {
  state,
  tokenValidateDisabled: disabled,
  validateConfirmationToken,
} = inject(SignUpKey) as SignUpStore;

const processing = ref(false);
const toast = useToast();

async function next () {
  processing.value = true;
  const error = await validateConfirmationToken();
  processing.value = false;

  if (error === 'expired') {
    return toast.error(t('expiredError'));
  } else if (error) {
    return toast.error(t('wrongError'));
  }

  emit('next');
}
</script>
