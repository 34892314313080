<template>
  <div class="text-grayAlpha60 whitespace-pre-line py-4 text-sm">
    <strong>【商標とは？】</strong><br>
    商標とは、自社商品と他社商品を見分けるための目印（ネーミングやロゴ）です。<br>
    文字、図形、記号や、これらを組み合わせたものなど様々なタイプがあります。<br>
    <ul class="list-disc pl-4">
      <li>
        <a
          class="text-primary underline"
          href="https://cotobox.com/primer/about-trademark/"
          target="_blank"
        >
          商標登録とは？〜10分で詳しく解説します
        </a>
      </li>
    </ul>
    <br>
    <strong>【文字商標とロゴ商標】</strong><br>
    Cotoboxでは、検索・お申し込みを「文字商標」と「ロゴ商標」の2タイプに分けています。<br>
    文字商標：特許庁指定の「標準文字」で出願します。<br>
    ロゴ商標：jpg形式の画像をご利用いただけます。「ロゴ内の文字」欄は、出願書類には記載されません。<br>
    <ul class="list-disc pl-4">
      <li>
        <a
          class="text-primary underline"
          href="https://support.cotobox.com/faq-wordmark-or-logomark"
          target="_blank"
        >
          文字／ロゴどちらにすべき？
        </a>
      </li>
      <li>
        <a
          class="text-primary underline"
          href="https://support.cotobox.com/faq-registrable-characters"
          target="_blank"
        >
          「！」や「？」などの記号は使えますか？
        </a>
      </li>
    </ul>
    <br>
    <strong>【検索のコツ】</strong><br>
    よみがなは出願書類に記載されません。<br>
    一般的なよみかたをひらがなで入力してください。<br>
    その商品・サービスにおける一般名称は除いたよみがなを入力すると、より広い範囲で検索結果を確認することができます。<br>
    <ul class="list-disc pl-4">
      <li>
        <a
          class="text-primary underline"
          href="https://support.cotobox.com/search-tips"
          target="_blank"
        >
          検索のコツ
        </a>
      </li>
    </ul>
  </div>
</template>
