<template>
  <div>
    <div class="mb-2 text-base font-normal tracking-wider">
      キーワードを入力して区分を絞り込む
    </div>
    <div class="mt-6 items-center justify-between sm:flex">
      <div
        class="border-borderGray flex w-full flex-1 items-center rounded-sm border border-solid"
      >
        <BaseTextInput
          v-model="queryText"
          class="mr-4 flex-1 px-4 py-4 outline-none"
        />
        <div
          v-if="loading"
          class="h-6 w-6 bg-contain bg-center bg-no-repeat"
          :style="{ backgroundImage: `url(${LoadingGif})` }"
        />
        <button
          class="text-primary hover:bg-grayAlpha5 h-[58px] w-20 rounded bg-white font-semibold tracking-wider"
          @click="onKlassFilterButtonClick"
          @@keydown.enter="onKlassFilterButtonClick"
        >
          検索
        </button>
      </div>
      <div
        class="text-grayAlpha60 mt-4 w-48 text-lg font-semibold sm:ml-4 sm:mt-0"
      >
        {{ `選択中の区分数：${klassIdsForSearch.length}区分` }}
      </div>
    </div>
    <div
      v-if="Object.keys(queryResult).length"
      class="mt-4 sm:bg-white"
    >
      <KlassListVue :filter-type="'AI'" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, provide } from 'vue';
import {
  APIClientKey,
  KlassListStoreKey,
  KlassSelectStoreKey,
} from '@/composables/injectionKeys';
import { useKlassFilter } from '@/composables/useKlassFilter';
import { KlassSelectStore } from '@/composables/useKlassSelect';
import KlassListVue from '@/components/search/KlassList.vue';
import { APIClientInterface } from '@/api/APIClientInterface';
import BaseTextInput from '@/components/common/BaseTextInput.vue';
import LoadingGif from '@/assets/images/loading.gif';
import { useKlassList } from '@/composables/useKlassList';
import { useToast } from 'vue-toastification';

const APIClient = inject(APIClientKey) as APIClientInterface;

const klassListModel = useKlassList();
provide(KlassListStoreKey, klassListModel);

const { klassIdsForSearch } = inject(KlassSelectStoreKey) as KlassSelectStore;

const { queryText, queryResult, loading, executeAIFilter } =
  useKlassFilter(APIClient);

const toast = useToast();

async function onKlassFilterButtonClick () {
  if (!queryText.value) {
    return toast.error(
      'キーワードをご入力ください。\nヒント：商標を使用する商品やサービスに関するキーワード（例：アパレル、 動画配信）',
    );
  }

  const succeeded = await executeAIFilter();
  if (!succeeded) {
    return toast.error(
      '区分絞り込みに失敗しました。右下のチャットを開いて最適な区分をご質問ください。',
    );
  }

  klassListModel.source.value = queryResult.value;
}
</script>
