<template>
  <!-- 詳細Modal -->
  <div class="rounded bg-white p-4 sm:w-full md:w-[640px]">
    <!-- 商標 -->
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.standardCharacter') }}
      </div>
      <div
        v-if="applicationStore.trademarkType === 'text_type'"
        class="text-left"
      >
        {{ applicationStore.standardCharacter }}
      </div>
      <div
        v-if="applicationStore.trademarkType === 'image_type'"
        class="mb-2"
      >
        <img
          class="border-grayAlpha20 h-[80px] w-[80px] cursor-pointer border border-solid object-contain shadow-md hover:shadow-xl"
          :src="applicationStore.imageUrl"
          @click="openImage(applicationStore.imageUrl)"
        >
      </div>
    </div>
    <!-- 表示用商標 -->
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.nameOfTradeMarkInfo') }}
      </div>
      <div
        v-if="applicationStore.trademarkType === 'image_type'"
        class="text-left"
      >
        {{ blankToHyphen(applicationStore.nameOfTradeMarkInfo) }}
      </div>
    </div>
    <!-- 称呼 -->
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.pronunciation') }}
      </div>
      <div class="text-left">
        {{ blankToHyphen(applicationStore.pronunciation) }}
      </div>
    </div>
    <!-- 出願番号 -->
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.applicationNumber') }}
      </div>
      <div class="text-left">
        {{ blankToHyphen(applicationStore.applicationNumber) }}
      </div>
    </div>
    <!-- 出願日 -->
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.filingDate') }}
      </div>
      <div class="text-left">
        {{ blankToHyphen(applicationStore.filingDate) }}
      </div>
    </div>
    <!-- 登録番号 -->
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.registrationNumber') }}
      </div>
      <div class="text-left">
        {{ blankToHyphen(applicationStore.registrationNumber) }}
      </div>
    </div>
    <!-- 登録日 -->
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.registrationDate') }}
      </div>
      <div class="text-left">
        {{ blankToHyphen(applicationStore.registrationDate) }}
      </div>
    </div>
    <!-- 納付期間 -->
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.regFeeKindName') }}
      </div>
      <div class="text-left">
        {{ blankToHyphen(applicationStore.regFeeKindName) }}
      </div>
    </div>
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.deadline') }}
      </div>
      <div class="text-left">
        {{ blankToHyphen(applicationStore.deadline) }}
      </div>
    </div>
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.applicant.name') }}
      </div>
      <div class="text-left">
        <span
          v-for="(applicant, index) in applicationStore.applicants"
          :key="index"
        >
          {{ applicant.name }}&nbsp;
        </span>
      </div>
    </div>
    <div class="flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-24 font-bold">
        {{ t('applicationInfo.applicant.address') }}
      </div>
      <div class="text-left">
        <span
          v-for="(applicant, index) in applicationStore.applicants"
          :key="index"
        >
          {{ applicant.address }}&nbsp;
        </span>
      </div>
    </div>
    <div class="mb-4 flex border-b border-solid border-[#e9e9e9] py-3">
      <div class="w-32 font-bold">
        {{ t('applicationInfo.document') }}
      </div>
      <div v-if="applicationStore.documents.length === 0">
        -
      </div>
      <div
        v-for="(document, index) in applicationStore.documents"
        :key="index"
        class="flex items-center"
      >
        <span class="material-symbols-outlined mr-1">receipt_long</span>
        <a
          class="underline"
          :href="document.path"
          target="_blank"
        >{{ document.name }}（{{ document.code }}）</a>
      </div>
    </div>
    <!-- 区分 -->
    <div
      v-for="(klass, index) in applicationStore.klasses"
      :key="index"
      class="mb-4 flex justify-around"
    >
      <div class="w-full">
        <div class="bg-bgGray flex rounded py-4">
          <div
            class="border-borderGray flex w-24 items-center border-r border-solid pl-2 font-bold"
          >
            {{ t('applicationInfo.class') }} {{ klass.klassNo }}
          </div>
          <div class="flex h-full w-full items-center px-2">
            {{ klass.goodsAndService }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./詳細Modal -->
</template>

<script setup lang="ts">
import { useApplicationStore } from '@/composables/useApplicationStore';
import { blankToHyphen } from '@/lib/util/stringUtils';
import { useI18n } from 'vue-i18n';
import { ja } from '@/i18n/pages/app/applications/ja';

const applicationStore = useApplicationStore();
const { t } = useI18n({ messages: ja });

const openImage = (url: string) => {
  window.open(url);
};
</script>
