<template>
  <div :class="`${$options.name}`">
    <div :class="`${$options.name}__description`">
      <i18n-t
        keypath="descriptionText"
        tag="p"
      >
        <strong>{{ t('descriptionStrong') }}</strong>
      </i18n-t>
    </div>
    <div class="mb-6">
      <div :class="`${$options.name}__rowTitle`">
        {{ t('productName') }}
      </div>
      <FormTextInput
        v-model="state.productName"
        class="mb-8 text-base"
        :name="'productName'"
        :placeholder="'自社デザインのスマホケース'"
        :autocomplete="'off'"
        :disabled="false"
        :required="false"
        :shows-required-label="false"
        :shows-error="false"
      />
    </div>
    <div :class="`${$options.name}__row`">
      <div :class="`${$options.name}__rowTitle`">
        {{ t('urlTitle') }}
        <span :class="`${$options.name}__rowTitleTag`">
          {{ globalT('common.optional') }}
        </span>
      </div>
      <FormTextInput
        v-model="state.url"
        :name="'url.placeholder'"
        :placeholder="t('urlPlaceholder')"
        :style="{ fontSize: '14px' }"
        :required="true"
        :shows-required-label="false"
        :shows-error="false"
      />
    </div>
    <div :class="`${$options.name}__row`">
      <div :class="`${$options.name}__rowTitle`">
        {{ t('interviewTitle') }}
        <span :class="`${$options.name}__rowTitleTag`">
          {{ globalT('common.optional') }}
        </span>
      </div>
      <div
        :class="`${$options.name}__checkList`"
        class="mb-8"
      >
        <div
          v-for="question in questions"
          :key="question.id"
          class="mb-4"
        >
          <div class="flex cursor-pointer items-center">
            <BaseCheckbox
              :id="`q_${question.id}`"
              :name="`q_${question.id}`"
              :model-value="
                checkedInterviewNumbers.includes(
                  `${question.id}` as '1' | '2' | '4' | '5' | '6',
                )
              "
              @update:model-value="
                onInterviewCheck(
                  `${question.id}` as '1' | '2' | '4' | '5' | '6',
                )
              "
            />
            <label
              :for="`q_${question.id}`"
              class="ml-2 cursor-pointer text-sm tracking-wider"
            >
              {{ question.text }}
            </label>
          </div>
        </div>
      </div>
      <div :class="`${$options.name}__rowTitle`">
        ご不明な点や現在の状況など、記載例を参考にご入力ください。
        <span :class="`${$options.name}__rowTitleTag`">
          {{ globalT('common.optional') }}
        </span>
      </div>
      <FormTextArea
        v-model="state.comment"
        :placeholder="''"
        :height="120"
      />
      <div class="text-grayAlpha80 mt-2 whitespace-pre-line text-sm">
        {{ t('commentExample') }}
      </div>
      <div class="bg-primaryOrange5 mt-4 rounded p-2 text-sm">
        出願人を代理してお申し込みされている場合は、下記項目を必ずご記載ください。<br>
        ⑴ 代理でお申し込みをされている方の会社名、担当者名、連絡先<br>
        ⑵ 出願人（権利者）となる方のご連絡先<br>
        ⑶ 出願人とのご関係
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplyFormKey } from '@/composables/injectionKeys';
import { ApplyFormStore } from '@/composables/useApplyForm';
import BaseCheckbox from '@/components/common/BaseCheckbox.vue';

export default defineComponent({
  name: 'ApplyInterview',
  components: {
    BaseCheckbox,
  },
  setup () {
    const { t } = useI18n<object, 'ja' | 'en' | 'zh'>({
      messages: {
        ja: {
          descriptionText:
            '詳しくご入力いただくと弁理士の確認に役立ち、{0}につながります。',
          descriptionStrong: '出願までの時間短縮',
          note: '弁理士とのご相談を希望される場合は、安心フルサポートオプションが必要です',
          productName: '商標の事業内容および商品（サービス）を教えてください。',
          urlTitle:
            '商標が表示されているサイト（ウェブサイト、SNS，ECサイトなど）',
          urlPlaceholder: 'https://cotobox.com',
          interviewTitle:
            '出願依頼にあたり、該当する項目にチェックを入れてください。（複数可）',
          interviewOptions1: '特許庁に出願(商標・特許・意匠)したことがある',
          interviewOptions2: 'とりあえず1区分だけを選択',
          interviewOptions4: '追加費用を払うので、他の区分の提案を希望',
          interviewOptions5: '出願商標を文字とロゴで迷っている',
          interviewOptions6: '商標を既に使用している',
          commentExample:
            '記載例\n' +
            '・海外への登録を考えている。国は、米国、中国、インド。\n' +
            '・◯ヶ月後に住所変更の予定がある。',
        },
        en: {
          descriptionText: 'Input will lead to {0}.',
          descriptionStrong:
            'Helps patent attorneys confirm requirements for early examination, appropriate classification, etc., and saves time',
          note: 'If you wish to consult with a patent attorney, we can provide full support.',
          productName:
            'What is the nature of the business and products of the trademark for which you are applying?',
          urlTitle:
            'Websites displaying the trademark (websites, SNS, e-commerce sites, etc.)',
          urlPlaceholder: 'https://cotobox.com',
          interviewTitle:
            'Please contact us with any requests or questions you may have in requesting an application.',
          interviewOptions1:
            'Have filed an application (trademark, patent, or design) with the Patent Office',
          interviewOptions2: 'Select only one category for now.',
          interviewOptions4:
            'I am willing to pay the additional cost and would like a proposal for another classification.',
          interviewOptions5:
            'I\'m confused between the letters and the logo for the applied trademark.',
          interviewOptions6: 'Trademark is already in use',
          commentExample:
            'Example of description\n' +
            'The business is 00.\n' +
            'I am considering registering overseas. The countries are the U.S., China, and India.\n' +
            'Someone else is using my trademark and I want them to stop immediately.',
        },
        zh: {
          descriptionText: '输入的结果是{0}。',
          descriptionStrong:
            '帮助专利代理人检查早期审查、适当分类等要求，并节省时间。',
          note: '如果你希望向专利律师咨询，我们可以为你提供全面的支持，让你放心。',
          productName: '你所申请的商标的业务和产品的性质是什么？',
          urlTitle: '显示商标的网站（网站、社交网站、电子商务网站等）。',
          urlPlaceholder: 'https://cotobox.com',
          interviewTitle: '在要求申请时，请让我们知道你的要求和问题。',
          interviewOptions1: '已向专利局提出申请（商标、专利或设计）。',
          interviewOptions2: '暂时只选择一个类别。',
          interviewOptions4: '我愿意支付额外费用，并希望收到其他类别的提案。',
          interviewOptions5: '我对所申请的商标的字母和标志感到困惑。',
          interviewOptions6: '已经在使用的商标。',
          commentExample:
            '例子描述。\n业务性质为00。\n...我正在考虑在国外注册。国家是美国、中国和印度。\n别人在使用我的商标，我希望他们立即停止。',
        },
      },
    });

    const { t: globalT } = useI18n({ useScope: 'global' });

    const { state } = inject(ApplyFormKey) as ApplyFormStore;

    const questions = [
      { id: '1', text: t('interviewOptions1') },
      { id: '2', text: t('interviewOptions2') },
      { id: '4', text: t('interviewOptions4') },
      { id: '5', text: t('interviewOptions5') },
      { id: '6', text: t('interviewOptions6') },
    ];

    const checkedInterviewNumbers = computed(() =>
      (Object.keys(state.interview) as ['1', '2', '4', '5', '6']).filter(
        (interviewNumber) => state.interview[interviewNumber] === true,
      ),
    );

    function onInterviewCheck (interviewNumber: '1' | '2' | '4' | '5' | '6') {
      state.interview[interviewNumber] = !state.interview[interviewNumber];
    }

    return {
      t,
      globalT,
      state,
      questions,
      checkedInterviewNumbers,
      onInterviewCheck,
    };
  },
});
</script>

<style lang="scss" scoped>
.ApplyInterview {
  &__description {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;

    strong {
      color: $primary-color;
      font-weight: 700;
    }
  }

  &__note {
    background-color: #fff3e0;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__row {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__rowTitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__rowTitleTag {
    font-size: 10px;
    padding: 3px 4px;
    background-color: #e7e7e7;
    color: #555;
    border-radius: 2px;
    text-align: center;
    margin-left: 8px;
    font-weight: 200;
  }

  &__rowHelperText {
    font-size: 14px;
    color: #222;
    font-weight: 200;
    margin-bottom: 8px;
    line-height: 22px;
    white-space: pre-line;
  }

  &__checkListItem {
    margin-bottom: 16px;
  }

  &__checkListItemCheckboxLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__checkListItemTitle {
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 22px;
    margin-left: 8px;
  }

  &__textarea {
    width: 100%;
    height: 128px;
    margin-bottom: 16px;
  }
}
</style>
