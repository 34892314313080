<template>
  <teleport to="body">
    <transition>
      <div v-show="isVisible">
        <div
          id="modal"
          class="bg-grayAlpha60 fixed bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center"
          :class="disableOverlayClose ? 'cursor-default' : 'cursor-pointer'"
          @click="onOverlayClick"
        />
        <div
          v-show="isVisible"
          class="fixed bottom-0 left-6 right-6 top-0 m-auto table max-w-[90vw] rounded-lg bg-white sm:left-0 sm:right-0 sm:max-w-[620px]"
        >
          <button
            class="absolute -top-8 right-0 font-bold text-white"
            @click="$emit('close')"
          >
            <span
              v-if="!closeButtonLabel"
              class="material-icons"
            > close </span>
            <span
              v-else
              class="text-sm tracking-wider"
            >
              {{ closeButtonLabel }}
            </span>
          </button>
          <div
            v-if="isVisible"
            class="max-h-[85svh] overflow-y-auto"
          >
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup lang="ts">
const props = defineProps<{
  // モーダルの表示非表示
  isVisible: boolean;
  // 閉じるボタンのラベル（デフォルトはxアイコン）
  closeButtonLabel?: string;
  // 黒背景クリックで閉じれなくする
  disableOverlayClose?: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function onOverlayClick () {
  if (props.disableOverlayClose) {
    return;
  }

  emit('close');
}
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
