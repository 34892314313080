<template>
  <div
    :id="`${id}-visible`"
    class="inline-block"
    @click="onChange"
  >
    <input
      :id="id"
      type="checkbox"
      class="checkbox"
      :name="name"
      :checked="modelValue"
    >
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  id: string;
  name: string;
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void;
}>();

const checked = computed(() => props.modelValue);

function onChange (_e: Event) {
  emit('update:model-value', !checked.value);
}
</script>

<style lang="scss" scoped>
  .checkbox {
    appearance: none;
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    position: relative;
    border: 1px solid #ccc !important;
    border-radius: 2px;
    background-color: #fff;
  }
  .checkbox:checked {
    background-color: rgba(230, 113, 61, 1);
    border: none !important;
    &:after {
      content: "";
      position: absolute;
      top: 20%;
      left: 40%;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
</style>
