<template>
  <li class="w-full items-start bg-white px-4 py-2 sm:flex">
    <!-- ロゴ -->
    <div class="mb-2 sm:mb-2 sm:mr-4 sm:w-40">
      <NotificationSourceLogo
        class="mt-1 h-12 w-40 sm:w-full"
        :item="item"
      />
    </div>
    <div class="sm:flex-1">
      <!-- 投稿日 -->
      <time class="text-grayAlpha60 mb-2 text-sm">
        {{ props.item.createdDate }}
      </time>
      <!-- タイトル -->
      <div class="text-grayAlpha80 mb-2 font-bold">
        {{ item.title }}
      </div>
      <!-- 本文 -->
      <div class="text-grayAlpha60 line-clamp line-clamp-3 text-sm">
        {{ bodyText }}
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { NotificationItem } from '../types/notification-item.types';
import NotificationSourceLogo from './NotificationSourceLogo.vue';

const props = defineProps<{
  item: NotificationItem;
}>();

const bodyText = computed(() => props.item.body.replace(/<[^>]*>/g, ''));
</script>
