import { reactive, computed } from 'vue';
import { TrademarkType, GoodsServiceIds, KlassId } from '@/types';
import { APIClientInterface } from '@/api/APIClientInterface';
import { User } from './useUserStore';

/**
 * 申込情報の管理
 * @note `state`変数に申込の入力情報(弁理士への伝達事項やオプションの有無など)を保持し、`submit`メソッドで送信するだけの状態管理
 */
export const useApplyForm = ({
  trademarkType,
  standardCharacter,
  inputtedYomi,
  imageDataURL,
  imageToken,
  imageText,
  klassesForSubmit,
  apiClient,
}: {
  trademarkType: TrademarkType;
  standardCharacter?: string;
  inputtedYomi: string;
  imageDataURL?: string;
  imageToken?: string;
  imageText?: string;
  klassesForSubmit: GoodsServiceIds;
  apiClient: APIClientInterface;
}) => {
  const state = reactive<{
    premiumPlan: boolean;
    speedPlan: boolean;
    url: string;
    productName: string;
    comment: string;
    interview: {
      [key in '1' | '2' | '3' | '4' | '5' | '6' | '7']: boolean;
    };
    termsAgreed: boolean;
  }>({
    premiumPlan: false,
    speedPlan: false,
    url: '',
    productName: '',
    comment: '',
    interview: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
    },
    termsAgreed: false,
  });

  const klassCount = computed(() => Object.keys(klassesForSubmit).length);

  async function submit (user: User) {
    const paymentId = await apiClient.submitTrademarkApplication({
      trademarkType,
      ...(trademarkType === 'text' && { standardCharacter }),
      ...(trademarkType === 'image' &&
        imageText && { standardCharacter: imageText }),
      inputtedYomi,
      ...(trademarkType === 'image' && { imageToken: imageToken! }),
      selectedGoodsServiceIds: (Object.keys(klassesForSubmit) as KlassId[])
        .map((klassId) => klassesForSubmit[klassId]!)
        .flat(),
      premiumPlan: state.premiumPlan,
      speedPlan: state.speedPlan,
      url: state.url,
      interview: state.interview,
      productName: state.productName,
      comment: state.comment,
      address: user.address,
      phoneNumber: user.phoneNumber,
      termsAgreed: state.termsAgreed,
    });
    return paymentId;
  }

  function clearState () {
    Object.assign(state, {
      premiumPlan: false,
      speedPlan: false,
      url: '',
      comment: '',
      interview: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
      },
      termsAgreed: false,
    });
  }

  return {
    state,
    klassCount,
    submit,
    trademarkType,
    ...(trademarkType === 'text' && { standardCharacter: standardCharacter! }),
    inputtedYomi,
    ...(trademarkType === 'image' && { imageDataURL: imageDataURL! }),
    ...(trademarkType === 'image' && { imageToken: imageToken! }),
    ...(trademarkType === 'image' && { imageText: imageText! }),
    klassesForSubmit,
    clearState,
  };
};

export type ApplyFormStore = ReturnType<typeof useApplyForm>;
